import React, { useState, useEffect, FC, useMemo } from 'react';
import ReactDatetime from 'react-datetime';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import ApiCaller from '../../../../lib/ApiCaller';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import ExamScheduleCollection from '../../../../lib/common/models/examScheduleCollection';
import ExamSchedule from '../../../../lib/common/models/examSchedule';
import ExamScheduleApi from '../../../../api/ExamScheduleApi';
import { RRuleSet, rrulestr } from 'rrule';
import Module from '../../../../lib/common/models/module';
import Select from 'react-select';

interface IFilteredDateProps {
  date: Date;
  setDate: Function;
  module?: Module;
  setException: Function;
}

const FilteredDateComponent: FC<IFilteredDateProps> = ({
  date,
  setDate,
  module,
  setException,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [rruleSet, setRruleSet] = useState<RRuleSet>(new RRuleSet());
  const [availableTimes, setAvailableTimes] = useState<{ [key: string]: string[] }>({});
  const [noDatesAvailable, setNoDatesAvailable] = useState<boolean>(false);

  useEffect(() => {
    const scheduleApi = new ExamScheduleApi(new ApiCaller(loggedUser.token));
    if (module?._id) {
      scheduleApi.getScheduleByModule(module._id).then((schedules) => {
        setRruleSet(parseRules(schedules));
      });
    }
  }, [module]);

  useEffect(() => {
    const allDates = rruleSet.all();
    const dates = allDates
      .reduce((dates: Date[], currentDate) => {
        const dateSlots = availableTimes[getTimeSlotKey(currentDate)];
        if (dateSlots?.length) {
          dateSlots.map((timeSlot) => {
            const values = timeSlot.split(':');
            const newDate = new Date(currentDate);
            newDate.setHours(parseInt(values[0]), parseInt(values[1]), 0);
            dates = [...dates, newDate];
          });
        }
        return dates;
      }, [])
      .filter((date) => moment(date).isAfter(moment().add(72, 'hours')));
    if (dates && dates.length > 0) {
      let defaultDate = new Date(date);
      defaultDate.setDate(dates[0].getDate());
      defaultDate.setMonth(dates[0].getMonth());
      defaultDate.setFullYear(dates[0].getFullYear());
      const dateSlots = availableTimes[getTimeSlotKey(defaultDate)];
      if (dateSlots?.length) {
        for (const item of dateSlots) {
          const dateWithTimeSlot = updateTime(item, defaultDate);
          if (moment(dateWithTimeSlot).isAfter(moment().add(72, 'hours'))) {
            defaultDate = dateWithTimeSlot;
            break;
          }
        }
      }
      setDate(defaultDate);
      setNoDatesAvailable(false);
      setException(false);
    } else {
      setNoDatesAvailable(true);
      setException(true);
    }
  }, [rruleSet, availableTimes]);

  const getTimeSlotKey = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  }

  const parseRules = (scheduleCollection: ExamScheduleCollection): RRuleSet => {
    const rruleSet = new RRuleSet();
    const slots: { [key: string]: string[] } = {};
    scheduleCollection.schedules.map((schedule: ExamSchedule) => {
      const rule = rrulestr(schedule.rrule);
      rule.all().map((date) => {
        const key = getTimeSlotKey(date);
        slots[key] = slots[key]
          ? Array.from(new Set([...slots[key], ...schedule.timeSlots])).sort()
          : schedule.timeSlots;
      });
      rruleSet.rrule(rule);
    });
    setAvailableTimes(slots);
    return rruleSet;
  };

  const isAfterCutoffDate = (currentDate: any) => {
    const dateSlots = availableTimes[getTimeSlotKey(new Date(currentDate.toString()))];
    const lastTimeSlot = dateSlots
      ? dateSlots[dateSlots.length - 1].split(':')
      : ['00', '00'];
    const currentDateWithTime = moment(currentDate);
    currentDateWithTime.set({
      hours: parseInt(lastTimeSlot[0]),
      minutes: parseInt(lastTimeSlot[1]),
    });
    return currentDateWithTime.isAfter(moment().add(72, 'hours'));
  };

  const dateInRange = (currentDate: any) => {
    const validDays = rruleSet.all();
    const validMoments = validDays.map((day) => {
      const momentDay = moment(day);
      return momentDay.isSame(currentDate, 'day');
    });
    return validMoments.includes(true);
  };

  const validDate = (currentDate: any) => {
    return isAfterCutoffDate(currentDate) && dateInRange(currentDate);
  };

  const onTimeChange = (e: any) => {
    setDate(updateTime(e.label, date));
  };

  const updateTime = (timeStr: string, currentDate: Date) => {
    const values = timeStr.split(':');
    const newDate = new Date(currentDate);
    newDate.setHours(parseInt(values[0]), parseInt(values[1]), 0);
    return newDate;
  };

  const timeSlotsOptions = useMemo(() => {
    const dateSlots = availableTimes[getTimeSlotKey(date)];
    return dateSlots?.map((item) => {
      return {
        value: item,
        label: item,
        disabled: moment(updateTime(item, date)).isBefore(
          moment().add(72, 'hours'),
        ),
      };
    })
  }, [date]);

  return (
    <>
      {!noDatesAvailable && (
        <>
          <ReactDatetime
            isValidDate={validDate}
            inputProps={{
              className: 'form-control',
              placeholder: 'Pick Date',
              name: 'Date of exam',
              readOnly: true,
            }}
            timeFormat={false}
            dateFormat={'DD-MM-YYYY'}
            timeConstraints={{
              hours: { min: 0, max: 23, step: 1 },
              minutes: { min: 0, max: 59, step: 15 },
            }}
            onChange={(e: string | moment.Moment) => {
              const dateSlots = availableTimes[getTimeSlotKey(new Date(e.toString()))];
              if (dateSlots?.length) {
                for (const item of dateSlots) {
                  const dateWithTimeSlot = updateTime(
                    item,
                    new Date(e.toString()),
                  );
                  if (
                    moment(dateWithTimeSlot).isAfter(moment().add(72, 'hours'))
                  ) {
                    setDate(dateWithTimeSlot);
                    break;
                  }
                }
              } else {
                setDate(e);
              }
            }}
            value={date}
          />
          <Select
            className="react-select primary"
            classNamePrefix="react-select"
            name="scheduleTime"
            value={{
              value: moment(date).format('HH:mm'),
              label: moment(date).format('HH:mm'),
              disabled: false,
            }}
            onChange={onTimeChange}
            options={timeSlotsOptions}
            isOptionDisabled={(option) => option.disabled}
            placeholder="Time slot"
          />
          {!module?._id && (
            <label className="error">
              Select module to see available dates.
            </label>
          )}
        </>
      )}
      {noDatesAvailable && (
        <Select
          className="react-select primary"
          classNamePrefix="react-select"
          name="noDatesAvailable"
          value={null}
          onChange={() => {}}
          options={[]}
          placeholder="No dates available for this module"
        />
      )}
    </>
  );
};

export default FilteredDateComponent;
