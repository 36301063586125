import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import { ToastContainer, toast } from 'react-toastify';

import NavigationExamCollection from '../../../../lib/common/models/navigationExamCollection';
import NavigationExamApi from '../../../../api/NavigationExamApi';
import ApiCaller from '../../../../lib/ApiCaller';
import {
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import Settings from '../../../../lib/settings';
import { useHistory } from 'react-router-dom';

import MarkerApi from '../../../../api/MarkerApi';
import {
  setCandidates,
  setHistoricalGrid,
  setMarkerGrid,
  setQuestions,
  setResults,
} from 'store/actions/marker/markerActions';

import { UserTypesLabels } from "../../../../lib/common/models/user";
import { stateListNames } from 'store/reducers/listsReducer';
import { useTableControls, useClearListStateOnPageChange } from 'hooks';
import 'react-toastify/dist/ReactToastify.css';

const   NavigationExamPending: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const [exams, updateExams] = React.useState<NavigationExamCollection>(
    new NavigationExamCollection([]),
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const navigationExamApi = new NavigationExamApi(
    new ApiCaller(loggedUser.token),
  );

  const listName = stateListNames.pendingExams;
  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  useClearListStateOnPageChange(listName, [
    "/navigation_exam/marked",
    "/navigation_exam/marks",
  ]);

  useEffect(() => {
    if (loggedUser.type==UserTypesLabels.mcaExaminer) {
      navigationExamApi.getMarkedMCAPendingExams().then((exams) => {
        exams && updateExams(exams);
      });
    } else if (loggedUser.type==UserTypesLabels.IAMIExamAdmin) {
      navigationExamApi.getMarkedExamAdminPendingExams().then((exams) => {
        exams && updateExams(exams);
      });
    } else {
      navigationExamApi.getMarkedPendingExams().then((exams) => {
        exams && updateExams(exams);
      });
    }

  }, []);


  const getCustomUserColumns = () => {
    let cols: any[];
    switch (loggedUser.type) {
      case UserTypesLabels.admin:
      case UserTypesLabels.IAMIExamAdmin:
        cols = [
          {
            Header: '1st Marker',
            accessor: (d: any) => d.firstMarker ? d.firstMarker.name : '-',
            sortable: true,
          },
          {
            Header: '2nd Marker',
            accessor: (d: any) => d.secondMarker ? d.secondMarker.name : '-',
            sortable: true,
          },
        ]
        break;
      default:
        cols = []
    }
    return cols;
  }
  const getCustomUserColumnsPost = () => {
    let cols: any[];
    switch (loggedUser.type) {
      case UserTypesLabels.admin:
      case UserTypesLabels.IAMIExamAdmin:
        cols = [{ Header: 'Status', accessor: (d: any) => d.status, sortable: true, },]
        break;
      default:
        cols = []
    }
    return cols;
  }

  const getColumns = () => {
    return [
      ...getCustomUserColumns(),
      {
        Header: 'EXAM NUMBER',
        accessor: (d: any) => (d ? d.getExamNumber() : '-'),
        sortable: true,
      },
      {
        Header: 'EXAM CENTRE',
        accessor: (d: any) =>
          d.examCenterId
            ? `(${d.examCenterId.code}) ${d.examCenterId.name}`
            : '',
        sortable: true,
      },
      {
        Header: 'DATE',
        accessor: (d: any) => formatDateTime(d.date),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
      },
      {
        Header: 'ENROLLED CANDIDATES',
        accessor: (d: any) => d.candidates.length,
        sortable: true,
      },
      {
        Header: 'SCRIPT INTENTION',
        accessor: 'scriptIntention',
        sortable: true,
      },
      ...getCustomUserColumnsPost(),
      {
        Header: 'ACTIONS',
        Cell: (row: any) => {
          return (
            <div>
              <Button
                className="btn-round"
                color="info"
                onClick={() => {
                  prepareMark(row.row.original);
                }}>
                OPEN
              </Button>
            </div>
          );
        },
      },
    ];
  };

  const prepareMark = (exam: any) => {
    if (exam.candidates.length == 0) {
      toast.error('There are not candidates');
      return;
    }
    Settings.setCurrentNavigationExam(exam);
    const markerApi = new MarkerApi(new ApiCaller(loggedUser.token));
    markerApi.getMarkerInitialData(exam.id).then((msg: any) => {
      dispatch(setCandidates(msg.candidates));
      dispatch(setQuestions(msg.questions));
      dispatch(setMarkerGrid(msg.grid));
      dispatch(setResults(msg.results));
      if (loggedUser.hasMCARole() && msg.historicalGrid) {
        dispatch(setHistoricalGrid(msg.historicalGrid));
      }
      let destPath = loggedUser.hasMCARole() ? `/navigation_exam/marksmca`:`/navigation_exam/marks`;
      history.push(`/${layout}${destPath}`);
    });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Pending to mark</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={exams?.navigationExams}
                  columns={getColumns()}
                  initialFilters={filters}
                  onChangeFilters={onChangeFilters}
                  initialSorting={sorting}
                  onChangeSorting={onChangeSorting}
                  initialPagination={pagination}
                  onChangePagination={onChangePagination}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default NavigationExamPending;
