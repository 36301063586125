import Settings from 'lib/settings';
import React from 'react';
//@ts-ignore
import FileViewer from 'react-file-viewer';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'reactstrap';
import { downloadDocument } from 'shared/utils/ApiCommands';
import { AppState } from 'store/store';
import NZDocument from '../../../../lib/common/models/nzDocument';

import "./style.css";

type DocumentPreviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  document: NZDocument;
}

const DocumentPreviewModal = ({
  isOpen,
  onClose,
  title,
  document,
}: DocumentPreviewModalProps) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      style={{ maxWidth: '800px', width: '100%' }}>
      <div className="modal-header">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onClose}
        >
          <i className="nc-icon nc-simple-remove" />
        </button>
        <div className='preview-modal-header'>

          <h5 className="card-title">{title}</h5>
          <Button
            className="btn-round"
            color="success"
            size="sm"
            title="Download file"
            onClick={() => {
              downloadDocument(
                document.fileHash,
                document.displayName,
                loggedUser.token
              )
            }}
          >
            <i className="nc-icon nc-cloud-download-93" />
          </Button>
        </div>
      </div>
      <div className="modal-body">
        <FileViewer
          fileType={`${document.mimeType.split('/')[1]}`}
          filePath={`${Settings.getApiURL()}/v1/documents/document/download/${document.fileHash
            }?token=${loggedUser.token}`}
          onError={() => {
            console.log('Error loading PDF');
          }}
        />
      </div>
    </Modal>
  )
}

export default DocumentPreviewModal;
