import ApiCaller from '../lib/ApiCaller';
import moment from 'moment';
import { AvgMarkPerMarkerReportType } from '../lib/common/types/avgMarkPerMarkerReport';
import { IQuestionStat } from '../lib/common/reports/questionBankReport';

class DashboardApi {
  static getPassRateReport(
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    token?: string,
    params?: any,
  ): Promise<any> {
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';
    const apiCaller = new ApiCaller(token);
    let url = `/v1/dashboard/passrate?startDate=${dateStart}&endDate=${dateEnd}`;
    if (params) {
      Object.keys(params).forEach((key) => {
        url = url.concat(`&${key}=${params[key]}`);
      });
    }
    return apiCaller
      .call(url, 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getQuestionBankReport(
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    token?: string,
    params?: any,
  ): Promise<IQuestionStat[]> {
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';
    const apiCaller = new ApiCaller(token);
    let url = `/v1/dashboard/questionBankAnalytics?startDate=${dateStart}&endDate=${dateEnd}`;
    if (params) {
      Object.keys(params).forEach((key) => {
        url = url.concat(`&${key}=${params[key]}`);
      });
    }
    return apiCaller
      .call(url, 'GET')
      .then((data) => {
        if (data.message) {
          throw Error(data.message);
        }
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getExamCentresMarksReport(
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    token?: string,
    params?: any,
  ): Promise<any> {
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';
    const apiCaller = new ApiCaller(token);
    let url = `/v1/dashboard/examCentreMarksAnalytics?startDate=${dateStart}&endDate=${dateEnd}`;
    if (params) {
      Object.keys(params).forEach((key) => {
        url = url.concat(`&${key}=${params[key]}`);
      });
    }
    return apiCaller
      .call(url, 'GET')
      .then((data) => {
        if (data.message) {
          throw Error(data.message);
        }
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getExamCandidatesReport(
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    token?: string,
    params?: any,
  ): Promise<any> {
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';
    const apiCaller = new ApiCaller(token);
    let url = `/v1/dashboard/examcandidates?startDate=${dateStart}&endDate=${dateEnd}`;
    if (params) {
      Object.keys(params).forEach((key) => {
        url = url.concat(`&${key}=${params[key]}`);
      });
    }
    return apiCaller
      .call(url, 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getQuestionsPerModuleAndTopicReport(token?: string): Promise<any> {
    const apiCaller = new ApiCaller(token);
    return apiCaller
      .call('/v1/dashboard/questionsPerModuleTopic', 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  static getActiveQuestionsPercentageReport(token?: string): Promise<any> {
    const apiCaller = new ApiCaller(token);
    return apiCaller
      .call('/v1/dashboard/questionsPercentage', 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  static getQuestionsUsageReport(
    startDate?: moment.Moment | undefined,
    endDate?: moment.Moment | undefined,
    token?: string,
    params?: any,
  ): Promise<any> {
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';
    const apiCaller = new ApiCaller(token);
    let url;
    if (startDate && endDate) {
      url = `/v1/dashboard/questionsUsage?startDate=${dateStart}&endDate=${dateEnd}`;
    } else {
      url = `/v1/dashboard/questionsUsage`;
    }
    return apiCaller
      .call(url, 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  static getQuestionsPerWriterReport(token?: string): Promise<any> {
    const apiCaller = new ApiCaller(token);
    return apiCaller
      .call('/v1/dashboard/questionsPerWriter', 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  static getQuestionsPipelineReport(token?: string): Promise<any> {
    const apiCaller = new ApiCaller(token);
    return apiCaller
      .call('/v1/dashboard/questionsPipeline', 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  static async getAvgMarkPerMarkerReport(
    token?: string,
  ): Promise<AvgMarkPerMarkerReportType> {
    const apiCaller = new ApiCaller(token);
    return apiCaller
      .call('/v1/dashboard/avgMarkPerMarker', 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  static async getAvgMarkPerQuestionReport(
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    token?: string,
    params?: any,
  ): Promise<any> {
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';
    const apiCaller = new ApiCaller(token);
    let url = `/v1/dashboard/avgMarkPerQuestion?startDate=${dateStart}&endDate=${dateEnd}`;
    if (params) {
      Object.keys(params).forEach((key) => {
        url = url.concat(`&${key}=${params[key]}`);
      });
    }
    return apiCaller
      .call(url, 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        throw Error(err);
      });
  }
}

export default DashboardApi;
