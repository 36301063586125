import Candidate from "./candidate";
import Syllabus from "./syllabus";
import Module from "./module";
import ExamCenter from "./examCenter";
import CandidateExamMark from "./candidateExamMark";
import NavigationExam from "./navigationExam";

export interface IGradeDetails {
    [key: string]: { result: string, grade?: number }
}

interface ExamGradeInfo {
    _id?: string,
    candidateId: any,
    syllabus: any,
    module: any,
    date: string,
    examId: any,
    examCentre: any,
    examCentreId: any,
    result: string,
    grade: number,
    details?: IGradeDetails,
    certificateId?: any
    isCertificateSent?: boolean;
}

class ExamGrade {
    _id?: string;
    candidateId: any;
    syllabus: any;
    module: any;
    date: string;
    examId: any;
    examCentre: any;
    examCentreId: any;
    result: string;
    grade?: number;
    details?: IGradeDetails;
    certificateId?: string;
    isCertificateSent?: boolean;

    constructor(examGradeInfo: ExamGradeInfo) {
        this._id = examGradeInfo._id
        this.candidateId = examGradeInfo.candidateId;
        this.syllabus = examGradeInfo.syllabus;
        this.module = examGradeInfo.module;
        this.date = examGradeInfo.date;
        this.examId = examGradeInfo.examId;
        this.examCentre = examGradeInfo.examCentre;
        this.examCentreId = examGradeInfo.examCentreId;
        this.result = examGradeInfo.result;
        this.grade = examGradeInfo.grade;
        this.details = examGradeInfo.details;
        this.certificateId = examGradeInfo.certificateId;
        this.isCertificateSent = examGradeInfo.isCertificateSent;
    }

    load(data: any): ExamGrade {
        return new ExamGrade(data);
    }

    toObject(): any {
        return {
            _id: this._id,
            candidateId: this.candidateId,
            syllabus: this.syllabus,
            module: this.module,
            date: this.date,
            examId: this.examId,
            examCentre: this.examCentre,
            examCentreId: this.examCentreId,
            result: this.result,
            grade: this.grade,
            details: this.details,
            certificateId: this.certificateId,
            isCertificateSent: this.isCertificateSent,
        }
    }

    fromDataModel(data: any): ExamGrade {
        data.candidateId = data.candidateId ? new Candidate(data.candidateId) : null;
        data.syllabus = data.syllabus ? Syllabus.prototype.toDto(data.syllabus): null;
        data.module = data.module ? Module.prototype.toDto(data.module): null;
        data.examCentre = data.examCentreId ? new ExamCenter(data.examCentreId) : null;
        // data.examId = data.examId ? NavigationExam.prototype.fromDataModel(data.examId) : null;

        return new ExamGrade(data);
    }

    isCertificateEligible(): boolean {
        return this.result === "PASS";
    }


    isInBorderlineTransition(): boolean {
        return !(this.result === "PASS" || this.result === "FAIL");
    }

    static fromCandidateExamMark(candidateExamMark: CandidateExamMark, navigationExam: NavigationExam) {
        const obj = {
            candidateId: candidateExamMark.candidateId,
            syllabus: navigationExam.syllabus.id,
            module: navigationExam.module.id,
            date: new Date().toUTCString(),
            examId: navigationExam.id,
            examCentre: navigationExam.examCenterId?.name,
            examCentreId: navigationExam.examCenterId,
            result: 'BORDERLINE',
            details: undefined,
            grade:0,
            isCertificateSent: false,
        };
        return new ExamGrade(obj);
    }

}

export default ExamGrade;
