import socketIOClient from "socket.io-client";
import Settings from '../lib/settings';
import {INotificationEvent} from "../views/presenters/NotificationPresenter";

class SocketConn {

    socket: SocketIOClient.Socket;

    constructor(private user:any, private notifyEngine:INotificationEvent) {
        this.socket = socketIOClient(Settings.getSocketURL());
        this.connect();
    }

    connect() {
        this.socket.emit("doAuth", this.user.token);
        this.socket.emit("user-in", this.user);
        this.socket.on('notification', (msg: any) => this.notifyEngine.onMessage(msg.message));
        this.socket.on('user-in', () => this.notifyEngine.onUserIn());
    }

    close() {
        this.socket.close();
    }

}


export default SocketConn;
