import React from "react";
import { Bar} from 'react-chartjs-2';
import { BarCharProps } from '../../types';
import { getPercentage } from '../../helpers'

const BarGraphic: React.FC<BarCharProps> = ({
    data
}) => {
    let porcentageFail = [];
    let porcentagePassed = [];
    for(let i = 0; i < data.pass.length; i++)
    {
        porcentagePassed.push(Math.round(getPercentage(data.pass[i] ,data.pass[i] + data.fail[i])));
    }
    for(let i = 0; i < data.fail.length; i++)
    {
        porcentageFail.push(Math.round(getPercentage(data.fail[i] ,data.pass[i] + data.fail[i])));
    }
    data.porcentageFail = porcentageFail;
    data.porcentagePassed = porcentagePassed;
    return (
<Bar
                        data={{
                            labels: [...data.code],
                            datasets: [
                                {
                                    label: ['Pass'],
                                    data: [...data.pass],
                                    backgroundColor: '#6BD098',
                                },
                                {
                                    label: 'Fail',
                                    data: [...data.fail],
                                    backgroundColor: '#EE8157',
                                },
                            ],
                        }}
                        options={{
                            scales: {
                                yAxes: [
                                    {
                                        stacked: true,
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                                xAxes: [
                                    {
                                        stacked: true,
                                    },
                                ],
                            },
                        }}
                    />
                    )
}

export default BarGraphic;