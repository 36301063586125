import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    CardTitle, Button,
} from "reactstrap";

import ApiCaller from "../../../../lib/ApiCaller";
import ReactTable from "../../../../components/ReactTable/ReactTable";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";
import CertificateApi from "../../../../api/CertificateApi";
import ExamCertificateCollection from "../../../../lib/common/models/examCertificateCollection";
import moment from "moment";
import Settings from "../../../../lib/settings";
import {useHistory} from "react-router-dom";
import {formatDateTime, sortDateTable} from "../../../../shared/utils/dateTime";
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';
import { error } from 'console';

const CertificateList: React.FC = () => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const layout = useSelector((state: AppState) => state.session.layout);
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTip, setLoadingTip] = useState<string>('');

    const [certificates, updateCertificates] = React.useState<ExamCertificateCollection>(new ExamCertificateCollection([]));

    React.useEffect(() => {
        setLoadingTip('loading certificates...');
        setLoading(true);
        const candidateApi = new CertificateApi(new ApiCaller(loggedUser.token));
        candidateApi.getAll().then((certificates: any) => {
            if (certificates && Object.keys(certificates).length > 0) {
                updateCertificates(certificates);
            }
            setLoading(false);
            toast.success('All certificates loaded successfully');
        }).catch(err =>{
            console.error(err);
            setLoading(false);
            toast.error('Error loading certificates');
        })
    }, []);

    const chooseCertificate = (cell: any) => {
        const certificate = cell.row.original;
        Settings.setCurrentCertificate(certificate);
        history.push(`/${layout}/certificate/detail`);
    }

    const columns = [
        { Header: 'NUMBER', accessor: (d: any) => d.sequence ? d.getCertificateNumber() : '-', sortable: true },
        { Header: 'TITLE', accessor: (d: any) => d.getTitle(), sortable: true },
        { Header: 'CREATE DATE', accessor: (d: any) => formatDateTime(d.createdAt), sortable: true, sortType: (a: any, b: any) => sortDateTable(a, b, "createdAt") },
        { Header: 'DATE', accessor: (d: any) => moment(d.date).utc().format("DD-MM-YYYY"), sortable: true, sortType: (a: any, b: any) => sortDateTable(a, b, "date") },
        { Header: 'CANDIDATE', accessor: (d: any) => d.candidateId ? `${d.candidateId.firstName} ${d.candidateId.lastName}` : '-', sortable: true },
        { Header: 'EXAM', accessor: (d: any) => d.examId ? d.examId.sequence : '-', sortable: true },
        { Header: 'STATUS', accessor: (d: any) => d.status, sortable: true },
        { Header: 'ACTIONS', Cell: (row: any) => {
                return (
                    <>
                        <div>
                            <Button className="btn-round" color="info" onClick={() => { chooseCertificate(row) }}>VIEW</Button>
                        </div>
                    </>
                )}
        },
    ]

    return (
       <>
        <div className="content">
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">Certificates List</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <LoadingSpinner spinning={loading} tip={loadingTip}>
                              <ReactTable
                                  data={certificates?.certificates}
                                  columns={columns}
                              />
                            </LoadingSpinner>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ToastContainer />
        </div>
      </>
    )
}

export default CertificateList;

