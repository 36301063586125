import React from "react";
import { PassTableProps } from '../../types';
import { splitSylabus, getPercentage } from '../../helpers'
import BarGraphic from './barGraphic';
import PieGraphic from './pieGraphic';
import './style.css';

const PassTable: React.FC<PassTableProps> = ({
    data,
    syllabus
}) => {
    let dataRender = splitSylabus(data, syllabus);

     let dataObj = {
         code: dataRender.map((item) => item.code.replace((`${syllabus}-`), '')),
         title: dataRender.map(item => item.title),
         pass: dataRender.map(item => item.pass),
         fail: dataRender.map(item => item.fail),
    }

    let passed = dataObj.pass.reduce((accumulator, value) => {
        return accumulator + value;
    }, 0);

    let failed = dataObj.fail.reduce((accumulator, value) => {
        return accumulator + value;
    }, 0);

    let total = failed + passed;

    let percentageFail = Math.round(getPercentage(failed, total));
    let percentagePassed = Math.round(getPercentage(passed, total));
    //console.log(percentageFail)

    return (
        <div className="passrate-table__container">
            <div className="passrate-unit__container">
                <div className="passrate-unit__bar">
                    <BarGraphic data={dataObj}/>
                </div>
                <div className="passrate-unit__pie">
                    <PieGraphic
                    passed={passed}
                    failed={failed}
                    percentagePassed={percentagePassed ? percentagePassed : 0}
                    percentageFail={percentageFail ? percentageFail : 0}/>
                </div>
            </div>
        </div>
    );
};

export default PassTable;
