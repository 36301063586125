import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';

import { useTable, useSortBy, useGroupBy, useExpanded } from 'react-table';
import { Button, Table, Input, Label, FormGroup } from 'reactstrap';

import NZDocument from '../../../lib/common/models/nzDocument';
import NavigationExam, { ScriptIntentionTypes, } from '../../../lib/common/models/navigationExam';
import {
  MarkingGridRow,
  MarkingQuestion,
  IGridResults,
  MarkingHistoricalGridRow,
} from '../../../lib/common/models/markingData';

interface IRowResult {
  result: string;
  grade?: number;
  details?: {
    [key: string]: {
      result: string;
      grade?: number;
    };
  };
}

export interface ExamMarkTableProps {
  exam: NavigationExam;
  grid: MarkingGridRow[];
  historicalGrid?: MarkingHistoricalGridRow[];
  questions: MarkingQuestion[];
  docs: NZDocument[];
  results: IGridResults;
  manualTotal: { [key: string]: number };
  onChangeManualTotal: (value: { [key: string]: number }) => void;
  onViewQuestionAnswer: (questionId: string) => void;
  onOpenExamDocument: (candidateId: string) => void;
  onSetFieldParams: (
    index: number,
    value: string,
    data: string,
    field: string,
  ) => void;
  onSetCandidateVoid: (index: number, value: boolean) => void;
}

const ExamMarkTable: React.FC<ExamMarkTableProps> = ({
  exam,
  grid,
  historicalGrid = [],
  questions,
  docs,
  results,
  manualTotal,
  onChangeManualTotal,
  onViewQuestionAnswer,
  onOpenExamDocument,
  onSetFieldParams,
  onSetCandidateVoid,
}) => {
  const isDisplayHistory = exam.syllabus.code !== 'S02' && historicalGrid.length !== 0;
  const [total, setTotal] = useState(0);
  const keyToFocus = useRef<null | string>(null);

  useEffect(() => {
    const marks = questions.map((question) => question.marker);
    const total = marks.reduce((subtotal, element) => subtotal + element, 0);
    setTotal(total);
  }, [questions]);

  const displayResult = (result: IRowResult): string => {
    return result.grade ? `${result.result} (${result.grade})` : result.result;
  };

  const getScriptColumn = useCallback(() => {
    if (exam?.scriptIntention === ScriptIntentionTypes.SCAN) {
      return [{
        Header: 'Script',
        disableSortBy: true,
        Cell: ({ row }: any) => !isDisplayHistory ? (
          <Button onClick={() => onOpenExamDocument(row.original.candidateId)}>
            Download
          </Button>
        ) : null,
        Aggregated: (cell: any) => isDisplayHistory ? (
          <Button onClick={() => onOpenExamDocument(cell.row.subRows[0].original.candidateId)}>
            Download
          </Button>
        ) : null,
      }];
    }
    return [];
  }, [exam, isDisplayHistory, onOpenExamDocument]);

  const columns = useMemo(() => {
    return [
      { Header: 'Candidate', accessor: 'candidateName' },
      ...getScriptColumn(),
      {
        Header: 'Void',
        accessor: 'isVoid',
        disableSortBy: true,
        Cell: ({ value, row }: any) => (
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                checked={value}
                onChange={(e: any) => {
                  keyToFocus.current = null;
                  onSetCandidateVoid(row.original.index, e.target.checked);
                }}
              />
              <span className="form-check-sign" />
            </Label>
          </FormGroup>
        ),
      },
      {
        Header: 'Result',
        Cell: ({ row }: any) => displayResult(results[row.original.candidateId])
      },
      ...questions.map((question: MarkingQuestion, index: number) => {
        return {
          Header: (
            <Button
              id={`pop_${question._id}`}
              className={'btn-sm'}
              type="button"
              onClick={() => {
                keyToFocus.current = null;
                onViewQuestionAnswer(question.questionName);
              }}>
              Q{String(index + 1).padStart(2, '0')} ({question.marker})
            </Button>
          ),
          accessor: `${question.questionName}`,
          disableSortBy: true,
          Cell: ({ row }: any) => !row.isGrouped && (
            <>
              <small>Q{String(index + 1).padStart(2, '0')}</small>
              <input
                key={`${question.questionName}-${row.original.candidateId}`}
                className="table-input__input-field"
                value={row.original[question.questionName] ?? ''}
                type="number"
                min={0}
                placeholder="--"
                pattern="[0-9]*"
                onChange={(e: any) => {
                  keyToFocus.current = `${question.questionName}-${row.original.candidateId}`;
                  onSetFieldParams(
                    row.original.index,
                    e.target.value,
                    e.nativeEvent.data,
                    question.questionName,
                  );
                }}
                disabled={
                  !Object.prototype.hasOwnProperty.call(
                    row.original,
                    question.questionName,
                  ) || Boolean(row.original.isVoid)
                }
                autoFocus={keyToFocus.current === `${question.questionName}-${row.original.candidateId}`}
              />
            </>
          )
        };
      }),
      {
        Header: `Total Marks ${total}`,
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <>
            <small>TOTAL</small>
            <input
              key={`total-${row.original.candidateId}`}
              className="table-input__input-field"
              value={manualTotal[row.original.candidateId]}
              placeholder="--"
              type="number"
              pattern="[0-9]*"
              onChange={(e: any) => {
                keyToFocus.current = `total-${row.original.candidateId}`;
                onChangeManualTotal({
                  ...manualTotal,
                  [row.original.candidateId]: e.target.value,
                });
              }}
              disabled={Boolean(row.original.isVoid)}
              autoFocus={keyToFocus.current === `total-${row.original.candidateId}`}
            />
          </>
        ),
      },
    ];
  }, [questions, total, getScriptColumn, onViewQuestionAnswer, results, manualTotal, onChangeManualTotal, onSetCandidateVoid, onSetFieldParams]);

  const columnsHistorical = useMemo(() => {
    return [
      {
        Header: () => null,
        accessor: 'candidateId',
        Cell: () => null,
      },
      {
        Header: 'Candidate',
        accessor: 'candidateName',
        Cell: () => null,
        Aggregated: (cell: any) => {
          const rowCandidateId = cell.row.subRows[0].original.candidateId;
          return grid.filter((mark) => mark.id === rowCandidateId)[0].name;
        },
      },
      ...getScriptColumn(),
      {
        Header: 'Void',
        accessor: 'isVoid',
        disableSortBy: true,
        Aggregated: (cell: any) => {
          const rowCandidateId = cell.row.subRows[0].original.candidateId;
          const mcaMark = grid.filter((mark) => mark.id === rowCandidateId)[0];
          return (
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={Boolean(mcaMark.isVoid)}
                  onChange={(e: any) => {
                    keyToFocus.current = null;
                    onSetCandidateVoid(
                      grid.findIndex((mark) => mark.id === mcaMark.id),
                      e.target.checked
                    );
                  }}
                />
                <span className="form-check-sign" />
              </Label>
            </FormGroup>
          )
        },
        Cell: () => null,
      },
      {
        Header: 'Result',
        accessor: 'result',
        Cell: ({ row }: any) => row.original.markerType,
        Aggregated: (cell: any) => displayResult(results[cell.row.subRows[0].original.candidateId]),
      },
      ...questions.map((question: MarkingQuestion, index: number) => {
        return {
          Header: (
            <Button
              id={`pop_${question._id}`}
              className={'btn-sm'}
              type="button"
              onClick={() => {
                keyToFocus.current = null;
                onViewQuestionAnswer(question.questionName);
              }}>
              Q{String(index + 1).padStart(2, '0')} ({question.marker})
            </Button>
          ),
          accessor: `${question.questionName}`,
          disableSortBy: true,
          Aggregated: (cell: any) => {
            const rowCandidateId = cell.row.subRows[0].original.candidateId;
            const mcaMark = grid.filter((mark) => mark.id === rowCandidateId)[0];
            return (
              <>
                <small>Q{String(index + 1).padStart(2, '0')}</small>
                <input
                  key={`${question.questionName}-${rowCandidateId}`}
                  className="table-input__input-field"
                  value={mcaMark[question.questionName] ?? ''}
                  type="number"
                  min={0}
                  placeholder="--"
                  pattern="[0-9]*"
                  onChange={(e: any) => {
                    keyToFocus.current = `${question.questionName}-${rowCandidateId}`;
                    onSetFieldParams(
                      grid.findIndex((mark) => mark.id === mcaMark.id),
                      e.target.value,
                      e.nativeEvent.data,
                      question.questionName,
                    );
                  }}
                  disabled={
                    !Object.prototype.hasOwnProperty.call(
                      mcaMark,
                      question.questionName,
                    ) || Boolean(mcaMark.isVoid)
                  }
                  autoFocus={keyToFocus.current === `${question.questionName}-${rowCandidateId}`}
                />
              </>
            )
          },
          Cell: ({ row }: any) => !row.isGrouped && (
            <>
              <small>Q{String(index + 1).padStart(2, '0')}</small>
              <input
                className="table-input__input-field"
                value={row.original[question.questionName] ?? ''}
                type="number"
                placeholder="--"
                disabled
              />
            </>
          )
        };
      }),
      {
        Header: `Total Marks ${total}`,
        accessor: 'total',
        disableSortBy: true,
        Aggregated: (cell: any) => {
          const rowCandidateId = cell.row.subRows[0].original.candidateId;
          const mcaMark = grid.filter((mark) => mark.id === rowCandidateId)[0];
          return (
            <>
              <small>TOTAL</small>
              <input
                key={`total-${rowCandidateId}`}
                className="table-input__input-field"
                value={manualTotal[mcaMark.id]}
                placeholder="--"
                type="number"
                pattern="[0-9]*"
                onChange={(e: any) => {
                  keyToFocus.current = `total-${rowCandidateId}`;
                  onChangeManualTotal({
                    ...manualTotal,
                    [mcaMark.id]: e.target.value,
                  });
                }}
                disabled={Boolean(mcaMark.isVoid)}
                autoFocus={keyToFocus.current === `total-${rowCandidateId}`}
              />
            </>
          )
        },
        Cell: ({ value }: any) => (
          <>
            <small>TOTAL</small>
            <input
              className="table-input__input-field"
              value={value}
              placeholder="--"
              type="number"
              disabled
            />
          </>
        )
      },
    ];
  }, [questions, total, getScriptColumn, onViewQuestionAnswer, results, grid, manualTotal,
    onChangeManualTotal, onSetCandidateVoid, onSetFieldParams]);

  const tableData = useMemo(
    () =>
      grid.map((value: MarkingGridRow, index: number) => {
        return {
          index,
          candidateName: value.name,
          candidateId: value.id,
          isVoid: Boolean(value.isVoid),
          ...Object.keys(value).reduce((result, current) => {
            if (current === 'name' || current === 'id') {
              return result;
            }
            return {
              ...result,
              [current]: value[current],
            };
          }, {}),
        };
      }),
    [grid],
  );

  const tableDataHistorical = useMemo(
    () =>
      historicalGrid.map((value: MarkingHistoricalGridRow, index: number) => {
        return {
          index,
          candidateName: value.name,
          candidateId: value.id,
          isVoid: Boolean(value.isVoid),
          result: value.result,
          markerType: value.markerType,
          ...Object.keys(value).reduce((result, current) => {
            if (
              current === 'name' ||
              current === 'id' ||
              current === 'result' ||
              current === 'markerType'
            ) {
              return result;
            }
            return {
              ...result,
              [current]: value[current],
              total: result.total + Number(value[current]),
            };
          }, { total: 0 }),
        };
      }),
    [historicalGrid],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    toggleAllRowsExpanded,
    // @ts-ignore
    isAllRowsExpanded,
  } =
    useTable(
      {
        // @ts-ignore
        columns: isDisplayHistory ? columnsHistorical : columns,
        data: isDisplayHistory ? tableDataHistorical : tableData,
        autoResetExpanded: false,
        initialState: {
          // @ts-ignore
          groupBy: isDisplayHistory ? ['candidateId'] : [],
          expanded: true,
        }
      },
      useGroupBy,
      useSortBy,
      useExpanded
    );

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [isAllRowsExpanded, toggleAllRowsExpanded]);

  return (
    <Table {...getTableProps()} responsive className={`grid-table ${isDisplayHistory ? "historical-table" : ""}`}>
      <thead className="text-primary">
        {grid &&
          headerGroups.map((headerGroup: any, i: number) => (
            <tr key={`tr-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                if (column.id !== 'candidateId') {
                  return <th
                    key={`th-${i}`}
                    className={!column.disableSortBy ? 'marks-table-nowrap' : ''}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                  </th>
                }
              })}
            </tr>
          ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {grid &&
          rows.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr key={`row-${i}`} {...row.getRowProps()} className="table-input">
                {row.cells.map((cell: any, index: number) => {
                  if (cell.column.id !== 'candidateId') {
                    return (
                      <td key={`cell-${index}`} {...cell.getCellProps()}>
                        {cell.isGrouped ? (
                          cell.render('Cell')
                        ) : cell.isAggregated ? (
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default ExamMarkTable;
