import * as XLSX from 'xlsx';
import { Column } from 'react-table';

export const onTableExport = (
  tableName: string,
  columns: Column<object>[],
  tableData: any[],
) => {
  const headers: any = [];
  columns.forEach((item: Column) => {
    if (['name', 'totalAvg'].includes(String(item.accessor))) {
      headers.push(item.Header);
    }
  });
  const data: any = [];
  tableData.forEach((item: any) => {
    const { name, totalAvg } = item;
    data.push({
      name,
      totalAvg,
    });
  });

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([[]]);

  ws['!merges'] = [
    { s: { c: 0, r: 0 }, e: { c: 2, r: 0 } },
    { s: { c: 3, r: 0 }, e: { c: 5, r: 0 } },
  ];
  XLSX.utils.sheet_add_aoa(ws, [['EXAM']], {
    origin: getCellPosition(1, 1),
  });
  XLSX.utils.sheet_add_aoa(ws, [['TOTAL AVG. MARK']], {
    origin: getCellPosition(4, 1),
  });
  let rowQ = 3;
  if (tableData.length > 0) {
    tableData.forEach((row: any, index: number) => {
      XLSX.utils.sheet_add_aoa(ws, [[row?.name]], {
        origin: getCellPosition(1, rowQ - 1),
      });
      XLSX.utils.sheet_add_aoa(ws, [[row?.totalAvg]], {
        origin: getCellPosition(4, rowQ - 1),
      });

      // ADD QUESTION HEADER
      XLSX.utils.sheet_add_aoa(ws, [['QUESTION']], {
        origin: getCellPosition(1, rowQ),
      });
      XLSX.utils.sheet_add_aoa(ws, [['QUESTION NUMBER']], {
        origin: getCellPosition(2, rowQ),
      });
      XLSX.utils.sheet_add_aoa(ws, [['TOPIC']], {
        origin: getCellPosition(3, rowQ),
      });
      XLSX.utils.sheet_add_aoa(ws, [['MIN. MARK']], {
        origin: getCellPosition(4, rowQ),
      });
      XLSX.utils.sheet_add_aoa(ws, [['MAX. MARK']], {
        origin: getCellPosition(5, rowQ),
      });
      XLSX.utils.sheet_add_aoa(ws, [['AVG. MARK']], {
        origin: getCellPosition(6, rowQ),
      });
      if (row.questions.length > 0) {
        row.questions.forEach((question: any, pos: number) => {
          const rowK = rowQ + pos + 1;
          XLSX.utils.sheet_add_aoa(ws, [[question.name]], {
            origin: getCellPosition(1, rowK),
          });
          XLSX.utils.sheet_add_aoa(ws, [[question.id]], {
            origin: getCellPosition(2, rowK),
          });
          XLSX.utils.sheet_add_aoa(ws, [[question.topic]], {
            origin: getCellPosition(3, rowK),
          });
          XLSX.utils.sheet_add_aoa(ws, [[question.minMark]], {
            origin: getCellPosition(4, rowK),
          });
          XLSX.utils.sheet_add_aoa(ws, [[question.maxMark]], {
            origin: getCellPosition(5, rowK),
          });
          XLSX.utils.sheet_add_aoa(ws, [[question.avgMark]], {
            origin: getCellPosition(6, rowK),
          });
        });
      }
      rowQ = rowQ + row.questions.length + 2;
    });
  }
  XLSX.utils.book_append_sheet(wb, ws, 'Data');

  XLSX.writeFile(wb, `${tableName}.xlsx`);
};

const getCellPosition = (text: number, num: number) => {
  let letters = '';
  let number = text;
  while (number > 0) {
    let remainder = number % 26;
    if (remainder === 0) {
      letters = 'Z' + letters;
      number = Math.floor(number / 26) - 1;
    } else {
      letters =
        String.fromCharCode(remainder - 1 + 'A'.charCodeAt(0)) + letters;
      number = Math.floor(number / 26);
    }
  }
  return letters + num.toString();
};
