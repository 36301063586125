import User from './user';

class UserCollection {
  users: Array<User>;

  constructor(files: Array<User>) {
    this.users = files;
  }

  length() {
    return this.users.length;
  }
}

export default UserCollection;
