import React from 'react';

import { Spin } from 'antd';

type LoadingSpinnerProps = {
  spinning: boolean;
  tip?: string;
  delay?: number;
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  spinning,
  tip,
  delay = 0,
  children,
}) => {
  return (
    <Spin spinning={spinning} tip={tip} size={'large'} delay={delay}>
      {children}
    </Spin>
  );
};

export default LoadingSpinner;
