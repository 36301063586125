import NZDocument from './nzDocument';

class NZDocumentCollection {
  files: Array<NZDocument>;

  constructor(files: Array<NZDocument>) {
    this.files = files;
  }

  length() {
    return this.files.length;
  }
}

export default NZDocumentCollection;
