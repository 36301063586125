import MYDocument from '../../lib/common/models/myDocument';
import Exam from '../../lib/common/models/exam';
import NZDocument, { NZDocumentType } from '../../lib/common/models/nzDocument';
import { DocsActionTypes } from 'store/actions/docs/docsActions';
import {
  GET_DRAFTS_LIST,
  GET_SIGNED_LIST,
  SET_CURRENT_DOC,
  SET_CURRENT_EXAM,
} from 'store/actions/actionTypes';

export interface DocsState {
  doc: MYDocument;
  exam: Exam;
  drafts: MYDocument[];
  signed: NZDocument[];
  ready: boolean;
}

const initialState: DocsState = {
  doc: new MYDocument({
    type: NZDocumentType.GENERAL_PURPOSE,
    customName: '',
    customDescription: '',
    content: '',
  }),
  exam: new Exam({
    title: '',
    module: '',
    date: new Date(),
    slug: '',
    examContent: {
      vars: [],
      content: [
        {
          _id: 'nothing',
          html: '',
          plainText: '',
          questionName: '',
          questionId: '',
          topicName: '',
          topicId: '',
          marker: 0,
        },
      ],
      outerTag: 'p',
      questions: 0,
    },
  }),
  ready: false,
  drafts: [],
  signed: [],
};

export function docsReducer(
  state = initialState,
  action: DocsActionTypes,
): DocsState {
  switch (action.type) {
    case SET_CURRENT_EXAM: {
      return {
        ...state,
        exam: action.payload,
        ready: true,
      };
    }
    case SET_CURRENT_DOC: {
      return {
        ...state,
        doc: action.payload,
        ready: true,
      };
    }
    case GET_DRAFTS_LIST: {
      return {
        ...state,
        drafts: action.payload,
        ready: true,
      };
    }
    case GET_SIGNED_LIST: {
      return {
        ...state,
        signed: action.payload,
        ready: true,
      };
    }
    default:
      return state;
  }
}
