import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  Row,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import TransactionApi from '../../../../api/TransactionApi';
import { formatDateTimeConversational } from '../../../../shared/utils/dateTime';
import AddCredits from '../../admin/payment/addCredits';

import './TransactionsComponent.scss';

const TransactionsComponent: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [balanceState, setBalanceState] = useState({
    balance: 0,
    timestamp: 0,
  });
  const [modal, setModal] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [balanceDisplay, setBalanceDisplay] = useState('0.00');

  useEffect(() => {
    const transactionApi = new TransactionApi(new ApiCaller(loggedUser.token));
    transactionApi
      .getBalance()
      .then((data) => {
        setBalanceState(data);
      })
      .catch(() => {
        toast.error('Error retrieving user balance');
      });
  }, [refresh]);

  useEffect(() => {
    setBalanceDisplay(balanceState.balance.toFixed(2));
  }, [balanceState]);

  const addCredits = () => {
    setRefresh(false);
    setModal(true);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <h5 className="title">Account Balance</h5>
        </CardHeader>
        <CardBody>
          <p>
            <a id="balance-board">£{balanceDisplay.split('.')[0]}.</a>
            <a id="balance-board-decimal">{balanceDisplay.split('.')[1]}</a>
          </p>
          <br />
          <p>
            Last updated:{' '}
            {formatDateTimeConversational(balanceState.timestamp)}
          </p>
          <Button
            id="modal-footer-button"
            className="btn-round btn btn-success"
            onClick={() => addCredits()}>
            Add Credit
          </Button>
        </CardBody>
      </Card>
      <Modal isOpen={modal}>
        <AddCredits setModal={setModal} setRefresh={setRefresh} />
      </Modal>
    </>
  );
};

export default TransactionsComponent;
