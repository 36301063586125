import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
} from 'reactstrap';

import { AppState } from 'store/store';

import ApiCaller from 'lib/ApiCaller';

import QuestionApi from 'api/QuestionApi';

import TopicApi from 'api/TopicApi';
import { useSyllabus } from 'hooks';

const TopicCreate: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const { syllabus } = useSyllabus();
  const [modules, setModules] = useState<any[]>([]);
  const [topic, setTopics] = useState<any[]>([]);
  const [form, setState] = React.useState({
    code: '',
    title: '',
    marks: 0,
    syllabus: '',
    modules: '',
  });

  const resetForm = () => {
    setState({
      code: '',
      title: '',
      marks: 0,
      syllabus: '',
      modules: '',
    });
  };

  const handleChange = (e: any) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const onSyllabusChange = (selectedSyllabus: any) => {
    setState({
      ...form,
      syllabus: selectedSyllabus,
    });
    var questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
    questionApi.getModulesFromSyllabus(selectedSyllabus._id).then((m: any) => {
      setModules(m.modules);
    });
  };

  const onModuleChange = (selectedModule: any) => {
    setState({
      ...form,
      modules: selectedModule,
    });
  };

  const createTopic = (e: React.FormEvent) => {
    e.preventDefault();
    var topicApi = new TopicApi(new ApiCaller());
    topicApi
      .createTopic(
        form.code,
        form.title,
        form.marks,
        form.syllabus,
        form.modules,
        loggedUser.token,
      )
      .then((createModule: any) => {
        if (createModule) {
          toast.success('Module created successfully');
          resetForm();
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast.error('Error creating module, please try again');
      });
  };

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <h4 className="card-title">Create Topic</h4>
          </CardHeader>
          <CardBody>
            <Form className="form-horizontal" onSubmit={createTopic}>
              <Row>
                <Label sm="2">Syllabus</Label>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="syllabus"
                      value={form?.syllabus as any}
                      onChange={onSyllabusChange}
                      options={syllabus}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a syllabus"
                    />
                  </FormGroup>
                </Col>
              </Row>
              {true && (
                <Row>
                  <Label sm="2">Module</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="module"
                        value={form.modules}
                        onChange={onModuleChange}
                        options={modules}
                        getOptionLabel={(item) => item.code + ' ' + item.title}
                        getOptionValue={(item) => item.code}
                        placeholder="Choose a module"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Label sm="2">Code</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={form.code}
                      onChange={handleChange}
                      type="text"
                      name="code"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Title</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={form.title}
                      onChange={handleChange}
                      type="text"
                      name="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Marks</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={form.marks}
                      onChange={handleChange}
                      type="number"
                      name="marks"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2"></Label>
                <Col sm="10">
                  <Button type="submit" className="btn-round" color="info">
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <ToastContainer />
      </div>
    </>
  );
};

export default TopicCreate;
