import React from 'react';

import {
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Card,
    Col,
} from 'reactstrap';

import ExamCenterDateFilteredTable from "../../filteredComponents/examCenterDateFilteredTable/examCenterDateFilteredTable";
import TreasurerDashboardApi from "../../../../api/TreasurerDashboardApi";
import { formatCurrency } from "../../../../shared/utils/currency";
import { sortCurrencyTable } from "../../../../shared/utils/dateTime";
import { defaultCurrency } from "../../../../lib/common/models/transaction";

const PositionSummaryReport: React.FC = () => {
    const columns = [
        { Header: 'EXAM CENTRE', accessor: 'examCentre', sortable: true },
        {
            Header: 'TOTAL PAYMENTS',
            accessor: (d: any) => formatCurrency(d.paymentsReceived, defaultCurrency),
            sortable: true,
            sortType: (a: any, b: any) => sortCurrencyTable(a, b, 'paymentsReceived')
        },
        {
            Header: 'TOTAL INVOICES',
            accessor: (d: any) => formatCurrency(d.totalInvoices, defaultCurrency),
            sortable: true,
            sortType: (a: any, b: any) => sortCurrencyTable(a, b, 'totalInvoices')
        },
        {
            Header: 'CREDIT BALANCE',
            accessor: (d: any) => formatCurrency(d.creditBalance, defaultCurrency),
            sortable: true,
            sortType: (a: any, b: any) => sortCurrencyTable(a, b, 'creditBalance')
        },
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Position Summary Report</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <ExamCenterDateFilteredTable
                                columns={columns}
                                fetchMethod={TreasurerDashboardApi.getPositionSummaryReport}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default PositionSummaryReport;
