import React from 'react';
import type { FC } from 'react';
import { Button, Col, Row } from 'reactstrap';

interface ICustomToolbar {
  date: any;
  label: string;
  onView: any;
  onNavigate: any;
}
const CustomToolbar: FC<ICustomToolbar> = ({
  date,
  label,
  onView,
  onNavigate,
}) => {
  return (
    <Row>
      <Col>
        <Button onClick={() => onNavigate('PREV')}>Previous</Button>
        <Button onClick={() => onNavigate('NEXT')}>Next</Button>
      </Col>
      <Col className="text-center">
        <h3 className="card-title">{label}</h3>
      </Col>
      <Col className="text-center text-md-right"></Col>
    </Row>
  );
};

export default CustomToolbar;
