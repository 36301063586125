import ExamCenterCollection from 'lib/common/models/examCenterCollection';
import {
  SET_EXAM_CENTER,
  SET_STATUS_EXAM_CENTER,
} from 'store/actions/actionTypes';
import { APP_STATUS } from 'types/app';

interface IExamCenterState {
  list: ExamCenterCollection;
  status: APP_STATUS;
}

export const INITIAL_EXAM_STATE: IExamCenterState = {
  list: new ExamCenterCollection([]),
  status: APP_STATUS.IDLE,
};

const examCenterReducer = (state = INITIAL_EXAM_STATE, action: any) => {
  switch (action.type) {
    case SET_EXAM_CENTER: {
      return {
        ...state,
        list: action.payload,
      };
    }

    case SET_STATUS_EXAM_CENTER: {
      return {
        ...state,
        status: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default examCenterReducer;
