import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Label,
    FormGroup,
    Form,
    Input,
    Col,
    Row,
    CardTitle,
} from "reactstrap";

import ApiCaller from "../../../../lib/ApiCaller";
import ExamCenterApi from "../../../../api/ExamCenterApi"
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";
import ExamCenter from "../../../../lib/common/models/examCenter";

const ExamCenterCreate: React.FC = () => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const [form, setState] = React.useState<ExamCenter>(new ExamCenter({
        code: '',
        name: '',
        address: '',
    }));

    const resetForm = () => {
        setState(new ExamCenter({
            code: '',
            name: '',
            address: '',
        }));
    };

    const handleChange = (e:any) => {
        setState(new ExamCenter({
            ...form,
            [e.target.name]: e.target.value
        }));
    };


    const createExamCenter = (e: React.FormEvent) => {
        e.preventDefault();
        const examCenterApi = new ExamCenterApi(new ApiCaller());
        examCenterApi.createExamCenter(form, loggedUser.token).then((user: any) => {
            if (user) {
                toast.success(' Exam center created successfully');
                resetForm();
            }
        }).catch((err: any) => {
            console.log(err);
            toast.error('Error creating exam center, please try again');
        });
    };

    return(
        <>
            <div className="content">
                <Card>
                    <Row>
                        <Col md="12">
                            <CardHeader>
                                <CardTitle tag="h4">Create Exam Center</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" onSubmit={createExamCenter}>
                                    <Row>
                                        <Label sm="2">Code</Label>
                                        <Col sm="10">
                                            <FormGroup>
                                                <Input
                                                    value={form.code}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="code"
                                                    placeholder="Code"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Name</Label>
                                        <Col sm="10">
                                            <FormGroup>
                                                <Input
                                                    value={form.name}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Address</Label>
                                        <Col sm="10">
                                            <FormGroup>
                                                <Input
                                                    value={form.address}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="address"
                                                    placeholder="Address"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2"/>
                                        <Col sm="10">
                                            <Button className="btn-round" color="info" type="submit">
                                                Create
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Col>
                    </Row>
                </Card>
                <ToastContainer/>
            </div>
        </>
    )
}

export default ExamCenterCreate;
