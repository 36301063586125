import React, { useState } from 'react';

import ReactTable from 'components/ReactTable/ReactTable';
import { DateFilteredTableProps } from '../types';
import DateFilteredComponent from '../dateFiltered/dateFilteredComponent/dateFilteredComponent';

const MarkerPaymentsDateFilteredTable: React.FC<DateFilteredTableProps> = ({
  fetchMethod,
  columns,
  hasTotal,
}) => {
  const [tableData, setTableData] = useState<any>([]);

  return (
    <>
      <div className="filtered-table__container">
        <DateFilteredComponent
          component={[
            <ReactTable
              data={tableData}
              columns={columns}
              topPaginationClassName="table-pagination_top"
              isLastColumnSortable={true}
              hasTotal={hasTotal}
            />]
          }
          fetchMethod={fetchMethod}
          updateDataMethod={setTableData}
          componentName="table"
        />
      </div>
    </>
  );
};

export default MarkerPaymentsDateFilteredTable;
