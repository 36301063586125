import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from "../../store/store";

const RouteListener: React.FC = () => {
    const history = useHistory();
    const routesWithCrisp = ['/', '/login', '/register', '/forgotpassword', '/customer', '/restaurant'];
    const currentUser = useSelector((state: AppState) => state.session.userInfo);
    var unlisten: any;

    const toggleCrispVisibility = (path: string, currentUser: any) => {
        /*        if(routesWithCrisp.indexOf(path) != -1) {
                    //@ts-ignore
                    window.$crisp.push(['do', 'chat:show']);
                } else {
                    //@ts-ignore
                    window.$crisp.push(['do', 'chat:hide']);
                }*/
    }

    React.useEffect(() => {
        if (currentUser && currentUser.email) {
            //@ts-ignore
            //window.$crisp.push(["set", "user:nickname", [currentUser.name]])
            //@ts-ignore
            //window.$crisp.push(["set", "user:email", [currentUser.email]])
        }
        if (unlisten) {
            unlisten();
        }
        unlisten = history.listen((location, action) => {
            toggleCrispVisibility(document.location.pathname, currentUser);
        });
        toggleCrispVisibility(document.location.pathname, currentUser);
    }, [currentUser]);

    return (
        <div></div>
    );
};

export default RouteListener;
