import { APP_STATUS } from 'types/app';
import { SET_EXAM_CENTER, SET_STATUS_EXAM_CENTER } from '../actionTypes';

export const setExamCenters = (list: any) => {
  return {
    type: SET_EXAM_CENTER,
    payload: list,
  };
};

export const setExamCentersStatus = (status: APP_STATUS) => {
  return {
    type: SET_STATUS_EXAM_CENTER,
    payload: status,
  };
};
