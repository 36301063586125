interface NotificationInfo {
    id:string;
    code: string,
    title: string,
    createdBy: string;
    createdAt: string;
}

class Notification {
  id:string;
  code: string;
  title: string;
  createdAt: string;
  createdBy: string;

  constructor(info: NotificationInfo) {
    this.code = info.code;
    this.title = info.title;
    this.createdAt = info.createdAt;
    this.createdBy = info.createdBy;
    this.id = info.id;
  }

  loadMany(data: any[]): Notification[] {
    return data ? data.map(n=>new Notification(n)) : [];
  }
  load(data: any): Notification {
    return new Notification(data);
  }

  toObject(): any {
    return {
      id: this.id,
      code: this.code,
      title: this.title,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
    };
  }
}

export default Notification;
