import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
} from 'reactstrap';

import Settings from '../../../../lib/settings';
import { AppState } from '../../../../store/store';

import ApiCaller from '../../../../lib/ApiCaller';
import TopicApi from 'api/TopicApi';
import Topic from 'lib/common/models/topic';

const TopicDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const history = useHistory();
  const [currentTopic, setCurrentTopic] = useState<Topic>();

  useEffect(() => {
    setCurrentTopic(Settings.getCurrentTopic());
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const topicInfo = currentTopic?.toObject();

    if (topicInfo) {
      topicInfo[name] = value;
      setCurrentTopic(new Topic(topicInfo));
    }
  };

  const updateModule = (evt: any) => {
    evt.preventDefault();
    if (currentTopic) {
      const topicApi = new TopicApi(new ApiCaller());
      topicApi
        .updateTopic(currentTopic, loggedUser.token)
        .then((topic: any) => {
          history.push('/navozyme/topic/list');
          toast.info('Updated successfully');
        })
        .catch((err) => {
          console.log(err);
          toast.error('Error updating call number, please try again');
        });
    }
  };

  return (
    <>
      <div className="content">
        {currentTopic && (
          <Card>
            <Row>
              <Col md="12">
                <CardHeader>
                  <CardHeader>
                    <h4 className="card-title">Update Topic</h4>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal" onSubmit={updateModule}>
                    <Row>
                      <Label sm="2">Code</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentTopic.code}
                            onChange={handleChange}
                            type="text"
                            name="code"
                            placeholder="Code"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Title</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentTopic.title}
                            onChange={handleChange}
                            type="text"
                            name="title"
                            placeholder="Title"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Marks</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentTopic.marks}
                            onChange={handleChange}
                            type="number"
                            name="marks"
                            placeholder="Marks"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2" />
                      <Col sm="10">
                        <Button
                          className="btn-round"
                          color="info"
                          type="submit">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Col>
            </Row>
          </Card>
        )}
      </div>
    </>
  );
};

export default TopicDetail;
