import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Col,
} from 'reactstrap';

import { AppState } from 'store/store';
import Settings from 'lib/settings';
import User,{
  UserTypes,
  UserTypesLabels
} from 'lib/common/models/user';
import {UserSecondaryProfiles} from 'lib/common/models/userPreference';
import { formatDateTimeConversational } from 'shared/utils/dateTime';
import defaultAvatar from 'assets/img/placeholder.jpg';
import { NotificationPresenter } from 'views/presenters';
import { updateNotifications, resetNotifications } from 'store/notificationStore';
import Notifications from 'lib/notifications';
import Clock from 'views/components/clock';
import {updateAvatar, updateSession} from 'store/actions/session/sessionActions';

import './style.css';
import UserApi from "api/UserApi";
import ApiCaller from 'lib/ApiCaller';
import {SessionState} from "store/reducers/sessionReducer";
import AuthApi from 'api/AuthApi';
import {getJwtExpiration} from "shared/utils/jwt";
import { pushMatomoUserId } from 'utils/matomo';
import { userTypesRedirect } from 'layouts/routes';

interface ContainerProps {
  handleMiniClick: any;
}

const AdminNavbar: React.FC<ContainerProps> = ({ handleMiniClick }) => {
  const [state, setState] = useState({
    collapseOpen: false,
    color: 'navbar-transparent',
    sidebarOpen: false,
  });
  const notificationOptions = useSelector(
    (state: AppState) => state.notification.options,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const notifyMessage = (msg: string) => toast.success(msg);

  let notificationPresenter: NotificationPresenter;

  useEffect(() => {
    notificationPresenter = new NotificationPresenter(loggedUser, {
      dispatch: dispatch,
      notify: notifyMessage,
    });
    window.addEventListener('resize', updateColor);
    return () => notificationPresenter.socketConn.close();
  }, [false]);


  const updateColor = () => {
    if (window.innerWidth < 993 && state.collapseOpen) {
      setState({ ...state, color: 'bg-white' });
    } else {
      setState({ ...state, color: 'navbar-transparent' });
    }
  };
  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };
  const toggleCollapse = () => {
    const newState = {
      collapseOpen: !state.collapseOpen,
      color: !state.collapseOpen ? 'bg-white' : 'navbar-transparent',
      sidebarOpen: !state.sidebarOpen,
    };
    setState(newState);
  };
  const buttonLogout = (e: any) => {
    e.preventDefault();
    if (loggedUser.impersonated) {
      const authApi = new AuthApi(new ApiCaller());
      authApi
        .logoutImpersonated(loggedUser.token)
        .then((data: any) => {
          if (data.error) {
            toast.error('Failed to logout');
            return;
          }
          const user = new User({
            _id: data._id,
            name: data.name,
            email: data.email,
            token: data.token,
            type: data.type,
            status: data.status,
            entityId: data.entityId,
          });

          const sessionData: SessionState = {
            loggedIn: user.email ? true : false,
            ready: true,
            userInfo: user,
            tokenExpiration: getJwtExpiration(user.token),
            type: user.type,
            layout: user.type === 'admin' ? 'navozyme' : user.type,
          };

          dispatch(updateSession(sessionData));

          Settings.setCurrentUser(user).then(() => {
            const userApi = new UserApi(new ApiCaller());
            const url = userApi.getAvatarUrl(user._id || '');
            dispatch(updateAvatar(url));
            dispatch(
              updateNotifications({
                options: Notifications.restoreNotifications(user._id),
              }),
            );
            const userType: string = sessionData.userInfo.type.toLowerCase();
            history.push(
              userType === UserTypesLabels.admin
                ? '/navozyme/user/list'
                : `/${userType}${userTypesRedirect[userType]}`,
            );
            window.location.reload();
          });
        })
        .catch((err) => {
          console.error(err);
          toast.error('Failed to logout');
        });
    } else {
      Settings.logout();
      dispatch(
        updateSession({
          loggedIn: false,
          userInfo: new User({
            name: 'Guest',
            email: '',
            token: 'GUEST_TOKEN',
            type: 'guest',
          }),
          tokenExpiration: 0,
          ready: false,
          type: 'guest',
          avatar: defaultAvatar,
          layout: 'navozyme',
        }),
      );
      pushMatomoUserId(null);
      dispatch(updateNotifications({ options: [] }));
      history.push('/');
    }
  };
  const removeNotification = (e: any, idx: number) => {
    Notifications.removeNotifications(idx, loggedUser._id);
    dispatch(
      updateNotifications({
        options: notificationOptions.filter((n, i) => i != idx),
      }),
    );
  };

  const markAllNotificationsAsRead = () => {
    Notifications.resetNotifications(loggedUser._id);
    dispatch(resetNotifications());
  };

  const [profileGrid, setProfileGrid] = useState<any>();
  const [hasProfiles, setHasProfiles] = useState<boolean>(false);
  React.useEffect(() => {
    const userApi = new UserApi(new ApiCaller(loggedUser.token));
    loggedUser && userApi.getSecondaryProfile(loggedUser?._id!)
        .then((data: UserSecondaryProfiles) => {
          setProfileGrid(data.secondaryProfiles);
          setHasProfiles(Object.keys(data.secondaryProfiles).filter(f=>data.secondaryProfiles[f]).length>0);
        });
  }, []);

  const switchProfile = (profile:any) => {
    const authApi = new AuthApi(new ApiCaller(loggedUser.token));
    authApi
        .switch(loggedUser.token, profile)
        .then((data: any) => {
          const user = new User({
            name: data.name,
            email: data.email,
            token: data.token,
            type: data.type,
            status: data.status,
            entityId: data.entityId,
            impersonated: data.impersonated,
          });

          user._id = data._id;

          const sessionData: SessionState = {
            loggedIn: user.email ? true : false,
            ready: true,
            userInfo: user,
            tokenExpiration: getJwtExpiration(user.token),
            type: user.type,
            layout: user.type,
          };

          dispatch(updateSession(sessionData));

          Settings.setCurrentUser(user).then(() => {
            const userApi = new UserApi(new ApiCaller());
            const url = userApi.getAvatarUrl(user._id || '');
            dispatch(updateAvatar(url));
            dispatch(updateNotifications({options: Notifications.restoreNotifications(user._id)}));
            const userType: string = sessionData.userInfo.type.toLowerCase();
            history.push(`/${userType}${userTypesRedirect[userType]}`);
            window.location.reload();
          });
        })
        .catch((err) => {
          toast.error(`Can't switch the profile`);
        });
  }

  return (
    <>
      <Navbar
        className={classnames('navbar-absolute fixed-top', state.color)}
        expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={handleMiniClick}>
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames('navbar-toggle', {
                toggled: state.sidebarOpen,
              })}>
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand
              href="https://iami.info"
              onClick={(e) => e.preventDefault()}>
              <span className="d-none d-md-block">
                International Association Of Maritime Institutions
              </span>
              <span className="d-block d-md-none">
                International Association Of Maritime Institutions
              </span>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={state.collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            /* data-target="#navigation"*/ data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={state.collapseOpen}>
            <div className="justify-content-center vertical-align-middle">
              <Col>
                <Button
                  color="primary"
                  onClick={() => window.open('https://navozyme.net')}>
                  Support Site
                </Button>
              </Col>
            </div>
            <div className="justify-content-center vertical-align-middle text-center">
              <Clock />
            </div>
            <Nav navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav>
                  <i className="nc-icon nc-bell-55" />
                  {notificationOptions.length > 0 && (
                    <span className="notification-icon__counter">
                      {notificationOptions.length}
                    </span>
                  )}
                  <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownMenuLink"
                  end>
                  {notificationOptions.length > 0 ? (
                    <>
                      <Button
                        className="btn-link float-right mr-3"
                        size="sm"
                        color="primary"
                        variant="link"
                        onClick={() => markAllNotificationsAsRead()}>
                        Mark All as Read
                      </Button>
                      {notificationOptions.map((option, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            href={option.href}
                            onClick={(e) => e.preventDefault()}>
                            <div
                              className="notification-item"
                              onClick={() => removeNotification(option, i)}>
                              <p>({i + 1})</p>
                              <b className="notification-item__title">
                                {option.title.msg
                                  ? option.title.msg
                                  : option.title}
                              </b>
                              <b className="notification-item__time">
                                {formatDateTimeConversational(option.createdAt)}
                              </b>
                            </div>
                          </DropdownItem>
                        );
                      })}
                    </>
                  ) : (
                    <DropdownItem href={''} onClick={(e) => e.preventDefault()}>
                      <p>You don't have notifications</p>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              { hasProfiles && <UncontrolledDropdown>
                 <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownProfiles"
                  nav>
                  <i className="nc-icon nc-layout-11" />
                  <p>
                    <span>Change Profile</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownProfiles"
                  end>
                  {hasProfiles && Object.keys(profileGrid).filter(f=>profileGrid[f]).map(p=>
                    <DropdownItem onClick={(e) => switchProfile(p)}>
                      Change profile to {UserTypes[p]}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown> }

              <UncontrolledDropdown>
                <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav>
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>
                    <span className="d-lg-none d-md-block">Settings</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownMenuLink"
                  end>
                  <DropdownItem onClick={(e) => buttonLogout(e)}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
