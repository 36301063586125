import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Card,
  Col,
  Button,
} from 'reactstrap';

import DashboardApi from 'api/DashboardApi';
import DateFilteredComponent from 'views/components/filteredComponents/dateFiltered/dateFilteredComponent/dateFilteredComponent';
import ReactTable from 'components/ReactTable/ReactTable';
import { onTableExport } from 'shared/utils/excel';
import SyllabusFilter from '../syllabusFilter/SyllabusFilter';

type QuestionBankReportProps = {
  allowedSyllabuses: string[];
};

const QuestionBankReport = ({ allowedSyllabuses }: QuestionBankReportProps) => {
  const [tableData, setTableData] = useState<any>([]);
  const [syllabus, setSyllabus] = useState<string>('');

  const syllabuses = useMemo(() => {
    return new Set(
      tableData.map((item: any) => item.questionNumber.slice(0, 3)),
    );
  }, [tableData]);

  const filteredData = useMemo(() => {
    if (!syllabus) {
      return tableData;
    }
    return tableData.filter(
      (item: any) => item.questionNumber.slice(0, 3) === syllabus,
    );
  }, [tableData, syllabus]);

  const columns = [
    { Header: 'QUESTION NUMBER', accessor: 'questionNumber', sortable: true },
    {
      Header: 'MIN MARK (%)',
      accessor: (data: any) =>
        `${data.usage !== 0 ? data.minMark + '%' : 'N/A'}`,
      sortable: true,
    },
    {
      Header: 'MAX MARK (%)',
      accessor: (data: any, row: any) =>
        `${data.usage !== 0 ? data.maxMark + '%' : 'N/A'}`,
      sortable: true,
    },
    {
      Header: 'AVG MARK (%)',
      accessor: (data: any, row: any) =>
        `${data.usage !== 0 ? data.avgMark + '%' : 'N/A'}`,
      sortable: true,
    },
    { Header: 'USAGE', accessor: 'usage', sortable: true },
  ];

  useEffect(() => {
    if (!syllabus && syllabuses.size) {
      setSyllabus(syllabuses.values().next().value);
    }
  }, [syllabuses]);

  const orderColumns = useCallback((response: any) => {
    if (response.length) {
      const responseOrdered = response.map((item: any) => {
        const { questionNumber, usage, minMark, maxMark, avgMark } = item;
        return {
          questionNumber,
          minMark,
          maxMark,
          avgMark,
          usage,
        };
      });
      setTableData(responseOrdered);
    } else {
      setTableData(response);
    }
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Question Bank Analytics</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <SyllabusFilter
                disableButtons={{
                  S01: !syllabuses.has('S01'),
                  S02: !syllabuses.has('S02'),
                  S03: !syllabuses.has('S03'),
                  S04: !syllabuses.has('S04'),
                }}
                syllabus={syllabus}
                setSyllabus={setSyllabus}
                allowedSyllabuses={allowedSyllabuses}
              />
            </Col>
            <Col className="text-right">
              <Button
                onClick={() =>
                  onTableExport('Question Bank Analytics', columns, tableData)
                }
                color="success"
                className="btn-label">
                <span>
                  <i className="nc-icon nc-cloud-download-93" />
                </span>
                Export to Excel
              </Button>
            </Col>
          </Row>
          <DateFilteredComponent
            component={[
              <ReactTable
                data={filteredData}
                {...{ columns }}
                topPaginationClassName="table-pagination_top"
              />,
            ]}
            fetchMethod={DashboardApi.getQuestionBankReport}
            updateDataMethod={orderColumns}
            componentName="question-bank-analytics"
          />
        </CardBody>
      </Card>
    </>
  );
};

export default QuestionBankReport;
