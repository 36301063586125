
export enum CommentType {
  "REVOCATION" = "revocation",
  "ACCEPT" = "accept",
  "MODIFY" = "modify",
  "REVOKE" = "revoke",
  "APPROVED" = "approved",
}

interface CommentInfo {
  commentQuestionId: any;
  commentAuthor: any;
  commentParent: any;
  commentContent: string;
  commentDate: Date;
  commentType: string;
  createdOn?: string;
}
class Comment {
  commentQuestionId: any;
  commentAuthor: any;
  commentParent: any;
  commentContent: string;
  commentDate: Date;
  commentType: string;
  createdOn?: string;

  constructor(commentInfo: CommentInfo) {
      this.commentQuestionId = commentInfo.commentQuestionId;
      this.commentAuthor = commentInfo.commentAuthor;
      this.commentParent = commentInfo.commentParent;
      this.commentContent = commentInfo.commentContent;
      this.commentDate = commentInfo.commentDate;
      this.commentType = commentInfo.commentType;
      this.createdOn = commentInfo.createdOn;
  }

  toObject(): any {
      return {
        commentQuestionId: this.commentQuestionId,
        commentAuthor: this.commentAuthor,
        commentParent: this.commentParent,
        commentContent: this.commentContent,
        commentDate: this.commentDate,
        commentType: this.commentType,
        createdOn: this.createdOn,

      };
  }

  load(data: any): Comment {
      return new Comment(data);
  }

}

export default Comment;
