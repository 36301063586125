import {
  SET_CANDIDATES,
  SET_GRID,
  SET_HISTORICAL_GRID,
  SET_QUESTIONS,
  SET_RESULTS,
} from '../actionTypes';
import Candidate from '../../../lib/common/models/candidate';

export type MarkersActionTypes =
  | SetQuestions
  | SetCandidates
  | SetMarkerGrid
  | SetHistoricalGrid
  | SetResults;

interface SetQuestions {
  type: typeof SET_QUESTIONS;
  payload: any[];
}
interface SetCandidates {
  type: typeof SET_CANDIDATES;
  payload: any[];
}

interface SetMarkerGrid {
  type: typeof SET_GRID;
  payload: any[];
}

interface SetHistoricalGrid {
  type: typeof SET_HISTORICAL_GRID;
  payload: any[];
}

interface SetResults {
  type: typeof SET_RESULTS;
  payload: any;
}

export function setCandidates(candidates: Candidate[]): MarkersActionTypes {
  return {
    type: SET_CANDIDATES,
    payload: candidates,
  };
}

export function setMarkerGrid(grid: any[]): MarkersActionTypes {
  return {
    type: SET_GRID,
    payload: grid,
  };
}

export function setHistoricalGrid(grid: any[]): MarkersActionTypes {
  return {
    type: SET_HISTORICAL_GRID,
    payload: grid,
  };
}

export function setQuestions(questions: any[]): MarkersActionTypes {
  return {
    type: SET_QUESTIONS,
    payload: questions,
  };
}

export function setResults(results: any): MarkersActionTypes {
  return {
    type: SET_RESULTS,
    payload: results,
  };
}
