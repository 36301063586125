export enum CandidateEnrolmentTypeLabels {
    MOTOR_EK = "MOTOR_EK",
    STEAM_EK = "STEAM_EK",
    COMBINED_EK = "COMBINED_EK",
    MOTOR_ENDORSEMENT = "MOTOR_ENDORSEMENT",
    STEAM_ENDORSEMENT = "STEAM_ENDORSEMENT"
}

export const CandidateEnrolmentTypes = {
    [CandidateEnrolmentTypeLabels.MOTOR_EK]: "Motor and General",
    [CandidateEnrolmentTypeLabels.STEAM_EK]: "Steam and General",
    [CandidateEnrolmentTypeLabels.COMBINED_EK]: "Combined (Steam, General and Motor)",
    [CandidateEnrolmentTypeLabels.MOTOR_ENDORSEMENT]: "Motor Endorsement",
    [CandidateEnrolmentTypeLabels.STEAM_ENDORSEMENT]: "Steam Endorsement",
}

interface CandidateEnrolmentInfo {
    candidateId: any;
    navigationExamId: any;
    courseCompletion?: boolean;
    isVoid?: boolean;
    noShow?: boolean;
    type?: string;
    comment?: string;
    _id?: string;
}

class CandidateEnrolment {
    candidateId: any;
    navigationExamId: any;
    courseCompletion?: boolean;
    isVoid?: boolean;
    noShow?: boolean;
    type?: string;
    comment?: string;
    _id?: string;

    constructor(candidateEnrolmentInfo: CandidateEnrolmentInfo) {
        this.candidateId = candidateEnrolmentInfo.candidateId;
        this.navigationExamId = candidateEnrolmentInfo.navigationExamId;
        this.courseCompletion = candidateEnrolmentInfo.courseCompletion;
        this.isVoid = candidateEnrolmentInfo.isVoid;
        this.noShow = candidateEnrolmentInfo.noShow;
        this.type = candidateEnrolmentInfo.type;
        this.comment = candidateEnrolmentInfo.comment;
        this._id = candidateEnrolmentInfo._id;
    }

    load(data: any): CandidateEnrolment {
        return new CandidateEnrolment(data);
    }

    toObject(): any {
        return {
            candidateId: this.candidateId,
            navigationExamId: this.navigationExamId,
            courseCompletion: this.courseCompletion,
            isVoid: this.isVoid,
            noShow: this.noShow,
            type: this.type,
            comment: this.comment,
            _id: this._id
        }
    }

    empty(): CandidateEnrolment {
        return new CandidateEnrolment({ candidateId: "", navigationExamId: "", courseCompletion: false, type: '' });
    }
}

export default CandidateEnrolment;
