import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';

import ApiCaller from '../../../lib/ApiCaller';
import DocumentsApi from '../../../api/DocumentsApi';

import './image.css';

interface gallery {
  dispachClick: Function;
}

const ImageGalleryComp: React.FC<gallery> = ({ dispachClick }) => {
  const [status, setStatus] = useState<boolean>(false);
  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    new DocumentsApi(new ApiCaller())
      .getAllImages()
      .then((img: any) => setImages(img));
  }, [status]);

  return (
    <ImageGallery
      items={images}
      showBullets={false}
      showFullscreenButton={false}
      showPlayButton={false}
      showThumbnails={true}
      lazyLoad={true}
      onClick={(e: any) => dispachClick(e)}
    />
  );
};
export default ImageGalleryComp;
