import ExamCenter from "./examCenter";

class ExamCenterCollection {
    centers: Array<ExamCenter>;

    constructor(centers: Array<ExamCenter>) {
        this.centers = centers
    }

    length() {
        return this.centers.length;
    }
}

export default ExamCenterCollection;
