import React, { useCallback } from "react";
import { AvCandidatesProps } from '../types';
import { Bar } from 'react-chartjs-2';
import { splitSylabus } from '../helpers'
import './style.css'

const AvCandidates: React.FC<AvCandidatesProps> = ({
    data,
}) => {
    let data1 = splitSylabus(data, "S01")
    let data2 = splitSylabus(data, "S02")
    let data3 = splitSylabus(data, "S03")
    let data4 = splitSylabus(data, "S04")

    let dataObj1 = {
        code: data1.map(item => item.code.replace('S01-', '')),
        exams: data1.map(item => item.exams),
        avgCandidate: data1.map(item => item.avgCandidates),
    }

    let dataObj2 = {
        code: data2.map(item => item.code.replace('S02-', '')),
        exams: data2.map(item => item.exams),
        avgCandidate: data2.map(item => item.avgCandidates),
    }

    let dataObj3 = {
        code: data3.map(item => item.code.replace('S03-', '')),
        exams: data3.map(item => item.exams),
        avgCandidate: data3.map(item => item.avgCandidates),
    }

    let dataObj4 = {
        code: data4.map(item => item.code.replace('S04-', '')),
        exams: data4.map(item => item.exams),
        avgCandidate: data4.map(item => item.avgCandidates),
    }

    const ticksCallback = useCallback((value: number) => {
        if (value % 1 === 0) {
            return value;
        }
    }, []);

    const getData = (item: any, title: string) => {
        const barOptions = {
            title: {
                display: true,
                text: `${title}`,
            },
            legend: {
                position: 'bottom',
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            callback: ticksCallback,
                        },
                    },
                ],
            },
        };
        const dataBar = {
            labels: [...item.code],
            datasets: [
                {
                    label: 'Exams',
                    data: [...item.exams],
                    backgroundColor: '#fcc468',
                },
                {
                    label: 'Avg Candidate',
                    data: [...item.avgCandidate],
                    backgroundColor: '#E6B25E',
                },
            ],
        }
        return (
            <div className="AvgCandidate-table__bar">
                <Bar options={barOptions} data={dataBar} />
            </div>
        )
    }
    return (
        <div className={`AvgCandidate-table__container`}>
            {dataObj1["code"].length != 0 && getData(dataObj1, "YDES")}
            {dataObj2["code"].length != 0 && getData(dataObj2, "EKES")}
            {dataObj3["code"].length != 0 && getData(dataObj3, "Fishing")}
            {dataObj4["code"].length != 0 && getData(dataObj4, "ETO")}
        </div>
    );
};

export default AvCandidates;