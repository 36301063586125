import ApiCaller from '../lib/ApiCaller';
import ExamPayment from '../lib/common/models/examPayment';
import ExamPaymentCollection from '../lib/common/models/examPaymentCollection';

class ExamPaymentApi {
  static releaseExamPayments(
    examPayments: string[],
    token?: string,
  ): Promise<any> {
    const apiCaller = new ApiCaller();
    return apiCaller
      .call('/v1/examPayment/release', 'PUT', { examPayments }, token)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getAwaitingExamPayments(token?: string): Promise<ExamPaymentCollection> {
    const apiCaller = new ApiCaller();
    return apiCaller
      .call('/v1/examPayment/getAwaitingExamPayments', 'GET', undefined, token)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        const payments = data.payments.map((payment: any) => {
            return new ExamPayment(payment);
          });
        return new ExamPaymentCollection(payments);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getGeneratedExamPayments(
    startDate?: moment.Moment,
    endDate?: moment.Moment,
    token?: string,
  ): Promise<ExamPayment[]> {
    const apiCaller = new ApiCaller();

    let url = '/v1/examPayment/getGeneratedExamPayments';
    
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';

    if (dateStart && dateEnd) {
      url = `${url}?startDate=${dateStart}&endDate=${dateEnd}`;
    }

    return apiCaller
      .call(url, 'GET', undefined, token)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data.payments.map((payment: any) => {
            return new ExamPayment(payment);
          });
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }
}

export default ExamPaymentApi;
