import React, { useState } from 'react';
import ImageGalleryComp from '../../components/ImageGalleryComp';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import QuestionUploadPictures from '../admin/question/questionImageUpload';
import ReactBSAlert from 'react-bootstrap-sweetalert';

const ImageGallery: React.FC = () => {
  const [alert, setAlert] = useState<any>();

  const imageClick = (event: any) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure to delete this image?"
        onConfirm={() => successDelete()}
        onCancel={() => cancelDetele()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize="">
        You will not be able to recover this image!
      </ReactBSAlert>,
    );
  };
  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: 'block', marginTop: '-100px' }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize="">
        Your image has been deleted.
      </ReactBSAlert>,
    );
  };
  const cancelDetele = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: 'block', marginTop: '-100px' }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize="">
        We keep this image in the gallery
      </ReactBSAlert>,
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Label sm="2">Upload</Label>
          <Col sm="10">
            <QuestionUploadPictures
              questionName="UPLOADED_FROM_ADMIN"
              uploadFromGallery={false}
            />
          </Col>
        </Row>
        <Row>
          <Label sm="2">Gallery</Label>
          <Col className="text-center" sm="10">
            <Card>
              <CardHeader>
                <h4 className="card-title">Image Gallery</h4>
              </CardHeader>
              <CardBody>
                <ImageGalleryComp dispachClick={imageClick} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {alert}
    </>
  );
};

export default ImageGallery;
