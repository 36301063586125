
class GenericCollection {
  items: Array<any>;

  constructor(item: Array<any>) {
    this.items = item;
  }

  length() {
    return this.items.length;
  }
}

export default GenericCollection;
