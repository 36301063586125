import React from 'react';

import ReactBSAlert from 'react-bootstrap-sweetalert';

type ConfirmationModalProps = {
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
  text?: string;
  showConfirm?: boolean;
  style?: React.CSSProperties;
  processingAction?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  onConfirm,
  onCancel,
  text,
  showConfirm,
  style,
  children,
  processingAction = false,
  confirmBtnText = "Yes",
  cancelBtnText = "No",

}) => {
  return (
    <ReactBSAlert
      style={{ ...{ display: 'block' }, ...style }}
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="outline-info"
      btnSize=""
      confirmBtnText={confirmBtnText}
      cancelBtnText={cancelBtnText}
      showConfirm={showConfirm}
      showCancel={true}
      disabled={processingAction}
    >
      {<p>{text}</p>}
      {children}
    </ReactBSAlert>
  );
};

export default ConfirmationModal;
