import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../store/store';

import ApiCaller from '../../../../lib/ApiCaller';
import PaymentApi from 'api/PaymentApi';
import TransactionCollection from '../../../../lib/common/models/transactionCollection';

import {
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import { formatCurrency } from '../../../../shared/utils/currency';
import {
  PaymentMethod,
  TransactionStatus,
} from '../../../../lib/common/models/transaction';

import OperationHistoryList from './operationHistoryList';

import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'reactstrap';
import TransactionApi from '../../../../api/TransactionApi';
import { toast } from 'react-toastify';

interface PaymentListProps {
  centreId?: string;
  refresh: boolean;
}

const PaymentList: React.FC<PaymentListProps> = ({ centreId, refresh }) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [payments, setPayments] = useState<TransactionCollection>(
    new TransactionCollection([]),
  );

  const [paymentsUpdate, setPaymentsUpdate] = useState<boolean>(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const [idToReverse, setId] = useState<string>('');

  const handle_click = (id: string) => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
    revertTransaction(id);
  };

  const revertTransaction = useCallback(
    (id: string) => {
      const transactionApi = new TransactionApi(new ApiCaller());
      const userId = loggedUser._id ? loggedUser._id : '';
      transactionApi
        .revertTransaction(id, userId, loggedUser.token)
        .then((res) => {
          if (res && res.error) {
            toast.error(res.error);
            return;
          }
          setPaymentsUpdate(!paymentsUpdate);
          toast.success('Transaction reverted successfully');
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    [loggedUser._id, loggedUser.token, paymentsUpdate],
  );

  const getActions = useCallback(() => {
    if (loggedUser.isPaymentAdmin()) {
      return [
        {
          Header: 'ACTIONS',
          Cell: (row: any) => {
            return (
              <div>
                <Button
                  className="btn-round"
                  color="info"
                  onClick={() => {
                    setIsConfirmationModalOpen(!isConfirmationModalOpen);
                    setId(row.row.original._id);
                  }}>
                  REVERT
                </Button>
              </div>
            );
          },
        },
      ];
    }
    return [];
  }, [loggedUser]);

  const columns = [
    {
      Header: 'TIMESTAMP',
      accessor: (d: any) => formatDateTime(d.timestamp),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'timestamp'),
    },
    { Header: 'EXAM CENTRE', accessor: 'entityId.name', sortable: true },
    { Header: 'CREATED BY', accessor: 'userId.name', sortable: true },
    {
      Header: 'AMOUNT',
      accessor: (d: any) => formatCurrency(d.amount, d.currency),
      sortable: true,
    },
    {
      Header: 'REFERENCE',
      accessor: (d: any) => (d.reference ? d.reference : '-'),
      sortable: true,
    },
    {
      Header: 'PAYMENT METHOD',
      accessor: (d: any) =>
        d.paymentMethod ? PaymentMethod[d.paymentMethod] : '',
      sortable: true,
    },
    {
      Header: 'DESCRIPTION',
      accessor: (d: any) => (d.description ? d.description : ''),
      sortable: true,
    },
    {
      Header: 'STATUS',
      accessor: (d: any) => (d.status ? TransactionStatus[d.status] : ''),
      sortable: true,
    },
      ...getActions()
  ];

  useEffect(() => {
    const paymentApi = new PaymentApi(new ApiCaller(loggedUser.token));

    paymentApi.getPayments(centreId).then((payments: any) => {
      setPayments(payments);
    });
  }, [refresh, loggedUser.token, paymentsUpdate]);

  return (
    <OperationHistoryList
      refresh={refresh}
      listTitle="Payments History"
      operations={payments}
      columns={columns}
      modalopen={isConfirmationModalOpen}
      setmodalopen={setIsConfirmationModalOpen}
      handleClick={handle_click}
      idToRevert={idToReverse}
    />
  );
};

export default PaymentList;
