import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateListFilters, updateListPagination, updateListSorting } from 'store/actions/lists/listsActions';
import { Pagination } from 'store/reducers/listsReducer';
import { AppState } from 'store/store';

const useTableControls = (listName: string) => {
  const dispatch = useDispatch();
  const filters = useSelector((state: AppState) => state.lists[listName].filters);
  const sorting = useSelector((state: AppState) => state.lists[listName].sorting);
  const pagination: Pagination = useSelector((state: AppState) => state.lists[listName].pagination);

  const onChangeFilters = useCallback((filters: any[]) => {
    dispatch(updateListFilters(filters, listName));
  }, [dispatch, listName]);

  const onChangeSorting = useCallback((sorting: any[]) => {
    dispatch(updateListSorting(sorting, listName));
  }, [dispatch, listName]);

  const onChangePagination = useCallback((pagination: Pagination) => {
    dispatch(updateListPagination(pagination, listName));
  }, [dispatch, listName]);

  return {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  };
};

export default useTableControls;
