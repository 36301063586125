import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast, ToastContainer } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Form,
  Modal,
  Row,
  Col,
  Input,
} from 'reactstrap';
import { AppState } from 'store/store';
import ApiCaller from 'lib/ApiCaller';
import NZDocument, {
  NZDocumentType,
} from 'lib/common/models/nzDocument';
import ExamResourceApi from 'api/ExamResourceApi';
import { DocumentDownloadContainer } from '../../../components/documents/ExamScriptUploader/compStyle';
import CustomDropZoneLayout from '../../../components/documents/FileUploaderDropZone';

import './style.css';
import DocumentPreviewModal from 'views/components/documents/DocumentPreviewModal/DocumentPreviewModal';
import DetailLayout from 'layouts/DetailLayout';
import QuestionApi from 'api/QuestionApi';

const ExamResourceCreate: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [resourceDocument, setResourceDocument] = useState<NZDocument>();
  const [modalDocument, setModalDocument] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const history = useHistory();

  const createResource = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!name) {
        toast.error('Resource name is required');
        return;
      }
      let body:any = {
        name,
        description,
      };
      if (resourceDocument && resourceDocument.id) {
        body = {
          ...body,
          resource: resourceDocument.id,
        };
      }
      const examResourceApi = new ExamResourceApi(new ApiCaller());
      examResourceApi
        .createResource(
          body,
          loggedUser.token,
        )
        .then(() => {
          history.goBack();
        })
        .catch((err) => {
          toast.error('Creating failed');
          console.log(err);
        });
    },
    [resourceDocument, name, description, loggedUser],
  );

  const cancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSubmitUploading = (data: NZDocument) => {
    setResourceDocument(data);
  };

  const removeFileResource = () => {
    const token = loggedUser.token;
    const questionApi = new QuestionApi(new ApiCaller(token));
    if (resourceDocument) {
      questionApi
        .removeQuestionImage(resourceDocument.fileHash)
        .then(() => {
          setResourceDocument(undefined);
          toast.success('File of resource was deleted successfully');
        })
        .catch((err) => {
          toast.error('Update failed.');
          console.error(err);
        });
    }
  };

  const renderExamResource = () => {
    return (
      <>
        <CustomDropZoneLayout
          onFinish={handleSubmitUploading}
          type={NZDocumentType.EXAM_RESOURCE}
          id={resourceDocument?.id}
        />
        {resourceDocument && (
          <DocumentDownloadContainer
            className="resource-download-container"
            onClick={(evt: any) => {
              evt.preventDefault();
              if (evt.target.className.includes('resource-remove')) {
                removeFileResource();
              } else {
                setModalDocument(!modalDocument);
              }
            }}
            >
            <p>{resourceDocument.displayName}</p>
            <Button
              variant="danger"
              title="Remove file of resource"
              size="sm"
              className="resource-remove">
              <i className="nc-icon resource-remove nc-simple-remove" />
            </Button>
          </DocumentDownloadContainer>
        )}
      </>
    );
  };

  return (
    <>
      <div className="content">
        <DetailLayout title="Go back">
          <Card>
            <CardHeader>
              <h4 className="card-title">Create Exam Resource</h4>
            </CardHeader>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label sm="2">Resource Name</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        value={name}
                        type="text"
                        name="name"
                        disabled={!loggedUser.isAdmin()}
                        onChange={(e: any) => setName(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Resource Description</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        value={description}
                        type="text"
                        name="description"
                        disabled={!loggedUser.isAdmin()}
                        onChange={(e: any) => setDescription(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Exam Resource</Label>
                  <Col sm="10">
                    <FormGroup>{renderExamResource()}</FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2" />
                  <Col sm="10">
                    <Button
                      className="btn-round"
                      color="info"
                      onClick={createResource}>
                      Save
                    </Button>
                    <Button
                      className="btn-round btn btn-danger"
                      onClick={cancel}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </DetailLayout>
        {resourceDocument && (
          <DocumentPreviewModal
            isOpen={modalDocument}
            onClose={() => setModalDocument(false)}
            document={resourceDocument}
            title="Exam Resource"
          />
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default ExamResourceCreate;
