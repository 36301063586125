const ACCEPT_MESSAGE = 'Are you sure you want to send the question to the';
const REVOKE_MESSAGE =
  'Are you sure you want to send back the question to the Writer?';

const defaultConfigTarget = {
  admin: {
    ACCEPT: `${ACCEPT_MESSAGE} Moderator?`,
    REVOKE: REVOKE_MESSAGE,
  },
  questionWriter: {
    ACCEPT: `${ACCEPT_MESSAGE} Moderator?`,
  },
  questionModerator: {
    ACCEPT: `${ACCEPT_MESSAGE} MCA Examiner?`,
    REVOKE: REVOKE_MESSAGE,
  },
  mcaExaminer: {
    ACCEPT: `${ACCEPT_MESSAGE} IAMI Exam Administrator?`,
    REVOKE: REVOKE_MESSAGE,
  },
  IAMIExamAdmin: {
    ACCEPT: `${ACCEPT_MESSAGE} Question Bank?`,
    REVOKE: REVOKE_MESSAGE,
  },
  IAMIExamManager: {
    ACCEPT: `${ACCEPT_MESSAGE} Question Bank?`,
    REVOKE: REVOKE_MESSAGE,
  },
};
const getMessageBySyllabus = (
  syllabus: string,
): { [index: string]: { [index: string]: string } } => {
  let configTarget = {};
  switch (syllabus) {
    case 'S03':
      configTarget = {
        ...defaultConfigTarget,
        questionModerator: {
          ...defaultConfigTarget.questionModerator,
          ACCEPT: `${ACCEPT_MESSAGE} Question Bank?`,
        },
      };
      break;
    case 'S04':
      configTarget = {
        ...defaultConfigTarget,
        questionModerator: {
          ...defaultConfigTarget.questionModerator,
          ACCEPT: `${ACCEPT_MESSAGE} IAMI Exam Manager?`,
        },
        IAMIExamManager: {
          ACCEPT: `${ACCEPT_MESSAGE} MCA Examiner?`,
          REVOKE: REVOKE_MESSAGE,
        },
        IAMIExamAdmin: {
          ACCEPT: `${ACCEPT_MESSAGE} MCA Examiner?`,
          REVOKE: REVOKE_MESSAGE,
        },
        mcaExaminer: {
          ACCEPT: `${ACCEPT_MESSAGE} Question Bank?`,
          REVOKE: REVOKE_MESSAGE,
        },
      };
      break;

    default:
      configTarget = defaultConfigTarget;
      break;
  }
  return configTarget;
};
export const getMessages = (
  userType: string,
  action: 'ACCEPT' | 'REVOKE',
  syllabus: string,
): string => {
  const messages = getMessageBySyllabus(syllabus);
  return messages[userType][action];
};
