import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  CardTitle,
  ModalHeader,
  ModalBody,
  Modal,
} from 'reactstrap';

import { AppState } from 'store/store';
import ApiCaller from 'lib/ApiCaller';
import QuestionApi from 'api/QuestionApi';
import ExaminationApi from 'api/NavigationExamApi';
import FilteredDateComponent from '../../../components/common/filteredDateComponent';
import NavigationExamPayComponent from '../../../components/payment/navigationExamPayComponent';
import { PaymentTypeLabels } from 'lib/common/models/transaction';
import { ScriptIntentions } from 'lib/common/models/navigationExam';
import { generateErrors, is } from 'shared/utils/validations';
import Syllabus from 'lib/common/models/syllabus';
import Module from 'lib/common/models/module';
import DetailLayout from 'layouts/DetailLayout';
import { useSyllabus } from 'hooks';

interface ExamRequestForm {
  syllabus: Syllabus | undefined;
  module: Module | undefined;
  date: Date;
  scriptIntention: string;
}

const NavigationExamCreate: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const history = useHistory();
  const { syllabus } = useSyllabus();
  const [modules, setModules] = useState<any[]>([]);

  const [form, setState] = React.useState<ExamRequestForm>({
    syllabus: undefined,
    module: undefined,
    date: new Date(),
    scriptIntention: '',
  });
  const [modalPayment, setModalPayment] = useState<boolean>(false);
  const [noDatesAvailable, setNoDatesAvailable] = useState<boolean>(false);

  const onSyllabusChange = (e: any) => {
    setState({
      ...form,
      syllabus: e,
    });

    const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
    questionApi.getModulesFromSyllabus(e._id).then((m: any) => {
      setModules(m.modules);
    });
  };

  const onModuleChange = (e: any) => {
    setState({
      ...form,
      module: e,
    });
  };

  const onDateChange = (e: any) => {
    setState({
      ...form,
      date: e,
    });
  };

  const onSelectChange = (e: any) => {
    setState({
      ...form,
      scriptIntention: e.value,
    });
  };

  const isLateRequest = () => {
    const weekAfter = moment().add(1, 'week');
    return weekAfter.isAfter(form.date);
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (checkErrors()) return;
    if (form?.syllabus?.title === 'YDES') {
      const examinationApi = new ExaminationApi(
        new ApiCaller(loggedUser.token),
      );
      examinationApi
        .checkExamRequestsLimit(form.syllabus, form.module, form.date)
        .then((isLimitExceeded) => {
          if (!isLimitExceeded) {
            isLateRequest() ? setModalPayment(true) : examCreate();
          } else {
            toast.error(
              'Exceeded the limit of YDES exam requests for the current module',
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      isLateRequest() ? setModalPayment(true) : examCreate();
    }
  };

  const examCreate = (transaction?: any) => {
    const examinationApi = new ExaminationApi(new ApiCaller());
    examinationApi
      .create(
        form.syllabus,
        form.module,
        form.date,
        form.scriptIntention,
        transaction?._id,
        loggedUser.token,
      )
      .then(() => {
        history.push(`/${layout}/navigation_exam/list`);
        toast.info('Exam requested successfully');
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(
          `${err || 'Error creating navigation exam, please try again'}`,
        );
      });
  };

  const checkErrors = () => {
    const syllabus = form.syllabus;
    const module = form.module;
    const date = form.date;
    const scriptIntention = form.scriptIntention;

    const errors: any = generateErrors(
      { syllabus, module, date, scriptIntention },
      {
        syllabus: [is.required()],
        module: [is.required()],
        date: [is.required()],
        scriptIntention: [is.required()],
      },
    );

    if (errors.syllabus) toast.error(errors.syllabus);
    if (errors.module) toast.error(errors.module);
    if (errors.date) toast.error(errors.date);
    if (errors.scriptIntention) toast.error(errors.scriptIntention);
    return Object.keys(errors).length !== 0;
  };

  return (
    <div className="content">
      <DetailLayout title="Go back">
        <Card>
          <Row>
            <Col md="12">
              <CardHeader>
                <CardTitle tag="h4">New Exam Request</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal" onSubmit={formSubmit}>
                  <Row>
                    <Label sm="2">Syllabus</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="syllabus"
                          value={form?.syllabus}
                          onChange={onSyllabusChange}
                          options={syllabus}
                          getOptionLabel={(item) =>
                            item.code + ' ' + item.title
                          }
                          getOptionValue={(item) => item.code}
                          placeholder="Choose a syllabus"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Module</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="module"
                          value={form?.module}
                          onChange={onModuleChange}
                          options={modules}
                          getOptionLabel={(item) =>
                            item.code + ' ' + item.title
                          }
                          getOptionValue={(item) => item.code}
                          placeholder="Choose a module"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {form.syllabus && form.module && (
                    <>
                      <Row>
                        <Label sm="2">Date & Time</Label>
                        <Col sm="10">
                          <FormGroup
                            className={
                              isLateRequest() ? `has-label has-danger` : ``
                            }>
                            <>
                              <FilteredDateComponent
                                date={form?.date}
                                setDate={onDateChange}
                                module={form?.module}
                                setException={setNoDatesAvailable}
                              />
                              {isLateRequest() && form.module && (
                                <label className="error">
                                  Late exam request fee will be applied for this
                                  exam.
                                </label>
                              )}
                            </>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Script Intention</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="scriptIntention"
                              value={{
                                label: ScriptIntentions[form?.scriptIntention],
                                value: form?.scriptIntention,
                              }}
                              onChange={onSelectChange}
                              options={Object.keys(ScriptIntentions).map(
                                function (type) {
                                  return {
                                    value: type,
                                    label: ScriptIntentions[type],
                                  };
                                },
                              )}
                              placeholder="Script Intention"
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2" />
                        <Col sm="10">
                          <Button
                            className="btn-round"
                            color="info"
                            type="submit"
                            disabled={
                              noDatesAvailable || !form.scriptIntention
                            }>
                            Schedule Exam
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form>
              </CardBody>
            </Col>
          </Row>
        </Card>
        <ToastContainer />
        <Modal isOpen={modalPayment}>
          <ModalHeader>Late Exam Request Fee</ModalHeader>
          <ModalBody>
            <NavigationExamPayComponent
              onCancel={() => setModalPayment(false)}
              onSuccess={examCreate}
              paymentType={PaymentTypeLabels.LATE_FEE}
              reference={''}
            />
          </ModalBody>
        </Modal>
      </DetailLayout>
    </div>
  );
};

export default NavigationExamCreate;
