import React, { useState } from 'react';
import type { FC } from 'react';
import Module from 'lib/common/models/module';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

export type IFilterModule = {
  title: string;
  moduleId: string | undefined;
};

type IFilterModules = {
  onSetFilter: (filter: any) => void;
  modules: Module[];
};

const FilterModule: FC<IFilterModules> = ({ onSetFilter, modules }) => {
  const defaultFilter = {
    title: 'All modules',
    moduleId: undefined,
  };

  const [moduleSelected, setModuleSelected] =
    useState<IFilterModule>(defaultFilter);
  const onFilter = (mod: Module | null) => {
    if (mod) {
      setModuleSelected({
        title: `${mod.code}-${mod.title}`,
        moduleId: mod._id,
      });
    } else {
      setModuleSelected(defaultFilter);
    }
    onSetFilter(mod);
  };
  return (
    <>
      <UncontrolledDropdown className={`filter-button_exam`}>
        <DropdownToggle caret>{moduleSelected.title}</DropdownToggle>
        <DropdownMenu className="filter-dropdown_scrollable">
          <DropdownItem onClick={() => onFilter(null)}>
            {defaultFilter.title}
          </DropdownItem>
          {modules.map((modul: Module) => {
            return (
              <DropdownItem key={modul._id} onClick={() => onFilter(modul)}>
                {modul.code}-{modul.title}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default FilterModule;
