import React, {FC} from 'react';
import Select from 'react-select';

export type SelectValueType = {
  label: string;
  value: string;
};

type SelectInputProps = {
  className?: string;
  name: string;
  onChange: (selectValue: SelectValueType) => void;
  value: SelectValueType;
  options: SelectValueType[];
  placeholder?: string;
};

const SelectInput: FC<SelectInputProps> = ({
  className = '',
  name,
  onChange,
  value,
  options,
  placeholder = '',
}) => {
  const handleSelectionChange = (selectedValue: any) => {
    onChange(selectedValue);
  };
  return (
    <Select
      className={`react-select primary ${className}`}
      classNamePrefix="react-select"
      name={name}
      value={value}
      onChange={handleSelectionChange}
      options={options}
      placeholder={placeholder}
    />
  );
};

export default SelectInput;
