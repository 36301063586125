import ApiCaller from '../lib/ApiCaller';
import MarkerPayment from '../lib/common/models/markerPayment';
import MarkerPaymentCollection from '../lib/common/models/markerPaymentCollection';

class MarkerPaymentApi {
  static releaseMarkerPayments(
    markerPayments: string[],
    token?: string,
  ): Promise<any> {
    const apiCaller = new ApiCaller();
    return apiCaller
      .call('/v1/markerPayment/release', 'PUT', { markerPayments }, token)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getNonPaidMarkerPaymentsReport(token?: string): Promise<any> {
    const apiCaller = new ApiCaller(token);
    return apiCaller
      .call('/v1/markerPayment/nonPaidMarkerPayments', 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getGeneratedMarkerPayments(
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    token?: string,
  ): Promise<any> {
    const apiCaller = new ApiCaller(token);

    let url = '/v1/markerPayment/generated';
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';

    if (dateStart && dateEnd) {
      url = `${url}?startDate=${dateStart}&endDate=${dateEnd}`;
    }

    return apiCaller
      .call(url, 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data.payments.map((payment: any) => {
          return new MarkerPayment(payment);
        });

        // return new MarkerPaymentCollection(payments);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getGeneratedMarkerPaymentsForMarker(
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    token?: string,
  ): Promise<any> {
    const apiCaller = new ApiCaller(token);

    let url = '/v1/markerPayment/marker/generatedPayments';
    const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
    const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';

    if (dateStart && dateEnd) {
      url = `${url}?startDate=${dateStart}&endDate=${dateEnd}`;
    }

    return apiCaller
      .call(url, 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return data.payments.map((payment: any) => {
          return new MarkerPayment(payment);
        });

        // return new MarkerPaymentCollection(payments);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  static getNonPaidMarkerPaymentsForMarker(
    token?: string,
  ): Promise<any> {
    const apiCaller = new ApiCaller(token);

    return apiCaller
      .call('/v1/markerPayment/marker/nonPaidPayments', 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        const payments = data.payments.map((payment: any) => {
          return new MarkerPayment(payment);
        });

        return new MarkerPaymentCollection(payments);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }
}

export default MarkerPaymentApi;
