import React, { useEffect, useMemo, useState } from 'react';
import {
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Card,
  Col,
  Button,
} from 'reactstrap';

import DashboardApi from 'api/DashboardApi';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import ExamCenterFilter from 'views/components/filteredComponents/filters/examCenterFilter';
import { onTableExport } from 'shared/utils/excel';
import FilteredComponent from 'views/components/filteredComponents/filteredComponent';
import ReactTable from 'components/ReactTable/ReactTable';
import SyllabusFilter from '../syllabusFilter/SyllabusFilter';

type ExamCentresMarksReportProps = {
  allowedSyllabuses: string[];
};

const ExamCentresMarksReport = ({
  allowedSyllabuses,
}: ExamCentresMarksReportProps) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const tableName = loggedUser.isExamCentre()
    ? 'Marks Analytics'
    : 'Marks Analytics by Module';
  const [tableData, setTableData] = useState<any>([]);
  const [syllabus, setSyllabus] = useState<string>('');
  const [filterParams, setFilterParams] = useState<any>({});

  const syllabuses = useMemo(() => {
    return new Set(tableData.map((item: any) => item.syllabus));
  }, [tableData]);

  useEffect(() => {
    if (!syllabus && syllabuses.size) {
      setSyllabus(syllabuses.values().next().value);
    }
  }, [syllabus, syllabuses]);

  const filteredData = useMemo(() => {
    if (!syllabus) {
      return tableData;
    }
    return tableData.filter((item: any) => item.syllabus === syllabus);
  }, [tableData, syllabus]);

  const filteredDataExcel = useMemo(() => {
    if (tableData.length) {
      const tableFiltered = tableData.filter(
        (item: any) => item.syllabus === syllabus,
      );
      return tableFiltered.map((item: any) => {
        const { module, moduleName, count, minMark, maxMark, avgMark } = item;
        return {
          module,
          moduleName,
          minMark: count !== 0 ? minMark + '%' : 'N/A',
          maxMark: count !== 0 ? maxMark + '%' : 'N/A',
          avgMark: count !== 0 ? avgMark + '%' : 'N/A',
        };
      });
    }
    return [];
  }, [tableData, syllabus]);

  const columns = [
    {
      Header: 'MODULE CODE',
      accessor: 'module',
      Cell: ({ value }: any) => `${value}`,
      Footer: () => null,
      sortable: true,
    },
    {
      Header: 'MODULE TITLE',
      accessor: 'moduleName',
      Cell: ({ value }: any) => `${value}`,
      Footer: () => null,
      sortable: true,
    },

    {
      Header: 'MIN MARK (%)',
      accessor: (data: any) =>
        `${data.count !== 0 ? data.minMark + '%' : 'N/A'}`,
      Footer: ({ data }: any) => {
        const total = useMemo(() => {
          return Math.min(
            ...data.reduce((results: number[], row: any) => {
              results.push(Number(row.minMark));
              return results;
            }, []),
          );
        }, [data]);
        return <b>{total.toFixed(2)}%</b>;
      },
      sortable: true,
    },
    {
      Header: 'MAX MARK (%)',
      accessor: (data: any) =>
        `${data.count !== 0 ? data.maxMark + '%' : 'N/A'}`,
      Footer: ({ data }: any) => {
        const total = useMemo(() => {
          return Math.max(
            ...data.reduce((results: number[], row: any) => {
              results.push(Number(row.maxMark));
              return results;
            }, []),
          );
        }, [data]);
        return <b>{total.toFixed(2)}%</b>;
      },
      sortable: true,
    },
    {
      Header: 'AVG MARK (%)',
      accessor: (data: any) =>
        `${data.count !== 0 ? data.avgMark + '%' : 'N/A'}`,
      Footer: ({ data }: any) => {
        const total = useMemo(() => {
          return data.reduce((result: number, row: any) => {
            return result + Number(row.avgMark);
          }, 0);
        }, [data]);
        return <b>{(total / data.length).toFixed(2)}%</b>;
      },
      sortable: true,
    },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h4">{tableName}</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <div className="filtered-table__container">
                <Row>
                  <Col>
                    <SyllabusFilter
                      disableButtons={{
                        S01: !syllabuses.has('S01'),
                        S02: !syllabuses.has('S02'),
                        S03: !syllabuses.has('S03'),
                        S04: !syllabuses.has('S04'),
                      }}
                      syllabus={syllabus}
                      setSyllabus={setSyllabus}
                      allowedSyllabuses={allowedSyllabuses}
                    />
                    {!loggedUser.isExamCentre() && (
                      <ExamCenterFilter onSetFilter={setFilterParams} />
                    )}
                  </Col>
                  <Col className="text-right">
                    <Button
                      onClick={() =>
                        onTableExport(tableName, columns, filteredDataExcel)
                      }
                      color="success"
                      className="btn-label">
                      <span>
                        <i className="nc-icon nc-cloud-download-93" />
                      </span>
                      Export to Excel
                    </Button>
                  </Col>
                </Row>
                <FilteredComponent
                  component={[
                    <ReactTable
                      data={filteredData}
                      columns={columns}
                      topPaginationClassName="table-pagination_top"
                      initialGroupBy={['syllabus']}
                      hasTotal
                    />,
                  ]}
                  fetchMethod={DashboardApi.getExamCentresMarksReport}
                  updateDataMethod={setTableData}
                  componentName="table"
                  examCenterFilter
                  params={filterParams}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ExamCentresMarksReport;
