import React from 'react';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';

import 'react-toastify/dist/ReactToastify.css';
import Switch from 'react-bootstrap-switch';

interface ContainerProps {}

const ModerationForm: React.FC<ContainerProps> = ({}) => {
  const questions: any[] = [
    'Question meets the syllabus',
    'The question is clear and unambiguous',
    'The question is grammatically correct with correct punctuation',
    'The question is gender neutral',
    'The question has the correct marks allocated, the marks for each section are clearly shown',
    'The answer matches the question and is at the expected EOOW / ETO operational level',
    'Management Level for SETO',
    'The answer is one that the diligent candidate should be able to give in the time allocated',
    'The answer has a marking scheme that clearly shows where marks are allocated',
    'The marking scheme is fair and proportionate for the knowledge and work required',
  ];

  return (
    <>
      {questions.map((q) => (
        <>
          <Row>
            <Label sm="5">{q}</Label>
            <Col sm="7">
              <Row>
                <Col md="3">
                  <FormGroup>
                    <Switch
                      onChange={() => {}}
                      defaultValue={false}
                      offColor="success"
                      offText={<i className="nc-icon nc-simple-remove" />}
                      onColor="success"
                      onText={<i className="nc-icon nc-check-2" />}
                    />
                  </FormGroup>
                </Col>
                <Col md="9">
                  <FormGroup>
                    <Input placeholder="comments" type="text" />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
        </>
      ))}
    </>
  );
};

export default ModerationForm;
