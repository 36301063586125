import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';

import ReactTable from '../../../../components/ReactTable/ReactTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import NavigationExamApi from '../../../../api/NavigationExamApi';

import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import MYDocument from '../../../../lib/common/models/myDocument';
import Exam from '../../../../lib/common/models/exam';
import { stateListNames } from 'store/reducers/listsReducer';
import {
  setCurrentDoc,
  setCurrentDrafts,
  setCurrentExam,
} from 'store/actions/docs/docsActions';
import { useClearListStateOnPageChange, useTableControls } from 'hooks';

import 'react-toastify/dist/ReactToastify.css';

const NavigationExamDraftList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const docs: MYDocument[] = useSelector(
    (state: AppState) => state.current.drafts,
  );

  const listName = stateListNames.examDrafts;

  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = loggedUser.token;
    const examApi = new NavigationExamApi(new ApiCaller());
    examApi
      .getDrafts(token)
      .then((item) => {
        dispatch(setCurrentDrafts(item));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useClearListStateOnPageChange(listName, [
    "/navigation_exam/edit",
    "/navigation_exam/generated/list",
  ]);

  const removeExam = (id: string) => {
    const token = loggedUser.token;
    const examApi = new NavigationExamApi(new ApiCaller());
    examApi
      .removeDraft(id!, token)
      .then((item) => {
        examApi
          .getDrafts(token!)
          .then((item) => {
            dispatch(setCurrentDrafts(item));
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const launchEditing: any = (row: any) => {
    dispatch(setCurrentDoc(new MYDocument(row)));
    dispatch(
      setCurrentExam(
        new Exam({
          title: '',
          module: '',
          date: new Date(),
          slug: '',
          examContent: {
            vars: [],
            content: [
              {
                _id: 'nothing',
                html: '',
                plainText: '',
                questionName: '',
                questionId: '',
                topicName: '',
                topicId: '',
                marker: 0,
              },
            ],
            outerTag: 'p',
            questions: 0,
          },
        }),
      ),
    );

    history.push(`/${layout}/navigation_exam/edit`);
  };

  const launch: any = (id: string) => {
    const examApi = new NavigationExamApi(new ApiCaller());
    examApi
      .launchDraft(id!)
      .then((item) => {
        examApi
          .getDrafts(loggedUser.token)
          .then((item) => {
            dispatch(setCurrentDrafts(item));
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { Header: 'SERIAL NO.', accessor: (d: any) => d.serialNumber ? d.serialNumber : '---', sortable: true },
    { Header: 'EXAM ID', accessor: (d: any) => d.customName, sortable: true },
    {
      Header: 'EXAM CENTRE',
      accessor: (d: any) => d.customDescription,
      sortable: true,
    },
    { Header: 'STATUS', accessor: (d: any) => d.status, sortable: true },
    {
      Header: 'DATE',
      accessor: (d: any) =>
        formatDateTime(d.expirationDate, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'expirationDate'),
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        loggedUser.hasAdminRole() &&
          <DropdownButton variant="default" title="Actions" size="sm">
            <Dropdown.Item
              style={{margin: 0}}
              onClick={() => launchEditing(row.row.original)}>
              <i className="nc-icon nc-settings" />
              &nbsp;&nbsp;EDIT
            </Dropdown.Item>
            <Dropdown.Item onClick={() => launch(row.row.original.id)}>
              <i className="nc-icon nc-send" />
              &nbsp;&nbsp;PUBLISH
            </Dropdown.Item>
            { loggedUser.isAdmin() &&
              <Dropdown.Item onClick={() => removeExam(row.row.original.id)}>
                <i className="nc-icon nc-simple-delete" />
                &nbsp;&nbsp;REMOVE
              </Dropdown.Item>
            }
          </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{'Exam Drafts'}</CardTitle>
              </CardHeader>
              <CardBody>
                {docs && (
                  <ReactTable
                    data={docs}
                    columns={columns}
                    initialFilters={filters}
                    onChangeFilters={onChangeFilters}
                    initialSorting={sorting}
                    onChangeSorting={onChangeSorting}
                    initialPagination={pagination}
                    onChangePagination={onChangePagination}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default NavigationExamDraftList;
