import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import { Card, CardHeader, CardBody } from 'reactstrap';

import { AppState } from '../../../../../store/store';
import ReactTable from '../../../../../components/ReactTable/ReactTable';
import ApiCaller from '../../../../../lib/ApiCaller';

import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../../shared/utils/dateTime';
import { NavigationExamStatus } from '../../../../../lib/common/models/navigationExam';
import Settings from '../../../../../lib/settings';
import NavigationExamApi from 'api/NavigationExamApi';
import ExamStatusLogCollection from 'lib/common/models/examStatusLogCollection';

const ExamStatusHistoryList = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [statuses, setStatuses] = React.useState<ExamStatusLogCollection>(
    new ExamStatusLogCollection([]),
  );

  useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();

    if (exam.id) {
      const examApi = new NavigationExamApi(new ApiCaller(loggedUser.token));
      examApi.getExamStatuses(exam.id).then((statuses: any) => {
        setStatuses(statuses);
      });
    }
  }, []);

  const columns = [
    {
      Header: 'TIMESTAMP',
      accessor: (d: any) =>
        formatDateTime(d.timestamp, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'timestamp'),
    },
    {
      Header: 'STATUS',
      accessor: (d: any) => (d.status ? NavigationExamStatus[d.status] : ''),
      sortable: true,
    },
    {
      Header: 'EDITOR',
      accessor: (d: any) => (d.createdBy ? d.createdBy : '-'),
      sortable: true,
    },
  ];

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <h4 className="card-title">Exam Status History</h4>
        </CardHeader>
        <CardBody>
          <ReactTable data={statuses.statuses} columns={columns} />
        </CardBody>
      </Card>
    </div>
  );
};

export default ExamStatusHistoryList;
