import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';
import Select from 'react-select';

import ApiCaller from 'lib/ApiCaller';
import User, { UserTypes, UserTypesLabels } from 'lib/common/models/user';
import UserApi from 'api/UserApi';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { generateErrors, is } from 'shared/utils/validations';
import Settings from 'lib/settings';
import { useHistory } from 'react-router-dom';
import { useExamCenter } from 'hooks';
import PasswordInput from 'views/components/input/PasswordInput';

const UserCreate: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const history = useHistory();
  const [fieldState, setFieldState] = React.useState({
    passwordState: '',
    passwordConfirmState: '',
  });

  const [form, setState] = React.useState({
    email: '',
    name: '',
    password: '',
    passwordConfirm: '',
    type: '',
    entityId: undefined,
  });

  const resetForm = () => {
    setState({
      email: '',
      name: '',
      password: '',
      passwordConfirm: '',
      type: '',
      entityId: undefined,
    });
  };
  const { examCenters: entities } = useExamCenter();

  const handleChange = (e: any) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const selectionChange = (e: any) => {
    setState({
      ...form,
      type: e.value,
    });
  };

  const onEntityChange = (e: any) => {
    setState({
      ...form,
      entityId: e,
    });
  };

  const passwordOnChange = (e: any, compareField: string) => {
    const newState =
      e.target.value == form[compareField] ? 'has-success' : 'has-danger';

    setFieldState({
      passwordState: newState,
      passwordConfirmState: newState,
    });

    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const createUser = (e: React.FormEvent) => {
    e.preventDefault();
    const { email } = form;
    const { password } = form;
    const { passwordConfirm } = form;
    const { name } = form;
    const { type } = form;
    const { entityId } = form;

    const errors: any = generateErrors(
      { email, password, passwordConfirm, name, type },
      {
        email: [is.required(), is.email()],
        password: [
          is.required(),
          is.minLength(3),
          is.maxLength(30),
          is.securePassword(),
        ],
        passwordConfirm: [
          is.required(),
          is.minLength(3),
          is.maxLength(30),
          is.securePassword(),
        ],
        name: [is.required(), is.minLength(3), is.maxLength(50)],
        type: [is.required()],
      },
    );

    if (errors.email) toast.error(errors.email);
    if (errors.password) toast.error(errors.password);
    if (errors.passwordConfirm) toast.error(errors.passwordConfirm);
    if (errors.name) toast.error(errors.name);
    if (errors.type) toast.error(errors.type);

    if (Object.keys(errors).length !== 0) return;

    const errorEntity: any = generateErrors(
      { entityId },
      {
        entityId: [is.required()],
      },
    );

    if (errorEntity.entityId && type === UserTypesLabels.examCentre) {
      toast.error('Exam centre is required.');
      return;
    }

    const userApi = new UserApi(new ApiCaller());
    userApi
      .create(email, password, name, type, entityId, loggedUser.token)
      .then((user: any) => {
        if (user) {
          if (!loggedUser.isAdmin()) {
            history.push(`/${layout}/user/list`);
            toast.success('User created successfully');
          } else {
            Settings.setCurrentAdminUser(new User(user));
            history.push(`/${layout}/user/detail`);
            toast.success('User created successfully');
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast.error('Error creating user, please try again');
      });
  };

  return (
    <>
      <div className="content">
        <Card>
          <Row>
            <Col md="12">
              <CardHeader>
                <CardTitle tag="h4">Create User</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal" onSubmit={createUser}>
                  <Row>
                    <Label sm="2">Name</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          value={form.name}
                          onChange={handleChange}
                          type="text"
                          name="name"
                          placeholder="User name"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Email</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          value={form.email}
                          onChange={handleChange}
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Password</Label>
                    <Col sm="10">
                      <FormGroup
                        className={`has-label ${fieldState['passwordState']}`}>
                        <PasswordInput
                          value={form.password}
                          onChange={(e) =>
                            passwordOnChange(e, 'passwordConfirm')
                          }
                          name="password"
                          autoComplete="new-password"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Confirm Password</Label>
                    <Col sm="10">
                      <FormGroup
                        className={`has-label ${fieldState['passwordConfirmState']}`}>
                        <PasswordInput
                          value={form.passwordConfirm}
                          onChange={(e) => passwordOnChange(e, 'password')}
                          name="passwordConfirm"
                          autoComplete="new-password"
                        />
                        {fieldState['passwordConfirmState'] === 'has-danger' ? (
                          <label className="error">
                            Password should match.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Type</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={{
                            label: UserTypes[form.type],
                            value: form.type,
                          }}
                          onChange={selectionChange}
                          options={Object.keys(UserTypes).map(function (type) {
                            return {
                              value: type,
                              label: UserTypes[type],
                            };
                          })}
                          placeholder="User Type"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {form.type === UserTypesLabels.examCentre && (
                    <Row>
                      <Label sm="2">Exam Center</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="entityId"
                            value={form?.entityId}
                            onChange={onEntityChange}
                            options={entities?.centers}
                            getOptionLabel={(item) =>
                              item.code + ' ' + item.name
                            }
                            getOptionValue={(item) => item.code}
                            placeholder="Choose an exam center"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Label sm="2" />
                    <Col sm="10">
                      <Button className="btn-round" color="info" type="submit">
                        Create
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Col>
          </Row>
        </Card>
        <ToastContainer />
      </div>
    </>
  );
};

export default UserCreate;
