import React from "react";
import { Pie } from 'react-chartjs-2';
import { PieCharProps } from '../../types';

const PieGraphic: React.FC<PieCharProps> = ({
    passed,
    failed,
    percentageFail,
    percentagePassed
}) => {
    return (
<Pie
                        data={{
                            labels: [`Pass (${percentagePassed}%)`, `Fail (${percentageFail}%)`],
                            datasets: [
                                {
                                    data: [passed, failed],
                                    backgroundColor: [
                                        '#6BD098',
                                        '#EE8157',
                                    ],
                                },
                            ],
                        }}
                        height={250}
                        options={{
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                    },
                                ],
                            },
                        }}
                    />
                    )
}

export default PieGraphic;