import React from 'react';
import type { FC } from 'react';
import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NavigationExam from 'lib/common/models/navigationExam';

export interface IEvent {
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  color?: string;
  data: NavigationExam;
}

interface IEventDetail {
  exam: IEvent | null;
  toggle: () => void;
}

const EventDetail: FC<IEventDetail> = ({ toggle, exam }) => {
  return (
    <Modal isOpen={Boolean(exam)} {...{ toggle }}>
      <ModalHeader {...{ toggle }} className="cal-modal-header" close={null}>
        Exam details - {exam?.data?.displayName}
      </ModalHeader>
      <ModalBody>
        <dl>
          <dt>Date</dt>
          <dd>{moment(exam?.data?.date).format('YYYY-MM-DD hh:mm A')}</dd>
          <dt>Syllabus</dt>
          <dd>
            {exam?.data?.syllabus?.code} {exam?.data?.syllabus?.title}
          </dd>

          <dt>Module</dt>
          <dd>
            {exam?.data?.module?.code} {exam?.data?.module?.title}
          </dd>
          <dt>Exam Center</dt>
          <dd>
            {exam?.data?.examCenterId?.code} {exam?.data?.examCenterId?.name}
          </dd>
        </dl>
      </ModalBody>
      <ModalFooter className="m-footer">
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default EventDetail;
