import React, {FC} from 'react';
import {ReactMultiEmail, isEmail} from 'react-multi-email';

import 'react-multi-email/style.css';
import './style.css';

type MultiEmailInputProps = {
  placeholder: string;
  emailList: string[];
  onChange: (emailList: string[]) => void;
};

const MultiEmailInput: FC<MultiEmailInputProps> = ({
  placeholder,
  emailList,
  onChange,
}) => {
  const emailChange = (emailList: string[]) => {
    onChange(emailList);
  };

  return (
    <ReactMultiEmail
      placeholder={placeholder}
      emails={emailList}
      onChange={emailChange}
      validateEmail={(email: string) => {
        return isEmail(email);
      }}
      getLabel={(
        email: string,
        index: number,
        removeEmail: (index: number) => void,
      ) => {
        return (
          <div data-tag key={index}>
            {email}
            <span data-tag-handle onClick={() => removeEmail(index)}>
              ×
            </span>
          </div>
        );
      }}
    />
  );
};

export default MultiEmailInput;
