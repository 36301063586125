import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Col,
  Row,
  CardTitle,
  Alert,
} from 'reactstrap';
import bgImg from '../../assets/img/bg/yacht-background.png';
import { useHistory, useParams } from 'react-router';
import AuthApi from '../../api/AuthApi';
import ApiCaller from '../../lib/ApiCaller';
import { Link } from 'react-router-dom';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';

const Verify: FC = () => {
  const { token } = useParams<any>();
  const [isValidated, setIsValidated] = useState<boolean>();
  const [loading , setIsLoading] = useState<boolean>(false);
  const [message , setMessage] = useState<string>('');
  const history = useHistory();
  useEffect(() => {
    if (token) {
      setIsLoading(true)
      const authApi = new AuthApi(new ApiCaller());
      authApi
        .verifyCandidate(token)
        .then((data) => {
          setIsLoading(false);
          setIsValidated(true);
          setMessage(data.message);
        })
        .catch((err) => {
          setMessage(err.message)
          setIsLoading(false)
          setIsValidated(false);
        });
    } else {
      history.push('/login');
    }
  }, []);
  return (
    <>
      <LoadingSpinner spinning={loading} tip={'checking your account...'}>
        <div className="wrapper wrapper-full-page">
          <div className="full-page section-image">
            <div className="register-page">
              <Container>
                <Row>
                  <Col className="m-auto" lg="6" md="6">
                    <Card className="card-signup text-center">
                      <CardHeader>
                        <CardTitle tag="h4">Verify email address</CardTitle>
                      </CardHeader>
                      <CardBody>
                        {isValidated === false && (
                          <>
                            <Alert color="danger">
                            {message}
                            </Alert>
                          </>
                        )}
                        {isValidated === true && (
                          <>
                            <Alert color="success">
                              {message}
                            </Alert>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <div
                className="full-page-background"
                style={{ backgroundImage: `url(${bgImg})` }}
              />
            </div>
          </div>
        </div>
      </LoadingSpinner>
    </>
  );
};

export default Verify;
