import React, { useCallback } from 'react';
import type { FC } from 'react';

import { CardBody, CardHeader, CardTitle, Card, Col, Button } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { chartExample4 } from 'variables/charts';

import { barChartBackground } from '../questions/colors';
import useMarks from './useMarks';
import FilterModule from './FilterModule';
import SyllabusFilter from '../syllabusFilter/SyllabusFilter';

type AvgMarkPerMarkerReportProps = {
  allowedSyllabuses: string[];
}

const AvgMarkPerMarkerReport = ({ allowedSyllabuses }: AvgMarkPerMarkerReportProps) => {
  const {
    modules,
    syllabus,
    data,
    labels,
    listSyllabusEnable,
    chooseSyllabus,
    onSetModule,
  } = useMarks();

  const ticksCallback = useCallback((value: number) => {
    if (value % 1 === 0) {
      return `${value}%`;
    }
  }, []);

  const barOptions = {
    tooltips: {
      callbacks: {
        label: (value: any) => `${value.value}%`,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: 100,
            callback: ticksCallback,
          },
        },
      ],
    },
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Average Mark per Marker</CardTitle>
      </CardHeader>
      <CardBody>
        <Col>
          <SyllabusFilter
            disableButtons={{
              S01: !listSyllabusEnable.includes('S01'),
              S02: !listSyllabusEnable.includes('S02'),
              S03: !listSyllabusEnable.includes('S03'),
              S04: !listSyllabusEnable.includes('S04'),
            }}
            syllabus={syllabus}
            setSyllabus={chooseSyllabus}
            allowedSyllabuses={allowedSyllabuses}
          />
        </Col>
        {syllabus === 'S01' && (
          <Col>
            <FilterModule {...{ modules }} onSetFilter={onSetModule} />
          </Col>
        )}
        <Col>
          <Bar
            data={{
              labels,
              datasets: [
                {
                  backgroundColor: barChartBackground,
                  data,
                },
              ],
            }}
            options={{
              ...chartExample4.options,
              ...barOptions,
            }}
          />
        </Col>
      </CardBody>
    </Card>
  );
};

export default AvgMarkPerMarkerReport;
