import React, { useState } from 'react';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, Card, CardBody, Label, FormGroup, Form, Input, Row, Col, CardHeader, } from "reactstrap";

import Settings from "../../../../lib/settings";
import { AppState } from "../../../../store/store";

import ApiCaller from "../../../../lib/ApiCaller";
import ExamCenterApi from "../../../../api/ExamCenterApi";

import ExamCenter from "../../../../lib/common/models/examCenter";


const ExamCenterDetail: React.FC = () => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const layout = useSelector((state: AppState) => state.session.layout);
    const history = useHistory();
    const [examCenter, setExamCenter] = useState<ExamCenter>();

    React.useEffect(() => {
        setExamCenter(Settings.getCurrentExamCenter());
    }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        const examCenterInfo = examCenter?.toObject();

        if (examCenterInfo) {
            examCenterInfo[name] = value;
            setExamCenter(new ExamCenter(examCenterInfo));
        }
    };

    const updateExamCenter = (e: any) => {
        e.preventDefault();
        if (examCenter) {
            const syllabusApi = new ExamCenterApi(new ApiCaller());
            syllabusApi.updateExamCenter(examCenter, loggedUser.token).then(() => {
                history.push(`/${layout}/examCenter/list`);
                toast.info('Updated successfully');
            }).catch(err => {
                console.log(err);
                toast.error('Error updating exam center, please try again');
            });
        }
    }

    return (
        <>
            <div className="content">
                {examCenter &&
                <Card>
                    <Row>
                        <Col md="12">
                            <CardHeader>
                                <CardHeader><h4 className="card-title">Update Exam Center</h4></CardHeader>
                            </CardHeader>
                            <CardBody>
                                <Form className="form-horizontal" onSubmit={updateExamCenter}>
                                    <Row>
                                        <Label sm="2">Code</Label>
                                        <Col sm="10">
                                            <FormGroup>
                                                <Input
                                                    value={examCenter.code}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="code"
                                                    placeholder="Code"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Name</Label>
                                        <Col sm="10">
                                            <FormGroup>
                                                <Input
                                                    value={examCenter.name}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Address</Label>
                                        <Col sm="10">
                                            <FormGroup>
                                                <Input
                                                    value={examCenter.address}
                                                    onChange={handleChange}
                                                    type="text"
                                                    name="address"
                                                    placeholder="Address"
                                                    required
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2"/>
                                        <Col sm="10">
                                            <Button className="btn-round" color="info" type="submit">
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Col>
                    </Row>
                </Card>
                }
            </div>
        </>
    )
}

export default ExamCenterDetail;





