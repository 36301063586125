interface ModuleInfo {
    code: string,
    title: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    _id:string;
}

interface BasicModuleDTO {
  _id?:string;
  code?: string;
  title?: string;
}

class Module {
  code: string;
  title: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  _id?:string;

  constructor(ModuleInfo: ModuleInfo) {
    this.code = ModuleInfo.code;
    this.title = ModuleInfo.title;
    this.createdAt = ModuleInfo.createdAt;
    this.createdBy = ModuleInfo.createdBy;
    this.updatedAt = ModuleInfo.updatedAt;
    this.updatedBy = ModuleInfo.updatedBy;
    this._id = ModuleInfo._id;
  }

  load(data: any): Module {
    return new Module(data);
  }

  toObject(): any {
    return {
      code: this.code,
      title: this.title,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      _id: this._id,
    };
  }

  toDto(data: any): BasicModuleDTO {
    return {_id: data._id, code: data.code, title: data.title};
  }
}

export default Module;
