import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Card,
  Col,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import ReactTable from 'components/ReactTable/ReactTable';
import {
  MarkerPaymentTypesLabels,
} from '../../../../../lib/common/models/markerPayment';
import MarkerPaymentCollection from '../../../../../lib/common/models/markerPaymentCollection';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../../shared/utils/dateTime';
import MarkerPaymentApi from 'api/MarkerPaymentApi';
import MarkerPaymentsDateFilteredTable from 'views/components/filteredComponents/markerPaymentsDateFilteredTable/markerPaymentsDateFilteredTable';
import { AppState } from '../../../../../store/store';

const enum DisplayedTableTypes {
  AWAITING_PAYMENT_MARKERS = 'AWAITING PAYMENT',
  GENERATED_PAYMENTS = 'GENERATED PAYMENTS',
}

const MarkerPaymentsSingleReport: FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [markerPayments, setMarkerPayments] = useState<MarkerPaymentCollection>(new MarkerPaymentCollection([]));
  const [displayedTableType, setDisplayedTableType] =
    useState<DisplayedTableTypes>(DisplayedTableTypes.AWAITING_PAYMENT_MARKERS);

  const getMarkerPayments = useCallback(() => {
    MarkerPaymentApi.getNonPaidMarkerPaymentsForMarker(loggedUser.token).then(
      (data: MarkerPaymentCollection) => {
        setMarkerPayments(data);
      },
    );
  }, [loggedUser])

  useEffect(() => {
    if (!loggedUser?.isEmpty()) {
      getMarkerPayments();
    }
  }, [loggedUser]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'EXAM CENTRE',
        accessor: 'examCentreName',
        sortable: true,
        Footer: () => (
          <>
            <b>TOTAL:</b>
          </>
        ),
      },
      {
        Header: 'EXAM NUMBER',
        accessor: 'examNumber',
        sortable: true,
      },
      {
        Header: 'SYLLABUS',
        accessor: 'syllabus',
        sortable: true,
      },
      {
        Header: 'MODULE',
        accessor: 'module',
        sortable: true,
      },
      {
        Header: 'DATE',
        accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
      },
      {
        Header: 'TYPE',
        accessor: (d: any) => MarkerPaymentTypesLabels[d.type],
        sortable: true,
      },
      {
        Header: 'CANDIDATES',
        accessor: 'numCandidates',
        Footer: (info: any) => {
          const total = useMemo(() => {
            return info.rows.reduce(
              (sum: number, row: any) => row.original.numCandidates + sum,
              0,
            );
          }, [info]);

          return (
            <>
              <b>{total}</b>
            </>
          );
        },
        sortable: true,
      },
      {
        Header: 'SCRIPTS',
        accessor: 'numScripts',
        Footer: (info: any) => {
          const total = useMemo(() => {
            return info.rows.reduce(
              (sum: number, row: any) => row.original.numScripts + sum,
              0,
            );
          }, [info]);

          return (
            <>
              <b>{total}</b>
            </>
          );
        },
        sortable: true,
      },
    ];
  }, []);

  const generatedPaymentsColumns = useMemo(() => {
    return [
      {
        Header: 'EXAM CENTRE',
        accessor: 'examCentreName',
        sortable: true,
        Footer: () => (
          <>
            <b>TOTAL:</b>
          </>
        ),
      },
      { Header: 'EXAM NUMBER', accessor: 'examNumber', sortable: true },
      {
        Header: 'SYLLABUS',
        accessor: (d: any) => (d.syllabus ? d.syllabus : '---'),
        sortable: true,
      },
      {
        Header: 'MODULE',
        accessor: (d: any) => (d.module ? d.module : '---'),
        sortable: true,
      },
      {
        Header: 'EXAM DATE',
        accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
      },
      {
        Header: 'TYPE',
        accessor: (d: any) => MarkerPaymentTypesLabels[d.type],
        sortable: true,
      },
      {
        Header: 'CANDIDATES',
        accessor: 'numCandidates',
        sortable: true,
        Footer: (info: any) => {
          const total = useMemo(() => {
            return info.rows.reduce(
              (sum: number, row: any) => row.original.numCandidates + sum,
              0,
            );
          }, [info]);

          return (
            <>
              <b>{total}</b>
            </>
          );
        },
      },
      {
        Header: 'SCRIPTS',
        accessor: 'numScripts',
        sortable: true,
        Footer: (info: any) => {
          const total = useMemo(() => {
            return info.rows.reduce(
              (sum: number, row: any) => row.original.numScripts + sum,
              0,
            );
          }, [info]);

          return (
            <>
              <b>{total}</b>
            </>
          );
        },
      },
      {
        Header: 'PAYMENT DATE',
        accessor: (d: any) =>
          formatDateTime(d.payment, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'payment'),
      },
    ];
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h4">Marker Payments</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <UncontrolledDropdown>
                <DropdownToggle caret>{displayedTableType}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      getMarkerPayments();
                      setDisplayedTableType(
                        DisplayedTableTypes.AWAITING_PAYMENT_MARKERS,
                      );
                    }}>
                    {DisplayedTableTypes.AWAITING_PAYMENT_MARKERS}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setDisplayedTableType(
                        DisplayedTableTypes.GENERATED_PAYMENTS,
                      );
                    }}>
                    {DisplayedTableTypes.GENERATED_PAYMENTS}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row>
            <Col>
              {displayedTableType ===
                DisplayedTableTypes.AWAITING_PAYMENT_MARKERS && (
                <ReactTable
                  data={markerPayments.payments}
                  columns={columns}
                  topPaginationClassName="table-pagination_top"
                  hasTotal
                />
              )}
              {displayedTableType ===
                DisplayedTableTypes.GENERATED_PAYMENTS && (
                <MarkerPaymentsDateFilteredTable
                  fetchMethod={MarkerPaymentApi.getGeneratedMarkerPaymentsForMarker}
                  columns={generatedPaymentsColumns}
                  hasTotal
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default MarkerPaymentsSingleReport;
