import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CardBody, CardHeader, CardTitle, Card, Col, Button } from 'reactstrap';

import { Doughnut } from 'react-chartjs-2';

import DashboardApi from '../../../../api/DashboardApi';
import { AppState } from '../../../../store/store';

import { ChartDataObject } from './types';
import {
  doughnutChartBackground1,
  doughnutChartBackground2,
  doughnutChartBackground3,
} from './colors';
import SyllabusFilter from '../syllabusFilter/SyllabusFilter';

type QuestionsPipelineReportProps = {
  allowedSyllabuses: string[];
}

const QuestionsPipelineReport = ({ allowedSyllabuses }: QuestionsPipelineReportProps) => {
  const [report, setReport] = useState<object>({});
  const [syllabus, setSyllabus] = useState<string>('');
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const labels = {
    writing: 'Still in writing process',
    moderation: 'Undergoing moderation',
    mca: 'MCA approval process',
  };

  useEffect(() => {
    DashboardApi.getQuestionsPipelineReport(loggedUser.token)
      .then((result: any) => {
        setReport(result);
        setSyllabus(Object.keys(result).sort()[0] ?? '');
      })
      .catch((error) => {
        throw new Error(error);
      });
  }, [loggedUser.token]);

  const getData = useCallback(() => {
    const dataObject: ChartDataObject = {
      labels: [],
      data: [],
    };
    if (report && report[syllabus]) {
      Object.keys(report[syllabus]).map((key) => {
        dataObject.labels.push(labels[key]);
        dataObject.data.push(Number(report[syllabus][key].toFixed(2)));
      });
    }
    return {
      labels:
        'errors' in report ? ['Information not Available'] : dataObject.labels,
      datasets: [
        {
          data: 'errors' in report ? [1] : dataObject.data,
          backgroundColor: [
            doughnutChartBackground1,
            doughnutChartBackground2,
            doughnutChartBackground3,
          ],
        },
      ],
    };
  }, [labels, report, syllabus]);

  const options = {
    legend: {
      position: 'bottom',
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const { index } = tooltipItem;
          const label = `${data.labels[index]}: ${
            data.datasets[0].data[tooltipItem.index]
          }`;
          return label;
        },
      },
    },
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Number of Questions in the pipeline</CardTitle>
        </CardHeader>
        <CardBody>
          <Col>
            <SyllabusFilter
              disableButtons={{
                S01: !report['S01'],
                S02: !report['S02'],
                S03: !report['S03'],
                S04: !report['S04'],
              }}
              syllabus={syllabus}
              setSyllabus={setSyllabus}
              allowedSyllabuses={allowedSyllabuses}
            />
          </Col>
          <Col>
            <Doughnut data={getData()} options={options} />
          </Col>
        </CardBody>
      </Card>
    </>
  );
};

export default QuestionsPipelineReport;
