import ApiCaller from '../lib/ApiCaller';
import QuestionPayment from '../lib/common/models/questionPayment';
import moment from "moment";

class QuestionPaymentApi {
    static getNonPaidQuestionPaymentsReport(token?: string): Promise<any> {
        const apiCaller = new ApiCaller();
        return apiCaller
            .call('/v1/questionPayment/nonPaid', 'GET', undefined, token)
            .then((data) => {
                if (data.error) throw Error(data.error);

                return data;
            }).catch((err) => {
                throw err;
            });
    }

    static getGeneratedQuestionPayments(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        token?: string,
    ): Promise<QuestionPayment[]> {
        const apiCaller = new ApiCaller();

        let url = '/v1/questionPayment/generated';
        const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
        const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';

        if (dateStart && dateEnd) url = `${url}?startDate=${dateStart}&endDate=${dateEnd}`;


        return apiCaller.call(url, 'GET', undefined, token)
            .then((data) => {
                if (data.error) throw Error(data.error);

                return data.payments.map((payment: any) => {
                    return new QuestionPayment(payment);
                });
            }).catch((err) => {
                throw err;
            });
    }

    static releaseQuestionPayments(questionPayments: string[], token?: string): Promise<any> {
        const apiCaller = new ApiCaller();
        return apiCaller.call('/v1/questionPayment/release', 'PUT', { questionPayments }, token)
            .then((data) => {
                if (data.error) return Error(data.error);

                return data
            }).catch((err) => {
                throw err;
            })
    }
}

export default QuestionPaymentApi;
