import Module from './module';

class ModuleCollection {
  modules: Array<Module>;

  constructor(Modules: Array<Module>) {
    this.modules = Modules;
  }

  length() {
    return this.modules.length;
  }
}

export default ModuleCollection;
