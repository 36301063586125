import React, { FC, useEffect, useCallback, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import {
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Card,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';

import ReactTable from 'components/ReactTable/ReactTable';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';
import ExamPaymentApi from 'api/ExamPaymentApi';
import ExamPayment from '../../../../lib/common/models/examPayment';
import ExamPaymentCollection from '../../../../lib/common/models/examPaymentCollection';
import ExamPaymentsDateFilteredTable from 'views/components/filteredComponents/examPaymentsDateFilteredTable/examPaymentsDateFilteredTable';

const enum DisplayedTableTypes {
  AWAITING_PAYMENT = 'AWAITING PAYMENT',
  GENERATED_PAYMENTS = 'GENERATED PAYMENTS',
}

interface ExamPaymentsReportProps {
  loggedUser: any;
}

const ExamPaymentsReport: FC<ExamPaymentsReportProps> = ({ loggedUser }) => {
  const [releaseModal, setReleaseModal] = useState(false);
  const [displayedTableType, setDisplayedTableType] =
    useState<DisplayedTableTypes>(DisplayedTableTypes.AWAITING_PAYMENT);
  const [examPayments, setExamPayments] = useState<ExamPayment[]>([]);
  const [filteredPayments, setFilteredPayments] = useState<ExamPayment[]>([]);

  const getExamPayments = useCallback(() => {
    ExamPaymentApi.getAwaitingExamPayments(loggedUser.token).then(
      (data: ExamPaymentCollection) => {
        setExamPayments(data.payments);
      },
    );
  }, [loggedUser]);

  useEffect(() => {
    setFilteredPayments(examPayments);
  }, [examPayments]);

  useEffect(() => {
    if (!loggedUser?.isEmpty()) {
      getExamPayments();
    }
  }, [loggedUser]);

  const columns = useMemo(() => {
    const tableColumns = [
      {
        Header: 'SYLLABUS',
        accessor: (d: any) =>
          d?.syllabus ? `${d.syllabus?.code} - ${d.syllabus?.title}` : '',
      },
      {
        Header: 'EXAM NUMBER',
        accessor: 'examNumber',
        Footer: () => (
          <>
            <b>TOTAL</b>
          </>
        ),
        sortable: true,
      },
      {
        Header: 'EXAM DATE',
        accessor: (d: any) =>
          formatDateTime(d.examDate, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'examDate'),
      },
      {
        Header: 'MARKED SCRIPTS',
        accessor: 'scripts',
        Footer: (info: any) => {
          const total = useMemo(() => {
            return info.rows.reduce(
              (sum: number, row: any) => row.original.scripts + sum,
              0,
            );
          }, [info]);

          return (
            <>
              <b>{total}</b>
            </>
          );
        },
        sortable: true,
      },
    ];
    if (displayedTableType === DisplayedTableTypes.GENERATED_PAYMENTS) {
      tableColumns.push({
        Header: 'PAYMENT DATE',
        accessor: (d: any) =>
          formatDateTime(d.payment, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'payment'),
      });
    }
    return tableColumns;
  }, [displayedTableType]);

  const onPaymentsRelease = () => {
    const examPaymentsId: string[] = [];
    filteredPayments.map((payment: ExamPayment) => {
      if (payment.id) {
        examPaymentsId.push(payment.id);
      }
    });
    ExamPaymentApi.releaseExamPayments(examPaymentsId, loggedUser?.token)
      .then(() => {
        const paymentsHeaders = columns.map((column) => column.Header);
        const wb = XLSX.utils.book_new();
        const excelTableData = filteredPayments.map((payment: ExamPayment) => {
          return {
            syllabus: `${payment.syllabus.code} - ${payment.syllabus.title}`,
            examNumber: payment.examNumber,
            examDate: formatDateTime(payment.examDate, DEFAULT_TIMEZONE_FORMAT),
            scripts: payment.scripts,
          };
        });
        const totalScripts = excelTableData.reduce((total: any, exam: any) => {
          return total + exam.scripts;
        }, 0);
        excelTableData.push({
          syllabus: '',
          examNumber: 'Total:',
          examDate: '',
          scripts: totalScripts,
        });
        const paymentsSheet = XLSX.utils.json_to_sheet(excelTableData);
        paymentsSheet['!cols'] = [{ wch: 18 }, { wch: 25 }, { wch: 18 }];
        XLSX.utils.sheet_add_aoa(paymentsSheet, [paymentsHeaders]);
        XLSX.utils.book_append_sheet(wb, paymentsSheet, 'Payments');

        XLSX.writeFile(wb, `Exam Payments ${new Date().toDateString()}.xlsx`);
        getExamPayments();
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to generate payments');
      });
    setReleaseModal(false);
  };

  const onChangeFilters = (filters: any) => {
    let values = examPayments;

    filters.forEach((filter: any) => {
      switch (filter.id) {
        case 'SYLLABUS':
          values = values.filter((payment) =>
            `${payment.syllabus.code} - ${payment.syllabus.title}`
              .toLowerCase()
              .includes(filter.value.toLowerCase()),
          );
          break;
        case 'examNumber':
          values = values.filter((payment) =>
            payment.examNumber
              .toLowerCase()
              .includes(filter.value.toLowerCase()),
          );
          break;
        case 'EXAM DATE':
          values = values.filter((payment) =>
            formatDateTime(payment.examDate, DEFAULT_TIMEZONE_FORMAT).includes(
              filter.value,
            ),
          );
          break;
        default:
          break;
      }
    });

    setFilteredPayments(values);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h4">MCA Royalties Report</CardTitle>
            </Col>
            <Col className="text-center text-md-right">
              {loggedUser.isPaymentAdmin() && (
                <Button
                  disabled={examPayments.length < 1}
                  onClick={() => setReleaseModal(true)}
                  color="success"
                  className="btn-label">
                  <span>
                    <i className="nc-icon nc-cloud-download-93" />
                  </span>
                  Generate Payments
                </Button>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <UncontrolledDropdown>
                <DropdownToggle caret>{displayedTableType}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      getExamPayments();
                      setDisplayedTableType(
                        DisplayedTableTypes.AWAITING_PAYMENT,
                      );
                    }}>
                    {DisplayedTableTypes.AWAITING_PAYMENT}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setDisplayedTableType(
                        DisplayedTableTypes.GENERATED_PAYMENTS,
                      );
                    }}>
                    {DisplayedTableTypes.GENERATED_PAYMENTS}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row>
            <Col>
              {displayedTableType === DisplayedTableTypes.AWAITING_PAYMENT && (
                <ReactTable
                  data={examPayments}
                  {...{ columns, onChangeFilters }}
                  topPaginationClassName="table-pagination_top"
                  hasTotal
                />
              )}
              {displayedTableType ===
                DisplayedTableTypes.GENERATED_PAYMENTS && (
                <ExamPaymentsDateFilteredTable
                  fetchMethod={ExamPaymentApi.getGeneratedExamPayments}
                  {...{ columns }}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ToastContainer />
      {releaseModal && (
        <ConfirmationModal
          title="Generate payments confirmation"
          onConfirm={() => onPaymentsRelease()}
          onCancel={() => setReleaseModal(false)}
          text="Are you sure you want to generate these payments?"
        />
      )}
    </>
  );
};

export default ExamPaymentsReport;
