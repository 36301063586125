import ApiCaller from '../lib/ApiCaller';
import NewsletterContact from '../lib/common/models/newsletterContact';
import User from '../lib/common/models/user';

class AuthApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  login(email: string, password: string): Promise<void | undefined> {
    return this.apiCaller.call('/v1/auth/login', 'POST', {
      email: email,
      password: password,
    });
  }

  submitCode(email: string, code: number): Promise<void | undefined> {
    return this.apiCaller.call('/v1/auth/submit', 'POST', { email, code });
  }

  refresh(): Promise<User | void> {
    return this.apiCaller
      .call('/v1/auth/refresh', 'POST')
      .then((res) => {
        return new User(res);
      })
      .catch((err) => {
        throw err;
      });
  }

    switch(token:string, profile:string): Promise<void  | undefined> {
        return this.apiCaller.call('/v1/auth/switch', 'POST', {profile:profile}, token)
    }

    loginAs(token: string, email: string): Promise<void | undefined> {
        return this.apiCaller
          .call('/v1/auth/loginAs', 'POST', { email }, token)
          .then((data) => {
            if (data.message) {
              throw Error(data.message);
            }
            return data;
          })
          .catch((err) => {
            console.error(err);
            throw Error(err);
          });
      }

    logoutImpersonated(token: string): Promise<void  | undefined> {
        return this.apiCaller.call('/v1/auth/logoutImpersonated', 'POST', undefined, token)
    }

  register(
    email: string,
    password: string,
    name: string,
    type: string,
  ): Promise<void | undefined> {
    return this.apiCaller.call('/v1/auth/register', 'POST', {
      name: name,
      email: email,
      password: password,
    });
  }

  checkAuthToken(): Promise<any> {
    return this.apiCaller
      .call('/v1/auth/checkAuthToken', 'GET')
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  verify(email: string, token: string): Promise<void | undefined> {
    return this.apiCaller.call(
      '/v1/auth/verify?email=' + email + '&code=' + token,
      'GET',
    );
  }

  /**
   * verifies a candidate using povided token
   * @param token 
   * @returns 
   */
  verifyCandidate(token: string): Promise<{message:string}> {
    return this.apiCaller
    .call( '/v1/auth/verifyCandidate?token=' + token,
      'GET',)
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
      throw Error(err);
    });
  }
  resetPassword(email: string): Promise<void | undefined> {
    return this.apiCaller.call('/v1/auth/resetPassword/' + email, 'GET');
  }

  changePassword(
    email: string,
    password: string,
    token: string,
  ): Promise<void | undefined> {
    return this.apiCaller.call('/v1/auth/resetPassword', 'POST', {
      email: email,
      password: password,
      token: token,
    });
  }

  subscribe(contact: NewsletterContact): Promise<boolean | undefined> {
    return this.apiCaller
      .call('/v1/newsletter/subscribe', 'POST', {
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        jobTitle: contact.jobTitle,
        company: contact.company,
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        throw Error(err);
      });
  }
}

export default AuthApi;
