import React, { useState } from 'react';

import { Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';

type SubmitMarksProps = {
  linkButtonText: string;
  validate: () => boolean;
  onConfirm: () => void;
};

const SubmitMarkers = ({
  linkButtonText,
  validate,
  onConfirm,
}: SubmitMarksProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = () => {
    setIsOpen(false);
    onConfirm();
  };
  return (
    <>
      <Button
        className="btn-sm"
        onClick={() => {
          if (!validate()) {
            setIsOpen(true);
          }
        }}>
        {linkButtonText}
      </Button>
      <ReactBSAlert
        onConfirm={onSubmit}
        show={isOpen}
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure you want to submit the exam marks?"
        customButtons={
          <>
            <Button
              className="btn-md"
              color="danger"
              onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button className="btn-md" color="info" onClick={onSubmit}>
              Submit
            </Button>
          </>
        }
        allowEscape={false}>
        You will no longer be able to edit them afterwards.
      </ReactBSAlert>
    </>
  );
};

export default SubmitMarkers;
