import ApiCaller from '../lib/ApiCaller';
import CandidateCollection from '../lib/common/models/candidateCollection';
import Candidate from '../lib/common/models/candidate';
import CandidateWithNZDocument from '../lib/common/models/candidateWithNZDocument';
import CandidateWithNZDocumentCollection from '../lib/common/models/candidateWithNZDocumentCollection';
import CandidateEnrolment from '../lib/common/models/candidateEnrolment';

class CandidateApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getAllCandidates(): Promise<CandidateCollection> {
    return this.apiCaller
      .call('/v1/candidate', 'GET')
      .then((data) => {
        if(data && data.candidates) {
          return new CandidateCollection(data.candidates.map((c:Candidate)=> new Candidate(c)));
        }
        throw new Error('Errot loading candidates');
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getAllCandidatesWithPendingReports(
    token?: string,
  ): Promise<void | CandidateWithNZDocumentCollection> {
    return this.apiCaller
      .call('/v1/candidatesWithReports', 'GET', undefined, token)
      .then((data) => {
        const apiArr = data.candidates.map(
          (candidate: any) =>
            new CandidateWithNZDocument(
              candidate.candidate,
              candidate.nzDocument,
            ),
        );
        return new CandidateWithNZDocumentCollection(apiArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  createCandidate(candidate: Candidate, token?: string): Promise<Candidate> {
    return this.apiCaller
      .call('/v1/candidate', 'PUT', candidate, token)
      .then((data) => {
        if (data.error) throw Error(data.error);
        return new Candidate(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  updateCandidate(candidate: Candidate, token?: string): Promise<Candidate> {
    return this.apiCaller
      .call('/v1/candidate/' + candidate._id, 'POST', candidate, token)
      .then((data) => {
        return new Candidate(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  addNoteCandidate(
    candidate: Candidate,
    note: string,
    token?: string,
  ): Promise<Candidate> {
    return this.apiCaller
      .call(`/v1/candidate/${candidate._id}/note`, 'POST', { note }, token)
      .then((data) => {
        return new Candidate(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  /**
   * Resends verification of a candidate
   * @param candidateId
   * @param token
   * @returns
   */
  resendVerification(
    candidateId:string,
    token?: string
  ): Promise<boolean> {
    return this.apiCaller
      .call(`/v1/candidate/${candidateId}/resendConfirmation`, 'POST',undefined, token)
      .then((data) => {
        return true;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }


  createCandidateBulk(
    candidates: CandidateCollection,
    token?: string,
  ): Promise<CandidateCollection> {
    return this.apiCaller
      .call('/v1/candidate/bulk', 'PUT', candidates.candidates, token)
      .then((data) => {
        console.log(data);
        if (data.error) {
          throw Error(data.error);
        }
        return new CandidateCollection(
          data.candidates.map((item: any) => new Candidate(item)),
        );
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  createCandidateEnrolment(
    candidateEnrolment: CandidateEnrolment,
    token?: string,
  ): Promise<CandidateEnrolment> {
    return this.apiCaller
      .call('/v1/candidate/enrol', 'PUT', candidateEnrolment, token)
      .then((data) => {
        if (data.error) throw new Error(data.error);
        return new CandidateEnrolment(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getCandidateEnrolment(
    examId: string,
    candidateId: string,
    token?: string,
  ): Promise<CandidateEnrolment> {
    return this.apiCaller
      .call(
        `/v1/candidate/enrol?candidateId=${candidateId}&examId=${examId}`,
        'GET',
        undefined,
        token,
      )
      .then((data) => {
        if (data.error) throw new Error(data.error);
        return new CandidateEnrolment(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getDyslexiaSyllabus(candidateId: any, token?: string): Promise<any> {
    return this.apiCaller
      .call(
        `/v1/candidatesWithReports/syll?candidateId=${candidateId._id}`,
        'GET',
        undefined,
        token,
      )
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  setDyslexiaSyllabus(id: any, code: string, token: string): Promise<any> {
    console.log(code);
    return this.apiCaller
      .call(
        `/v1/candidatesWithReports?candidateId=${id._id}&code=${code}`,
        'POST',
        undefined,
        token,
      )
      .then((data) => {
        if (data.error) throw new Error(data.error);
        return 'ok';
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }
}

export default CandidateApi;
