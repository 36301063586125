import React, { useEffect } from "react";
import {IPayment, IPaymentConfirm} from "../../../../lib/common/models/transaction";
import PaymentApi from "../../../../api/PaymentApi";
import ApiCaller from "../../../../lib/ApiCaller";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store/store";
import TransactionApi from "../../../../api/TransactionApi";

interface IPaypalComponentProps {
    onSuccess: Function,
    onFailure: Function,
    onCancel: Function,
    transaction: any,
    setTransaction: Function,
    amount: any,
    getAmount: Function
}

const PayPalComponent: React.FC<IPaypalComponentProps> = ({onSuccess, onFailure, onCancel, transaction, setTransaction, amount, getAmount}) => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const paypal = React.useRef<HTMLDivElement>(null);

    const createOrder = async (data: any, actions: any, err: any) => {
        const val = getAmount();
        const paymentApi = new PaymentApi(new ApiCaller());
        return paymentApi.getPaymentOrder(amount, loggedUser.token).then((paymentOrder: IPayment) => {
            setTransaction(paymentOrder.transactionId);
            return paymentOrder.client_secret;
        });
    }

    const onApprove = async (data: any, actions: any) => {
        const paymentApi = new PaymentApi(new ApiCaller());
        return paymentApi.capturePaymentOrder(data.orderID, loggedUser.token).then((res: IPaymentConfirm) => {
            console.log(res);
            if (res.error === 'INSTRUMENT_DECLINED') return actions.restart();
            else if (res.error) onFailure(res.error);
            else onSuccess(true);
        });
    }

    const onError = async (err: any) => {
        console.error(err);
        const transactionApi = new TransactionApi(new ApiCaller());
        return transactionApi.failTransaction(transaction, loggedUser.token).then((res: any) => {
            onFailure(err.toString());
        }).catch((error) => {
            console.error(error)
            onFailure(err.toString());
        });
    }

    const onAbort = (data: any) => {
        const transactionApi = new TransactionApi(new ApiCaller());
        return transactionApi.cancelTransactionByReference(data.orderID, loggedUser.token).then((res: any) => {
            onCancel(true);
        }).catch((error) => {
            console.error(error)
            onCancel(true)
        });
    }

    React.useEffect(() => {
        // @ts-ignore
        if (window.myButton) window.myButton.close();
        // @ts-ignore
        window.myButton = window.paypal
            .Buttons({
                createOrder: createOrder,
                onApprove: onApprove,
                onError: onError,
                onCancel: onAbort,
            });
        // @ts-ignore
        window.myButton.render(paypal.current);
    }, [amount]);

    return (
        <>
            <div>
                <div ref={paypal}/>
            </div>
        </>
    )
}

export default PayPalComponent;
