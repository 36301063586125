import React, { useState, useEffect } from 'react';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import './antd.css';
import { UploadFile } from 'antd/lib/upload/interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import { toast } from 'react-toastify';
import { Button, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import ImageGalleryComp from '../../../components/ImageGalleryComp';
import DocumentsApi from '../../../../api/DocumentsApi';
import UploadImagePresenter from '../../../presenters/UploadImagePresenter';

export interface upload {
  selectedFile: string[];
  loaded: number;
}

const QuestionUploadPictures = ({
  questionName = '',
  images = [],
  uploadFromGallery = true,
}: {
  questionName?: string;
  images?: any[];
  uploadFromGallery?: boolean;
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { Dragger } = Upload;
  const [uploadState, setUploadState] = useState<upload>({
    selectedFile: [],
    loaded: 0,
  });
  const currentUser = useSelector((state: AppState) => state.session.userInfo);

  const [modal, setModal] = useState<boolean>(false);
  const view = {
    setUploadState: setUploadState,
    alertSuccess: toast.success,
    alertError: toast.error,
  };
  const presenter: UploadImagePresenter = new UploadImagePresenter(
    currentUser,
    view,
  );

  useEffect(() => {
    if (images) {
      setFileList([...fileList, ...images])
    }
  }, []);

  const onChangeDrag = (info: any) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      setFileList(info.fileList);
    }
    if (status === 'done') {
      //toast.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      //toast.error(`${info.file.name} file upload failed.`);
    }
  };
  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    // @ts-ignore
    imgWindow.document.write(image.outerHTML);
  };

  const upload = async (uploadOptions: any) => {
    presenter.upload(uploadOptions, questionName);
  };

  const remove = (payload: any) => {
    //const {uid} = payload;
    //const questionApi = new QuestionApi(new ApiCaller(currentUser.token));
    //questionApi.removeQuestionImage(uid).then(data=>console.log(data)).catch(e=>console.error(e));
  };

  const imageClick = async (event: any) => {
    setModal(false);
    const { src } = event.target;
    const hash = src.substring(src.lastIndexOf('/') + 1);

    const documentsApi = new DocumentsApi(new ApiCaller());
    const imageDocument: any = await documentsApi.getByHash(hash);
    const fileDoc: any = {
      uid: imageDocument.slug,
      name: imageDocument.displayName,
      size: 100,
      type: imageDocument.mimeType,
      status: 'done',
      url: src,
    };
    setFileList([...fileList, fileDoc]);
    await documentsApi.addResourcefromGalleryToQuestion(
      imageDocument.id,
      questionName,
    );
  };

  return (
    <>
      {fileList.length > 0 && (
        <p>This question will have standalone pictures</p>
      )}
      {questionName == '' && (
        <div className="foto-upload  bg-gray">
          <p>Please inform syllabus, module and topic to upload images</p>
        </div>
      )}
      {questionName !== '' && (
        <div className="foto-upload  bg-gray">
          <ImgCrop grid aspect={400 / 300}>
            <Dragger
              accept="image/png, image/jpeg"
              onPreview={onPreview}
              name="file"
              multiple={true}
              listType="picture-card"
              onChange={onChangeDrag}
              onRemove={remove}
              customRequest={upload}
              fileList={fileList}>
              <p className="ant-upload-text">
                Drag & drop pictures for the question
              </p>
              <p className="ant-upload-hint">
                You can upload up to 10 images, the images will be shown at the
                question
              </p>
            </Dragger>
          </ImgCrop>
          {uploadFromGallery && (
            <Button
              className="btn-round"
              color="success"
              onClick={() => setModal(true)}>
              Add Image from Gallery
            </Button>
          )}
        </div>
      )}

      <Modal isOpen={modal} size="ml">
        <ModalHeader>
          <button
            type="button"
            className="close"
            onClick={() => setModal(false)}>
            ×
          </button>
          Select an image from the gallery
        </ModalHeader>
        <ModalBody>
          <Form className="form-horizontal">
            <Row>
              <ImageGalleryComp dispachClick={imageClick} />
            </Row>
            <Row>
              <Button color="danger" onClick={() => setModal(false)}>
                Cancel
              </Button>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/*<ul className="foto-upload  bg-gray"><li><ImgCrop grid aspect={400/300}><Upload accept="image/png, image/jpeg" name='file' action='https://www.mocky.io/v2/5cc8019d300000980a055e76' listType="picture-card" fileList={fileList} onChange={onChange} onPreview={onPreview}>{fileList.length < 10 && '+ Insert a picture'}</Upload></ImgCrop></li></ul>*/}
    </>
  );
};

export default QuestionUploadPictures;
