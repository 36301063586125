export const DEFAULT_DASHBOARD_STATS = {
    numerical: [
        { label: 'Exam Centres', value: 'N/A', timestamp: Date.now() },
        { label: 'Candidates', value: 'N/A', timestamp: Date.now() },
        { label: 'Exams requested', value: 'N/A', timestamp: Date.now() },
        { label: 'Users', value: 'N/A', timestamp: Date.now() },
    ],
    chart: [
        {
        title: 'Title',
        footer: 'Footer',
        value: 10,
        badge: 0,
        data: {
            label: 'Value',
            x: ['X1', 'X2', 'X3', 'X4'],
            y: [1, 2, 3, 4],
        },
        },
        {
        title: 'Title',
        footer: 'Footer',
        value: 10,
        badge: 0,
        data: {
            label: 'Value',
            x: ['X1', 'X2', 'X3', 'X4'],
            y: [1, 2, 3, 4],
        },
        },
        {
        title: 'Title',
        footer: 'Footer',
        value: 10,
        badge: 0,
        data: {
            label: 'Value',
            x: ['X1', 'X2', 'X3', 'X4'],
            y: [1, 2, 3, 4],
        },
        },
    ],
    gauge: [
        {
        title: 'Email Statistics',
        subtitle: 'Last Campaign Performance',
        label: 'N/A',
        value: 0,
        },
        {
        title: 'New Visitors',
        subtitle: 'Out Of Total Number',
        label: 'N/A',
        value: 0,
        },
        {
        title: 'Orders',
        subtitle: 'Total Number',
        label: 'N/A',
        value: 0,
        },
        {
        title: 'Subscriptions',
        subtitle: 'Our Users',
        label: 'N/A',
        value: 0,
        },
    ],
    table: [
        {
        title: 'Exam Centres',
        subtitle: 'Locations',
        rows: [
            {
            icon: 'US',
            location: 'USA',
            latLng: [6.91, 158.18],
            values: ['2.920', '53.23%'],
            },
            {
            icon: 'DE',
            location: 'Germany',
            latLng: [10, 10],
            values: ['1.300', '20.43%'],
            },
            {
            icon: 'AU',
            location: 'USA',
            latLng: [43.93, 12.46],
            values: ['760', '10.35%'],
            },
            {
            icon: 'GB',
            location: 'USA',
            latLng: [3.2, 73.22],
            values: ['690', '7.87%'],
            },
        ],

        
        },
    ],
}
