import NavigationExamApi from '../../../../api/NavigationExamApi';
import ApiCaller from '../../../../lib/ApiCaller';
import ClosedExamListTable, {
  ListTable,
  ListTableView,
} from './closedExamListTable';

class ClosedExamListPresenter {
  loggedUser: any;
  navigationExamApi: NavigationExamApi;
  table: ListTable;

  constructor(profile: any, private view: ListTableView) {
    this.loggedUser = profile;
    this.table = new ClosedExamListTable(this.loggedUser, view);
    this.navigationExamApi = new NavigationExamApi(
      new ApiCaller(this.loggedUser.token),
    );
  }

  async getAllClosedExams(): Promise<any> {
    return await this.navigationExamApi.getClosedExams();
  }

  getTableColumns() {
    return this.table.getColumns();
  }

  getTableName(): string {
    return this.table.getName();
  }
}

export default ClosedExamListPresenter;
