import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Card,
  Col,
  Button,
} from 'reactstrap';

import { Bar } from 'react-chartjs-2';
import { chartExample4 } from 'variables/charts';

import DashboardApi from '../../../../api/DashboardApi';
import { AppState } from '../../../../store/store';

import { QuestionTotal } from './types';
import { barChartBackground } from './colors';
import SyllabusFilter from '../syllabusFilter/SyllabusFilter';

type QuestionsPerModuleAndTopicReportProps = {
  allowedSyllabuses: string[];
}

const QuestionsPerModuleAndTopicReport = ({ allowedSyllabuses }: QuestionsPerModuleAndTopicReportProps) => {
  const rowSize = 2;
  const [syllabus, setSyllabus] = useState<string>('');
  const [questionTotal, setQuestionTotal] = useState<QuestionTotal>({
    S01: 0,
    S02: 0,
    S04: 0,
    S03: 0,
  });
  const [report, setReport] = useState<object>({});
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const ticksCallback = useCallback((value: number) => {
    if (value % 1 === 0) {
      return value;
    }
  }, []);

  const barOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: ticksCallback,
          },
        },
      ],
    },
  };

  useEffect(() => {
    DashboardApi.getQuestionsPerModuleAndTopicReport(loggedUser.token)
      .then((result: any) => {
        setSyllabus(Object.keys(result).sort()[0] ?? '');
        setReport(result);
      })
      .catch((error) => {
        throw new Error(error);
      });
  }, [loggedUser.token]);

  useEffect(() => {
    const res = {
      S01: 0,
      S02: 0,
      S03: 0,
      S04: 0,
    };
    Object.keys(report).map((syllabus) => {
      Object.keys(report[syllabus]).map((module) => {
        Object.keys(report[syllabus][module]).map((topic) => {
          res[syllabus] += report[syllabus][module][topic];
        });
      });
    });
    setQuestionTotal(res);
  }, [report]);

  const getBars = useCallback(() => {
    if (Object.keys(report).length && report[syllabus]) {
      const modules = Object.keys(report[syllabus]).map((module, index) => {
        const labels = Object.keys(report[syllabus][module]);
        const questionAmounts = labels.map(
          (topic) => report[syllabus][module][topic],
        );
        const options = {
          ...chartExample4.options,
          ...barOptions,
          title: {
            display: true,
            text: `Module ${module}`,
          },
        };
        const data = {
          labels: labels,
          datasets: [
            {
              label: module,
              backgroundColor: barChartBackground,
              data: questionAmounts,
            },
          ],
        };
        return (
          <Col sm="6" key={index}>
            <Bar key={index} data={data} options={options} />
          </Col>
        );
      });
      const result = [];
      for (let i = 0; i < modules.length; i += rowSize) {
        const elems = modules.slice(i, i + rowSize);
        const row = <Row>{elems}</Row>;
        result.push(row);
      }
      return result;
    }
  }, [barOptions, report, syllabus]);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Number of Active Questions per Module & Topic
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Col>
            {Object.keys(report).length > 0 && (
              <SyllabusFilter
                disableButtons={{
                  S01: !report['S01'],
                  S02: !report['S02'],
                  S03: !report['S03'],
                  S04: !report['S04'],
                }}
                syllabus={syllabus}
                setSyllabus={setSyllabus}
                displayTotal
                questionTotal={questionTotal}
                allowedSyllabuses={allowedSyllabuses}
              />
            )}
            {Object.keys(report).length === 0 && (
              <p>There are no active questions in your assigned list yet</p>
            )}
          </Col>
          <Col>{getBars()}</Col>
        </CardBody>
      </Card>
    </>
  );
};

export default QuestionsPerModuleAndTopicReport;
