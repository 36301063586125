import React, { useState, useEffect, SetStateAction, Dispatch} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Card, CardHeader, CardBody, Col, Row, CardTitle, ModalHeader,
  ModalBody, Modal,
} from 'reactstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import { downloadCertificate } from '../../../../shared/utils/ApiCommands';
import CertificateApi from 'api/CertificateApi';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import { PaymentTypeLabels } from "../../../../lib/common/models/transaction";
import { CertificateStatusLabels } from "../../../../lib/common/models/examCertificate";
import ExamCertificate from '../../../../lib/common/models/examCertificate';
import NavigationExamPayComponent from 'views/components/payment/navigationExamPayComponent';
import ExamCertificateCollection from '../../../../lib/common/models/examCertificateCollection';
import {
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';

interface CandidateCertificatesListProps {
  candidateId?: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setLoadingTip: Dispatch<SetStateAction<string>>,
}

const CandidateCertificatesList: React.FC<CandidateCertificatesListProps> = ({
  candidateId,
  setLoading,
  setLoadingTip,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [modalPayment, setModalPayment] = useState<boolean>(false);
  const [currentCertificateId, setCurrentCertificateId] = useState<string>('');
  const [certificates, setCertificates] =
    React.useState<ExamCertificateCollection>(
      new ExamCertificateCollection([]),
    );

  const getCertificates = () => {
    const certificateApi = new CertificateApi(new ApiCaller(loggedUser.token));
    if (candidateId) {
      certificateApi
        .getAllByCandidate(candidateId)
        .then((data: ExamCertificateCollection) => {
          setCertificates(data);
        });
    }
  }

  useEffect(() => {
    getCertificates();
  }, [candidateId]);

  const reissueCertificate = () => {
    setModalPayment(false);
    setLoadingTip('Reissuing certificate...');
    setLoading(true);
    const certificateApi = new CertificateApi(new ApiCaller());
    certificateApi
      .reissue(currentCertificateId, loggedUser.token)
      .then(() => {
        setLoading(false);
        getCertificates();
        toast.success('Certificate has been reissued');
      })
      .catch(() => {
        setLoading(false);
        toast.error('Failed to reissue certificate. Payment has been reverted.');
      });
  };

  const columns = [
    {
      Header: 'NUMBER',
      accessor: (data: ExamCertificate) =>
        data.displayName ? data.displayName : '-',
      sortable: true,
    },
    {
      Header: 'TITLE',
      accessor: (data: ExamCertificate) => data.getTitle(),
      sortable: true,
    },
    {
      Header: 'CREATE DATE',
      accessor: (data: ExamCertificate) => formatDateTime(data.createdAt),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'createdAt'),
    },
    {
      Header: 'DATE',
      accessor: (data: ExamCertificate) =>
        moment(data.date).utc().format('DD-MM-YYYY'),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
    },
    {
      Header: 'STATUS',
      accessor: (data: ExamCertificate) => data.status,
      sortable: true,
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item
            onClick={() =>
              downloadCertificate(row.row.original._id, loggedUser.token)
            }>
            <i className="nc-icon nc-cloud-download-93" />
            &nbsp;&nbsp;DOWNLOAD CERTIFICATE
          </Dropdown.Item>
          {
            row.row.original.status !== CertificateStatusLabels.REVOKED &&
            <>
              <Dropdown.Item
                onClick={() => {
                  setCurrentCertificateId(row.row.original._id);
                  setModalPayment(true);
                }}>
                <i className="nc-icon nc-settings" />
                &nbsp;&nbsp;REISSUE CERTIFICATE
              </Dropdown.Item>
            </>
          }
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Certificates List</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={certificates?.certificates}
                  columns={columns}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modalPayment}>
          <ModalHeader>
            Certificate Reissue Fee
          </ModalHeader>
          <ModalBody>
            <NavigationExamPayComponent
              onCancel={() => setModalPayment(false)} onSuccess={reissueCertificate}
              paymentType={PaymentTypeLabels.CERTIFICATE_REISSUE_FEE}
              reference={""}
              certificateId={currentCertificateId}
            />
          </ModalBody>
        </Modal>
    </>
  );
};

export default CandidateCertificatesList;
