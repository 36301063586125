import ExamSchedule from "./examSchedule";

class ExamScheduleCollection {
    schedules: Array<ExamSchedule>;

    constructor(schedules: Array<ExamSchedule>) {
        this.schedules = schedules
    }

    length() {
        return this.schedules.length;
    }
}

export default ExamScheduleCollection;
