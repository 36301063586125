/**
 * Refactored this from the backend, to sanitize the question for the exams.
 * @param question Question string to be displayed as html
 * @returns Question string html without font-size and font-family on them.
 */

const fontSizeRegex = /font-size\s*:\s*[^;]+;?/gi;
const fontFamilyRegex = /font-family\s*:\s*[^;]+;?/gi;

export const SanitizeQuestionString = (question?: string): string => {
    if (!question) return "";
    return question.replace(fontSizeRegex, '').replace(fontFamilyRegex, '').trim()
}