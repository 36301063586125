import Module from "./module";

interface SyllabusInfo {
  code: string,
  title: string,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  _id?:string;
  modules?: any[];
}

interface BasicSyllabusDTO {
  _id?:string;
  code?: string;
  title?: string;
}

class Syllabus {
  code: string;
  title: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  _id?:string;
  modules?: Module[];

  constructor(SyllabusInfo: SyllabusInfo) {
    this.code = SyllabusInfo.code;
    this.title = SyllabusInfo.title;
    this.createdAt = SyllabusInfo.createdAt;
    this.createdBy = SyllabusInfo.createdBy;
    this.updatedAt = SyllabusInfo.updatedAt;
    this.updatedBy = SyllabusInfo.updatedBy;
    this._id = SyllabusInfo._id;
    this.modules = SyllabusInfo.modules?.map((module) => Module.prototype.load(module));
  }

  load(data: any): Syllabus {
    return new Syllabus(data);
  }

  toObject(): any {
    return {
      code: this.code,
      title: this.title,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      _id: this._id,
    };
  }

  toDto(data: any): BasicSyllabusDTO {
    return {_id: data._id, code: data.code, title: data.title};
  }
}

export default Syllabus;
