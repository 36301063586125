import NavigationExam from './navigationExam'

class NavigationExamCollection {
    navigationExams: Array<NavigationExam>;

    constructor(navigationExams: Array<NavigationExam>) {
        this.navigationExams = navigationExams
    }

    length() {
        return this.navigationExams.length
    }
}

export default NavigationExamCollection;
