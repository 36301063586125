import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Card,
  Button,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
} from 'reactstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import Select from 'react-select';

import User from 'lib/common/models/user';
import UserRole, { AssignableObjects } from 'lib/common/models/userRole';
import ApiCaller from 'lib/ApiCaller';
import UserApi from 'api/UserApi';
import ModuleApi from 'api/ModuleApi';
import ReactTable from 'components/ReactTable/ReactTable';
import { AppState } from 'store/store';
import Settings from 'lib/settings';
import UserAlertModal from './userAlertModal';
import GenericCollection from 'lib/common/models/genericCollection';

import 'react-toastify/dist/ReactToastify.css';
import { useSyllabus } from 'hooks';
interface ContainerProps {
  objectName: string;
}

const UserRoleList: React.FC<ContainerProps> = ({ objectName }) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const history = useHistory();
  const userApi = new UserApi(new ApiCaller(loggedUser.token));

  const [selectedUser, updateSelectedUser] = useState<User>();
  const [filterObjects, updateFilterObjects] =
    React.useState<GenericCollection>(new GenericCollection([]));
  const [filterObjectId, setFilterObjectId] = React.useState('');
  const [objects, setObjects] = React.useState<GenericCollection>(
    new GenericCollection([]),
  );
  const [availableObjects, setAvailableObjects] =
    React.useState<GenericCollection>(new GenericCollection([]));
  const [selectedRole, updateSelectedRole] = React.useState<UserRole>(
    new UserRole({
      userId: loggedUser,
      objectIds: [],
      objectName: objectName,
    }),
  );
  const { syllabus } = useSyllabus();

  const [showAlert, setShowAlert] = React.useState<boolean>(false);

  const needsFilter = (): boolean => {
    return objectName == AssignableObjects.Module;
  };

  React.useEffect(() => {
    const user = Settings.getCurrentAdminUser();
    updateSelectedUser(user);
    console.log(`Selected user: ${user?._id}/${objectName}`);
    if (user?._id) {
      userApi
        .getUserRole(user?._id, objectName)
        .then((userRole: any) => {
          if (userRole) {
            updateSelectedRole(userRole);
            setShowAlert(userRole?.objectIds.length === 0);
          } else {
            setShowAlert(true);
          }
        })
        .catch((err: any) => {
          console.error(err);
          toast.error(err);
        });
    }
  }, []);

  React.useEffect(() => {
    // TODO Modify if more objects are added
    if (syllabus.length) {
      const collection = new GenericCollection(syllabus);

      if (objectName == AssignableObjects.Module) {
        updateFilterObjects(collection);
      } else {
        setObjects(collection);
        const availableItems = collection.items.filter(
          (item: any) =>
            selectedRole.objectIds.map((i) => i._id).indexOf(item._id) < 0,
        );
        setAvailableObjects(new GenericCollection(availableItems));
      }
    }
  }, [selectedRole]);

  React.useEffect(() => {
    // TODO Modify when more filter objects are possible
    if (filterObjectId !== '') {
      const moduleApi = new ModuleApi(new ApiCaller(loggedUser.token));
      moduleApi.getAllModules(filterObjectId).then((modules: any) => {
        if (modules && Object.keys(modules).length > 0) {
          setObjects(modules);
          const availableModules = modules.modules.filter(
            (item: any) =>
              selectedRole.objectIds.map((i) => i._id).indexOf(item._id) < 0,
          );
          setAvailableObjects(new GenericCollection(availableModules));
        }
      });
    }
  }, [filterObjectId]);

  const onFilterObjectChange = (selectedFilterObject: any) => {
    setFilterObjectId(selectedFilterObject._id);
  };

  const resetObjectValues = () => {
    updateSelectedRole(
      new UserRole({
        ...selectedRole,
        objectIds: [],
      }),
    );

    updateObject([]);
  };

  const assignObject = (e: any) => {
    const assignedObject = e.row.original;

    if (selectedUser?._id && assignedObject?._id) {
      const newList = [...selectedRole.objectIds, assignedObject];
      updateSelectedRole(
        new UserRole({
          ...selectedRole,
          objectIds: newList,
        }),
      );

      setAvailableObjects(
        new GenericCollection(
          availableObjects.items.filter((item) => item !== assignedObject),
        ),
      );
      updateObject(newList);
    }
  };

  const unAssignObject = (e: any) => {
    const unassignedObject = e.row.original;

    if (selectedUser?._id && unassignedObject?._id) {
      const newList = selectedRole?.objectIds.filter(
        (item) => item !== unassignedObject,
      );
      updateSelectedRole(
        new UserRole({
          ...selectedRole,
          objectIds: newList,
        }),
      );

      if (
        objects.items.map((item) => item._id).includes(unassignedObject._id)
      ) {
        setAvailableObjects(
          new GenericCollection([...availableObjects.items, unassignedObject]),
        );
      }

      updateObject(newList);
    }
  };

  const updateObject = (objectList: Array<any>) => {
    const api = new UserApi(new ApiCaller());
    api
      .updateUserRoles(
        selectedUser?._id!,
        objectName,
        objectList.map((item) => item._id),
        loggedUser.token,
      )
      .then(() => {
        toast.success('Permissions updated successfully');
      })
      .catch((err: any) => {
        console.error(err);
        toast.error('Error updating ' + objectName);
      });
  };

  const columnsAvailable = [
    { Header: 'CODE', accessor: 'code', sortable: true },
    { Header: 'TITLE', accessor: 'title', sortable: true },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item onClick={() => assignObject(row)}>
            <i className="nc-icon nc-air-baloon" />
            &nbsp;&nbsp;ASSIGN
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  const columnsAssigned = [
    { Header: 'CODE', accessor: 'code', sortable: true },
    { Header: 'TITLE', accessor: 'title', sortable: true },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item onClick={() => unAssignObject(row)}>
            <i className="nc-icon nc-simple-delete" />
            &nbsp;&nbsp;REMOVE
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  const close = () =>
    history.push(
      `/${
        loggedUser.isAdmin() ? 'navozyme' : loggedUser.type.toLowerCase()
      }/user/list`,
    );

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Available List</CardTitle>
            </CardHeader>
            {needsFilter() && (
              <Col sm="10">
                <FormGroup>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="syllabus"
                    //value={syllabus.syllabuses}
                    onChange={onFilterObjectChange}
                    options={filterObjects?.items}
                    getOptionLabel={(item) => item.code + ' ' + item.title}
                    getOptionValue={(item) => item.code}
                    placeholder="Choose a syllabus"
                  />
                </FormGroup>
              </Col>
            )}
            <CardBody>
              <ReactTable
                data={availableObjects?.items}
                columns={columnsAvailable}
              />
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Assigned List</CardTitle>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={selectedRole?.objectIds}
                columns={columnsAssigned}
              />
            </CardBody>
            <Col sm="10">
              <Button
                className="btn-round btn btn-danger"
                onClick={resetObjectValues}>
                Clear
              </Button>
              <Button className="btn-round btn btn-info" onClick={close}>
                Close
              </Button>
            </Col>
          </Card>
        </Col>
      </Row>
      <UserAlertModal modal={showAlert} setModal={setShowAlert} />
    </>
  );
};

export default UserRoleList;
