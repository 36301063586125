import { combineReducers, createStore, compose } from 'redux';
import { Store } from 'antd/lib/form/interface';

import { sessionReducer } from './reducers/sessionReducer';
import { notificationReducer } from './notificationStore';
import { docsReducer } from './reducers/docsReducer';
import { markerReducer } from './reducers/markerReducer';
import { listsReducer } from './reducers/listsReducer';
import examCenterReducer from './reducers/examCenterReducer';
import syllabusReducer from './reducers/syllabusReducer';

const appReducer = combineReducers({
  session: sessionReducer,
  notification: notificationReducer,
  current: docsReducer,
  marker: markerReducer,
  lists: listsReducer,
  exam_center: examCenterReducer,
  syllabus: syllabusReducer,
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

export type AppState = ReturnType<typeof appReducer>;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ || compose;

export const store = createStore(appReducer, composeEnhancers());
export default function configureStore(): Store {
  // const store = createStore(appReducer);
  return store;
}
