import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
} from 'react-dropzone-uploader';
import { toast } from 'react-toastify';

import axios from 'axios';
import ProgressBar from './nzProgressBar';

import { AppState } from '../../../store/store';
import Settings from '../../../lib/settings';

import './FileUploaderDropZone.css';

// add type defs to custom LayoutComponent prop to easily inspect props passed to injected components
const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files,
  extra: { maxFiles },
}: ILayoutProps) => {
  return (
    <div>
      {previews}
      {files.length === 0 && (
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
      )}
      {files.length > 0 && submitButton}
    </div>
  );
};

interface upload {
  selectedFile: string[];
  loaded: number;
}
interface ContainerProps {
  onFinish: Function;
  type: string;
  status?: string;
  projectId?: string;
  vesselIMO?: string;
  captainId?: string;
  expiredDate?: Date;
  id?: string | undefined;
  flowPermissionToUpload?:boolean;
}

const CustomDropZoneLayout: React.FC<ContainerProps> = ({
  onFinish,
  type,
  status = '',
  projectId,
  vesselIMO,
  captainId,
  expiredDate,
  id,
  flowPermissionToUpload= true
}) => {
  const [uploadState, setUploadState] = useState<upload>({
    selectedFile: [],
    loaded: 0,
  });
  const [dropIsActive, setdropIsActive] = useState(true);
  // add type defs to function props to get TS support inside function bodies,
  // and not just where functions are passed as props into Dropzone

  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const handleSubmit: IDropzoneProps['onSubmit'] = (files) => {

    if (!flowPermissionToUpload) {
      toast.error('Please inform the candidate\'s assigned syllabus before submitting the report');
      return;
    }
    const data = new FormData();
    for (let x = 0; x < files.length; x++) {
      data.append('file', files[x].file);
    }

    expiredDate && data.append('expirationDate', expiredDate.toDateString());
    data.append('projectId', projectId || ''); // THIS SHOULD BE THE EXAM ID
    data.append('vesselIMO', vesselIMO || '');
    data.append('captainId', captainId || '');
    if (id) {
      data.append('id', id);
    }
    data.append('type', type);
    data.append('status', status);

    setdropIsActive(false);
    axios
      .post(`${Settings.getApiURL()}/v1/documents/document`, data, {
        headers: {
          'x-access-token': loggedUser.token,
        },
        onUploadProgress: (ProgressEvent) => {
          setUploadState({
            selectedFile: [files[0].meta.name],
            loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then((res) => {
        setdropIsActive(true);
        // then print response status
        toast.success('Upload successful');
        onFinish(res.data);
      })
      .catch(() => {
        // then print response status
        setdropIsActive(true);
        toast.error('Upload failed');
      });
  };

  return (
    <div className='dropzone-area'>
      {dropIsActive && (
        <Dropzone
          maxFiles={1}
          accept="image/*,.pdf"
          LayoutComponent={Layout}
          onSubmit={handleSubmit}
          inputContent="Drop Document"
          canCancel
        />
      )}
      {!dropIsActive && (
        <ProgressBar
          completed={uploadState.loaded}
          bgcolor={'#0a2454'}
          label={`${uploadState.loaded}%`}
        />
      )}
    </div>
  );
};

export default CustomDropZoneLayout;
