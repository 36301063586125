import ApiCaller from '../lib/ApiCaller';
import ExamCenterCollection from '../lib/common/models/examCenterCollection';
import ExamCenter from '../lib/common/models/examCenter';

class CandidateApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getAllExamCenters(): Promise<void | ExamCenterCollection> {
    return this.apiCaller
      .call('/v1/examCenter', 'GET')
      .then((data) => {
        const apiArr = data.centers.map(
          (center: any) => new ExamCenter(center),
        );
        return new ExamCenterCollection(apiArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  createExamCenter(
    examCenter: ExamCenter,
    token?: string,
  ): Promise<ExamCenter> {
    return this.apiCaller
      .call('/v1/examCenter', 'PUT', examCenter, token)
      .then((data) => {
        return new ExamCenter(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  updateExamCenter(
    examCenter: ExamCenter,
    token?: string,
  ): Promise<ExamCenter> {
    return this.apiCaller
      .call('/v1/examCenter/' + examCenter._id, 'POST', examCenter, token)
      .then((data) => {
        return new ExamCenter(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  deleteExamCenter(examCenterId: string, token?: string): Promise<void> {
    return this.apiCaller
      .call(
        '/v1/rest/examCenter/' + examCenterId,
        'DELETE',
        {
          id: examCenterId,
        },
        token,
      )
      .catch((err) => {
        console.error(err);
      });
  }
}

export default CandidateApi;
