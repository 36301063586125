import ApiCaller from 'lib/ApiCaller';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { APP_STATUS } from 'types/app';
import SyllabusApi from 'api/SyllabusApi';
import { setSyllabus, setSyllabusStatus } from 'store/actions/syllabus/actions';
import Syllabus from 'lib/common/models/syllabus';

const useSyllabus = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const { list, status } = useSelector((state: AppState) => state.syllabus);
  const [refresh, setRefresh] = useState(0);
  const refetchSyllabus = () => setRefresh((prev) => prev + 1);
  const [allowedSyllabuses, setAllowedSyllabuses] = useState<string[]>([]);
  const dispatch = useDispatch();

  const getAllSyllabus = () => {
    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi
      .getAllSyllabus(loggedUser.token)
      .then((syllabusList: any) => {
        dispatch(setSyllabusStatus(APP_STATUS.IDLE));
        if (syllabusList.syllabuses.length) {
          setAllowedSyllabuses(
            syllabusList.syllabuses.map((syllabus: Syllabus) => syllabus.code),
          );
        }
        dispatch(setSyllabus(syllabusList.syllabuses));
      })
      .catch(() => {
        dispatch(setSyllabusStatus(APP_STATUS.FAILED));
      });
  };

  const updateSyllabus = (newList: Syllabus[]) => {
    dispatch(setSyllabus(newList));
  };

  useEffect(() => {
    if (!list.length && status !== APP_STATUS.LOADING) {
      dispatch(setSyllabusStatus(APP_STATUS.LOADING));
      getAllSyllabus();
    }
  }, [loggedUser.token]);

  useEffect(() => {
    if (refresh!== 0) {
      getAllSyllabus();
    }
  }, [refresh]);

  return {
    syllabus: list,
    allowedSyllabuses,
    listSyllabusCode: allowedSyllabuses,
    status,
    updateSyllabus,
    refetchSyllabus,
  };
};

export default useSyllabus;
