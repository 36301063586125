import { Editor as TinymceEditor } from 'tinymce';

export const pastePreprocess = (
  plugin: TinymceEditor,
  args: { content: string },
) => {
  args.content = args.content.replace(/class=MsoNormal/g, '');
  args.content = args.content.replace(/lang=ES-TRAD/g, '');
  args.content = args.content.replace(/lang=EN-TRAD/g, '');
  // Delete 'mso-'
  args.content = args.content.replace(/mso-.*;/gm, '');
  args.content = args.content.replace(/mso-ansi-language:ES-TRAD/g, '');
  args.content = args.content.replace(/mso-ansi-language:ES-TRAD;/g, '');
  args.content = args.content.replace(/mso-ansi-language: ES-TRAD;/g, '');
  args.content = args.content.replace(/mso-bidi-font-weight:normal/g, '');

  // Delete 'v:shape'
  args.content = args.content.replace(/<v:shape[^>]*>.*?<\/v:shape>/g, '');
  // Delete 'o:p'
  args.content = args.content.replace(/<o:p>\s*<\/o:p>/g, '');
  // Delete comments HTML
  args.content = args.content.replace(/<!--.*?-->/g, '');
  // Delete conditionals of Word
  args.content = args.content.replace(
    /<!-- \[if gte mso 9\]>.*?<!\[endif\]-->/g,
    '',
  );
};

/**
 * Gets the plugins to apply to the editor
 * @returns {string[]}
 */
export const getPlugins = (autoresize: boolean) => {
  let base_plugins = [
    'advlist',
    'autolink',
    'lists',
    'image',
    'anchor',
    'searchreplace',
    'visualblocks',
    'media',
    'table',
    'wordcount',
    'pagebreak',
    'quickbars',
    'nonbreaking',
    'code',
    'paste',
    'visualchars',
    'visualblocks,',
  ];
  if (autoresize) {
    base_plugins.push('autoresize');
  }
  return base_plugins;
};

/**
 * ISSUES:
 * <h2> elements has always <p> elements inside for the content. TinyMCE ignores the <h2> and only outputs the <p>
 *     Change Tiny Options to allow them and avoid HTML cleanup
 *     TODO: Change API to create h2 elements directly without <p> children
 */
export const getValidChildren = () => {
  return (
    '+h1[p|#text],' +
    '+h2[p|#text],' +
    '+h3[p|#text],' +
    '+h4[p|#text],' +
    '+h6[p|#text],' +
    '+h6[p|#text]'
  );
};
