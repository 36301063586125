import React from 'react';
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from 'reactstrap';

type StatsCardProps = {
  data: {
    label: string,
    value: string,
    timestamp: number,
  },
  iconClassName: string,
}

const StatsCard: React.FC<StatsCardProps> = ({ data, iconClassName }) => {
  return (
    <Col lg="3" md="6" sm="6">
      <Card className="card-stats">
        <CardBody>
          <Row>
            <Col md="4" xs="5">
              <div className="icon-big text-center icon-warning">
                <i className={iconClassName} />
              </div>
            </Col>
            <Col md="8" xs="7">
              <div className="numbers">
                <p className="card-category">
                  {data.label}
                </p>
                <CardTitle tag="p">
                  {data.value}
                </CardTitle>
                <p />
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <hr />
          <div className="stats">
            <i className="fa fa-refresh" />
            Updated now
          </div>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default StatsCard;
