import React, { FC } from 'react';
import {
  ModalHeader,
  ModalBody,
  Modal,
  ModalFooter,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import { Container, Label, Row, RowTitle, Title } from './style';
import { UserTypes } from 'lib/common/models/user';
interface IMarks {
  open: boolean;
  onClose: () => void;
  results: any[];
}
const Marks: FC<IMarks> = ({ open, onClose, results }) => {
  if (!results) {
    return null;
  }
  return (
    <Modal isOpen={open} size="lg">
      <ModalHeader>Marks for {results?.[0].candidate}</ModalHeader>
      <ModalBody>
        {results.length > 0 &&
          results.map((item: any, index: number) => {
            return (
              <Card key={item.createdAt}>
                <CardHeader style={{ display: 'flex', gap: '10px' }}>
                  <span>
                    <strong>Marker {results.length - index}: </strong> {item?.marker?.name}
                  </span>{' '}
                  <span>
                    <strong>Type:</strong> {UserTypes[item?.marker?.type]}
                  </span>
                </CardHeader>
                <CardBody>
                  <Container>
                    <RowTitle>
                      <Title>Question</Title>
                      <Title>Mark</Title>
                    </RowTitle>
                    {item.marks.map((mark: any) => (
                      <Row key={String(mark._id)}>
                        <Label>{mark.questionName}</Label>
                        <Label>{mark.questionMarks}</Label>
                      </Row>
                    ))}
                  </Container>
                </CardBody>
                <CardFooter>
                  Result: <strong>{item.result}</strong>
                </CardFooter>
              </Card>
            );
          })}
      </ModalBody>
      <ModalFooter style={{ marginRight: '20px' }}>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Marks;
