import React, { useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Select from 'react-select';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
  ModalHeader,
  ModalBody,
  Modal,
} from 'reactstrap';

import DetailLayout from 'layouts/DetailLayout';
import Settings from 'lib/settings';
import { AppState } from 'store/store';

import ApiCaller from 'lib/ApiCaller';
import UserApi from 'api/UserApi';
import NavigationExam, {
  ScriptIntentions,
  ScriptIntentionTypes,
} from 'lib/common/models/navigationExam';
import { UserTypesLabels } from 'lib/common/models/user';
import CandidatesList from './candidateList';
import CandidatesAssigned from './candidatesAssigned';
import CandidateCollection from 'lib/common/models/candidateCollection';
import QuestionApi from 'api/QuestionApi';
import NavigationExamApi from 'api/NavigationExamApi';
import PaymentList from './paymentList';
import NavigationExamPayComponent from '../../../../components/payment/navigationExamPayComponent';
import {
  defaultCurrency,
  PaymentTypeLabels,
} from 'lib/common/models/transaction';
import Candidate from 'lib/common/models/candidate';
import TransactionApi from 'api/TransactionApi';
import { formatCurrency } from 'shared/utils/currency';
import DocumentsApi from 'api/DocumentsApi';
import nzDocument from 'lib/common/models/nzDocument';
import ExamScriptUploader from '../../../../components/documents/ExamScriptUploader';
import CandidateEnrolmentDetail from './candidateEnrollmentDetail';
import { useSyllabus } from 'hooks';
import ExamStatusHistoryList from './examStatusHistoryList';

const NavigationExamDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const history = useHistory();
  const [currentExam, setCurrentExam] = useState<NavigationExam>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [firstMarkers, setFirstMarkers] = useState<any[]>([]);
  const [secondMarkers, setSecondMarkers] = useState<any[]>([]);
  const [examCandidates, setExamCandidates] = useState<CandidateCollection>(
    new CandidateCollection([]),
  );

  const { syllabus } = useSyllabus();
  const [modules, setModules] = useState<any[]>([]);

  const [modalPayment, setModalPayment] = useState<boolean>(false);
  const [modalCancel, setModalCancel] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<boolean>(false);

  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();
  const [currentScriptHash, setCurrentScriptHash] = useState<string>('');
  const [balanceState, setBalanceState] = useState({
    balance: 0,
    timestamp: 0,
  });

  const [isScriptUploadModalOpened, setIsScriptUploadModalOpened] =
    useState(false);

  useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();
    setCurrentExam(exam);

    const userApi = new UserApi(new ApiCaller(loggedUser.token));
    userApi.getUsersByType(UserTypesLabels.mcaMarker).then((userArr: any) => {
      setMarkers(userArr.users);
      setFirstMarkers(userArr.users);
      setSecondMarkers(userArr.users);
    });

    if (exam.id) {
      const examApi = new NavigationExamApi(new ApiCaller(loggedUser.token));
      examApi.getCandidates(exam?.id).then((data: any) => {
        setExamCandidates(data);
      });
    }
  }, [loggedUser.token]);

  useEffect(() => {
    if (currentExam?.syllabus) {
      const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
      questionApi
        .getModulesFromSyllabus(currentExam.syllabus._id)
        .then((m: any) => {
          setModules(m.modules);
        });
    }
  }, [currentExam?.syllabus, loggedUser.token]);

  useEffect(() => {
    const transactionApi = new TransactionApi(new ApiCaller(loggedUser.token));
    transactionApi
      .getBalance()
      .then((data) => {
        setBalanceState(data);
      })
      .catch(() => {
        toast.error('Error retrieving user balance');
      });
  }, [examCandidates, loggedUser.token]);

  const openUploadScriptModal = useCallback((scriptHash: string) => {
    setCurrentScriptHash(scriptHash);
    setIsScriptUploadModalOpened(true);
  }, []);

  const handleChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      const examInfo = currentExam?.toObject();
      if (examInfo) {
        examInfo[name] = value;
        setCurrentExam(new NavigationExam(examInfo));
      }
    },
    [currentExam],
  );

  const onSelectChange = useCallback(
    (e: any) => {
      const examInfo = currentExam?.toObject();
      if (examInfo) {
        examInfo['scriptIntention'] = e.value;
        setCurrentExam(new NavigationExam(examInfo));
      }
    },
    [currentExam],
  );

  const onDateChange = useCallback(
    (e: any) => {
      if (!loggedUser.isAdmin()) {
        return;
      }

      const info = currentExam?.toObject();

      if (info) {
        info['date'] = e.toDate();
        setCurrentExam(new NavigationExam(info));
      }
    },
    [currentExam, loggedUser],
  );

  const onSyllabusChange = useCallback(
    (e: any) => {
      const examInfo = currentExam?.toObject();
      if (examInfo) {
        examInfo['syllabus'] = e;
        setCurrentExam(new NavigationExam(examInfo));
      }
    },
    [currentExam],
  );

  const onModuleChange = useCallback(
    (e: any) => {
      const examInfo = currentExam?.toObject();
      if (examInfo) {
        examInfo['module'] = e;
        setCurrentExam(new NavigationExam(examInfo));
      }
    },
    [currentExam],
  );

  const onFirstMarkerChange = useCallback(
    (e: any) => {
      const markerInfo = currentExam?.toObject();
      if (markerInfo) {
        markerInfo['firstMarker'] = e;
        setCurrentExam(new NavigationExam(markerInfo));
        setSecondMarkers(markers.filter((item) => item !== e));
      }
    },
    [currentExam, markers],
  );

  const onSecondMarkerChange = useCallback(
    (e: any) => {
      const markerInfo = currentExam?.toObject();
      if (markerInfo) {
        markerInfo['secondMarker'] = e;
        setCurrentExam(new NavigationExam(markerInfo));
        setFirstMarkers(markers.filter((item) => item !== e));
      }
    },
    [currentExam, markers],
  );

  const updateExam = useCallback(() => {
    if (currentExam) {
      const navigationExamApi = new NavigationExamApi(new ApiCaller());
      navigationExamApi
        .updateNavigationExam(currentExam, loggedUser.token)
        .then((exam: any) => {
          if (exam) {
            history.push(`/${layout}/navigation_exam/list`);
            toast.info('Exam updated successfully');
          }
        })
        .catch(() => {
          toast.error('Error creating exam, please try again');
        });
    }
  }, [currentExam, history, layout, loggedUser.token]);

  const onCancelSuccess = () => {
    if (currentExam) {
      const navigationExamApi = new NavigationExamApi(new ApiCaller());
      navigationExamApi
        .cancel(currentExam, loggedUser.token)
        .then((exam: any) => {
          if (exam) {
            history.push(`/${layout}/navigation_exam/list`);
            toast.info('Exam cancelled successfully');
          }
        })
        .catch(() => {
          toast.error('Error cancelling exam, please try again');
        });
    }
  };

  const remove = useCallback(
    (e: any) => {
      const candidate = e.row.original;
      setSelectedCandidate(candidate);
      setModalPayment(true);
    },
    [setSelectedCandidate, setModalPayment],
  );

  const openDetails = useCallback(
    (e: any) => {
      const candidate = e.row.original;
      setSelectedCandidate(candidate);
      setModalDetail(true);
    },
    [setSelectedCandidate, setModalDetail],
  );

  const removeCandidate = useCallback(
    (candidate?: any) => {
      const selected = candidate ? candidate : selectedCandidate;

      if (selected && currentExam?.id) {
        const navigationExamApi = new NavigationExamApi(new ApiCaller());
        navigationExamApi
          .removeCandidate(currentExam?.id, selected, loggedUser.token)
          .then(() => {
            const remaining = examCandidates.candidates.filter(
              (item) => item._id !== selected._id,
            );
            setExamCandidates(new CandidateCollection(remaining));
          })
          .catch(() => {
            toast.error('Error removing candidate');
          });
      }
    },
    [currentExam, loggedUser.token, examCandidates, selectedCandidate],
  );

  const onRefundSuccess = useCallback(() => {
    setModalPayment(false);
    removeCandidate();
  }, [setModalPayment, removeCandidate]);

  const columns = [
    {
      Header: 'SERIAL NO.',
      accessor: (d: any) => (d.serialNumber ? d.serialNumber : '-'),
      sortable: true,
    },
    { Header: 'NAME', accessor: (d: any) => d.fullName(), sortable: true },
    {
      Header: 'BIRTH DATE',
      accessor: (d: any) => moment(d.birthDate).utc().format('DD-MM-YYYY'),
    },
    { Header: 'PASSPORT NO.', accessor: 'passportNumber', sortable: true },
    { Header: 'EMAIL', accessor: 'email', sortable: true },
    {
      Header: 'LEARNING DIFF.',
      accessor: (d: any) => (d.special ? 'Yes' : 'No'),
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => {
        const scriptHash = `${row.row.original._id}`;
        const currentDocs = docs.filter((d) => d.type === scriptHash);
        return (
          <>
            <DropdownButton variant="default" title="Actions" size="sm">
              <Dropdown.Item onClick={() => openDetails(row)}>
                <i className="nc-icon nc-settings" />
                &nbsp;&nbsp;DETAILS
              </Dropdown.Item>
              {!currentExam?.isDeadlineReached() &&
                !currentExam?.isCancelled() &&
                loggedUser.hasCandidatePermissions() && (
                  <Dropdown.Item onClick={() => remove(row)}>
                    <i className="nc-icon nc-settings-gear-65" />
                    &nbsp;&nbsp;REMOVE
                  </Dropdown.Item>
                )}
              {currentExam?.scriptIntention === ScriptIntentionTypes.SCAN &&
                !loggedUser.isExamCentreAuditor() && (
                  <Dropdown.Item
                    onClick={() => openUploadScriptModal(scriptHash)}>
                    <i className="nc-icon nc-cloud-upload-94" />
                    &nbsp;&nbsp;SCRIPT UPLOAD
                  </Dropdown.Item>
                )}
            </DropdownButton>
            <div className={currentDocs[0] ? 'table-script-uploader' : ''}>
              <ExamScriptUploader
                doc={currentDocs}
                examId={currentExam?.id}
                label={currentScriptHash}
                isModalOpened={isScriptUploadModalOpened}
                setIsModalOpened={setIsScriptUploadModalOpened}
                onChangeDocs={getDocs}
              />
            </div>
          </>
        );
      },
    },
  ];

  const [docs, setDocs] = useState<Array<nzDocument>>([]);

  const getDocs = useCallback(() => {
    if (!currentExam?.id) return;
    const api = new DocumentsApi(new ApiCaller());
    api
      .getAllByProjectId(currentExam.id)
      .then((item: any) => {
        setDocs(item.files);
      })
      .catch((e) => {
        toast.error('There are no documents, please, proceed with update');
      });
  }, [currentExam?.id]);

  useEffect(() => {
    getDocs();
  }, [currentExam?.id]);

  const isScriptIntentionFieldDisabled = () => {
    if (currentExam) {
      return (
        !loggedUser.isAdmin() &&
        !(
          loggedUser.type === UserTypesLabels.IAMIExamAdmin &&
          moment(currentExam.date).isAfter(moment(new Date()))
        )
      );
    }
  };

  return (
    <>
      <div className="content">
        <DetailLayout title={'Go back to exam list'}>
          {currentExam && (
            <>
              <Card>
                <CardHeader>
                  {!loggedUser.isType(UserTypesLabels.examCentre) && (
                    <h4 className="card-title">
                      {currentExam?.getExamNumber()}
                    </h4>
                  )}
                  {loggedUser.isType(UserTypesLabels.examCentre) && (
                    <h4 className="card-title">
                      {currentExam?.getExamNumber()} (Available credit:
                      {formatCurrency(balanceState.balance, defaultCurrency)})
                    </h4>
                  )}
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Label sm="2">Exam Centre</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            readOnly
                            value={currentExam.examCenterId?.name}
                            type="text"
                            name="Exam Centre"
                            placeholder="Exam Centre"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Syllabus</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="syllabus"
                            value={currentExam?.syllabus}
                            onChange={onSyllabusChange}
                            options={syllabus}
                            getOptionLabel={(item) =>
                              item.code + ' ' + item.title
                            }
                            getOptionValue={(item) => item.code}
                            placeholder="Choose a syllabus"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Module</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="module"
                            value={currentExam?.module}
                            onChange={onModuleChange}
                            options={modules}
                            getOptionLabel={(item) =>
                              item.code + ' ' + item.title
                            }
                            getOptionValue={(item) => item.code}
                            placeholder="Choose a module"
                            isDisabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Date & Time</Label>
                      <Col sm="10">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{ readOnly: !loggedUser.isAdmin() }}
                            timeFormat={'HH:mm (Z)'}
                            dateFormat={'DD-MM-YYYY'}
                            onChange={(e: any) => onDateChange(e)}
                            value={moment(currentExam?.date)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ display: 'none' }}>
                      <Label sm="2">Estimated Candidates</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentExam.numCandidates}
                            onChange={handleChange}
                            type="number"
                            name="numCandidates"
                            placeholder="Estimated Candidates"
                            required
                            readOnly={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Script Intention</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="status"
                            value={{
                              label:
                                ScriptIntentions[currentExam?.scriptIntention],
                              value: currentExam?.scriptIntention,
                            }}
                            onChange={onSelectChange}
                            options={Object.keys(ScriptIntentions).map(
                              function (type) {
                                return {
                                  value: type,
                                  label: ScriptIntentions[type],
                                };
                              },
                            )}
                            placeholder="Status"
                            isDisabled={isScriptIntentionFieldDisabled()}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {currentExam?.isCancelled() && (
                      <Row>
                        <Label sm="2">Cancellation date</Label>
                        <Col sm="10">
                          <FormGroup>
                            <ReactDatetime
                              inputProps={{ readOnly: true }}
                              timeFormat={'HH:mm'}
                              dateFormat={'DD-MM-YYYY'}
                              onChange={(e: any) => onDateChange(e)}
                              value={moment(currentExam?.cancellationDate)}
                            />
                          </FormGroup>
                          {currentExam?.isLateCancellation() && (
                            <FormGroup className={`has-label has-danger`}>
                              <label className="error">
                                This exam was a late cancellation.
                              </label>
                            </FormGroup>
                          )}
                        </Col>
                      </Row>
                    )}
                    {loggedUser.hasAdminRole() && (
                      <Row>
                        <Label sm="2">First Marker</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="firstMarker"
                              value={currentExam?.firstMarker}
                              onChange={onFirstMarkerChange}
                              options={firstMarkers}
                              getOptionLabel={(item) =>
                                `${item.name} (${item.email})`
                              }
                              getOptionValue={(item) =>
                                `${item.name} (${item.email})`
                              }
                              placeholder="Choose first marker"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {loggedUser.hasAdminRole() && (
                      <Row>
                        <Label sm="2">Second Marker</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="secondMarker"
                              value={currentExam?.secondMarker}
                              onChange={onSecondMarkerChange}
                              options={secondMarkers}
                              getOptionLabel={(item) =>
                                `${item.name} (${item.email})`
                              }
                              getOptionValue={(item) =>
                                `${item.name} (${item.email})`
                              }
                              placeholder="Choose second marker"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Label sm="2" />
                      <Col sm="10">
                        {loggedUser.hasAdminRole() && (
                          <Button
                            className="btn-round btn btn-danger"
                            onClick={updateExam}>
                            Update
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {loggedUser.isType(UserTypesLabels.examCentre) &&
                      currentExam.isCancellable() && (
                        <Row>
                          <Label sm="2" />
                          <Col sm="10">
                            <Button
                              className="btn-round btn btn-danger"
                              onClick={() => setModalCancel(true)}>
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      )}
                  </Form>
                </CardBody>
              </Card>
              <CandidatesAssigned
                {...{ examCandidates, columns, currentExam }}
              />
              <CandidatesList {...{ examCandidates, setExamCandidates }} />
              <PaymentList refresh={examCandidates} />
              {loggedUser.hasAdminRole() && <ExamStatusHistoryList />}
            </>
          )}
        </DetailLayout>
      </div>
      <Modal isOpen={modalPayment}>
        <ModalHeader>Refund Candidate Fee</ModalHeader>
        <ModalBody>
          <NavigationExamPayComponent
            onCancel={() => setModalPayment(false)}
            onSuccess={onRefundSuccess}
            paymentType={PaymentTypeLabels.REFUND}
            reference={selectedCandidate?.fullName()}
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={modalCancel}>
        <ModalHeader>Cancel Exam</ModalHeader>
        <ModalBody>
          <NavigationExamPayComponent
            onCancel={() => setModalCancel(false)}
            onSuccess={onCancelSuccess}
            paymentType={PaymentTypeLabels.CANCELLATION}
            reference={currentExam?.id}
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDetail}>
        <ModalHeader>Enrollment Detail</ModalHeader>
        <ModalBody>
          <CandidateEnrolmentDetail
            candidateId={selectedCandidate}
            examId={currentExam}
            setModal={setModalDetail}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default NavigationExamDetail;
