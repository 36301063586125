export function splitSylabus(data, sylabus) {
  let s = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].code.indexOf(sylabus) == 0) {
      s.push(data[i]);
    }
  }
  return s;
}

export function getPercentage(partialValue, totalValue) {
  return (100 * partialValue) / totalValue;
}
