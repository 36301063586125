import ExamGrade from "./examGrade";

class ExamGradeCollection {
    grades: Array<ExamGrade>;

    constructor(grades: Array<ExamGrade>) {
        this.grades = grades;
    }

    length() {
        return this.grades.length;
    }
}

export default ExamGradeCollection;
