import styled, { createGlobalStyle } from 'styled-components';
import buttonImg from '../../../../images/upload.svg';
import calendar from '../../../../images/calendar.svg';
import doc from '../../../../images/doc.svg';
import clock from '../../../../images/clock.svg';
import download from '../../../../images/download.svg';
import blockchain from '../../../../images/icon-blockchain.svg';
import eye from '../../../../images/eye.svg';
import check from '../../../../images/check.svg';

export default createGlobalStyle`
:root {
  --primary: #2E0CF9;
  --black: #0A0319;
  --blackLighter: #9e9e9e;
  --grayLight: #f5f5f5;
  --grayMedium: #e5e5e5;
  --white: #ffffff;
  --arrow: rgba(255,255,255, 0.3);
}
`;

export const PrearrivalList: any = styled.div`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;
  color: #4f4f4f;
  padding-bottom: 100px;
`;

export const PrearrivalShipList: any = styled.div`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  width: 100%;
  color: #4f4f4f;
  padding-bottom: 100px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const PrearrivalColumn: any = styled.div`
  list-style-type: none;
  margin: 20px;
  padding: 10px 30px;
  width: 40%;
  color: #4f4f4f;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const ImgArrivalDocument: any = styled.span`
  width: 44px;
  height: 44px;
  padding: 12px;
  background: rgba(46, 12, 249, 0.05);
  border-radius: 12px;
  > img {
    top: 10px;
  }
  margin-right: 10px;
  @media (max-width: 1200px) {
    width: 80px;
    height: 80px;
    padding: 27px;
  }
  &:hover,
  &:focus {
    background: transparent;
  }
`;

export const ImgDocument: any = styled.span`
  width: 44px;
  height: 44px;
  padding: 12px;
  background: transparent;
  border-radius: 12px;
  > img {
    top: 10px;
  }
  margin-right: 10px;
`;

export const DocumentUploadContainer: any = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  > p {
    color: black;
    font-size: 12px;
    width: 50px;
    @media (max-width: 800px) {
      padding-top: 10px;
    }
  }
  opacity: 0.9;
  transition: all 1s ease;
  &:hover,
  &:focus {
    opacity: 1;
    background: #e9e9e9;
    border-radius: 5px;
    padding-bottom: 5px;
    padding-top: 10px;
    margin-bottom: 10px;
    border: 5px dashed #f9f9f9;
  }
`;
export const DocumentDownloadContainer: any = styled.div`
  display: flex
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
  gap: 20px;
  > p {
    color: black;
    font-size: 12px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  border: 3px dashed #d9d9d9;
  transition: all 1s ease;
  &:hover,
  &:focus {
    border-color: #b9b9b9;
    cursor: pointer;
  }
`;

export const DateContainer: any = styled.span`
  width: 245px;
  padding: 12px;
  color: #4f4f4f;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  border: 0px !important;
  margin-left: 20px;
  padding-left: 20px;
  background: url(${calendar}) no-repeat;
  background-position: 0% 39%;
  z-index: 999999;
`;

export const TimeContainer: any = styled.span`
  width: 100px;
  border: 0px !important;
  margin-left: -105px;
  padding-left: 0px;
  background: url(${clock}) no-repeat;
  background-position: -0% 39%;
`;

export const Blockchain: any = styled.span`
  width: 100px;
  border: 0px !important;
  margin-left: 0px;
  padding-left: 0px;
  background: url(${blockchain}) no-repeat;
  background-position: -0% 39%;
`;

export const DocumentImg: any = styled.span`
  width: 120px;
  border: 0px !important;
  margin-left: 0px;
  padding-left: 0px;
  background: url(${doc}) no-repeat;
  background-position: -0% 39%;
`;

export const Eye: any = styled.span`
  width: 100px;
  border: 0px !important;
  margin-left: 0px;
  padding-left: 0px;
  background: url(${eye}) no-repeat;
  background-position: -0% 39%;
`;

export const UploadDocument: any = styled.button`
  cursor: pointer;
  padding-left: 26px;
  margin-left: 105px;
  outline: none;
  border: 0;
  background: url(${buttonImg}) no-repeat;
  background-position: 0% 40%;
  color: #2e0cf9;
  margin: 0px auto;
  font-style: normal;
  font-weight: 400px;
  font-size: 13px;
  @media (max-width: 800px) {
    display: block;
  }
`;
export const DownloadDocument: any = styled.button`
  cursor: pointer;
  padding-left: 26px;
  margin-left: 105px;
  outline: none;
  border: 0;
  background: url(${download}) no-repeat;
  background-position: 0% 40%;
  color: #2e0cf9;
  margin: 0px auto;
  font-style: normal;
  font-weight: 400px;
  font-size: 13px;
  @media (max-width: 800px) {
    display: block;
  }
`;

export const PreATitle: any = styled.h2`
  padding-bottom: 50px;
  color: #2e0cf9;
  font-style: normal;
  font-weight: 400px;
  font-size: 34px;
`;

export const OkCkeckDocument: any = styled.button`
  padding-left: 26px;
  margin-left: 105px;
  outline: none;
  background-size: 200px 200px;
  background: url(${check}) no-repeat;
  background-position: 0% 40%;
  margin: 10px auto;
`;
