import React from 'react';
import type { FC } from 'react';
import { Button, Col } from 'reactstrap';

interface IFilter {
  active: string;
  onFilter: (type: string) => void;
}

const Filters: FC<IFilter> = ({ active, onFilter }) => {
  return (
    <Col className="filtered-table__filters">
      <Button
        color="primary"
        outline
        active={active === 'ALL'}
        onClick={() => onFilter('ALL')}>
        ALL
      </Button>
      <Button
        color="primary"
        outline
        active={active === 'S01'}
        onClick={() => onFilter('S01')}>
        YDES
      </Button>
      <Button
        color="primary"
        outline
        active={active === 'S02'}
        onClick={() => onFilter('S02')}>
        EKES
      </Button>
      <Button
        color="primary"
        outline
        active={active === 'S04'}
        onClick={() => onFilter('S04')}>
        ETO
      </Button>
      <Button
        color="primary"
        outline
        active={active === 'S03'}
        onClick={() => onFilter('S03')}>
        Fishing
      </Button>
    </Col>
  );
};

export default Filters;
