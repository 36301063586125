import ApiCaller from '../lib/ApiCaller';

class LegalApi {
  apiCaller: ApiCaller;
  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getCertificatesRaw(user: string): Promise<void | any | undefined> {
    return this.apiCaller.call(`/v1/documents/document/${user}`, 'GET');
  }

  getDraftRaw(user: string): Promise<void | any | undefined> {
    return this.apiCaller.call(`/v1/documents/draft/${user}`, 'GET');
  }
  removeDraft(id: string): Promise<void | any | undefined> {
    return this.apiCaller.call(`/v1/exam/removedraft/${id}`, 'DELETE');
  }

  getDocsRaw(country: string): Promise<void | any | undefined> {
    return this.apiCaller
      .call(`/v1/exam/getAllModels/${country}`, 'GET')
      .then((data) => {
        return {
          models: data.models.map((m: any) => {
            return m;
          }),
        };
      });
  }

  search(term: string, country?: string): Promise<void | any | undefined> {
    return this.apiCaller
      .call(`/v1/exam/search/${term}/${country}`, 'GET')
      .then((data) => {
        return {
          models: data.models.map((m: any) => {
            return m;
          }),
        };
      });
  }

  getModelBySlug(slug: string): Promise<any> {
    return this.apiCaller
      .call(`/v1/exam/getModelBySlug/${slug}`, 'GET')
      .then((data) => {
        data.examContent.vars = data.examContent.vars.map((v: string) => {
          return { key: v, value: '' };
        });
        return data;
      });
  }

  sendToWriteDoc(
    content: string,
    slug: string,
  ): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/sendToWriteDoc', 'POST', {
        base: content,
        slug: slug,
      })
      .then((file) => {
        return file;
      });
  }

  sendToWritePDF(
    content: string,
    slug: string,
  ): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/sendToWritePDF', 'POST', {
        base: content,
        slug: slug,
      })
      .then((file) => {
        return file;
      });
  }

  createPDFWithCertificate(
    content: string,
    slug: string,
    userId: string,
  ): Promise<void | any | undefined> {
    return this.apiCaller
      .call('/v1/exam/createPDFWithCertificate', 'POST', {
        base: content,
        slug: slug,
        userId: userId,
      })
      .then((file) => {
        return file;
      });
  }

  saveDraft(
    content: string,
    slug: string,
    userId: string,
    customName: string,
    customDescription: string,
    vars: any[],
  ): Promise<void | any | undefined> {
    const params = {
      base: content,
      slug: slug,
      userId: userId,
      customName: customName,
      customDescription: customDescription,
      vars,
    };
    return this.apiCaller
      .call('/v1/exam/createDraft', 'POST', params)
      .then((file) => {
        return file;
      });
  }

  createDraft(
    slug: string,
    userId: string,
    customName: string,
    customDescription: string,
  ): Promise<void | any | undefined> {
    const params = {
      base: '',
      slug: slug,
      userId: userId,
      customName: customName,
      customDescription: customDescription,
      vars: [],
    };
    return this.apiCaller
      .call('/v1/exam/createDraft', 'POST', params)
      .then((file) => {
        return file;
      });
  }

  updateDraft(
    content: string,
    slug: string,
    userId: string,
    customName: string,
    customDescription: string,
    date: Date,
    vars: any[],
    draftId: string,
  ): Promise<void | any | undefined> {
    const params = {
      base: content,
      slug: slug,
      userId: userId,
      customName: customName,
      customDescription: customDescription,
      date: date,
      vars: vars,
    };
    return this.apiCaller
      .call('/v1/exam/createDraft/' + draftId, 'POST', params)
      .then((file) => {
        return file;
      });
  }

  downloadExamByExamId(id: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/download/exam/' + id, 'GET')
      .then((file) => {
        return file;
      });
  }

  downloadExamByDocumentId(id: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/download/' + id, 'GET')
      .then((file) => {
        return file;
      });
  }

  downloadAnswers(id: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/download/answers/' + id, 'GET')
      .then((file) => {
        return file;
      });
  }
}

export default LegalApi;
