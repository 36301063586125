import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'store/store';
import {ToastContainer, toast} from 'react-toastify';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';
import ApiCaller from '../../../../lib/ApiCaller';
import {generateErrors, is} from '../../../../shared/utils/validations';
import NotificationApi from '../../../../api/NotificationApi';
import {NotificationMessageTypes} from '../../../../lib/common/models/notificationMessage';

import MultiEmailInput from '../../../../components/MultiEmailInput/MultiEmailInput';
import SelectInput, {
  SelectValueType,
} from '../../../../components/SelectInput/SelectInput';

export type MessageSendFormType = {
  message: string;
  messageType: string;
  email: string[];
};

const MessageSend: FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const [messageForm, setMessageForm] = React.useState<MessageSendFormType>({
    message: '',
    messageType: '',
    email: [],
  });

  const handleMessageChange = (e: any) => {
    setMessageForm({
      ...messageForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectionChange = (selectValue: SelectValueType) => {
    setMessageForm({
      ...messageForm,
      messageType: selectValue.value,
    });
  };

  const handleEmailChange = (emailList: string[]) => {
    setMessageForm({
      ...messageForm,
      email: emailList,
    });
  };

  const sendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    const {message, messageType, email} = messageForm;

    const errors: any = generateErrors(
      {message, messageType, email},
      {
        message: [is.required()],
        messageType: [is.required()],
        email: [is.minLength(1)],
      },
    );

    if (errors.message) toast.error('Message is required');
    if (errors.messageType) toast.error('Message type is required');
    if (errors.email) toast.error('At least 1 email is required');

    if (Object.keys(errors).length !== 0) return;

    const notificationApi = new NotificationApi(new ApiCaller());
    notificationApi
      .sendMessage(
        messageType,
        loggedUser.email,
        email,
        message,
        loggedUser.token,
      )
      .then((success: boolean) => {
        if (success) {
          setMessageForm({message: '', messageType: '', email: []});
          toast.success('Message was sent successfully');
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast.error('Error sending message, please try again');
      });
  };

  return (
    <>
      <div className="content">
        <Card>
          <Row>
            <Col md="12">
              <CardHeader>
                <CardTitle tag="h4">Send Message</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal" onSubmit={sendMessage}>
                  <Row>
                    <Label sm="2">Message</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Input
                          type="textarea"
                          value={messageForm.message}
                          onChange={handleMessageChange}
                          name="message"
                          placeholder="Enter message"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Message type</Label>
                    <Col sm="10">
                      <FormGroup>
                        <SelectInput
                          name="messageType"
                          value={{
                            label:
                              NotificationMessageTypes[messageForm.messageType],
                            value: messageForm.messageType,
                          }}
                          onChange={handleSelectionChange}
                          options={Object.keys(NotificationMessageTypes).map(
                            function (type) {
                              return {
                                value: type,
                                label: NotificationMessageTypes[type],
                              };
                            },
                          )}
                          placeholder="Message type"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Recipient</Label>
                    <Col sm="10">
                      <FormGroup>
                        <MultiEmailInput
                          placeholder="Enter email"
                          emailList={messageForm.email}
                          onChange={handleEmailChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2" />
                    <Col sm="10">
                      <Button className="btn-round" color="info" type="submit">
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Col>
          </Row>
        </Card>
        <ToastContainer />
      </div>
    </>
  );
};

export default MessageSend;
