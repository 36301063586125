import Topic from './topic';
import ExamResource, { ExamResourceInfo } from './examResource';

export enum QuestionStatusLabels {
    IN_MODIFICATION = "IN_MODIFICATION",
    AWAITING_MODERATION = "AWAITING_MODERATION",
    RETURNED_FOR_IMPROVEMENT = "RETURNED_FOR_IMPROVEMENT",
    ACCEPTED = "ACCEPTED",
    APPROVED = "APPROVED",
    ACTIVE = "ACTIVE",
    REJECTED = "REJECTED",
    INACTIVE = "INACTIVE"
}

export const QuestionStatus = {
    [QuestionStatusLabels.IN_MODIFICATION]: "Editing",
    [QuestionStatusLabels.AWAITING_MODERATION]: "Awaiting moderation",
    [QuestionStatusLabels.ACTIVE]: "Active",
    [QuestionStatusLabels.REJECTED]: "Rejected by QM",
    [QuestionStatusLabels.RETURNED_FOR_IMPROVEMENT]: "Returned for improvements",
    [QuestionStatusLabels.ACCEPTED]: "Awaiting MCA",
    [QuestionStatusLabels.APPROVED]: "Approved",
    [QuestionStatusLabels.INACTIVE]: "Archived",
}

export enum QuestionActions {
    ACCEPT = "ACCEPT",
    REVOKE = "REVOKE"
}

export interface QuestionInfo {
    name: string;
    description: string;
    displayName: string;
    associatedAnswer: string;
    id?: string;
    syllabus: any;
    module: any;
    topic: any;
    date: Date;
    status: string;
    createdBy?: any;
    createdOn?: string;
    updatedBy?: string;
    updatedOn?: string;
    images?: any[];
    resources?: ExamResource[];
}

interface QuestionCategoryDTO {
    _id?:string;
    code?: string;
    title?: string;
}

class Question {
    name: string;
    description: string;
    displayName: string;
    associatedAnswer: string;
    id?: string;
    syllabus?: any;
    module: any;
    topic: any;
    date: Date
    status: string;
    createdBy?: any;
    createdOn?: string;
    updatedBy?: string;
    updatedOn?: string;
    images?: any[];
    resources?: ExamResource[];

    constructor(questionInfo: QuestionInfo) {
        this.id = questionInfo.id;
        this.name = questionInfo.name;
        this.description = questionInfo.description;
        this.displayName = questionInfo.displayName;
        this.associatedAnswer = questionInfo.associatedAnswer;
        this.syllabus = questionInfo.syllabus;
        this.module = questionInfo.module;
        this.topic = questionInfo.topic;
        this.date = questionInfo.date;
        this.status = questionInfo.status;
        this.createdBy = questionInfo.createdBy;
        this.createdOn = questionInfo.createdOn;
        this.updatedBy = questionInfo.updatedBy;
        this.updatedOn = questionInfo.updatedOn;
        this.images = questionInfo.images;
        this.resources = questionInfo.resources;
    }

    toObject(): any {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            syllabus: this.syllabus,
            module: this.module,
            topic: this.topic,
            date: this.date,
            status: this.status,
            createdBy: this.createdBy,
            createdOn: this.createdOn,
            updatedBy: this.updatedBy,
            updatedOn: this.updatedOn,
            associatedAnswer: this.associatedAnswer,
            resources: this.resources,
        };
    }

    load(data: any): Question {
        return new Question(data);
    }

    toDTO(data: any): QuestionCategoryDTO {
        return {_id: data._id, code: data.code, title: data.title};
    }

    emptyQuestion(): Question {
        return new Question({name: "", description: "", displayName: "", associatedAnswer: "", syllabus: null, module: null, topic: null, date: new Date(), status: "",});
    }

    toQuestionWithResources(data: any) {
        data.resources = data.resources? data.resources.map(
            (resource: ExamResourceInfo) => new ExamResource(resource),
          ) : [];
        return new Question(data);
    }

    fromDataModel(doc: any, emptyContent: boolean = false): Question {
        doc.syllabus = doc.syllabusId ? Question.prototype.toDTO(doc.syllabusId) : null;
        doc.module = doc.moduleId ? Question.prototype.toDTO(doc.moduleId) : null;
        doc.topic = doc.topicId ? new Topic(doc.topicId) : null;
        doc.images = doc.documents ? doc.documents.map((image: any) => {
            return {
                uid: image.slug,
                name: image.displayName,
                size: 100,
                type: image.mimeType,
                status: "done",
                url: `/api/v1/documents/images/${image.fileHash}` };
        }) : null;

        if (emptyContent) {
            doc.description = '';
            doc.associatedAnswer = '';
        }

        return new Question(doc);
    }

}

export default Question;
