import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
} from 'reactstrap';

import Settings from 'lib/settings';
import { AppState } from 'store/store';

import ApiCaller from 'lib/ApiCaller';
import SyllabusApi from 'api/SyllabusApi';
import Syllabus from 'lib/common/models/syllabus';

const SyllabusDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const history = useHistory();
  const [currentSyllabus, setCurrentSyllabus] = useState<Syllabus>();

  React.useEffect(() => {
    setCurrentSyllabus(Settings.getCurrentSyllabus());
  }, []);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const syllabusInfo = currentSyllabus?.toObject();

    if (syllabusInfo) {
      syllabusInfo[name] = value;
      setCurrentSyllabus(new Syllabus(syllabusInfo));
    }
  };

  const updateSyllabus = (evt: any) => {
    evt.preventDefault();
    if (currentSyllabus) {
      const syllabusApi = new SyllabusApi(new ApiCaller());
      syllabusApi
        .updateSyllabus(currentSyllabus, loggedUser.token)
        .then(() => {
          history.push('/navozyme/syllabus/list');
          toast.info('Updated successfully');
        })
        .catch((err) => {
          console.log(err);
          toast.error('Error updating call number, please try again');
        });
    }
  };

  return (
    <>
      <div className="content">
        {currentSyllabus && (
          <Card>
            <Row>
              <Col md="12">
                <CardHeader>
                  <CardHeader>
                    <h4 className="card-title">Update Syllabus</h4>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal" onSubmit={updateSyllabus}>
                    <Row>
                      <Label sm="2">Code</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentSyllabus.code}
                            onChange={handleChange}
                            type="text"
                            name="code"
                            placeholder="Code"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Title</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentSyllabus.title}
                            onChange={handleChange}
                            type="text"
                            name="title"
                            placeholder="Title"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2" />
                      <Col sm="10">
                        <Button
                          className="btn-round"
                          color="info"
                          type="submit">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Col>
            </Row>
          </Card>
        )}
      </div>
    </>
  );
};

export default SyllabusDetail;
