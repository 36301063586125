import Transaction from "./transaction";

class TransactionCollection {
    transactions: Array<Transaction>;

    constructor(transactions: Array<Transaction>) {
        this.transactions = transactions;
    }

    length() {
        return this.transactions.length;
    }
}

export default TransactionCollection;
