//------------  state definition
import Settings from 'lib/settings';
import Notifications from '../lib/notifications';

export interface NotificationState {
  options: Array<any>;
}
//------------ actions
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

interface UpdateNotificationAction {
  type: typeof UPDATE_NOTIFICATION;
  payload: NotificationState;
}
interface UpdateNotificationsAction {
  type: typeof UPDATE_NOTIFICATIONS;
  payload: NotificationState;
}

interface ResetNotificationsAction {
  type: typeof RESET_NOTIFICATIONS;
}

export type NotificationActionTypes =
  | UpdateNotificationAction
  | UpdateNotificationsAction
  | ResetNotificationsAction;

export function updateNotification(
  newNotification: any,
): NotificationActionTypes {
  return {
    type: UPDATE_NOTIFICATION,
    payload: newNotification,
  };
}
export function updateNotifications(
  notifications: any,
): NotificationActionTypes {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload: notifications,
  };
}
export function resetNotifications(): NotificationActionTypes {
  return {
    type: RESET_NOTIFICATIONS,
  };
}
//------------ reducer
const initialState: NotificationState = {
  options: Notifications.getCurrentNotifications(Settings.getCurrentUser()._id),
};

export function notificationReducer(
  state = initialState,
  action: NotificationActionTypes,
): NotificationState {
  switch (action.type) {
    case UPDATE_NOTIFICATION: {
      return {
        ...state,
        options: [action.payload, ...state.options, ],
      };
    }
    case UPDATE_NOTIFICATIONS: {
      return {
        ...state,
        options: action.payload.options,
      };
    }
    case RESET_NOTIFICATIONS: {
      return {
        ...state,
        options: [],
      };
    }
    default:
      return state;
  }
}
