import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';
import NavigationExamDetail from '../../views/pages/admin/navigation_exam/navigationExamDetail';
import TransactionList from '../../views/pages/admin/transactions/transactionList';
import TransactionCreate from '../../views/pages/admin/transactions/transactionCreate';
import PaymentList from '../../views/pages/admin/transactions/paymentList';
import NavigationExamList from '../../views/pages/admin/navigation_exam/navigationExamList';
import CancelledExamList from '../../views/pages/admin/cancelled_exam/cancelledExamList';
import ExamCenterList from '../../views/pages/admin/examCenter/examCenterList';
import ExamCenterFinanceDetail from '../../views/pages/admin/examCenter/examCenterFinanceDetail';
import TreasurerDashboard from '../../views/pages/questionusers/TreasurerDashboard';
import ClosedExamList from '../../views/pages/admin/closed_exam/closedExamList';
import ClosedExamDetail from '../../views/pages/admin/closed_exam/closedExamDetail';
import ClosedExamResults from '../../views/pages/admin/closed_exam/closedExamResults';

export const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    component: TreasurerDashboard,
  },
  {
    collapse: true,
    name: 'Exams',
    icon: 'nc-icon nc-book-bookmark',
    state: 'navigationCollapse',
    views: [
      {
        path: '/navigation_exam/list',
        name: 'Exam Requests',
        mini: 'EL',
        component: NavigationExamList,
      },
      {
        path: '/navigation_exam/cancelled/list',
        name: 'Cancelled exams',
        mini: 'EC',
        component: CancelledExamList,
      },
      {
        path: '/closed_exam/list',
        name: 'Closed Exams',
        mini: 'CE',
        component: ClosedExamList,
      },
    ],
  },
  {
    path: '/examCenter/list',
    name: 'Exam Centres',
    icon: 'nc-icon nc-shop',
    component: ExamCenterList,
  },
  {
    path: '/payments/history',
    name: 'Payments History',
    icon: 'nc-icon nc-money-coins',
    component: PaymentList,
  },
  {
    path: '/transactions/history',
    name: 'Transactions History',
    icon: 'nc-icon nc-money-coins',
    component: TransactionList,
  },
];

export const hiddenRoutes = [
  {
    path: '/navigation_exam/detail',
    name: 'Exam Request Detail',
    mini: 'ED',
    component: NavigationExamDetail,
  },
  {
    path: '/payments/create',
    name: 'Create Transaction',
    mini: 'TC',
    component: TransactionCreate,
  },
  {
    path: '/examCenter/transactions',
    name: 'Exam Center Detail',
    mini: 'ED',
    component: ExamCenterFinanceDetail,
  },
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
  {
    path: '/closed_exam/detail',
    name: 'Closed Exam Detail',
    mini: 'ED',
    component: ClosedExamDetail,
  },
  {
    path: '/closed_exam/results',
    name: 'Closed Exam Results',
    mini: 'ER',
    component: ClosedExamResults,
  },
];
