import User from './user';
import Question from "./question";

export enum QuestionPaymentTypes {
    WRITING = 'WRITING',
    MODERATION = 'MODERATION',
}

export enum QuestionPaymentStatus {
    DRAFT = 'DRAFT',
    CONFIRMED = 'CONFIRMED'
}

export const QuestionPaymentTypesLabels = {
    [QuestionPaymentTypes.WRITING]: 'Question writing',
    [QuestionPaymentTypes.MODERATION]: 'Question moderation',
}

export const QuestionPaymentStatusLabels = {
    [QuestionPaymentStatus.DRAFT]: 'Draft',
    [QuestionPaymentStatus.CONFIRMED]: 'Confirmed'
}

interface QuestionPaymentInfo {
    _id?: string,
    id?: string,
    syllabus?: string;
    module?: string;
    question: any;
    status: QuestionPaymentStatus;
    date: Date;
    type: QuestionPaymentTypes;
    payment?: Date;
    user: any;
}

class QuestionPayment {
    id?: string;
    syllabus?: string;
    module?: string;
    question: any;
    status: QuestionPaymentStatus;
    date: Date;
    type: QuestionPaymentTypes;
    payment?: Date;
    user: any;

    constructor(questionPaymentInfo: QuestionPaymentInfo) {
        this.id = questionPaymentInfo.id ? questionPaymentInfo.id : questionPaymentInfo._id;
        this.syllabus = questionPaymentInfo.syllabus;
        this.module = questionPaymentInfo.module;
        this.question = questionPaymentInfo.question;
        this.status = questionPaymentInfo.status;
        this.date = questionPaymentInfo.date;
        this.type = questionPaymentInfo.type;
        this.payment = questionPaymentInfo.payment;
        this.user = questionPaymentInfo.user;
    }

    fromDataModel(data: any): QuestionPayment {
        data.user = new User(data.user);
        data.question = new Question(data.question);
        return new QuestionPayment(data);
    }
}

export default QuestionPayment;
