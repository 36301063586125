import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';

export const ModalContent = styled.div`
  background: #0a2454;
  box-shadow: 6px 50px 52px rgba(0, 0, 0, 0.5);
  padding: 35px;
  border-radius: 0px;
  width: 800px;
  height: 480px;
  position: relative;
  outline: none;
  color: #fff;
  > span {
    position: absolute;
    top: 5px;
    right: 8px;
    cursor: pointer;
    color: white;
    font-size: 40px;
  }
  > hr {
    border-bottom:2px solid #white;
    padding-bottom:20px;
  }
  @media (max-width: 800px) {
    min-width: 0;
    width: 90%;
    height: 35%;
  }
`;

export const ModalContent400 = styled.div`
  background: white;
  box-shadow: 6px 50px 52px rgba(0, 0, 0, 0.5);
  padding: 35px;
  border-radius: 0px;
  width: 600px;
  height: 400px;
  position: relative;
  outline: none;
  color: #0a2454;
  > span {
    position: absolute;
    top: 5px;
    right: 8px;
    cursor: pointer;
    color: #0a2454;
    font-size: 40px;
  }
  > .button {
    position: absolute;
    top: 315px;
    right: 30px;
    cursor: pointer;
    color: #0a2454;
    font-size: 40px;
  }
  > p {
      > span {
        color: #0a2454;
        font-size: 24px;
      }
  }
  > h1 {
    position:absolute;
    top:7px;
    padding-bottom:40px;
  }
  > hr {
    border-bottom:5px solid #0a2454;
    padding-bottom:30px;
  }
  @media (max-width: 800px) {
    min-width: 0;
    width: 90%;
    height: 35%;
  }
`;

export const ModalContainer = styled(Modal)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ModalCloseImage = styled.img`
  margin: 20px;
  width: 38px;
  height: 38px;
  fill: red;
`;

export const ModalTitle = styled.div`
  margin: 20px;
`;

export const ModalSubTitle = styled.div`
  margin: 20px;
`;
