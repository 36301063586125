import React, { useState, useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import { toast, ToastContainer } from 'react-toastify';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { DropdownButton, Dropdown } from 'react-bootstrap';

import NavigationExam, {
  NavigationExamStatus,
} from '../../../../lib/common/models/navigationExam';
import Settings from '../../../../lib/settings';
import NavigationExamApi from '../../../../api/NavigationExamApi';
import ApiCaller from '../../../../lib/ApiCaller';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import NavigationExamCollection from '../../../../lib/common/models/navigationExamCollection';

const NavigationExamClone: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [navigationExam, setNavigationExam] = React.useState<NavigationExam>();
  const [exams, setExams] = useState<NavigationExamCollection>(
    new NavigationExamCollection([]),
  );
  const history = useHistory();

  useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();
    setNavigationExam(exam);
    const navigationExamApi = new NavigationExamApi(
      new ApiCaller(loggedUser.token),
    );

    if (exam.id) {
      navigationExamApi
        .getCloneableExams(exam.id)
        .then((exams: NavigationExamCollection) => {
          setExams(exams);
        })
        .catch(() => {
          toast.error('Error fetching exam papers.');
        });
    }
  }, []);

  const selectExam = (cell: any) => {
    const selectedExam: NavigationExam = cell.row.original;
    const navigationExamApi = new NavigationExamApi(new ApiCaller());
    navigationExamApi
      .cloneExam(navigationExam?.id!, selectedExam.examId!, loggedUser.token)
      .then(() => {
        toast.success('Exam successfully cloned');
        history.goBack();
      })
      .catch(() => {
        toast.error('Error cloning exam');
      });
  };

  const columns = [
    {
      Header: 'NUMBER',
      accessor: (d: any) => d.getExamNumber(),
      sortable: true,
    },
    {
      Header: 'SERIAL NO.',
      accessor: (d: any) => d.documentSerialNumber ? d.documentSerialNumber : '---',
      sortable: true
    },
    {
      Header: 'EXAM CENTRE',
      accessor: (d: any) =>
        d.examCenterId ? `(${d.examCenterId.code}) ${d.examCenterId.name}` : '',
      sortable: true,
    },
    {
      Header: 'SYLLABUS',
      accessor: (d: any) =>
        d.syllabus ? `${d.syllabus.code} - ${d.syllabus.title}` : '',
      sortable: true,
    },
    {
      Header: 'MODULE',
      accessor: (d: any) =>
        d.module ? `${d.module.code} - ${d.module.title}` : '',
      sortable: true,
    },
    {
      Header: 'DATE',
      accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
    },
    {
      Header: 'STATUS',
      accessor: (d: any) => (d.status ? NavigationExamStatus[d.status] : ''),
      sortable: true,
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item onClick={() => selectExam(row)}>
            <i className="nc-icon nc-settings" />
            &nbsp;&nbsp;SELECT
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <h4 className="card-title">Exam Details</h4>
          </CardHeader>
          <CardBody>
            <Form className="form-horizontal">
              <Row>
                <Label sm="2">Exam Centre</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      readOnly
                      value={navigationExam?.examCenterId?.name}
                      type="text"
                      name="Exam Centre"
                      placeholder="Exam Centre"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Syllabus</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      readOnly
                      value={`${navigationExam?.syllabus?.code} - ${navigationExam?.syllabus?.title}`}
                      type="text"
                      name="Syllabus"
                      placeholder="Syllabus"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Module</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      readOnly
                      value={`${navigationExam?.module?.code} - ${navigationExam?.module?.title}`}
                      type="text"
                      name="Module"
                      placeholder="Module"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Date & Time</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      readOnly
                      value={formatDateTime(navigationExam?.date)}
                      type="text"
                      name="Date"
                      placeholder="Date"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h4 className="card-title">Select Exam to Clone</h4>
          </CardHeader>
          <CardBody>
            <ReactTable data={exams?.navigationExams} columns={columns} />
          </CardBody>
        </Card>
      </div>
      <ToastContainer />
    </>
  );
};

export default NavigationExamClone;
