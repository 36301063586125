import React from 'react';
import { useDispatch } from 'react-redux';

import { DropdownButton, Dropdown } from 'react-bootstrap';

import {
  sortDateTable,
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
} from '../../../../shared/utils/dateTime';
import Settings from '../../../../lib/settings';
import ApiCaller from '../../../../lib/ApiCaller';
import {
  NavigationExamStatus,
} from '../../../../lib/common/models/navigationExam';
import MarkerApi from '../../../../api/MarkerApi';
import NavigationExamApi from '../../../../api/NavigationExamApi';
import { UserTypesLabels } from '../../../../lib/common/models/user';
import {
  downloadInvoiceReceipt,
} from '../../../../shared/utils/ApiCommands';

export interface ListTableView {
  history: any;
  setStatus: any;
  layout: string;
}

export interface ListTable {
  getColumns(): any;
  getName(): string;
}

class ClosedExamListTable implements ListTable {
  loggedUser: any;
  view: ListTableView;
  navigationExamApi: NavigationExamApi;
  markerApi: MarkerApi;
  dispatch: any;

  constructor(profile: any, view: ListTableView) {
    this.loggedUser = profile;
    this.view = view;
    this.navigationExamApi = new NavigationExamApi(
      new ApiCaller(profile.token),
    );
    this.markerApi = new MarkerApi(new ApiCaller(profile.token));
    this.dispatch = useDispatch();
  }

  viewExam(cell: any) {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.view.history.push(`/${this.view.layout}/closed_exam/detail`);
  }

  getName(): string {
    return 'Cancelled Exams';
  }

  getColumns(): any {
    return [
      ...this.getCustomUserColumns(),
      {
        Header: 'ACTIONS',
        Cell: (row: any) => (
          <DropdownButton variant="default" title="Actions" size="sm">
            <Dropdown.Item onClick={() => this.viewExam(row)}>
              <i className="nc-icon nc-settings" />
              &nbsp;&nbsp;VIEW
            </Dropdown.Item>
            {(row.row.original.isDeadlineReached() ||
              this.loggedUser.isAdmin()) && (
                <Dropdown.Item
                  onClick={() =>
                    downloadInvoiceReceipt(
                      row.row.original,
                      this.loggedUser.token,
                    )
                  }>
                  <i className="nc-icon nc-cloud-download-93" />
                  &nbsp;&nbsp;DOWNLOAD INVOICE
                </Dropdown.Item>
              )}
          </DropdownButton>
        ),
      },
    ];
  }

  getCustomUserColumns() {
    let columns = [];
    switch (this.loggedUser.type) {
      case UserTypesLabels.examCentre:
        columns = [
          { Header: 'EXAM NUMBER', accessor: (d: any) => d.getExamNumber(), sortable: true },
          {
            Header: 'EXAM CENTRE',
            accessor: (d: any) =>
              d.examCenterId ? `(${d.examCenterId.code}) ${d.examCenterId.name}` : '',
            sortable: true,
          },
          {
            Header: 'MODULE',
            accessor: (d: any) =>
              d.module ? `${d.module.code} - ${d.module.title}` : '',
            sortable: true,
          },
          {
            Header: 'DATE',
            accessor: (d: any) =>
              formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
            sortable: true,
            sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
          }
        ];
        break;
      default:
        columns = [
          { Header: 'EXAM NUMBER', accessor: (d: any) => d.getExamNumber(), sortable: true },
          {
            Header: 'EXAM CENTRE',
            accessor: (d: any) =>
              d.examCenterId ? `(${d.examCenterId.code}) ${d.examCenterId.name}` : '',
            sortable: true,
          },
          {
            Header: 'MODULE',
            accessor: (d: any) =>
              d.module ? `${d.module.code} - ${d.module.title}` : '',
            sortable: true,
          },
          {
            Header: 'DATE',
            accessor: (d: any) =>
              formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
            sortable: true,
            sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
          },
          {
            Header: 'STATUS',
            accessor: (d: any) =>
              d.status ? NavigationExamStatus[d.status] : '',
            sortable: true,
          }
        ];
        break;
    }
    return columns;
  }
}

export default ClosedExamListTable;
