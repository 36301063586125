import React from 'react';

import { Row, Col } from 'reactstrap';

import QuestionsPerModuleAndTopicReport from 'views/components/dashboard/questions/QuestionsPerModuleAndTopicReport';

import 'react-toastify/dist/ReactToastify.css';
import { useSyllabus } from 'hooks';

interface ContainerProps {}

const QuestionModeratorDashboard: React.FC<ContainerProps> = ({}) => {
  const { allowedSyllabuses } = useSyllabus();

  return (
    <div className="content">
      <Row>
        <Col>
          <QuestionsPerModuleAndTopicReport {...{ allowedSyllabuses }} />
        </Col>
      </Row>
    </div>
  );
};

export default QuestionModeratorDashboard;
