import * as XLSX from 'xlsx';
import { Column } from 'react-table';

export const onTableExport = (
  tableName: string,
  columns: Column<object>[],
  tableData: any[],
) => {
  const headers: any[] = [];
  columns.map((column) => headers.push(column.Header));
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(tableData, { skipHeader: false });

  autofitColumns(tableData, ws, headers);

  XLSX.utils.sheet_add_aoa(ws, [headers]);
  XLSX.utils.book_append_sheet(wb, ws, 'Data');
  XLSX.writeFile(wb, `${tableName}.xlsx`);
};

const autofitColumns = (
  data: any[],
  worksheet: XLSX.WorkSheet,
  headers: string[],
) => {
  if (!data.length) {
    worksheet['!cols'] = headers.map((header) => ({ wch: header.length + 1 }));
    return;
  }

  const headersObj = {};
  Object.keys(data[0]).forEach((key, i) => {
    headersObj[key] = headers[i];
  });
  const tableDataWithHeaders = [...data, headersObj];
  let objectMaxLength: number[] = [];
  for (let i = 0; i < tableDataWithHeaders.length; i++) {
    let value = <any>Object.values(tableDataWithHeaders[i]);
    for (let j = 0; j < value.length; j++) {
      if (typeof value[j] == 'number') {
        objectMaxLength[j] = 10;
      } else {
        objectMaxLength[j] =
          objectMaxLength[j] >= value[j].length
            ? objectMaxLength[j]
            : value[j].length;
      }
    }
  }

  worksheet['!cols'] = objectMaxLength.map((width) => ({ wch: width }));
};
