import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
} from 'reactstrap';
import Select from 'react-select';

import Settings from 'lib/settings';
import ApiCaller from 'lib/ApiCaller';
import ReactTable from 'components/ReactTable/ReactTable';
import { AppState } from 'store/store';
import QuestionApi from 'api/QuestionApi';
import TopicCollection from 'lib/common/models/topicCollection';
import TopicApi from 'api/TopicApi';

import 'react-toastify/dist/ReactToastify.css';
import { useSyllabus } from 'hooks';

const TopicList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const { syllabus } = useSyllabus();
  const [moduleId, setModuleId] = React.useState('');
  const [modules, updateModules] = useState<any[]>([]);
  const [topics, updateTopics] = React.useState<TopicCollection>(
    new TopicCollection([]),
  );
  const history = useHistory();
  const onSyllabusChange = (selectedSyllabus: any) => {
    const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
    questionApi.getModulesFromSyllabus(selectedSyllabus._id).then((m: any) => {
      updateModules(m.modules);
    });
  };

  const onModuleChange = (selectedModule: any) => {
    setModuleId(selectedModule._id);
  };

  React.useEffect(() => {
    if (moduleId !== '') {
      const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
      questionApi.getTopicsFromModule(moduleId).then((topic: any) => {
        if (topic && Object.keys(topic).length > 0) {
          updateTopics(topic);
        }
      });
    }
  }, [moduleId]);

  const chooseTopic: any = (cell: any) => {
    const topic = cell.row.original;
    Settings.setCurrentTopic(topic);
    history.push(`/${layout}/topic/detail`);
  };

  const deleteTopic: any = (cell: any) => {
    const topic = cell.row.original;
    const topicApi = new TopicApi(new ApiCaller());
    topicApi
      .deleteTopic(topic._id || '', loggedUser.token)
      .then(() => {
        toast.success('Topic deleted...');
        const updatedCollection = new TopicCollection(
          topics.topics.filter((item: any) => item !== topic),
        );
        updateTopics(updatedCollection);
      })
      .catch((err) => {
        console.error(err);
        toast.error('Error deleting topic..');
      });
  };

  const columns = [
    { Header: 'CODE', accessor: 'code', sortable: true },
    { Header: 'TITLE', accessor: 'title', sortable: true },
    { Header: 'MARKS', accessor: 'marks', sortable: true },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item onClick={() => chooseTopic(row)}>
            <i className="nc-icon nc-settings" />
            &nbsp;&nbsp;VIEW
          </Dropdown.Item>
          <Dropdown.Item onClick={() => deleteTopic(row)}>
            <i className="nc-icon nc-simple-delete" />
            &nbsp;&nbsp;DELETE
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Topic List</CardTitle>
              </CardHeader>
              <Col sm="10">
                <Row>
                  <Col>
                    <Link to={`/${layout}/topic/create`} className="btn-label">
                      <Button color="info">
                        <span>
                          <i className="nc-icon nc-simple-add" />
                        </span>
                        Create
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col sm="10">
                <FormGroup>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="syllabus"
                    //value={syllabus.syllabuses}
                    onChange={onSyllabusChange}
                    options={syllabus}
                    getOptionLabel={(item) => item.code + ' ' + item.title}
                    getOptionValue={(item) => item.code}
                    placeholder="Choose a syllabus"
                  />
                </FormGroup>
              </Col>
              <Col sm="10">
                <FormGroup>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="module"
                    //value={modules}
                    onChange={onModuleChange}
                    options={modules}
                    getOptionLabel={(item) => item.code + ' ' + item.title}
                    getOptionValue={(item) => item.code}
                    placeholder="Choose a module"
                  />
                </FormGroup>
              </Col>
              <CardBody>
                <ReactTable data={topics.topics} {...{ columns }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default TopicList;
