import React from 'react';
import { Row, Col } from 'reactstrap';

import 'react-toastify/dist/ReactToastify.css';
import ExamCentresMarksReport from 'views/components/dashboard/marks/ExamCentresMarksReport';
import ExamAnalytics from '../admin/exam_analytics/ExamAnalytics';
import { useSyllabus } from 'hooks';
import InvoiceReport from '../../components/dashboard/finance/InvoiceReport';

interface ContainerProps {}

const ExamCentreDashboard: React.FC<ContainerProps> = ({}) => {
  const { allowedSyllabuses } = useSyllabus();

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <ExamCentresMarksReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ExamAnalytics />
          </Col>
        </Row>
        <Row>
          <Col>
            <InvoiceReport />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ExamCentreDashboard;
