import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearListState } from 'store/actions/lists/listsActions';

const useClearListStateOnPageChange = (listName: string, paths: string[]) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (paths.every(path => !location.pathname.endsWith(path))) {
        dispatch(clearListState(listName));
      }
    });

    return () => {
      unlisten();
    };
  }, [history, dispatch, listName, paths]);
};

export default useClearListStateOnPageChange;
