import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';

import { Card, CardHeader, CardBody, Col, Row, Button } from 'reactstrap';

import { AppState } from '../../../../store/store';
import ReactTable from '../../../../components/ReactTable/ReactTable';

import TransactionCollection from '../../../../lib/common/models/transactionCollection';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';

interface OperationHistoryListProps {
  refresh: boolean;
  listTitle: string;
  operations: TransactionCollection;
  columns: Array<any>;
  modalopen: boolean;
  setmodalopen: any;
  handleClick: any;
  idToRevert: string;
}

export enum ListTypes {
  Payment,
  Transaction
};

const OperationHistoryList: React.FC<OperationHistoryListProps> = ({
  listTitle,
  operations,
  columns,
  modalopen,
  setmodalopen,
  handleClick,
  idToRevert
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const location = useLocation();
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h4 className="card-title">{listTitle}</h4>
              </CardHeader>
              <CardBody>
                {loggedUser.isPaymentAdmin() && (
                  <Row>
                    <Col>
                      {location.pathname != "/treasurer/transactions/history" &&
                        <Link
                          to={`/${layout}/payments/create`}
                          className="btn-label">
                          <Button color="info">
                            <span>
                              <i className="nc-icon nc-simple-add" />
                            </span>
                            Create
                          </Button>
                        </Link>
                      }
                    </Col>
                  </Row>
                )}
                <ReactTable
                  data={operations.transactions}
                  columns={columns}
                  isLastColumnSortable
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
        {modalopen && (
          <ConfirmationModal
            title="Revert Transaction"
            onConfirm={() => handleClick(idToRevert)}
            onCancel={() => setmodalopen(!modalopen)}
            text="Are you sure you want to revert this transaction?"
          />
        )}
      </div>
    </>
  );
};

export default OperationHistoryList;
