import React from 'react';
import { Row, Col } from 'reactstrap';

import { useSelector } from 'react-redux';
import { AppState } from '../../../store/store';

import 'react-toastify/dist/ReactToastify.css';
import MarkerPaymentsSingleReport from 'views/components/dashboard/markerPayments/markerPaymentsSingleReport/MarkerPaymentsSingleReport';

interface ContainerProps {}

const MarkerDashboard: React.FC<ContainerProps> = ({}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <MarkerPaymentsSingleReport />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MarkerDashboard;
