import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import type { FC } from 'react';
import {
  Button,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

interface INote {
  onSubmit: (e: any) => void;
  note: string | undefined;
  handleChange: (e: any) => void;
}
const Note: FC<INote> = ({ onSubmit, note, handleChange }) => {
  return (
    <CardBody>
      <Form className="form-horizontal" {...{ onSubmit }}>
        <Row>
          <Label sm="2">Note</Label>
          <Col sm="10">
            <FormGroup>
              <TextArea
                value={note}
                onChange={handleChange}
                cols={2}
                rows={2}
                name="notes"
                placeholder="Add any note"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="2" />
          <Col sm="10">
            <Button className="btn-round" color="info" type="submit">
              Add note
            </Button>
          </Col>
        </Row>
      </Form>
    </CardBody>
  );
};

export default Note;
