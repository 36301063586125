import React from 'react';
import { CardBody, CardHeader, CardTitle, Row, Card, Col } from 'reactstrap';

import DashboardApi from '../../../../api/DashboardApi';
import ExamCenterDateFilteredTable from '../../filteredComponents/dateFiltered/dateFilteredTable/dateFilteredTable';

type PassRateReportProps = {
  allowedSyllabuses: string[];
};

const PassRateReport = ({ allowedSyllabuses }: PassRateReportProps) => {
  const columns = [
    { Header: 'CODE', accessor: 'code', sortable: true },
    { Header: 'TITLE', accessor: 'title', sortable: true },
    { Header: 'PASS', accessor: 'pass', sortable: true },
    { Header: 'FAIL', accessor: 'fail', sortable: true },
    { Header: 'TOTAL', accessor: 'total', sortable: true },
    { Header: 'P/R', accessor: (d: any) => `${d.passrate}%`, sortable: true },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Number of Candidates & Passrates</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <ExamCenterDateFilteredTable
                multipleTables
                {...{ columns, allowedSyllabuses }}
                fetchMethod={DashboardApi.getPassRateReport}
                tableName="Number of Candidates & Passrates"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default PassRateReport;
