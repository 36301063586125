import {updateNotification} from "../../../store/notificationStore";
import Notification from "../../../lib/common/models/notification";
import Notifications from "../../../lib/notifications";
import SocketConn from "../../../lib/SocketConn";
import NotificationApi from "../../../api/NotificationApi";
import ApiCaller from "../../../lib/ApiCaller";

export interface INotificationView {
    dispatch: Function;
    notify: Function;
}

export interface INotificationEvent {
    onMessage: Function;
    onUserIn: Function;
}

class NotificationPresenter implements INotificationEvent {

    loggedUser: any;
    socketConn: any;
    notifyApi:NotificationApi;

    constructor(profile: any, private view:INotificationView) {
        this.loggedUser = profile;
        this.socketConn = new SocketConn(profile, this);
        this.notifyApi = new NotificationApi(new ApiCaller(profile.token));
    }

    onMessage(message: any) {
        this.notifyApi.deleteMessage(message._id).then(() => {
            if (message && (message.message || message.message.msg)) this.notify(message.message);
        })
    };

    onUserIn(){

    };

    notify(msg: string) {
        this.view.notify(msg);
        let n = {id:new Date().getUTCDate(), code: 'sample', title: msg, createdAt: new Date().toString(), createdBy: this.loggedUser.name };
        let newNotification = Notification.prototype.load(n);
        this.view.dispatch(updateNotification(newNotification));
        Notifications.addNotification(newNotification, this.loggedUser._id);
    }


}

export default NotificationPresenter;
