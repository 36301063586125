import React, { useState, useEffect } from 'react';
import './style.css';
import PassTable from './PassTable';
import { Col, Button, Row } from 'reactstrap';
import DateFilteredComponent from '../dateFilteredComponent/dateFilteredComponent';
import { DateFilteredTableProps } from '../../types';
import ReactTable from 'components/ReactTable/ReactTable';
import * as XLSX from 'xlsx';
import { onTableExport } from '../../../../../shared/utils/excel';
import ExamCenterFilter from '../../filters/examCenterFilter';
import SyllabusFilter from 'views/components/dashboard/syllabusFilter/SyllabusFilter';

const DateFilteredTable: React.FC<
  DateFilteredTableProps & { allowedSyllabuses: string[] }
> = ({
  columns,
  fetchMethod,
  multipleTables,
  tableName = 'Data',
  allowedSyllabuses,
}) => {
  const [tableData, setTableData] = useState<any>([]);
  const [syllabus, setSyllabus] = useState<any>([]);
  const [YDES, setYDES] = useState<boolean>(false);
  const [EKES, setEKES] = useState<boolean>(false);
  const [ETO, setETO] = useState<boolean>(false);
  const [Fishing, setFishing] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<any>({});
  const [filterData, setFilterData] = useState<any>([]);

  const getCode = (item: string, code: string) => {
    const initial = item.split('-')[0];
    return initial === code;
  };

  useEffect(() => {
    let filterYDES = tableData.filter((item: any) => item.code.includes('S01'));
    let filterEKES = tableData.filter((item: any) => item.code.includes('S02'));
    let filterETO = tableData.filter((item: any) => item.code.includes('S04'));
    let filterFishing = tableData.filter((item: any) =>
      item.code.includes('S03'),
    );

    setEKES(filterEKES.length > 0);
    setYDES(filterYDES.length > 0);
    setETO(filterETO.length > 0);
    setFishing(filterFishing.length > 0);
    filterYDES.length > 0 ? setSyllabus('S01') : setSyllabus('S02');
    const newData = tableData.filter((item: any) =>
      getCode(item.code, filterYDES.length > 0 ? 'S01' : 'S02'),
    );
    setFilterData(newData);
  }, [tableData]);

  useEffect(() => {
    if (typeof syllabus === 'string' && tableData?.length > 0) {
      setFilterData(
        tableData.filter((item: any) => getCode(item.code, syllabus)),
      );
    } else {
      setFilterData([]);
    }
  }, [syllabus]);

  return (
    <>
      <div className="filtered-table__container">
        <Row>
          <Col className="filtered-table__filters">
            <Col>
              <SyllabusFilter
                disableButtons={{
                  S01: !YDES,
                  S02: !EKES,
                  S03: !Fishing,
                  S04: !ETO,
                }}
                syllabus={syllabus}
                setSyllabus={setSyllabus}
                allowedSyllabuses={allowedSyllabuses}
              />
            </Col>
            <Col>
              <ExamCenterFilter onSetFilter={setFilterParams} />
            </Col>
          </Col>
          <Col className="text-right">
            <Button
              onClick={() => onTableExport(tableName, columns, tableData)}
              color="success"
              className="btn-label">
              <span>
                <i className="nc-icon nc-cloud-download-93" />
              </span>
              Export to Excel
            </Button>
          </Col>
        </Row>

        {multipleTables && columns ? (
          <DateFilteredComponent
            component={[
              <PassTable data={filterData} {...{ syllabus }} />,
              <ReactTable
                data={filterData}
                {...{ columns }}
                topPaginationClassName="table-pagination_top"
              />,
            ]}
            fetchMethod={fetchMethod}
            params={filterParams}
            updateDataMethod={setTableData}
            componentName="table"
          />
        ) : (
          <DateFilteredComponent
            component={
              columns
                ? [
                    <ReactTable
                      data={tableData}
                      {...{ columns }}
                      topPaginationClassName="table-pagination_top"
                    />,
                  ]
                : [<PassTable data={tableData} syllabus={syllabus} />]
            }
            fetchMethod={fetchMethod}
            params={filterParams}
            updateDataMethod={setTableData}
            componentName="table"
          />
        )}
      </div>
    </>
  );
};

export default DateFilteredTable;
