import React, { useCallback, useRef } from 'react';
import type { FC } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
  Table,
} from 'reactstrap';
import ReactTable from 'components/ReactTable/ReactTable';
import { useExamMarks } from 'hooks';
import { IQuestion } from 'types/analytics';
import DateFilteredComponent from 'views/components/filteredComponents/dateFiltered/dateFilteredComponent/dateFilteredComponent';
import DashboardApi from 'api/DashboardApi';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import ExamCenterFilter from 'views/components/filteredComponents/filters/examCenterFilter';
import { onTableExport } from './excel';

export const QUESTION_MARK_COLUMNS = [
  'Question',
  'Question Number',
  'Topic',
  'Min. Mark',
  'Max. Mark',
  'Avg. Mark',
];

const ExamAnalytics: FC = () => {
  const {
    columns,
    exams,
    setExams,
    filterParams,
    setFilterParams,
    syllabus,
    onSelectSyllabus,
    activeSyllabus,
  } = useExamMarks();
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const renderRowSubComponent = useCallback(
    (row) => {
      return (
        <div className="table-full-width compact-expanded">
          <Table>
            <thead>
              <tr>
                {QUESTION_MARK_COLUMNS.map(
                  (columnName: string, index: number) => (
                    <th
                      key={columnName}
                      className={index > 2 ? 'right' : 'left'}>
                      {columnName}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {row.original.questions.map((data: IQuestion) => (
                <tr key={data.name}>
                  <td>{data.name}</td>
                  <td>{data.questionNumber}</td>
                  <td>{data.topic}</td>
                  <td align="right">{data.minMark}</td>
                  <td align="right">{data.maxMark}</td>
                  <td align="right">{data.avgMark}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    },
    [exams],
  );

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-calendar">
              <CardHeader>
                <Row>
                  <Col>
                    <CardTitle tag="h4">Exam Marks Statistics</CardTitle>
                  </Col>
                  <Col className="text-center text-md-right">
                    <Button
                      disabled={exams.length === 0}
                      onClick={() =>
                        onTableExport('avgMarkByQuestion', columns, exams)
                      }
                      color="success"
                      className="btn-label">
                      <span>
                        <i className="nc-icon nc-cloud-download-93" />
                      </span>
                      Export to Excel
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Col>
                  {syllabus.length > 0 &&
                    syllabus.map((sylla: any) => (
                      <Button
                        color="primary"
                        outline
                        active={activeSyllabus === sylla.code}
                        onClick={() => onSelectSyllabus(sylla)}>
                        {sylla.title}
                      </Button>
                    ))}
                  {!loggedUser.isExamCentre() && (
                    <ExamCenterFilter onSetFilter={setFilterParams} />
                  )}
                </Col>
                <Col>
                  <DateFilteredComponent
                    component={[
                      <ReactTable
                        data={exams}
                        {...{ columns, renderRowSubComponent }}
                        topPaginationClassName="table-pagination_top"
                        hasToggleRowColumn
                        hasTotal={false}
                        className="compact-table"
                      />,
                    ]}
                    fetchMethod={DashboardApi.getAvgMarkPerQuestionReport}
                    updateDataMethod={setExams}
                    params={filterParams}
                  />
                </Col>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ExamAnalytics;
