import React, { useState, useEffect, useCallback, FC } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import * as Sentry from "@sentry/react";
import ModalNote from './modalNotes';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  ModalHeader,
  ModalBody,
  Modal,
  Row,
  Label,
  Col,
  Form,
  FormGroup,
} from 'reactstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import { AppState } from 'store/store';
import ReactTable from 'components/ReactTable/ReactTable';
import Settings from 'lib/settings';

import ApiCaller from 'lib/ApiCaller';
import NavigationExamApi from 'api/NavigationExamApi';
import CandidateApi from 'api/CandidateApi';
import NavigationExam from 'lib//common/models/navigationExam';
import CandidateCollection from 'lib/common/models/candidateCollection';
import Candidate from 'lib/common/models/candidate';
import NavigationExamPayComponent from '../../../../components/payment/navigationExamPayComponent';
import { PaymentTypeLabels } from 'lib/common/models/transaction';
import CandidateBatchImport from '../../../../components/candidate/candidateBatchImport';
import CandidateModal from '../../candidate/candidateModal';
import CandidateEnrolment, {
  CandidateEnrolmentTypes,
} from 'lib/common/models/candidateEnrolment';

import 'react-toastify/dist/ReactToastify.css';
interface ContainerProps {
  examCandidates: any;
  setExamCandidates: any;
}

const CandidatesList: FC<ContainerProps> = ({
  examCandidates,
  setExamCandidates,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [currentExam, setCurrentExam] = useState<NavigationExam>();
  const [candidates, setCandidates] = useState<CandidateCollection>(
    new CandidateCollection([]),
  );
  const [availableCandidates, setAvailableCandidates] =
    useState<CandidateCollection>(new CandidateCollection([]));

  const [modal, setModal] = useState<boolean>(false);
  const [candidateForm, setCandidateForm] = useState(
    Candidate.prototype.emptyCandidate,
  );

  const [modalPayment, setModalPayment] = useState<boolean>(false);
  const [selectedCandidate, setSelectedCandidate] = React.useState<Candidate>();
  const [modalImport, setModalImport] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [note, setNote] = useState(null);

  const [candidateEnrolment, setCandidateEnrolment] = useState({
    type: '',
    courseCompletion: false,
  });

  const resetForm = () => {
    setCandidateForm(Candidate.prototype.emptyCandidate);
  };

  const resetEnrolment = () => {
    setCandidateEnrolment({
      type: '',
      courseCompletion: false,
    });
  };

  useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();
    setCurrentExam(exam);
    const candidateApi = new CandidateApi(new ApiCaller(loggedUser.token));
    candidateApi.getAllCandidates().then((data: any) => {
      setCandidates(data);
      setRefresh(false);
    });
  }, [refresh]);

  useEffect(() => {
    if (
      examCandidates.candidates.length > 0 &&
      candidates.candidates.length > 0
    ) {
      const deltaCandidates = candidates.candidates.filter(
        (item: any) =>
          examCandidates.candidates.map((i: any) => i._id).indexOf(item._id) <
          0,
      );
      setAvailableCandidates(new CandidateCollection(deltaCandidates));
    } else {
      setAvailableCandidates(candidates);
    }
  }, [examCandidates, candidates]);

  const openCandidate = (e: any) => {
    const candidate = e.row.original;
    setCandidateForm(candidate);
    setModal(true);
  };

  const add = (e: any) => {
    const candidate = e.row.original;
    setSelectedCandidate(candidate);
    setModalPayment(true);
  };

  const onPaymentSuccess = () => {
    setModalPayment(false);
    addCandidate();
  };

  const addCandidate = (candidate?: any) => {
    const selected = candidate ? candidate : selectedCandidate;

    if (selected && currentExam?.id) {
      const navigationExamApi = new NavigationExamApi(new ApiCaller());
      navigationExamApi
        .addCandidate(currentExam?.id, selected, loggedUser.token)
        .then(() => {
          const res = availableCandidates.candidates.filter(
            (item) => item._id !== selected._id,
          );
          setAvailableCandidates(new CandidateCollection(res));
          setExamCandidates(
            new CandidateCollection([...examCandidates.candidates, selected]),
          );
        })
        .catch((err: any) => {
          toast.error('Error adding candidate');
          Sentry.captureException(`Error adding candidate: ${JSON.stringify(err)}`);
        });

      const candidateApi = new CandidateApi(new ApiCaller());
      candidateApi
        .createCandidateEnrolment(
          new CandidateEnrolment({
            ...candidateEnrolment,
            navigationExamId: currentExam.id,
            candidateId: selected._id,
          }),
          loggedUser.token,
        )
        .then((enrolment) => {
          resetEnrolment();
          console.log('Enrolment created');
        })
        .catch((err: any) => {
          resetEnrolment();
          console.log(err);
        });
    }
  };

  const createCandidate = () => {
    resetForm();
    setModal(true);
  };

  const handleToggle = (e: any) => {
    setCandidateEnrolment({
      ...candidateEnrolment,
      courseCompletion: e.target.checked,
    });
  };

  const onSelectChange = (e: any) => {
    setCandidateEnrolment({
      ...candidateEnrolment,
      type: e.value,
    });
  };

  const columns = [
    {
      Header: 'SERIAL NO.',
      accessor: (d: any) => (d.serialNumber ? d.serialNumber : '-'),
      sortable: true,
    },
    { Header: 'NAME', accessor: (d: any) => d.fullName(), sortable: true },
    {
      Header: 'BIRTH DATE',
      accessor: (d: any) => moment(d.birthDate).utc().format('DD-MM-YYYY'),
    },
    { Header: 'PASSPORT NO.', accessor: 'passportNumber', sortable: true },
    { Header: 'EMAIL', accessor: 'email', sortable: true },
    {
      Header: 'LEARNING DIFF.',
      accessor: (d: any) => (d.special ? 'Yes' : 'No'),
    },
    {
      Header: 'NOTE',
      Cell: (row: any) => {
        if (row.row.original.notes) {
          return (
            <Button
              size="sm"
              className="btn-label"
              onClick={() => setNote(row.row.original.notes)}>
              Show
            </Button>
          );
        }
        return null;
      },
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          {loggedUser.isAdmin() && (
            <Dropdown.Item onClick={() => openCandidate(row)}>
              <i className="nc-icon nc-settings" />
              &nbsp;&nbsp;UPDATE
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => add(row)}
            disabled={
              currentExam?.isDeadlineReached() ||
              currentExam?.isCancelled() ||
              !loggedUser.hasCandidatePermissions()
            }>
            <i className="nc-icon nc-simple-add" />
            &nbsp;&nbsp;ADD
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <h4 className="card-title">Candidates List</h4>
          </CardHeader>
          <CardBody>
            <>
              <Button
                onClick={createCandidate}
                color="info"
                className="btn-label"
                disabled={!loggedUser.hasCandidatePermissions()}>
                <span>
                  <i className="nc-icon nc-simple-add" />
                </span>
                Register Candidate
              </Button>
              <Button
                onClick={() => setModalImport(true)}
                color="success"
                className="btn-label"
                disabled={!loggedUser.hasCandidatePermissions()}>
                <span>
                  <i className="nc-icon nc-cloud-upload-94" />
                </span>
                Upload Excel
              </Button>
            </>
            <ReactTable
              data={availableCandidates?.candidates}
              {...{ columns }}
            />
          </CardBody>
        </Card>

        <CandidateModal
          modal={modal}
          setModal={setModal}
          candidates={candidates}
          setCandidates={setCandidates}
          candidateForm={candidateForm}
          setCandidateForm={setCandidateForm}
        />

        <Modal isOpen={modalPayment}>
          <ModalHeader>Add Candidate</ModalHeader>
          <ModalBody>
            <Form className="form-horizontal">
              <Row>
                <Label sm="3">
                  <b />
                </Label>
                <Col sm="9">
                  <FormGroup>
                    <Label check>
                      <input
                        checked={candidateEnrolment?.courseCompletion}
                        type="checkbox"
                        name="courseCompletion"
                        onChange={handleToggle}
                      />{' '}
                      <span className="form-check-sign" />
                      Course Completion
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              {currentExam?.hasCandidateTypes() && (
                <Row>
                  <Label sm="3">
                    <b>Candidate Type</b>
                  </Label>
                  <Col sm="9">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="scriptIntention"
                        value={{
                          label:
                            CandidateEnrolmentTypes[candidateEnrolment?.type],
                          value: candidateEnrolment?.type,
                        }}
                        onChange={onSelectChange}
                        options={Object.keys(CandidateEnrolmentTypes).map(
                          function (type) {
                            return {
                              value: type,
                              label: CandidateEnrolmentTypes[type],
                            };
                          },
                        )}
                        placeholder="Script Intention"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Form>
            <NavigationExamPayComponent
              onCancel={() => setModalPayment(false)}
              onSuccess={onPaymentSuccess}
              paymentType={PaymentTypeLabels.PAYMENT}
              reference={selectedCandidate?.fullName()}
            />
          </ModalBody>
        </Modal>
        <Modal isOpen={modalImport}>
          <ModalHeader>Import Candidates Excel</ModalHeader>
          <ModalBody>
            <CandidateBatchImport
              setModalImport={setModalImport}
              collection={availableCandidates}
              setCollection={setAvailableCandidates}
              setRefresh={setRefresh}
            />
          </ModalBody>
        </Modal>
        <ModalNote
          show={Boolean(note)}
          message={note as any}
          onClose={() => setNote(null)}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default CandidatesList;
