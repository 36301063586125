import { SessionState } from 'store/reducers/sessionReducer';
import {UPDATE_AVATAR, UPDATE_JWT, UPDATE_SESSION} from '../actionTypes';

export type SessionActionTypes = UpdateSessionAction | UpdateAvatarAction | UpdateTokenAction;

export interface UpdateSessionAction {
  type: typeof UPDATE_SESSION;
  payload: SessionState;
}

export function updateSession(newSession: SessionState): SessionActionTypes {
  return {
    type: UPDATE_SESSION,
    payload: newSession,
  };
}

export interface UpdateAvatarAction {
  type: typeof UPDATE_AVATAR;
  payload: string;
}

export function updateAvatar(newAvatar: string): SessionActionTypes {
  return {
    type: UPDATE_AVATAR,
    payload: newAvatar,
  };
}

export interface UpdateTokenAction {
  type: typeof UPDATE_JWT;
  jwt: string;
  jwtExpiry: number;
}

export function updateJwt(jwt: string, jwtExpiry: number): SessionActionTypes {
  return {
    type: UPDATE_JWT,
    jwt: jwt,
    jwtExpiry: jwtExpiry
  }
}

