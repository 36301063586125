import Storage from './storage';
import User from './common/models/user';
import Question from './common/models/question';
import Syllabus from './common/models/syllabus';
import Module from './common/models/module';
import Topic from './common/models/topic';
import NavigationExam from './common/models/navigationExam';
import ExamCenter from './common/models/examCenter';
import Candidate from './common/models/candidate';
import ExamCertificate from './common/models/examCertificate';
import ExamResource from "./common/models/examResource";
import {BASE_URL, ENV, SOCKET_BASE_URL} from "./buildSettings";

let currentUser: User;
let currentAdminUser: User;
let currentQuestion: Question;
let currentAdminQuestion: Question;
let currentSyllabus: Syllabus;
let currentExamCenter: ExamCenter;
let currentModule: Module;
let currentTopic: Topic;
let currentNavigationExam: NavigationExam;
let currentCandidate: Candidate;
let currentExamCertificate: ExamCertificate;
let currentExamResource: ExamResource;

export interface SessionData {
  user: User;
  question: string;
}
const getCurrentSessionData = (): SessionData => {
  const data = Storage.read('user');
  const dataQuestion = Storage.read('question');
  currentUser = User.prototype.load(data);
  currentQuestion = Question.prototype.load(dataQuestion);
  return { user: currentUser, question: dataQuestion };
};

const getCurrentQuestion = (): string => {
  return Storage.read('question');
};

const getCurrentAdminQuestion = (): Question => {
  const data = Storage.read('adminQuestion');
  currentAdminQuestion = Question.prototype.load(data);
  return currentAdminQuestion;
};

const getCurrentSyllabus = (): Syllabus => {
  const data = Storage.read('syllabus');
  currentSyllabus = Syllabus.prototype.load(data);
  return currentSyllabus;
};

const getCurrentExamCenter = (): ExamCenter => {
  const data = Storage.read('examCenter');
  currentExamCenter = ExamCenter.prototype.load(data);
  return currentExamCenter;
};

const getCurrentModule = (): Module => {
  const data = Storage.read('module');
  currentModule = Module.prototype.load(data);
  return currentModule;
};

const getCurrentTopic = (): Topic => {
  const data = Storage.read('topic');
  currentTopic = Topic.prototype.load(data);
  return currentTopic;
};

const getCurrentNavigationExam = (): NavigationExam => {
  const data = Storage.read('navigationExam');
  currentNavigationExam = NavigationExam.prototype.load(data);
  return currentNavigationExam;
};

const getCurrentUser = (): User => {
  const data = Storage.read('user');
  currentUser = User.prototype.load(data);
  return currentUser;
};

const getCurrentUserType = (): string => {
  const data = Storage.read('user');
  return data.type;
};

const getCurrentAdminUser = (): User => {
  const data = Storage.read('adminUser');
  currentAdminUser = User.prototype.load(data);
  return currentAdminUser;
};

const getCurrentCandidate = (): Candidate => {
  const data = Storage.read('candidate');
  currentCandidate = Candidate.prototype.load(data);
  return currentCandidate;
};

const getCurrentExamCertificate = (): ExamCertificate => {
  const data = Storage.read('certificate');
  currentExamCertificate = ExamCertificate.prototype.load(data);
  return currentExamCertificate;
};

const getCurrentExamResource = (): ExamResource => {
  const data = Storage.read('examResource');
  currentExamResource = ExamResource.prototype.load(data);
  return currentExamResource;
};

const getEnv = (name: string, defaultValue: string): string => {
  if (!(window as any).envars) return defaultValue;
  return (window as any).envars[name]
    ? (window as any).envars[name]
    : defaultValue;
};

const Settings = {
  hasSeenTutorial: (): boolean => {
    return Storage.read('hasSeenTutorial');
  },

  markTutorialAsSeen: (): void => {
    Storage.write('hasSeenTutorial', true);
  },

  currentUser: (): User => currentUser,
  currentAdminUser: (): User => currentAdminUser,
  currentQuestion: (): Question => currentQuestion,
  currentAdminQuestion: (): Question => currentAdminQuestion,
  currentSyllabus: (): Syllabus => currentSyllabus,
  currentModule: (): Module => currentModule,
  currentExamCenter: (): ExamCenter => currentExamCenter,
  currentCandidate: (): Candidate => currentCandidate,
  currentExamCertificate: (): ExamCertificate => currentExamCertificate,
  currentExamResource: (): ExamResource => currentExamResource,

  getCurrentUser: getCurrentUser,
  getCurrentUserType: getCurrentUserType,
  getCurrentAdminUser: getCurrentAdminUser,
  getCurrentSessionData: getCurrentSessionData,
  getCurrentQuestion: getCurrentQuestion,
  getCurrentAdminQuestion: getCurrentAdminQuestion,
  getCurrentSyllabus: getCurrentSyllabus,
  getCurrentExamCenter: getCurrentExamCenter,
  getCurrentModule: getCurrentModule,
  getCurrentTopic: getCurrentTopic,
  getCurrentNavigationExam: getCurrentNavigationExam,
  getCurrentCandidate: getCurrentCandidate,
  getCurrentExamCertificate: getCurrentExamCertificate,
  getCurrentExamResource: getCurrentExamResource,

  isLoggedIn: (): boolean => {
    try {
      const currentUser: User = getCurrentUser();
      return currentUser ? true : false;
    } catch (err) {
      return false;
    }
  },

  setCurrentQuestion: (cn: Question) => {
    currentQuestion = cn;
    return Storage.write('question', cn);
  },

  setCurrentAdminQuestion: (cn: Question) => {
    currentAdminQuestion = cn;
    return Storage.write('adminQuestion', cn);
  },

  setCurrentSyllabus: (cn: Syllabus) => {
    currentSyllabus = cn;
    return Storage.write('syllabus', cn);
  },

  setCurrentExamCenter: (cn: ExamCenter) => {
    currentExamCenter = cn;
    return Storage.write('examCenter', cn);
  },

  setCurrentModule: (cn: Module) => {
    currentModule = cn;
    return Storage.write('module', cn);
  },

  setCurrentTopic: (cn: Topic) => {
    currentTopic = cn;
    return Storage.write('topic', cn);
  },

  setCurrentNavigationExam: (exam: NavigationExam) => {
    currentNavigationExam = exam;
    return Storage.write('navigationExam', exam);
  },

  setCurrentUser: async (user: User) => {
    currentUser = user;
    return Storage.write('user', user);
  },

  setCurrentAdminUser: (user: User) => {
    currentAdminUser = user;
    return Storage.write('adminUser', currentAdminUser);
  },

  setCurrentCandidate: (candidate: Candidate) => {
    currentCandidate = candidate;
    return Storage.write('candidate', candidate);
  },

  setCurrentCertificate: (certificate: ExamCertificate) => {
    currentExamCertificate = certificate;
    return Storage.write('certificate', certificate);
  },

  setCurrentExamResource: (resource: ExamResource) => {
    currentExamResource = resource;
    return Storage.write('examResource', resource);
  },

  logout: async () => {
    // await Storage.clear('question');
    await Storage.clear('user');
  },

  getApiURL: (): string => {
    return BASE_URL;
  },

  getSocketURL: (): string => {
    return SOCKET_BASE_URL;
  },

  getPublicURL: (): string => {
    return window.location.protocol + '//' + window.location.hostname + '/qr/';
  },

  getNumDaysToReturn: (): number => {
    return 15;
  },
  getResiduesPerUnit: (): number => {
    return 0.014;
  },

  getVersion: (): string => {
    return getEnv('VERSION', '1.0.0');
  },

  getStripeKey: (): string => {
    return getEnv(
      'PUBLIC_STRIPE_KEY',
      'pk_test_t1WHSW3dlbCyWaJe8vStOrxf00Gl3m9aWM',
    );
  },
};

export default Settings;
