import moment from 'moment';

const DEFAULT_DATETIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const DEFAULT_TIMEZONE_FORMAT = 'DD-MM-YYYY HH:mm (Z)'

export const formatDate = (date, format = 'MMMM D, YYYY') =>
    date ? moment(date).format(format) : date;

export const formatDateTime = (date, format = DEFAULT_DATETIME_FORMAT) =>
    date ? moment(date).format(format) : date;

export const formatDateTimeUTC = (date, format = DEFAULT_DATETIME_FORMAT) =>
    date ? moment(date).utc().format(format) : date;

export const formatDateTimeForAPI = date =>
    date
        ? moment(date)
            .utc()
            .format()
        : date;

export const formatDateTimeConversational = date => (date ? moment(date).fromNow() : date);

export const formatExcelDateTime = (excelDate, format = 'DD-MM-YYYY') => {
    return new Date(Date.UTC(0, 0, (excelDate - 1))).toString();
}

export const sortDateTable = (a, b, sortField = "date") => {
    const a1 = moment(a.original[sortField]).toDate().getTime();
    const b1 = moment(b.original[sortField]).toDate().getTime();

    if (a1 < b1) return 1;
    else if (a1 > b1) return -1;
    else return 0;
}

export const sortCurrencyTable = (a, b, sortField) => {
    const a1 = a.original[sortField];
    const b1 = b.original[sortField];

    if (a1 < b1) return 1;
    else if (a1 > b1) return -1;
    else return 0;
};
