import Topic from './topic';

class TopicCollection {
  topics: Array<Topic>;

  constructor(topics: Array<Topic>) {
    this.topics = topics;
  }

  length() {
    return this.topics.length;
  }
}

export default TopicCollection;
