import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';

import Settings from 'lib/settings';
import ApiCaller from 'lib/ApiCaller';
import ReactTable from 'components/ReactTable/ReactTable';
import { AppState } from 'store/store';
import SyllabusApi from 'api/SyllabusApi';
import { useSyllabus } from 'hooks';

const SyllabusList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const { syllabus, updateSyllabus } = useSyllabus();
  const history = useHistory();

  const chooseSyllabus: any = (cell: any) => {
    const syllabus = cell.row.original;
    Settings.setCurrentSyllabus(syllabus);
    history.push(`/${layout}/syllabus/detail`);
  };

  const deleteSyllabus: any = (cell: any) => {
    const syllabu = cell.row.original;

    const syllabusApi = new SyllabusApi(new ApiCaller());
    syllabusApi
      .deleteSyllabus(syllabu._id || '', loggedUser.token)
      .then(() => {
        toast.success('Syllabus deleted');
        updateSyllabus(syllabus.filter((item: any) => item !== syllabu));
      })
      .catch((err) => {
        toast.error('Error deleting syllabus');
      });
  };

  const columns = [
    { Header: 'CODE', accessor: 'code', sortable: true },
    { Header: 'TITLE', accessor: 'title', sortable: true },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => (
        <DropdownButton variant="default" title="Actions" size="sm">
          <Dropdown.Item onClick={() => chooseSyllabus(row)}>
            <i className="nc-icon nc-settings" />
            &nbsp;&nbsp;VIEW
          </Dropdown.Item>
          <Dropdown.Item onClick={() => deleteSyllabus(row)}>
            <i className="nc-icon nc-simple-delete" />
            &nbsp;&nbsp;DELETE
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Syllabus List</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Link
                      to={`/${layout}/syllabus/create`}
                      className="btn-label">
                      <Button color="info">
                        <span>
                          <i className="nc-icon nc-simple-add" />
                        </span>
                        Create
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <ReactTable data={syllabus} {...{ columns }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default SyllabusList;
