export enum ListLabels {
  user = 'questionList',
  questionBank = 'questionBank',
  archivedQuestions = 'archivedQuestions',
  questionPipeline = 'questionPipeline',
};

export const ListTypes = {
  [ListLabels.user]: 'Question List',
  [ListLabels.questionBank]: 'Question Bank',
  [ListLabels.archivedQuestions]: 'Archived Questions',
  [ListLabels.questionPipeline]: 'Question Pipeline',
};
