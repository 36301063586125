import Candidate from "./candidate";
import NZDocument from "./nzDocument";

class CandidateWithNZDocument {
    candidate: Candidate;
    nzDocument: NZDocument;
    sylls: string[];

    constructor(candidate: Candidate, nzDocument: NZDocument, sylls?: string[]) {
        this.candidate = candidate;
        this.nzDocument = nzDocument;
        this.sylls = sylls || [];
    }

    toObject(): any {

        return {
            candidate: this.candidate.toObject(),
            nzDocument: this.nzDocument.toObject(),
            sylls: this.sylls
        }
    }

}

export default CandidateWithNZDocument;
