import User from "./user";
import NavigationExam from "./navigationExam";
import ExamCenter from "./examCenter";

export const defaultCurrency = "GBP";

export enum TransactionStatusLabels {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
    CANCEL = "CANCELLED"
}

export const TransactionStatus = {
    [TransactionStatusLabels.PENDING]: "Pending",
    [TransactionStatusLabels.SUCCESS]: "Successful",
    [TransactionStatusLabels.FAILED]: "Failed",
    [TransactionStatusLabels.CANCEL]: "Cancelled",
}

export enum PaymentMethodLabels {
    CARD = "CARD",
    PAYPAL = "PAYPAL",
    CREDITS = "CREDITS",
    TRANSFER = "TRANSFER",
    OTHER = "OTHER"
}

export const PaymentMethod = {
    [PaymentMethodLabels.CARD]: "Card",
    [PaymentMethodLabels.PAYPAL]: "Paypal",
    [PaymentMethodLabels.CREDITS]: "Credits",
    [PaymentMethodLabels.TRANSFER]: "Bank Transfer",
    [PaymentMethodLabels.OTHER]: "Other",
}

export const ManualPaymentMethod = {
    [PaymentMethodLabels.PAYPAL]: "Paypal",
    [PaymentMethodLabels.TRANSFER]: "Bank Transfer",
    [PaymentMethodLabels.OTHER]: "Other"
}

export enum PaymentTypeLabels {
    PAYMENT = "PAYMENT",
    REFUND = "REFUND",
    LATE_FEE = "LATE_FEE",
    CANCELLATION = "CANCELLATION",
    CERTIFICATE_REISSUE_FEE = "CERTIFICATE_REISSUE_FEE",
}

export const PaymentType = {
    [PaymentTypeLabels.PAYMENT]: "Payment",
    [PaymentTypeLabels.REFUND]: "Refund",
    [PaymentTypeLabels.LATE_FEE]: "Late Request Fee",
    [PaymentTypeLabels.CANCELLATION]: "Cancellation",
    [PaymentTypeLabels.CERTIFICATE_REISSUE_FEE]: "Certificate Reissue Fee"
}

export interface IPayment {
    client_secret: string,
    transactionId: string
}

export interface IBalance {
    balance: number,
    timestamp: number
}

export interface IPaymentConfirm {
    reference: string,
    error: any
}

export interface IPaymentOrder {
    amount: number,
    concept: string,
    quantity: number
}

export interface ISimpleTransaction {
    timestamp: string,
    description: string,
    amount: string
}

interface TransactionInfo {
    _id?: string,
    timestamp?: any,
    userId?: any,
    amount: number,
    currency: string,
    description?: string,
    reference?: string,
    paymentMethod: string,
    status?: string,
    examId?: any,
    entityId?: any,
    certificateId?: any,
}

class Transaction {
    _id?: string;
    timestamp?: any;
    userId?: any;
    amount: number;
    currency: string;
    description?: string;
    reference?: string;
    paymentMethod: string;
    status?: string;
    examId?: any;
    entityId?: any;
    certificateId?: any;

    constructor(transactionInfo: TransactionInfo) {
        this._id = transactionInfo._id;
        this.timestamp = transactionInfo.timestamp;
        this.userId = transactionInfo.userId;
        this.amount = transactionInfo.amount;
        this.currency = transactionInfo.currency;
        this.description = transactionInfo.description;
        this.reference = transactionInfo.reference;
        this.paymentMethod = transactionInfo.paymentMethod;
        this.status = transactionInfo.status;
        this.examId = transactionInfo.examId;
        this.entityId = transactionInfo.entityId;
        this.certificateId = transactionInfo.certificateId;
    }

    loadData(data: any): Transaction {
        return new Transaction(data);
    }

    toObject(): any {
        return {
            _id: this._id,
            timestamp: this.timestamp,
            userId: this.userId,
            amount: this.amount,
            currency: this.currency,
            description: this.description,
            reference: this.reference,
            paymentMethod: this.paymentMethod,
            status: this.status,
            examId: this.examId,
            entityId: this.entityId,
            certificateId: this.certificateId,
        }
    }

    fromDataModel(data: any): Transaction {
        data.userId = data.userId ? new User(data.userId) : null;
        data.examId = data.examId ? new NavigationExam(data.examId) : null;
        data.entityId = data.entityId ? new ExamCenter(data.entityId) : null;
        return new Transaction(data);
    }

    emptyTransaction(userId?: any): Transaction {
        return new Transaction({
            userId: userId ? userId : null,
            amount: 0.00,
            currency: "GBP",
            description: "",
            reference: "",
            paymentMethod: PaymentMethodLabels.TRANSFER,
            status: TransactionStatusLabels.SUCCESS,
            examId: null,
            entityId: null,
            certificateId: null,
        })
    }
}

export default Transaction;
