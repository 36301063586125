import CandidateWithNZDocument from "./candidateWithNZDocument";

class CandidateWithNZDocumentCollection {

    candidates: Array<CandidateWithNZDocument>;

    constructor(candidates: Array<CandidateWithNZDocument>) {
        this.candidates = candidates;
    }

    length() {
        return this.candidates.length;
    }
}

export default CandidateWithNZDocumentCollection;
