import NZDocument from "./nzDocument";

export interface ExamResourceInfo {
  id?: string;
  _id?: string;
  name: string;
  description?: string;
  resource?: NZDocument;
  createdAt?: Date;
  updatedAt?: Date;
}

class ExamResource {
  id?: string;
  name: string;
  description?: string;
  resource?: NZDocument;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(resource: ExamResourceInfo) {
    this.id = resource.id ? resource.id : resource._id;
    this.name = resource.name;
    this.description = resource.description;
    this.resource = resource.resource;
    this.createdAt = resource.createdAt;
    this.updatedAt = resource.updatedAt;
  }

  load(data: any): ExamResource {
    return new ExamResource(data);
  }

  toObject(): ExamResourceInfo {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      resource: this.resource,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }

  fromDataModel(data: any): ExamResource {
    data.resource = data.resource ? new NZDocument(data.resource) : null;
    return new ExamResource(data);
  }
}

export default ExamResource;
