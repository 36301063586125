import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Card, CardHeader, CardBody, Col, Row, CardTitle } from 'reactstrap';

import ReactTable from '../../../../components/ReactTable/ReactTable';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import NavigationExamCollection from 'lib/common/models/navigationExamCollection';
import CompletedExamListPresenter from './closedExamListPresenter';
import { useHistory } from 'react-router-dom';
import { stateListNames } from 'store/reducers/listsReducer';
import { useClearListStateOnPageChange, useTableControls } from 'hooks';

const ClosedExamList: React.FC = () => {
  const listName = stateListNames.completedExams;

  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const [status, setStatus] = useState<boolean>();
  const [selectedExams, updateSelectedExams] =
    useState<NavigationExamCollection>(new NavigationExamCollection([]));

  const view = { history: useHistory(), layout: layout, setStatus: setStatus };
  const presenter = new CompletedExamListPresenter(loggedUser, view);

  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  useClearListStateOnPageChange(listName, [
    "/closed_exam/list",
    "/closed_exam/detail",
    "/closed_exam/results",
  ]);

  useEffect(() => {
    presenter.getAllClosedExams().then((exams) => {
      updateSelectedExams(exams);
    });
  }, [status]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{presenter.getTableName()}</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={selectedExams?.navigationExams}
                  columns={presenter.getTableColumns()}
                  initialFilters={filters}
                  onChangeFilters={onChangeFilters}
                  initialSorting={sorting}
                  onChangeSorting={onChangeSorting}
                  initialPagination={pagination}
                  onChangePagination={onChangePagination}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default ClosedExamList;
