import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Button } from 'reactstrap';

import { AppState } from '../../../../store/store';

import ApiCaller from '../../../../lib/ApiCaller';
import TransactionApi from '../../../../api/TransactionApi';
import TransactionCollection from '../../../../lib/common/models/transactionCollection';

import {
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import { formatCurrency } from '../../../../shared/utils/currency';
import { downloadCertificateInvoiceReceipt } from 'shared/utils/ApiCommands';
import { TransactionStatus } from '../../../../lib/common/models/transaction';
import { UserTypesLabels } from '../../../../lib/common/models/user';

import OperationHistoryList from './operationHistoryList';

import 'react-toastify/dist/ReactToastify.css';

interface TransactionListProps {
  centreId?: string;
  refresh: boolean;
}

type TableColumnsType =
  | {
      Header: string;
      accessor: (d: any) => any;
      sortable: boolean;
      sortType: (a: any, b: any) => 1 | -1 | 0;
    }
  | {
      Header: string;
      accessor: string;
      sortable: boolean;
      sortType?: undefined;
    }
  | {
      Header: string;
      accessor: (d: any) => any;
      sortable: boolean;
      sortType?: undefined;
    }
  | {
      Header: string;
      Cell: (row: any) => JSX.Element;
    };

const TransactionList: React.FC<TransactionListProps> = ({
  centreId,
  refresh,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const [transactions, setTransactions] = useState<TransactionCollection>(
    new TransactionCollection([]),
  );
  const [transactionsUpdate, setTransactionsUpdate] = useState<boolean>(false);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const [idToReverse, setId] = useState<string>('');

  const handle_click = (id: string) => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
    revertTransaction(id);
  };

  const revertTransaction = useCallback(
    (id: string) => {
      const transactionApi = new TransactionApi(new ApiCaller());
      const userId = loggedUser._id ? loggedUser._id : '';
      transactionApi
        .revertTransaction(id, userId, loggedUser.token)
        .then((res) => {
          if (res && res.error) {
            toast.error(res.error);
            return;
          }
          setTransactionsUpdate(!transactionsUpdate);
          toast.success('Transaction reverted successfully');
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
    [loggedUser._id, loggedUser.token, transactionsUpdate],
  );

  const getColumns = () => {
    const cols: any[] = [
      {
        Header: 'TIMESTAMP',
        accessor: (d: any) => formatDateTime(d.timestamp),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'timestamp'),
      },
      { Header: 'EXAM CENTRE', accessor: 'entityId.name', sortable: true },
      { Header: 'CREATED BY', accessor: 'userId.name', sortable: true },
      {
        Header: 'AMOUNT',
        accessor: (d: any) => formatCurrency(d.amount, d.currency),
        sortable: true,
      },
      {
        Header: 'REFERENCE',
        accessor: (d: any) => (d.reference ? d.reference : '-'),
        sortable: true,
      },
      {
        Header: 'DESCRIPTION',
        accessor: (d: any) => (d.description ? d.description : ''),
        sortable: true,
      },
      {
        Header: 'STATUS',
        accessor: (d: any) => (d.status ? TransactionStatus[d.status] : ''),
        sortable: true,
      },
      {
        Header: 'INVOICE',
        accessor: (d: any) => (
          d.certificateId && d.amount < 0 && 
          <Button
            className="btn-round"
            color="info"
            onClick={() => downloadCertificateInvoiceReceipt(d.certificateId, loggedUser.token)}
          >
            DOWNLOAD INVOICE
          </Button>
        ),
        sortable: true,
      },
    ];

    return cols;
  };

  useEffect(() => {
    const transactionApi = new TransactionApi(new ApiCaller(loggedUser.token));

    transactionApi.getTransactions(centreId).then((transactions: any) => {
      setTransactions(transactions);
    });
  }, [refresh, loggedUser, transactionsUpdate]);

  return (
    <OperationHistoryList
      refresh={refresh}
      listTitle="Transactions History"
      operations={transactions}
      columns={getColumns()}
      modalopen={isConfirmationModalOpen}
      setmodalopen={setIsConfirmationModalOpen}
      handleClick={handle_click}
      idToRevert={idToReverse}
    />
  );
};

export default TransactionList;
