import React from "react";
import { VectorMap } from "react-jvectormap";
import {
    Button, ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row
} from "reactstrap";

var mapData = {
    AU: 760,
    BR: 550,
    CA: 120,
    DE: 1300,
    FR: 540,
    GB: 690,
    GE: 200,
    IN: 200,
    RO: 600,
    RU: 300,
    US: 2920,
};

class WorldMap extends React.Component {
    render() {
        return (
            <VectorMap
                map={ "world_mill" }
                markers={
                    this.props.markers.map((row) => {
                        return { latLng: row.latLng, name: row.location }
                    })
                }
                markerStyle={{
                    initial: {
                        fill: '#e93535',
                        stroke: '#383f47',
                    }
                }}
                backgroundColor="transparent"
                zoomOnScroll={false}
                containerStyle={{
                    height: "300px",
                }}
                containerClassName="map"
                regionStyle={{
                    initial: {
                        fill: "#acacac",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0,
                    },
                }}
            />
        );
    }
}

export default WorldMap;