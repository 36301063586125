export interface IUserPreference {
    _id?: string;
    userId: any;
    emailNotifications: boolean;
}

class UserPreference implements IUserPreference {
    _id?: any;
    userId: any;
    emailNotifications: boolean;

    constructor(userPreference: IUserPreference) {
        this._id = userPreference._id ? userPreference._id : '';
        this.userId = userPreference.userId;
        this.emailNotifications = userPreference.emailNotifications;
    }

    load(data: IUserPreference): UserPreference {
        return new UserPreference(data);
    }

    toObject(): any {
        return {
            _id: this._id,
            userId: this.userId,
            emailNotifications: this.emailNotifications,
        };
    }
}

export class UserSecondaryProfiles {
    _id?: any;
    userId: any;
    secondaryProfiles: any;

    constructor(userPreference: any) {
        this._id = userPreference._id ? userPreference._id : '';
        this.userId = userPreference.userId;
        this.secondaryProfiles = userPreference.secondaryProfiles;
    }

    load(data: any): UserSecondaryProfiles {
        return new UserSecondaryProfiles(data);
    }

    toObject(): any {
        return {
            _id: this._id,
            userId: this.userId,
            secondaryProfiles: this.secondaryProfiles,
        };
    }

}

export default UserPreference
