import React from "react";

import { Input } from "reactstrap";
interface ICurrencyInput {
    input: string,
    setInput: Function,
    setDecimal: Function,
    currency: string
}
const CurrencyInput: React.FC<ICurrencyInput> = ({input, setInput, setDecimal, currency}) => {
    let start = 0;

    const onChange = (e: any) => {
        start = e.target.selectionStart;
        let val = e.target.value;
        val = val.replace(/([^0-9.]+)/, "");
        val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);

        if (match && match.length > 2) {
            const value = match[1] + match[2];
            e.target.value = value;
            setInput(value);

            if (val.length > 0) {
                e.target.value = Number(value).toFixed(2);
                e.target.setSelectionRange(start, start);
                setDecimal(Number(value).toFixed(2));
            }
        }
    };

    const blur = (e: any) => {
        const val = e.target.value;
        if (val.length > 0) {
            e.target.value = Number(val).toFixed(2);
            setInput(e.target.value);
        }
    }

    return (
        <>
            <Input
                type="text"
                onBlur={blur}
                onChange={onChange}
                value={input}
                placeholder={`Enter amount (${currency})`}
            />
        </>
    )
}

export default CurrencyInput;
