import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { AppState } from 'store/store';
import { ListLabels } from './listTypes';
import QuestionListPresenter from './questionListPresenter';
import QuestionCollection from '../../../../lib/common/models/questionCollection';
import { useHistory } from 'react-router';
import ReactTable from 'components/ReactTable/ReactTable';
import { getCustomUserColumns } from './questionListCustomColumns';

const QuestionPipeline = () => {
  const history = useHistory();
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [questions, setQuestions] = useState<QuestionCollection>(
    new QuestionCollection([]),
  );
  const view = {
    onPrepareAction: () => {},
    history: history,
    setStatus: () => {},
  };
  const presenter = new QuestionListPresenter(
    loggedUser,
    view,
    ListLabels.questionPipeline,
  );

  const columns = useMemo(() => {
    return [
      { Header: 'QUESTION ID', accessor: 'name', sortable: true },
      ...getCustomUserColumns(loggedUser),
    ];
  }, [loggedUser]);

  useEffect(() => {
    presenter.getPipelineQuestions().then((questions) => {
      setQuestions(questions);
    });
  }, []);

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <h4 className="card-title">Question Pipeline</h4>
        </CardHeader>
        <CardBody>
          <ReactTable data={questions.questions} columns={columns} />
        </CardBody>
      </Card>
    </div>
  );
};

export default QuestionPipeline;
