import React from 'react';

import DateFilteredComponent from './dateFiltered/dateFilteredComponent/dateFilteredComponent';
import { DateFilterComponentProps } from './types';

type FilteredComponentProps = DateFilterComponentProps & {
  examCenterFilter?: boolean;
};

const FilteredComponent: React.FC<FilteredComponentProps> = ({
  component,
  fetchMethod,
  updateDataMethod,
  componentName,
  examCenterFilter,
  params
}) => {

  return (
    <>
      <DateFilteredComponent
        {...{ component, fetchMethod, params, updateDataMethod, componentName }}
      />
    </>
  );
};

export default FilteredComponent;
