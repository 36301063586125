import React, {useState} from 'react';
import {Button, Input, Row} from "reactstrap";
import * as XLSX from 'xlsx';
import {areArraysEqualSets} from "../../../shared/utils/arrays";
import CandidateCollection from "../../../lib/common/models/candidateCollection";
import Candidate from "../../../lib/common/models/candidate";
import CandidateApi from "../../../api/CandidateApi";
import ApiCaller from "../../../lib/ApiCaller";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/store";
import {toast} from "react-toastify";
import {formatExcelDateTime} from "../../../shared/utils/dateTime";
import {generateErrors, is} from "shared/utils/validations";

interface IComponentProps {
    setModalImport: Function,
    collection: CandidateCollection,
    setCollection: Function,
    setRefresh: Function
}

const COLUMNS = {
    "First Name": "string",
    "Last Name": "string",
    "Birth Date": "number",
    "Passport Number": "string",
    "Email": "string",
    "Learning Difficulties": "boolean"
}

const CandidateBatchImport: React.FC<IComponentProps> = ({ setModalImport, collection, setCollection, setRefresh }) => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);

    const [file, setFile] = React.useState()
    const [fileData, setFileData] = React.useState([[]])
    const [valid, setValid] = React.useState<boolean>(false)
    const [errors, setErrors] = React.useState<string[]>([]);
    const [warnings, setWarnings] = React.useState<string[]>([]);
    const [candidates, setCandidates] = React.useState<CandidateCollection>(new CandidateCollection([]));

    const resetValues = (e: any) => {
        (e.target as HTMLInputElement).value = ""
        setValid(false);
        setFileData([[]]);
        setErrors([]);
        setWarnings([])
    }

    React.useEffect(() => {
        if (fileData[0].length !== 0) {
            const errors:string[] = checkErrors();
            console.log(errors);
            if (errors.length !== 0) setErrors(errors)
            else parseData();
        }
    }, [fileData])

    const onFileChange = (e: any) => {
        const size = e.target.files[0].size / 1024 / 1024;
        if (size > 5) setErrors([...errors, 'Maximum size allowed 5M.'])
        else setFile(e.target.files[0]);
    }

    const validateFile = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                // @ts-ignore
                const data = e.target.result
                let readData = XLSX.read(data, {type: 'binary'});
                const worksheetName = readData.SheetNames[0];
                const workSheet = readData.Sheets[worksheetName];
                const dataParse = XLSX.utils.sheet_to_json(workSheet, {header:1});
                // @ts-ignore
                setFileData(dataParse)
                console.log(dataParse);
            }
            // @ts-ignore
            reader.readAsBinaryString(file);
        }
    }

    const checkColumns = () => {
        const fileCols = fileData[0].map((col: string) => col.toLowerCase())
        const baseCols = Object.keys(COLUMNS).map((col: string) => col.toLowerCase());
        return areArraysEqualSets(fileCols, baseCols);
    }

    const checkDataType = (index: number, type: string) => {
        const rows = fileData.slice(1);
        const checks = rows.map((row) => {
            return typeof row[index] === type || typeof row[index] === "number"
        });

        return checks.every(Boolean);
    }

    const checkErrors = () => {
        let errors = []

        if (!checkColumns()) errors.push("Incorrect file columns.");
        if (fileData.length < 2) errors.push("No candidates in Excel.");
        if (fileData.length > 101) errors.push("Candidates import limit (100) exceeded");

        Object.keys(COLUMNS).forEach((column, index) => {
            if (!checkDataType(index, COLUMNS[column])) errors.push(`Incorrect formatting in column "${column}"`)
        });

        return errors
    }

    const parseData = () => {
        let dataErrors:any[] = []
        let dataWarnings:any[] = []
        const rows = fileData.slice(1);
        const data = rows.map((row, index) => {
            if (!row.every(v => v !== "")) dataErrors.push(`Row ${index}: Empty values found.`)

            const email = row[4];
            const err:any = generateErrors(
                { email },
                {
                    email: [is.required(), is.email()],
                });

            if (err.email) dataErrors.push(`Row ${index}: Invalid email`)

            const res = new Candidate({
                firstName: row[0],
                lastName: row[1],
                birthDate: new Date(formatExcelDateTime(row[2])),
                passportNumber: row[3],
                email: row[4],
                special: row[5]
            });

            const checks = collection.candidates.map((item) => Candidate.prototype.checkDuplicate(item, res))
            if (checks.indexOf(true) !== -1) dataWarnings.push(`Row ${index}: Duplicate candidate`)

            return res;
        });

        if (dataWarnings.length !== 0) setWarnings(dataWarnings);

        if (dataErrors.length !== 0) {
            setErrors(dataErrors);
        } else {
            setValid(true);
            setCandidates(new CandidateCollection([...candidates.candidates, ...data]))
        }
    }

    const confirmImport = () => {
        const candidateApi = new CandidateApi(new ApiCaller());
        candidateApi.createCandidateBulk(candidates, loggedUser.token).then((candidates) => {
            toast.success('Candidates imported');
            setCollection(new CandidateCollection([...collection.candidates, ...candidates.candidates]))
            setModalImport(false);
        }).catch((err) => {
            setErrors([err.message]);
        })
    }

    const closeModal = () => {
        if (errors) setRefresh(true);
        setModalImport(false);
    }

    return (
        <>
            <Row>
                <Input type="file" accept=".xlsx" onChange={onFileChange} onClick={resetValues}/>
            </Row>
            { errors &&
            <Row>
                {errors.map((error) => <p className="text-danger">{error}</p>)}
            </Row>
            }
            { warnings &&
                <>{warnings.map((warning) => <Row><p className="text-warning">{warning}</p></Row>)}</>
            }
            { valid &&
            <>
                <Row>
                    <p className="text-success">Found {fileData.length - 1} candidates to import.</p>
                </Row>
            </>
            }
            <Row>
                <Button color="danger"  onClick={closeModal}>Cancel</Button>
                { file && !valid &&
                    <Button color="info" onClick={validateFile}>Validate</Button>
                }
                { valid &&
                    <Button color="info" onClick={confirmImport}>Import</Button>
                }
            </Row>
        </>
    )
}

export default CandidateBatchImport;
