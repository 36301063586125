import NavigationExamList from '../../views/pages/admin/navigation_exam/navigationExamList';
import CancelledExamList from '../../views/pages/admin/cancelled_exam/cancelledExamList';
import NavigationExamDetail from '../../views/pages/admin/navigation_exam/navigationExamDetail';
import ExamCenterList from '../../views/pages/admin/examCenter/examCenterList';
import CandidateList from '../../views/pages/admin/candidate/candidateList';
import ExamCenterDetail from '../../views/pages/admin/examCenter/examCenterDetail';
import CandidateDetail from '../../views/pages/admin/candidate/candidateDetail';
import ExamCenterFinanceDetail from '../../views/pages/admin/examCenter/examCenterFinanceDetail';
import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';
import ClosedExamList from '../../views/pages/admin/closed_exam/closedExamList';
import ClosedExamDetail from '../../views/pages/admin/closed_exam/closedExamDetail';
import ClosedExamResults from '../../views/pages/admin/closed_exam/closedExamResults';
import ExamMark from "../../views/pages/ExamMark";
import AuditorDashboard from "../../views/pages/questionusers/AuditorDashboard";
import QuestionUserList from '../../views/pages/admin/question/questionUserList';
import QuestionBankList from '../../views/pages/admin/question/questionBankList';
import QuestionDetail from '../../views/pages/admin/question/questionDetail';
import CalendarExamList from "../../views/pages/admin/calendar/ExamList";

export const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    component: AuditorDashboard,
  },
  {
    collapse: true,
    name: 'Questions',
    icon: 'nc-icon nc-book-bookmark',
    state: 'questionCollapse',
    views: [
      {
        path: '/question/list',
        name: 'Question List',
        mini: 'QL',
        component: QuestionUserList,
      },
      {
        path: '/question/bank',
        name: 'Question Bank',
        mini: 'QB',
        component: QuestionBankList,
      },
    ],
  },
  {
    path: '/examCenter/list',
    name: 'Exam Centers',
    icon: 'nc-icon nc-shop',
    component: ExamCenterList,
  },
  {
    path: '/candidate/list',
    name: 'Candidates',
    icon: 'nc-icon nc-single-02',
    component: CandidateList,
  },
  {
    collapse: true,
    name: 'Exams',
    icon: 'nc-icon nc-book-bookmark',
    state: 'navigationCollapse',
    views: [
      {
        path: '/exams/calendar',
        name: 'Exam Calendar',
        mini: 'CC',
        component: CalendarExamList,
      },
      {
        path: '/navigation_exam/list',
        name: 'Exam Requests',
        mini: 'EL',
        component: NavigationExamList,
      },
      {
        path: '/closed_exam/list',
        name: 'Closed Exams',
        mini: 'CE',
        component: ClosedExamList,
      },
      {
        path: '/navigation_exam/cancelled/list',
        name: 'Cancelled exams',
        mini: 'EC',
        component: CancelledExamList,
      },

    ],
  },
];

export const hiddenRoutes = [
  {
    path: '/navigation_exam/detail',
    name: 'Exam Request Detail',
    mini: 'ED',
    component: NavigationExamDetail,
  },
  {
    path: '/examCenter/detail',
    name: 'Exam Center Detail',
    mini: 'ED',
    component: ExamCenterDetail,
  },
  {
    path: '/examCenter/transactions',
    name: 'Exam Center Detail',
    mini: 'ED',
    component: ExamCenterFinanceDetail,
  },
  {
    path: '/candidate/detail',
    name: 'Candidate Detail',
    mini: 'CD',
    component: CandidateDetail,
  },
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
  {
    path: '/closed_exam/detail',
    name: 'Closed Exam Detail',
    mini: 'ED',
    component: ClosedExamDetail,
  },
  {
    path: '/closed_exam/results',
    name: 'Closed Exam Results',
    mini: 'ER',
    component: ClosedExamResults,
  },
  {
    path: '/navigation_exam/marks',
    name: 'Current Marks',
    mini: 'EM',
    component: ExamMark,
  },
  {
    path: '/question/detail',
    name: 'Question Detail',
    mini: 'QD',
    component: QuestionDetail,
  },
];
