import ApiCaller from '../lib/ApiCaller';
import Topic from 'lib/common/models/topic';

class TopicApi {
    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    createTopic(code:string, title:string, marks: number, syllabus:any, module:any, token?: string): Promise<void | Topic> {
        return this.apiCaller.call(`/v1/syllabus/module/${module._id}/topic`, 'POST', {
            code: code,
            title: title,
            marks: marks,
            syllabus: syllabus._id,
            module: module,
        }, token)
            .then((data) => {
                return new Topic(data);
            }).catch(err => {
                console.error(err);
                throw Error(err);
            });
    }
    
    async updateTopic(topic: Topic, token?: string): Promise<void | Topic> {
        return this.apiCaller.call('/v1/syllabus/module/topic/' + topic._id, 'PUT', {
                code: topic.code,
                title: topic.title,
                marks: topic.marks
            }, token).then((data) => { return new Topic(data); })
            .catch((err) => {
                console.error(err);
                throw Error(err);
        });
    }

    async deleteTopic(topicId: string, token?: string): Promise<void> {
        return this.apiCaller.call('/v1/syllabus/module/topic/' + topicId, 'DELETE', {
            id: topicId
        }, token).catch((err) => {
            console.error(err);
            alert("Error deleting Topic, please try again");
        });
    }
}

export default TopicApi;
