import React, { ChangeEvent, createRef, useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Button,
  FormText,
} from 'reactstrap';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Form as BootstrapForm } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import UserApi from '../../../../api/UserApi';
import User, {
  UserTypes,
  UserTypesLabels,
} from '../../../../lib/common/models/user';
import UserPreference from '../../../../lib/common/models/userPreference';

import Settings from '../../../../lib/settings';

import mcaBackground from '../../../../assets/img/bg/maritime-banner.png';
import TransactionsComponent from './TransactionsComponent';
import { SessionState } from 'store/reducers/sessionReducer';
import {
  updateAvatar,
  updateSession,
} from 'store/actions/session/sessionActions';
import AuthApi from 'api/AuthApi';

import './styles.css';
import {getJwtExpiration} from "../../../../shared/utils/jwt";

const UserProfile: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const [name, setName] = useState(loggedUser.name);
  const [emailNotificationsPreference, setEmailNotificationsPreference] = useState(false);
  const dispatch = useDispatch();
  const [imageState, setState] = useState({
    file: null,
    imagePreviewUrl: useSelector((state: AppState) => state.session.avatar),
  });
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (loggedUser) {
      const userApi = new UserApi(new ApiCaller(loggedUser.token));
      userApi
        .getUserPreference()
        .then((data: UserPreference) => {
          setEmailNotificationsPreference(data.emailNotifications);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [loggedUser.token]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const { files } = e.target;

    if (files && files.length > 0) {
      if (files[0].size > 1024000) {
        toast.error('Image maximum size allowed is 1M.');
      } else {
        reader.onloadend = () => {
          setState({
            // @ts-ignore
            file: files[0],
            // @ts-ignore
            imagePreviewUrl: reader.result,
          });
        };
        reader.readAsDataURL(files[0]);
      }
    }
  };
  const notifyForm: any = createRef();

  const handleEmailNotificationPreference = (e: any) => {
    setEmailNotificationsPreference(!emailNotificationsPreference);
    console.log(emailNotificationsPreference)
    const userApi = new UserApi(new ApiCaller());
    userApi.updateUserPreference({ emailNotifications: !emailNotificationsPreference, }, loggedUser.token,)
      .then((data: UserPreference) => {
        toast.success('Settings Updated');
        //setEmailNotificationsPreference(data.emailNotifications);
      });
  };

  const updateUser = (e: React.FormEvent) => {
    e.preventDefault();
    const userApi = new UserApi(new ApiCaller());
    userApi
      .updateUser(new User({ ...loggedUser, name: name }), loggedUser.token)
      .then((user: any) => {
        if (user) {
          user.token = loggedUser.token;
          const sessionData: SessionState = {
            loggedIn: user.email ? true : false,
            ready: true,
            userInfo: user,
            tokenExpiration: getJwtExpiration(user.token),
            type: user.type,
            avatar: undefined,
            layout: user.type === 'admin' ? 'navozyme' : user.type,
          };
          dispatch(updateSession(sessionData));

          if (imageState.file) {
            userApi
              .setAvatar(loggedUser._id, imageState.file, loggedUser?.token)
              .then(() => {
                Settings.setCurrentUser(user);
                toast.success('Profile Updated');
                dispatch(updateAvatar(imageState.imagePreviewUrl || ''));
              });
          } else {
            Settings.setCurrentUser(user).then(() => {
              toast.success('Profile Updated');
            });
          }
        }
      })
      .catch((err) => {
        toast.error('Error updating user, please try again');
      });
  };

  const doReset = (e: React.FormEvent) => {
    e.preventDefault();

    const authApi = new AuthApi(new ApiCaller());
    authApi.resetPassword(loggedUser.email).then((data: any) => {
      if (data.status === "ok") {
        setSuccess(true);
      } else {
        setError(data.status);
      }
    }).catch(err => {
      console.error(err);
      setError(err.message);
    });

    e.preventDefault();
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <div className="image">
                <img alt="..." src={mcaBackground} className="picture-src" />
              </div>
              <CardBody>
                <div className="author">
                  <a>
                    <div className="picture-container">
                      <div className="picture">
                        <img
                          src={imageState.imagePreviewUrl}
                          className="picture-src"
                          alt="..."
                        />
                        <input
                          type="file"
                          onChange={(e) => handleImageChange(e)}
                        />
                      </div>
                    </div>
                    <h5 className="title">{loggedUser.name}</h5>
                  </a>
                  <p className="description">{loggedUser.email}</p>
                </div>
                <p className="description text-center">
                  {Object.keys(UserTypes).find(
                    (key) => UserTypes[key] === loggedUser.type,
                  )}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Edit Profile</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={updateUser}>
                  <Row>
                    <Col className="pr-1" md="12">
                      <FormGroup className={name ? '' : 'has-danger'}>
                        <label>Name</label>
                        <Input
                          defaultValue={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                          type="text"
                        />
                        {name ? null : (
                          <label className="error">
                            This field is required.
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Email</label>
                        <Input
                          defaultValue={loggedUser.email}
                          placeholder="Email"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Role</label>
                        <Input
                          defaultValue={UserTypes[loggedUser.type]}
                          placeholder="Role"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col md="12">
                        <FormText color="default" tag="span">
                          <a className="edit-form__text_reset-password"
                             onClick={(e) => doReset(e)} href='/'>
                            Reset password
                          </a>
                        </FormText>
                      </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Button
                            className="btn-round float-left"
                            disabled={!name}
                            color="info"
                            type="submit">
                          Save
                        </Button>
                      </FormGroup>
                    </Col>
                    {success &&
                      <ReactBSAlert
                        success
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Email successfully sent!"
                        onConfirm={() => { setSuccess(false) }}
                        confirmBtnBsStyle="success"
                        btnSize=""
                        confirmBtnText="Go to profile"
                      >
                        An email was sent to your account with the link to reset the password.
                      </ReactBSAlert>
                    }
                    {error && (
                      <ReactBSAlert
                        danger
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Error sending recovery email"
                        onConfirm={() => setError("")}
                        onCancel={() => setError("")}
                        confirmBtnBsStyle="danger"
                        btnSize=""
                        confirmBtnText="Close"
                      >
                        {error}
                      </ReactBSAlert>
                    )}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Card>
              <CardHeader>
                <h5 className="title">Settings</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <div className="card-customized">
                          <BootstrapForm.Check
                            type="switch"
                            checked={emailNotificationsPreference}
                            onClick={handleEmailNotificationPreference}
                            id="email-notification-switch"
                          />
                          <p>Email notifications (If enabled, email notifications will be sent along with push notifications)</p>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                </Form>
              </CardBody>
            </Card>
          </Col>
          {loggedUser.isType(UserTypesLabels.examCentre) && (
            <Col md="8">
              <TransactionsComponent />
            </Col>
          )}
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default UserProfile;
