import ApiCaller from '../lib/ApiCaller';
import ExamScheduleCollection from "../lib/common/models/examScheduleCollection";
import ExamSchedule from "../lib/common/models/examSchedule";

class ExamScheduleApi {
    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    async getScheduleByModule(moduleId: string): Promise<ExamScheduleCollection> {
        return this.apiCaller.call('/v1/examSchedule/' + moduleId, 'GET')
            .then(data => {
                const apiArr = data.schedules.map((schedule: any) => new ExamSchedule(schedule));
                return new ExamScheduleCollection(apiArr);
            }).catch(err => {
                console.error(err);
                throw Error(err);
            })
    }

    createSchedule(syllabusId: string, moduleId: string, rrule: string, selectedTimeSlots: string[], token?: string): Promise<ExamSchedule> {
        return this.apiCaller.call('/v1/examSchedule', 'PUT',
            { syllabus: syllabusId, module: moduleId, rrule: rrule, timeSlots: selectedTimeSlots }, token
        ).then((data) => {
            return new ExamSchedule(data);
        }).catch(err => {
            console.error(err);
            throw new Error(err);
        })
    }

    deleteSchedule(scheduleId: string, token?: string): Promise<void> {
        return this.apiCaller.call('/v1/rest/examSchedule/' + scheduleId, 'DELETE', {
            id: scheduleId
        }, token).catch((err) => {
            console.error(err);
        })
    }
}

export default ExamScheduleApi;
