import React, {useState} from 'react';
import { formatDateTime, DEFAULT_TIMEZONE_FORMAT } from "../../../shared/utils/dateTime";
import moment from 'moment';

const Clock: React.FC = () => {
    const [date, setDate] = useState(moment().local().toDate());

    function refreshClock() {
        setDate(moment().local().toDate());
    }

    React.useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    return (
        <span>
            {formatDateTime(date, DEFAULT_TIMEZONE_FORMAT)}
        </span>
    );
}

export default Clock;
