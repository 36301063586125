import ApiCaller from '../lib/ApiCaller';
import Track from '../lib/common/models/track';

class TrackApi {
    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    questions(): Promise<any> {
        return this.apiCaller.call('/v1/dashboard/questions', 'GET').then((data)=>{
            var trackData = data.map((t: any) => new Track(t));
            return trackData;
            }
        ).catch((err) => {
            console.error(err);
            throw Error(err);
        });
    }


}

export default TrackApi;
