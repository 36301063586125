import React, { FC, useState, useEffect } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import NZDocument, {
  NZDocumentType,
} from '../../../lib/common/models/nzDocument';
import NavigationExam, {
  NavigationExamStatusLabels,
} from '../../../lib/common/models/navigationExam';
import { MarkingGridRow } from '../../../lib/common/models/markingData';
import { formatDateTime } from '../../../shared/utils/dateTime';
import CustomDropZoneLayout from '../../components/documents/FileUploaderDropZone';
import { DocumentDownloadContainer } from '../../components/documents/ExamScriptUploader/compStyle';
import SubmitMarkers from './SubmitMarkers';
import DocumentPreviewModal from 'views/components/documents/DocumentPreviewModal/DocumentPreviewModal';
import SubmitMarkersPresenter from './SubmitMarkersPresenter';

export type ExamMarkDetailProps = {
  exam: NavigationExam | undefined;
  grid: MarkingGridRow[];
  currentDoc: NZDocument | undefined;
  onSaveMarks: () => void;
  onDownloadExam: () => void;
  onDownloadAnswers: () => void;
  onSubmitUploading: (doc: NZDocument) => void;
  checkErrors: () => boolean;
  blockInput?: boolean;
  onMarkerReportDeletion?: () => void;
  presenter: SubmitMarkersPresenter;
};

const ExamMarkDetail: FC<ExamMarkDetailProps> = ({
  exam,
  grid,
  currentDoc,
  onSaveMarks,
  onDownloadExam,
  onDownloadAnswers,
  onSubmitUploading,
  checkErrors,
  blockInput,
  onMarkerReportDeletion = () => {},
  presenter,
}) => {
  const [title, setTitle] = useState<string>('Marker');
  const [isDownloadDocumentModalOpen, setIsDownloadDocumentModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (exam) setTitle(exam.getExamNumber());
  }, [exam]);

  const handleSubmitUploading = (data: NZDocument) => {
    onSubmitUploading(data);
  };

  const renderMarkerReport = () => {
    return (
      <>
        {exam?.status === NavigationExamStatusLabels.GENERATED && !currentDoc && (
          <CustomDropZoneLayout
            onFinish={handleSubmitUploading}
            type={NZDocumentType.MARKER_REPORT}
            projectId={exam?.id}
          />
        )}
        {currentDoc && (
          <>
            <DocumentDownloadContainer
              onClick={(event: any) => {
                event.preventDefault();
                if (event.target.className.includes('remove')) {
                  onMarkerReportDeletion();
                } else {
                  setIsDownloadDocumentModalOpen(!isDownloadDocumentModalOpen);
                }
              }}
            >
              <p>{currentDoc.displayName}</p>
              {exam?.status === NavigationExamStatusLabels.GENERATED && (
                <Button
                  className="btn-round remove-file-button btn-no-margin"
                  title="Remove report file"
                  size="sm"
                >
                  <i className="nc-icon nc-simple-remove" />
                </Button>
              )}
            </DocumentDownloadContainer>
          </>
        )}
        {!currentDoc && exam?.status !== NavigationExamStatusLabels.GENERATED && <p>There is no marker report</p>}
      </>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">{title}</CardTitle>
        { !blockInput && <Button
          id={`pop_${title}`}
          className={'btn-sm'}
          type="button"
          onClick={onSaveMarks}>
          Save Marks
        </Button> }
        {exam?.examId && (
          <>
            <Button
              id={`download_${title}`}
              className={'btn-sm'}
              type="button"
              onClick={onDownloadExam}>
              Download Exam Paper
            </Button>
            <Button
              id={`download_answers${title}`}
              className={'btn-sm'}
              type="button"
              onClick={onDownloadAnswers}>
              Download Grading Scheme
            </Button>
          </>
        )}
        {!blockInput ? exam?.status === NavigationExamStatusLabels.GENERATED ? (
          <SubmitMarkers
            onConfirm={() => presenter.submit(grid, exam?.id!)}
            linkButtonText="Marking Completed"
            validate={checkErrors}
          />
        ) : (
          <SubmitMarkers
            onConfirm={() => presenter.revission(grid, exam?.id!)}
            linkButtonText="Revision Completed"
            validate={checkErrors}
          />
        ) : <></>}
      </CardHeader>
      <CardBody className="table-full-width table-hover">
        <Form className="form-horizontal exam-mark-detail-form">
          <Row>
            <Label sm="2">Date</Label>
            <Col sm="10">
              <FormGroup>
                <Input
                  readOnly
                  value={formatDateTime(exam?.date)}
                  type="text"
                  name="Exam Date"
                  placeholder="Exam Date"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="2">Exam Centre</Label>
            <Col sm="10">
              <FormGroup>
                <Input
                  readOnly
                  value={exam?.examCenterId?.name}
                  type="text"
                  name="Exam Centre"
                  placeholder="Exam Centre"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="2">Syllabus</Label>
            <Col sm="10">
              <FormGroup>
                <Input
                  readOnly
                  value={`(${exam?.syllabus?.code}) ${exam?.syllabus?.title}`}
                  type="text"
                  name="Syllabus"
                  placeholder="Syllabus"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="2">Module</Label>
            <Col sm="10">
              <FormGroup>
                <Input
                  readOnly
                  value={`(${exam?.module?.code}) ${exam?.module?.title}`}
                  type="text"
                  name="Module"
                  placeholder="Module"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Label sm="2">Marker Report</Label>
            <Col sm="10">
              {renderMarkerReport()}
              {currentDoc && <DocumentPreviewModal
                isOpen={isDownloadDocumentModalOpen}
                onClose={() => setIsDownloadDocumentModalOpen(false)}
                document={currentDoc}
                title="Marker Report"
              />}
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ExamMarkDetail;
