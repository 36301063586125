import React from 'react';
import { CardBody, CardHeader, CardTitle, Row, Card, Col } from 'reactstrap';

import DashboardApi from '../../../../api/DashboardApi';
import ExamCenterDateFilteredTable from '../../filteredComponents/examCenterDateFilteredTable/examCenterDateFilteredTable';

const ExamCandidatesReport: React.FC = () => {
  const columns = [
    { Header: 'CODE', accessor: 'code', sortable: true },
    { Header: 'TITLE', accessor: 'title', sortable: true },
    { Header: 'EXAMS', accessor: 'exams', sortable: true },
    { Header: 'AVG. CANDIDATES', accessor: 'avgCandidates', sortable: true },
  ];

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Number of exams / Avg. candidates</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <ExamCenterDateFilteredTable
                multipleTables={true}
                columns={columns}
                fetchMethod={DashboardApi.getExamCandidatesReport}
                tableName='Number of exams / Avg. candidates'
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ExamCandidatesReport;
