import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import Settings from '../../../../lib/settings';
import { ToastContainer, toast } from 'react-toastify';

import {
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardHeader,
} from 'reactstrap';
import moment from 'moment';
import ReactDatetime from 'react-datetime';
import ApiCaller from '../../../../lib/ApiCaller';
import ExamCertificate from '../../../../lib/common/models/examCertificate';
import CertificateApi from '../../../../api/CertificateApi';

const CertificateDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const history = useHistory();
  const [currentCertificate, setCurrentCertificate] =
    useState<ExamCertificate>();

  useEffect(() => {
    setCurrentCertificate(Settings.getCurrentExamCertificate);
  }, []);

  const revoke = () => {
    if (currentCertificate) {
      const certificateApi = new CertificateApi(new ApiCaller());
      certificateApi
        .revoke(currentCertificate._id!, loggedUser.token)
        .then((certificate: ExamCertificate) => {
          toast.success('Certificate Revoked');
          setCurrentCertificate(
            new ExamCertificate({
              ...currentCertificate,
              status: certificate.status,
            }),
          );
        })
        .catch((err) => {
          toast.error('Error revoking certificate');
        });
    }
  };

  const getResult = () => {
    return currentCertificate?.grade
      ? `${currentCertificate.result} (${currentCertificate.grade})`
      : currentCertificate?.result;
  };

  const close = () => {
    history.push(`/${layout}/certificate/list`);
  };

  return (
    <>
      <div className="content">
        {currentCertificate && (
          <Card>
            <Row>
              <Col md="12">
                <CardHeader>
                  <CardHeader>
                    <h4 className="card-title">Certificate Detail</h4>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal">
                    <Row>
                      <Label sm="2">Certificate No.</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentCertificate?.getCertificateNumber()}
                            type="text"
                            name="serialNumber"
                            placeholder="Certificate Number"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Title</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentCertificate?.getTitle()}
                            type="text"
                            name="firstName"
                            placeholder="Title"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Candidate</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={`${currentCertificate.candidateId?.firstName} ${currentCertificate.candidateId?.lastName}`}
                            type="text"
                            name="fullName"
                            placeholder="Candidate Name"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Exam</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentCertificate.examId?.sequence}
                            type="text"
                            name="exam"
                            placeholder="Exam"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Centre</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentCertificate.examCentre?.name}
                            type="text"
                            name="exam"
                            placeholder="Centre"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Created At</Label>
                      <Col sm="10">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{ readOnly: true }}
                            timeFormat={false}
                            dateFormat={'DD-MM-YYYY HH:mm'}
                            value={moment(currentCertificate?.createdAt).utc()}
                            // input={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Date</Label>
                      <Col sm="10">
                        <FormGroup>
                          <ReactDatetime
                            inputProps={{ readOnly: true }}
                            timeFormat={false}
                            dateFormat={'DD-MM-YYYY'}
                            value={moment(currentCertificate?.date).utc()}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Result</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={getResult()}
                            type="text"
                            name="status"
                            placeholder="Status"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Status</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentCertificate.status}
                            type="text"
                            name="status"
                            placeholder="Status"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">Reference</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentCertificate.identifier}
                            type="text"
                            name="status"
                            placeholder="Reference"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">File URL</Label>
                      <Col sm="10">
                        <FormGroup>
                          <Input
                            value={currentCertificate.fileUrl}
                            type="text"
                            name="status"
                            placeholder="Cert URL"
                            required
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2" />
                      <Col sm="10">
                        {loggedUser.isAdmin() && (
                          <Button
                            className="btn-round"
                            color="info"
                            onClick={revoke}>
                            Revoke
                          </Button>
                        )}
                        <Button
                          className="btn-round btn btn-danger"
                          onClick={close}>
                          Close
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Col>
            </Row>
          </Card>
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default CertificateDetail;
