import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';

import { AppState } from 'store/store';
import UserApi from 'api/UserApi';
import ApiCaller from 'lib/ApiCaller';
import QuestionsPipelineReport from '../../components/dashboard/questions/QuestionsPipelineReport';
import ActiveQuestionsPercentageReport from '../../components/dashboard/questions/ActiveQuestionsPercentageReport';
import QuestionsPerModuleAndTopicReport from '../../components/dashboard/questions/QuestionsPerModuleAndTopicReport';
import ExamCandidatesReport from '../../components/dashboard/exams/ExamCandidatesReport';
import PassRateReport from '../../components/dashboard/exams/PassRateReport';
import StatsCardsContainer from '../../../components/StatsCardsContainer/StatsCardsContainer';

import { DEFAULT_DASHBOARD_STATS } from '../../../shared/constants/defaultDashboardStats';

import 'react-toastify/dist/ReactToastify.css';
import { useSyllabus } from 'hooks';

interface ContainerProps {}

const AuditorDashboard: React.FC<ContainerProps> = ({}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [data, setData] = useState(DEFAULT_DASHBOARD_STATS);
  const { allowedSyllabuses } = useSyllabus();

  useEffect(() => {
    if (!loggedUser?.isEmpty()) {
      const userApi = new UserApi(new ApiCaller());
      userApi
        .getMyStats(loggedUser.token)
        .then((data: any) => {
          if (data) {
            setData(data);
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Error retrieving data.');
        });
    }
  }, [loggedUser]);

  return (
    <>
      <div className="content">
        <Row>
          <StatsCardsContainer data={data.numerical} />
        </Row>
        <Row>
          <Col>
            <QuestionsPipelineReport {...{ allowedSyllabuses }} />
          </Col>
          <Col>
            <ActiveQuestionsPercentageReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <QuestionsPerModuleAndTopicReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <PassRateReport {...{ allowedSyllabuses }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ExamCandidatesReport />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AuditorDashboard;
