import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactDatetime from 'react-datetime';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
  Row,
  Button,
  Label,
  Col,
} from 'reactstrap';

import moment from 'moment';

import { AppState } from '../../../../../store/store';
import { DateFilterComponentProps } from '../../types';

type DateFilter = {
  title: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
};

enum DateFilters {
  thisMonth,
  lastMonth,
  currentQuarter,
  lastQuarter,
  currentYear,
  lastYear,
  financialYear,
  custom,
}

export const dateFilterValues = {
  [DateFilters.thisMonth]: {
    title: 'This month',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
  },
  [DateFilters.lastMonth]: {
    title: 'Last month',
    startDate: moment().subtract(1, 'months').startOf('month'),
    endDate: moment().startOf('month'),
  },
  [DateFilters.currentQuarter]: {
    title: 'Current quarter',
    startDate: moment().quarter(moment().quarter()).startOf('quarter'),
    endDate: moment().quarter(moment().quarter()).endOf('quarter'),
  },
  [DateFilters.lastQuarter]: {
    title: 'Last quarter',
    startDate: moment().quarter(moment().quarter()).subtract(1,'Q').startOf('quarter'),
    endDate: moment().quarter(moment().quarter()).subtract(1,'Q').endOf('quarter'),
  },
  [DateFilters.currentYear]: {
    title: 'Current year',
    startDate: moment().startOf('year'),
    endDate: moment().endOf('year'),
  },
  [DateFilters.lastYear]: {
    title: 'Last year',
    startDate: moment().subtract(1, 'years').startOf('year'),
    endDate: moment().subtract(1, 'years').endOf('year'),
  },
  [DateFilters.financialYear]: {
    title: 'Financial year',
    startDate: moment().subtract(1, 'years').month('April').startOf('month'),
    endDate: moment().month('March').endOf('month'),
  },
  [DateFilters.custom]: {
    title: 'Custom',
    startDate: moment(),
    endDate: moment().endOf('month'),
  },
};

const DateFilteredComponent: React.FC<DateFilterComponentProps> = ({
  component,
  fetchMethod,
  updateDataMethod,
  componentName,
  params,
}) => {
  const [dateFilter, setDateFilter] = useState<DateFilter | undefined>(
    dateFilterValues[DateFilters.thisMonth],
  );
  const [isCustomModalOpened, setIsCustomModalOpened] =
    useState<boolean>(false);
  const [customStartDate, setCustomStartDate] = useState<moment.Moment>();
  const [customEndDate, setCustomEndDate] = useState<moment.Moment>();
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  useEffect(() => {
    fetchMethod(
      dateFilter?.startDate,
      dateFilter?.endDate,
      loggedUser.token,
      params,
    )
    .then((result: any) => {
      updateDataMethod(result);
    })
    .catch((error) => {
      console.error(error);
    });
  }, [fetchMethod, params, loggedUser, updateDataMethod]);

  const setFilter = useCallback(
    (filter: DateFilter) => {
      setDateFilter(filter);

      fetchMethod(filter.startDate, filter.endDate, loggedUser.token, params)
        .then((result: any) => {
          updateDataMethod(result);
        })
        .catch((err: any) => {
          console.error(err);
        });
    },
    [fetchMethod, loggedUser, updateDataMethod, params],
  );

  const clearCustomFilter = useCallback(() => {
    setCustomStartDate(undefined);
    setCustomEndDate(undefined);
  }, []);

  const setCustomFilter = useCallback(() => {
    if (customStartDate && customEndDate) {
      if (customStartDate > customEndDate) {
        toast.error('Start date must be before End date');
        return;
      }
      const filter: DateFilter = {
        title: `${customStartDate.format('DD MMM YYYY')} - 
        ${customEndDate.format('DD MMM YYYY')}`,
        startDate: customStartDate.startOf('day'),
        endDate: customEndDate,
      };
      setFilter(filter);
      setIsCustomModalOpened(false);
      clearCustomFilter();
    }
  }, [customStartDate, customEndDate, setFilter, clearCustomFilter]);

  const isValidDate = useCallback(
    (date: moment.Moment) => {
      return date.isAfter(customStartDate);
    },
    [customStartDate],
  );

  const dropdownItems = useMemo(() => {
    return Object.keys(dateFilterValues).map((f, index) => {
      const filter = dateFilterValues[f];
      if (filter === dateFilterValues[DateFilters.custom]) {
        return (
          <DropdownItem
            key={index}
            onClick={() => setIsCustomModalOpened(true)}>
            {filter.title}
          </DropdownItem>
        );
      }
      return (
        <DropdownItem key={index} onClick={() => setFilter(filter)}>
          {filter.title}
        </DropdownItem>
      );
    });
  }, [setFilter]);

  return (
    <>
      <UncontrolledDropdown
        className={`filtered-${componentName}__filter-button_date`}>
        <DropdownToggle caret>{dateFilter?.title}</DropdownToggle>
        <DropdownMenu>{dropdownItems}</DropdownMenu>
      </UncontrolledDropdown>
      {component.map(item => (item))}
      <Modal isOpen={isCustomModalOpened} size={'ml'}>
        <ModalHeader>
          <button
            type="button"
            className="close"
            onClick={() => setIsCustomModalOpened(false)}>
            ×
          </button>
          {'Set custom date range'}
        </ModalHeader>
        <ModalBody>
          <Form className="form-vertical">
            <Row md="12" className="mb-4">
              <Col sm="6">
                <Label>Start:</Label>
                <ReactDatetime
                  inputProps={{
                    className: 'form-control',
                    placeholder: 'Pick Date',
                    name: 'Start date',
                  }}
                  timeFormat={false}
                  dateFormat={'DD-MM-YYYY'}
                  onChange={(e: any) => setCustomStartDate(e)}
                  value={customStartDate}
                />
              </Col>
              <Col sm="6">
                <Label>End:</Label>
                <ReactDatetime
                  isValidDate={isValidDate}
                  inputProps={{
                    className: 'form-control',
                    placeholder: 'Pick Date',
                    name: 'End date',
                  }}
                  timeFormat={false}
                  dateFormat={'DD-MM-YYYY'}
                  onChange={(e: any) => setCustomEndDate(e)}
                  value={customEndDate}
                />
              </Col>
            </Row>
            <Row sm="10" />
            <Row className="justify-content-md-center">
              <Button color="primary" onClick={setCustomFilter}>
                Yes
              </Button>
              <Button
                color="danger"
                onClick={() => setIsCustomModalOpened(false)}>
                No
              </Button>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DateFilteredComponent;
