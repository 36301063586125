import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import { Card, CardHeader, CardBody } from 'reactstrap';

import { AppState } from '../../../../../store/store';
import ReactTable from '../../../../../components/ReactTable/ReactTable';
import ApiCaller from '../../../../../lib/ApiCaller';

import TransactionApi from '../../../../../api/TransactionApi';
import TransactionCollection from '../../../../../lib/common/models/transactionCollection';

import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../../shared/utils/dateTime';
import { formatCurrency } from '../../../../../shared/utils/currency';
import { TransactionStatus } from '../../../../../lib/common/models/transaction';
import NavigationExam from './../../../../../lib/common/models/navigationExam';
import Settings from '../../../../../lib/settings';

interface IPaymentListProps {
  refresh: any;
}

const PaymentList: React.FC<IPaymentListProps> = ({ refresh }) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [transactions, setTransactions] = React.useState<TransactionCollection>(
    new TransactionCollection([]),
  );
  const [currentExam, setCurrentExam] = useState<NavigationExam>();

  useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();
    setCurrentExam(exam);

    if (exam.id) {
      const transactionApi = new TransactionApi(
        new ApiCaller(loggedUser.token),
      );
      transactionApi.getExamPayments(exam.id).then((transactions: any) => {
        setTransactions(transactions);
      });
    }
  }, [refresh]);

  const columns = [
    {
      Header: 'TIMESTAMP',
      accessor: (d: any) =>
        formatDateTime(d.timestamp, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'timestamp'),
    },
    {
      Header: 'DESCRIPTION',
      accessor: (d: any) => (d.description ? d.description : '-'),
      sortable: true,
    },
    {
      Header: 'AMOUNT',
      accessor: (d: any) => formatCurrency(d.amount, d.currency),
      sortable: true,
    },
    {
      Header: 'STATUS',
      accessor: (d: any) => (d.status ? TransactionStatus[d.status] : ''),
      sortable: true,
    },
  ];

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <h4 className="card-title">Payment History</h4>
          </CardHeader>
          <CardBody>
            <ReactTable data={transactions.transactions} columns={columns} />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default PaymentList;
