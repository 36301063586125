import { QuestionStatusLabels } from '../../../../lib/common/models/question';

export const typeStatus: {
  [index: string]: { [index: string]: Array<string> };
} = {
  admin: {
    ACCEPT: [
      QuestionStatusLabels.IN_MODIFICATION,
      QuestionStatusLabels.RETURNED_FOR_IMPROVEMENT,
      QuestionStatusLabels.AWAITING_MODERATION,
    ],
    REVOKE: [
      QuestionStatusLabels.IN_MODIFICATION,
      QuestionStatusLabels.AWAITING_MODERATION,
      QuestionStatusLabels.ACCEPTED,
      QuestionStatusLabels.APPROVED,
    ],
  },
  questionWriter: {
    ACCEPT: [
      QuestionStatusLabels.IN_MODIFICATION,
      QuestionStatusLabels.RETURNED_FOR_IMPROVEMENT,
    ],
    REVOKE: [],
  },
  questionModerator: {
    ACCEPT: [QuestionStatusLabels.AWAITING_MODERATION],
    REVOKE: [QuestionStatusLabels.AWAITING_MODERATION],
  },
  mcaExaminer: {
    ACCEPT: [QuestionStatusLabels.ACCEPTED],
    REVOKE: [QuestionStatusLabels.ACCEPTED],
  },
  IAMIExamAdmin: {
    ACCEPT: [QuestionStatusLabels.APPROVED],
    REVOKE: [QuestionStatusLabels.APPROVED, QuestionStatusLabels.ACTIVE],
  },
  IAMIExamManager: {
    ACCEPT: [QuestionStatusLabels.APPROVED],
    REVOKE: [QuestionStatusLabels.APPROVED, QuestionStatusLabels.ACTIVE],
  },
  auditor: {
    ACCEPT: [],
    REVOKE: [],
  },
  mcaMarker: {
    ACCEPT: [],
    REVOKE: [],
  }
};
