import ApiCaller from 'lib/ApiCaller';
import ExamCenterApi from '../api/ExamCenterApi';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/store';
import {
  setExamCenters,
  setExamCentersStatus,
} from 'store/actions/examCenter/actions';
import { APP_STATUS } from 'types/app';

const useExamCenter = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const { list, status } = useSelector((state: AppState) => state.exam_center);
  const dispatch = useDispatch();

  const getExamCenters = () => {
    const examCenterApi = new ExamCenterApi(new ApiCaller(loggedUser.token));
    examCenterApi
      .getAllExamCenters()
      .then((centers: any) => {
        dispatch(setExamCentersStatus(APP_STATUS.IDLE));
        if (centers && Object.keys(centers).length > 0) {
          dispatch(setExamCenters(centers));
        }
      })
      .catch(() => {
        dispatch(setExamCentersStatus(APP_STATUS.FAILED));
      });
  };

  useEffect(() => {
    if (!list.centers.length && status !== APP_STATUS.LOADING) {
      dispatch(setExamCentersStatus(APP_STATUS.LOADING));
      getExamCenters();
    }
  }, [loggedUser.token]);

  return {
    examCenters: list,
    status,
  };
};

export default useExamCenter;
