import ExamStatusLog from "./examStatusLog";

class ExamStatusLogCollection {
  statuses: Array<ExamStatusLog>;

  constructor(statuses: Array<ExamStatusLog>) {
    this.statuses = statuses;
  }

  length() {
    return this.statuses.length;
  }
}

export default ExamStatusLogCollection;
