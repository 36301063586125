import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect, useReducer } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import UploadImagePresenter from '../../views/presenters/UploadImagePresenter';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Settings from '../../lib/settings';
import { getPlugins, getValidChildren, pastePreprocess } from './util';

const propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  getEditor: PropTypes.func,
  isDisabled: PropTypes.bool,
  preserveWhitespace: PropTypes.bool,
  autoresize: PropTypes.bool,
};

const defaultProps = {
  className: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  value: undefined,
  onChange: () => {},
  getEditor: () => {},
  isDisabled: false,
  preserveWhitespace: true,
  autoresize: true,
};

const TinyMCETextEditor = ({
  className,
  placeholder,
  value,
  onChange,
  getEditor,
  isDisabled,
  preserveWhitespace,
  autoresize,
}) => {
  const editorRef = useRef(null);

  const [currentValue, setCurrentValue] = useState(value);
  const currentUser = useSelector((state) => state.session.userInfo);
  let view = {
    setUploadState: () => {},
    alertSuccess: toast.success,
    alertError: toast.error,
  };
  const presenter = new UploadImagePresenter(currentUser, view);

  useEffect(() => {
    setCurrentValue(value);
  }, []);

  /**
   * Callback function to save the image in the server
   * @param input
   * @param cb
   */

  const saveImageInServer = (input, cb) => {
    let file = input.files[0];
    file.uid = `tinyMCE_${Date.now()}`;
    const uploadOptions = {
      file: file,
      onSuccess: (data) => {
        cb(`${Settings.getApiURL()}/v1/documents/images/${data.fileHash}`, {
          title: file.name,
        });
      },
      onError: () => {},
    };
    presenter.upload(uploadOptions, 'quill_editor_image');
  };

  const customFilepicker = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.onchange = function (ev) {
      //saveImageAsBlob(ev.target, cb);
      saveImageInServer(ev.target, (location, props) => {
        //editorRef.current.execCommand("mceInsertContent", false, "<img src='" + location + "' />");
        editorRef.current.insertContent(
          editorRef.current.dom.createHTML('img', {
            src: location,
            style: 'transform: rotate(0deg);',
          }),
        );
        onChange(editorRef.current.getContent());
      });
    };

    input.click();
  };

  const handleUpdate = (value, editor) => {
    setCurrentValue(value);
    onChange(value);
  };

  const initEditor = (evt, editor) => {
    editorRef.current = editor;
    getEditor(editor);
  };

  const getCurrentImageAngle = (imageNode) => {
    const styles = window.getComputedStyle(imageNode, null);
    const transformValue =
      styles.getPropertyValue('-webkit-transform') ||
      styles.getPropertyValue('-moz-transform') ||
      styles.getPropertyValue('-ms-transform') ||
      styles.getPropertyValue('-o-transform') ||
      styles.getPropertyValue('transform') ||
      '';
    let currentAngle = 0;
    if (transformValue !== 'none') {
      let values = transformValue.split('(')[1];
      values = values.split(')')[0];
      values = values.split(',');

      const a = values[0];
      const b = values[1];
      currentAngle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
    }
    return currentAngle;
  };

  const getImageProperties = (imageNode, rotateDirection) => {
    const width = imageNode.getAttribute('width').match(/\d+/)[0];
    const height = imageNode.getAttribute('height').match(/\d+/)[0];
    const imageProperties = {
      imageVerticalMargin: (width - height) / 2,
      imageHorizontalMargin: (height - width) / 2,
    };
    const currentAngle = getCurrentImageAngle(imageNode);
    const newAngle =
      rotateDirection === 'left' ? currentAngle + 90 : currentAngle - 90;
    return {
      ...imageProperties,
      angle: newAngle,
      isRotated: Math.abs(newAngle) === 90 || Math.abs(newAngle) === 270,
    };
  };

  return (
    <div>
      <Editor
        value={currentValue}
        tinymceScriptSrc="assets/tinymce/tinymce.min.js"
        onInit={initEditor}
        onEditorChange={handleUpdate}
        disabled={isDisabled}
        init={{
          height: 600,
          menubar: false,
          skin: 'oxide-iami',
          content_style:
            '.ql-align-right { text-align: right} ' +
            '.ql-align-center { text-align: center} ' +
            '.ql-align-left { text-align: left} ' +
            ' body { margin: 50px; } ',
          plugins: getPlugins(autoresize),
          valid_children: getValidChildren(),
          paste_preprocess: pastePreprocess,
          verify_html: false,
          toolbar:
            ' undo redo | bold italic underline subscript superscript forecolor backcolor |' +
            ' numlist bullist | alignment_group |' +
            '  table customImage | blocks strikethrough  blockquote pagebreak',
          toolbar_groups: {
            alignment_group: {
              icon: 'align-left',
              tooltip: 'alignment options',
              items: 'alignleft aligncenter alignright alignjustify',
            },
            font_style_group: {
              icon: 'format',
              tooltip: 'font styles',
              items: 'strikethrough subscript superscript blockquote',
            },
          },
          setup: (editor) => {
            editor.ui.registry.addButton('customImage', {
              icon: 'image',
              tooltip: 'Insert image',
              onAction: () => customFilepicker(),
            });
            editor.ui.registry.addIcon(
              'rotate-left',
              '<svg width="25" height="25" focusable="false"><path d="M4.7 10H9a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1V5a1 1 0 1 1 2 0v3l2.5-2.4a9.2 9.2 0 0 1 10.8-1.5A9 9 0 0 1 13.4 21c-2.4.1-4.7-.7-6.5-2.2a1 1 0 1 1 1.3-1.5 7.2 7.2 0 0 0 11.6-3.7 7 7 0 0 0-3.5-7.7A7.2 7.2 0 0 0 8 7L4.7 10Z" fill-rule="nonzero"></path></svg>',
            );
            editor.ui.registry.addIcon(
              'rotate-right',
              '<svg width="25" height="25" focusable="false"><path d="M20 8V5a1 1 0 0 1 2 0v6c0 .6-.4 1-1 1h-6a1 1 0 0 1 0-2h4.3L16 7A7.2 7.2 0 0 0 7.7 6a7 7 0 0 0 3 13.1c1.9.1 3.7-.5 5-1.7a1 1 0 0 1 1.4 1.5A9.2 9.2 0 0 1 2.2 14c-.9-3.9 1-8 4.5-9.9 3.5-1.9 8-1.3 10.8 1.5L20 8Z" fill-rule="nonzero"></path></svg>',
            );
            editor.ui.registry.addButton('rotateleft', {
              icon: 'rotate-left',
              tooltip: 'Rotate left',
              onAction: () => {
                const imageNode = editorRef.current.selection.getNode();
                const imageProps = getImageProperties(imageNode, 'right');
                editorRef.current.dom.setStyles(imageNode, {
                  transform: `rotate(${imageProps.angle}deg)`,
                  margin: `${
                    imageProps.isRotated ? imageProps.imageVerticalMargin : 0
                  }px ${
                    imageProps.isRotated ? imageProps.imageHorizontalMargin : 0
                  }px`,
                });
              },
            });
            editor.ui.registry.addButton('rotateright', {
              icon: 'rotate-right',
              tooltip: 'Rotate right',
              onAction: () => {
                const imageNode = editorRef.current.selection.getNode();
                const imageProps = getImageProperties(imageNode, 'left');
                editorRef.current.dom.setStyles(imageNode, {
                  transform: `rotate(${imageProps.angle}deg)`,
                  margin: `${
                    imageProps.isRotated ? imageProps.imageVerticalMargin : 0
                  }px ${
                    imageProps.isRotated ? imageProps.imageHorizontalMargin : 0
                  }px`,
                });
              },
            });
            editor.on('ObjectResized', (e) => {
              if (e.target.nodeName == 'IMG') {
                const currentAngle = getCurrentImageAngle(e.target);
                const isRotated =
                  Math.abs(currentAngle) === 90 ||
                  Math.abs(currentAngle) === 270;
                const imageVerticalMargin = (e.width - e.height) / 2;
                const imageHorizontalMargin = (e.height - e.width) / 2;
                console.log('imageVerticalMargin', imageVerticalMargin);
                editorRef.current.dom.setStyles(e.target, {
                  margin: `${isRotated ? imageVerticalMargin : 0}px ${
                    isRotated ? imageHorizontalMargin : 0
                  }px`,
                });
              }
            });
          },
          quickbars_image_toolbar: 'rotateleft rotateright',
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar: false,
          image_title: false,
          image_dimensions: false,
          image_caption: false,
          image_description: false,
          // image_uploadtab: true,
          // images_upload_handler: iamiUploadImageHanlder,
          //automatic_uploads: true,
          file_picker_types: 'image',
          nonbreaking_force_tab: true,
          paste_as_text: true
        }}
      />
    </div>
  );
};

TinyMCETextEditor.propTypes = propTypes;
TinyMCETextEditor.defaultProps = defaultProps;

export default TinyMCETextEditor;
