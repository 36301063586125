import React from 'react';
import type { FC } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface ImodalNotes {
  show: boolean;
  message: string;
  onClose: () => void;
}
const modalNotes: FC<ImodalNotes> = ({ show, message, onClose }) => {
  return (
    <Modal isOpen={show} toggle={onClose}>
      <ModalHeader>
        <button type="button" className="close" onClick={onClose}>
          ×
        </button>
        Note
      </ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter className="justify-content-center">
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default modalNotes;
