import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';

import ReactTable from '../../../../components/ReactTable/ReactTable';
import { AppState } from '../../../../store/store';

import NavigationExamCollection from '../../../../lib/common/models/navigationExamCollection';

import CancelledExamListPresenter from './cancelledExamListPresenter';
import { stateListNames } from 'store/reducers/listsReducer';
import { useTableControls,useClearListStateOnPageChange } from 'hooks';

import 'react-toastify/dist/ReactToastify.css';

const CancelledExamList: React.FC = () => {
  const history = useHistory();
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);

  const [exams, setExams] = useState<NavigationExamCollection | any>();
  const [status, setStatus] = useState<boolean>();

  const view = { history, layout, setStatus };
  const presenter = new CancelledExamListPresenter(loggedUser, view);

  const listName = stateListNames.cancelledExams;
  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  useClearListStateOnPageChange(listName, [
    "/cancelled_exam/list",
    "/closed_exam/detail",
  ]);
  
  useEffect(() => {
    presenter.getAllExams()
      .then((items) => {
        setExams(items);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [status]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{'Cancelled Exams'}</CardTitle>
              </CardHeader>
              <CardBody>
                {exams && (
                  <ReactTable
                    data={exams.navigationExams}
                    columns={presenter.getTableColumns()}
                    initialFilters={filters}
                    onChangeFilters={onChangeFilters}
                    initialSorting={sorting}
                    onChangeSorting={onChangeSorting}
                    initialPagination={pagination}
                    onChangePagination={onChangePagination}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </>
  );
};

export default CancelledExamList;
