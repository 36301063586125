import Syllabus from 'lib/common/models/syllabus';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IExamAnalytics } from 'types/analytics';
import useSyllabus from './useSyllabus';

const useExamMarks = () => {
  const [filterParams, setFilterParams] = useState<any>({});
  const [exams, setExams] = useState<IExamAnalytics[]>([]);
  const [originalExams, setOriginalExams] = useState<IExamAnalytics[]>([]);
  const [activeSyllabus, setActiveSyllabus] = useState('');
  const { syllabus } = useSyllabus();

  useEffect(() => {
    if (activeSyllabus !== '') {
      setExams(
        originalExams?.filter(
          (exam: any) => exam.syllabusCode === activeSyllabus,
        ),
      );
    }
  }, [activeSyllabus]);

  const onSelectSyllabus = useCallback((syllabu: Syllabus) => {
    setActiveSyllabus(syllabu.code);
  }, []);

  const onSetAndCleanExams = useCallback((listExams: IExamAnalytics[]) => {
    setExams(listExams);
    setOriginalExams(listExams);
    setActiveSyllabus('');
  }, []);

  const columns = useMemo(() => {
    return [
      {
        id: 'expander',
        Header: '',
        Cell: (row: any) => {
          return row.row.original.questions ? (
            <span {...row.row.getToggleRowExpandedProps()}>
              {row.row.isExpanded ? '▼' : '▶'}
            </span>
          ) : null;
        },
      },
      {
        Header: 'Exam',
        accessor: 'name',
        sortable: false,
      },
      {
        Header: 'Total avg. Mark',
        accessor: 'totalAvg',
        sortable: false,
        align: 'right',
        Cell: ({ cell }: any) => <span className="avg-cell">{cell.value}</span>,
      },
    ];
  }, []);

  return {
    columns,
    exams,
    setExams: onSetAndCleanExams,
    filterParams,
    setFilterParams,
    syllabus,
    onSelectSyllabus,
    activeSyllabus,
  };
};

export default useExamMarks;
