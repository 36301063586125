import React, { FC, useCallback, useMemo, useState } from 'react';
import QuestionPaymentApi from "api/QuestionPaymentApi";
import * as XLSX from 'xlsx';

import {
    DEFAULT_TIMEZONE_FORMAT,
    formatDateTime,
    sortDateTable,
} from '../../../../shared/utils/dateTime';
import {
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Card,
    Col,
    Table,
    Button,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import { toast, ToastContainer} from "react-toastify";
import ConfirmationModal from "../../confirmationModal/confirmationModal";

import {QuestionPaymentTypes} from "../../../../lib/common/models/questionPayment";
import {IQuestionPaymentReport} from "../../../../lib/common/reports/questionPaymentReport";
import ReactTable from "../../../../components/ReactTable/ReactTable";
import MarkerPaymentsDateFilteredTable
    from "../../filteredComponents/markerPaymentsDateFilteredTable/markerPaymentsDateFilteredTable";
import {QUESTION_PAYMENT_DETAIL_COLUMNS} from "./questionPaymentsDetails";

const enum DisplayedTableTypes {
    AWAITING_PAYMENT = 'AWAITING PAYMENT',
    GENERATED_PAYMENTS = 'GENERATED PAYMENTS',
}

interface QuestionPaymentsReportProps {
    loggedUser: any;
    data: IQuestionPaymentReport;
    refreshQuestionPayments: () => void;
}

const QuestionPayments: FC<QuestionPaymentsReportProps> = ({ loggedUser, data, refreshQuestionPayments }) => {
    const [releaseModal, setReleaseModal] = useState(false);
    const [tableDisplayName, setTableDisplayName] = useState<DisplayedTableTypes>(DisplayedTableTypes.AWAITING_PAYMENT);

    const onPaymentsRelease = () => {
        const questionPaymentIds: string[] = []

        mainTableData.map((user: any) => {
            user.questionPayments.map((payment: any) => {
                questionPaymentIds.push(payment.id);
            })
        });

        QuestionPaymentApi.releaseQuestionPayments(questionPaymentIds, loggedUser?.token).then(() => {
            generateExcel();
            refreshQuestionPayments();
        }).catch((error) => {
            console.error(error);
            toast.error("Failed to generate question payments");
        })

        setReleaseModal(false);
    }

    const generateExcel = () => {
        const paymentHeaders: any[] = columns.slice(1).map((column) => column.Header);

        const workbook = XLSX.utils.book_new();
        const excelTableData = mainTableData.map((user: any) => {
            return {
                name: user.name,
                writing: user.writing,
                moderation: user.moderation,
                total: user.total
            }
        });

        const totalRow = excelTableData.reduce(
            (total: any, user: any) => {
                total.writing += user.writing;
                total.moderation += user.moderation;
                total.total += user.total;
                return total;
            },
            { name: 'Total:', writing: 0, moderation: 0, total: 0 }
        )

        excelTableData.push(totalRow);

        const paymentsSheet = XLSX.utils.json_to_sheet(excelTableData);
        paymentsSheet['!cols'] = [{wch:25}, {wch:16}, {wch:16}, {wch:16}];
        XLSX.utils.sheet_add_aoa(paymentsSheet, [paymentHeaders]);
        XLSX.utils.book_append_sheet(workbook, paymentsSheet, 'Payments');

        let detailsTableData = mainTableData.map((user: any) => {
            return user.questionPayments.map((payment: any) => {
                return {
                    name: user.name,
                    question: payment.question,
                    type: QuestionPaymentTypes[payment.type],
                    syllabus: payment.syllabus ? payment.syllabus : '---',
                    module: payment.module ? payment.module : '---',
                    date: formatDateTime(payment.date, DEFAULT_TIMEZONE_FORMAT),
                }
            });
        });

        detailsTableData = detailsTableData.flat();
        const detailHeaders = ['USER NAME', 'PAYMENT TYPE', ...QUESTION_PAYMENT_DETAIL_COLUMNS]
        const detailsSheet = XLSX.utils.json_to_sheet(detailsTableData);
        detailsSheet['!cols'] = [{wch:25}, {wch:16}, {wch:25}, {wch:18}, {wch:10}, {wch:30}];
        XLSX.utils.sheet_add_aoa(detailsSheet, [detailHeaders]);
        XLSX.utils.book_append_sheet(workbook, detailsSheet, 'Details');
        XLSX.writeFile(workbook, `Question Payments ${new Date().toDateString()}.xlsx`);
    }

    const mainTableData = useMemo(() => {
        return Object.values(data).reduce((accumulator: any, current: any) => {
            let writing = 0, moderation = 0;

            current.questionPayments.map((payment: any) => {
                if (payment.type == QuestionPaymentTypes.WRITING) writing += 1;
                if (payment.type == QuestionPaymentTypes.MODERATION) moderation += 1;
            })

            accumulator.push({
                name: current.name,
                writing,
                moderation,
                total: writing + moderation,
                questionPayments: current.questionPayments
            })

            return accumulator;
        }, [])
    }, [data.length, data]);

    const columns = useMemo(() => {
        return [
            {
                id: 'expander',
                Header: () => null,
                Cell: (row: any) => {
                    return row.row.original.questionPayments ? (
                        <span {...row.row.getToggleRowExpandedProps()}>
              {row.row.isExpanded ? '▼' : '▶'}
            </span>
                    ) : null;
                },
                Footer: '▶',
            },
            {
                Header: 'USER',
                accessor: 'name',
                Footer: () => (
                    <>
                        <b>TOTAL</b>
                    </>
                ),
                sortable: true,
            },
            {
                Header: 'WRITING',
                accessor: 'writing',
                Footer: (info: any) => {
                    const total = useMemo(() => {
                        return info.rows.reduce(
                            (sum: number, row: any) => row.original.writing + sum,
                            0,
                        );
                    }, [info]);

                    return (
                        <>
                            <b>{total}</b>
                        </>
                    );
                },
                sortable: true,
            },
            {
                Header: 'MODERATION',
                accessor: 'moderation',
                Footer: (info: any) => {
                    const total = useMemo(() => {
                        return info.rows.reduce(
                            (sum: number, row: any) => row.original.moderation + sum,
                            0,
                        );
                    }, [info]);

                    return (
                        <>
                            <b>{total}</b>
                        </>
                    );
                },
                sortable: true,
            },
            {
                Header: 'TOTAL',
                accessor: 'total',
                Footer: (info: any) => {
                    const total = useMemo(() => {
                        return info.rows.reduce(
                            (sum: number, row: any) => row.original.total + sum,
                            0,
                        );
                    }, [info]);

                    return (
                        <>
                            <b>{total}</b>
                        </>
                    );
                },
                sortable: true,
            }
        ]
    }, []);

    const renderRowSubComponent = useCallback((row) => {
        return (
            <div className="table-full-width">
                <Table>
                    <thead>
                    <tr>
                        {QUESTION_PAYMENT_DETAIL_COLUMNS.map((columnName) => <th>{columnName}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {row.original.questionPayments.map((payment: any) => (
                        <tr key={payment.question}>
                            <td>{payment.question}</td>
                            <td>{payment.syllabus ? payment.syllabus : '---'}</td>
                            <td>{payment.module ? payment.module : '---'}</td>
                            <td>
                                {formatDateTime(payment.date, DEFAULT_TIMEZONE_FORMAT)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        )
    }, [data]);

    const generatedPaymentsColumns = useMemo(() => {
        return [
            { Header: 'QUESTION ID', accessor: 'question.name', sortable: true },
            {
                Header: 'SYLLABUS',
                accessor: (d: any) => (d.syllabus ? d.syllabus : '---'),
                sortable: true,
            },
            {
                Header: 'MODULE',
                accessor: (d: any) => (d.module ? d.module : '---'),
                sortable: true,
            },
            {
                Header: 'DATE',
                accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
                sortable: true,
                sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
            },
            {
                Header: 'TYPE',
                accessor: (d: any) => QuestionPaymentTypes[d.type],
                sortable: true,
            },
            {
                Header: 'PAYMENT DATE',
                accessor: (d: any) =>
                    formatDateTime(d.payment, DEFAULT_TIMEZONE_FORMAT),
                sortable: true,
                sortType: (a: any, b: any) => sortDateTable(a, b, 'payment'),
            },
            {
                Header: 'USER',
                accessor: (d: any) => d.user.name,
                sortable: true,
            },
        ]
    }, [])

    return (
        <>
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle tag="h4">Question Payments</CardTitle>
                        </Col>
                        <Col className="text-center text-md-right">
                            <Button
                                disabled={mainTableData.length < 1}
                                onClick={() => setReleaseModal(true)}
                                color="success"
                                className="btn-label">
                <span>
                  <i className="nc-icon nc-cloud-download-93" />
                </span>
                                Generate Payments
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <UncontrolledDropdown>
                                <DropdownToggle caret>{tableDisplayName}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        onClick={() => {
                                            refreshQuestionPayments();
                                            setTableDisplayName(
                                                DisplayedTableTypes.AWAITING_PAYMENT,
                                            );
                                        }}>
                                        {DisplayedTableTypes.AWAITING_PAYMENT}
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            setTableDisplayName(
                                                DisplayedTableTypes.GENERATED_PAYMENTS,
                                            );
                                        }}>
                                        {DisplayedTableTypes.GENERATED_PAYMENTS}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { tableDisplayName === DisplayedTableTypes.AWAITING_PAYMENT && (
                                <ReactTable
                                    data={mainTableData}
                                    columns={columns}
                                    topPaginationClassName="table-pagination_top"
                                    renderRowSubComponent={renderRowSubComponent}
                                    hasToggleRowColumn
                                    hasTotal
                                />
                            )}
                            { tableDisplayName === DisplayedTableTypes.GENERATED_PAYMENTS && (
                                <>
                                    <MarkerPaymentsDateFilteredTable columns={generatedPaymentsColumns} fetchMethod={QuestionPaymentApi.getGeneratedQuestionPayments}/>
                                </>
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            { releaseModal && (
                <ConfirmationModal
                    title="Generate payments"
                    onConfirm={() => onPaymentsRelease()}
                    onCancel={() => setReleaseModal(false)}
                    text="Are you sure you want to generate these payments?"
                />
            )}

            <ToastContainer />

        </>
    )
}

export default QuestionPayments;
