import ExamPayment from './examPayment';

class ExamPaymentCollection {
  payments: Array<ExamPayment>;

  constructor(payments: Array<ExamPayment>) {
    this.payments = payments;
  }

  length() {
    return this.payments.length;
  }
}

export default ExamPaymentCollection;
