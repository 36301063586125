import ApiCaller from "../../../lib/ApiCaller";
import MarkerApi from "../../../api/MarkerApi";

export interface SubmitMarkerView {
    dispatch: Function;
    onSubmitted: Function;
}

class SubmitMarkersPresenter {

    loggedUser: any;
    api:MarkerApi;
    loading: boolean;
    setLoading: (value: boolean) => void;

    constructor(profile: any, private view:SubmitMarkerView, setLoading: any) {
        this.loggedUser = profile;
        this.api = new MarkerApi(new ApiCaller());
        this.loading = false;
        this.setLoading = setLoading;
    }

    submit(payload: any, examId:string) {
        if (this.loading) return;
        this.loading=true;
        this.setLoading(true);
        this.api.submitMarks(payload, examId, this.loggedUser.token).then(() => {
            this.view.onSubmitted('ok');
            this.loading=false;
            this.setLoading(false);
        })
    }

    revission(payload: any, examId:string) {
        if (this.loading) return;
        this.loading=true;
        this.setLoading(true);
        this.api.submitRevissionMarks(payload, examId, this.loggedUser.token).then(() => {
            this.view.onSubmitted('ok');
            this.loading=false;
            this.setLoading(false);
        })
    }

}

export default SubmitMarkersPresenter;
