import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast, ToastContainer } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Form,
  Row,
  Col,
  Input,
} from 'reactstrap';

import Settings from 'lib/settings';
import { AppState } from 'store/store';
import ApiCaller from 'lib/ApiCaller';
import ExamResourceApi from 'api/ExamResourceApi';
import NZDocument, {
  NZDocumentType,
} from 'lib/common/models/nzDocument';
import ExamResource from 'lib/common/models/examResource';
import { DocumentDownloadContainer } from '../../../components/documents/ExamScriptUploader/compStyle';
import CustomDropZoneLayout from '../../../components/documents/FileUploaderDropZone';
import DocumentPreviewModal from 'views/components/documents/DocumentPreviewModal/DocumentPreviewModal';

import './style.css';
import DetailLayout from 'layouts/DetailLayout';
import QuestionApi from 'api/QuestionApi';

const ExamResourceDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [examResource, setExamResource] = useState<ExamResource>(
    new ExamResource({ name: '' }),
  );
  const [modalDocument, setModalDocument] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const history = useHistory();

  useEffect(() => {
    setExamResource(Settings.getCurrentExamResource());
  }, []);

  useEffect(() => {
    if (examResource) {
      setName(examResource.name);
      setDescription(examResource.description ? examResource.description : '');
    }
  }, [examResource]);

  const updateResource = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (examResource?.id) {
        const examResourceApi = new ExamResourceApi(new ApiCaller());
        examResourceApi
          .updateResource(
            {
              id: examResource.id,
              name,
              description,
              resource: examResource.resource?.id,
            },
            loggedUser.token,
          )
          .then((data) => {
            setExamResource(data);
            Settings.setCurrentExamResource(data);
            toast.success('Updated successfully');
          })
          .catch((err) => {
            toast.error('Update failed.');
            console.log(err);
          });
      }
    },
    [examResource, name, description, loggedUser],
  );

  const cancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleSubmitUploading = (data: NZDocument) => {
    setExamResource(
      ExamResource.prototype.load({ ...examResource, resource: data }),
    );
  };

  const removeFileResource = () => {
    const token = loggedUser.token;
    const questionApi = new QuestionApi(new ApiCaller(token));
    if (examResource?.resource?.id) {
      questionApi
        .removeQuestionImage(examResource?.resource?.fileHash)
        .then(() => {
          setExamResource(
            ExamResource.prototype.load({ ...examResource, resource: null }),
          );
          toast.success('File of resource was deleted successfully');
        })
        .catch((err) => {
          toast.error('Update failed.');
          console.error(err);
        });
    }
  };

  const renderExamResource = () => {
    return (
      <>
        <CustomDropZoneLayout
          onFinish={handleSubmitUploading}
          type={NZDocumentType.EXAM_RESOURCE}
          id={examResource.resource?.id}
        />
        {examResource.resource && (
          <DocumentDownloadContainer
            className="resource-download-container"
            onClick={(evt: any) => {
              evt.preventDefault();
              if (evt.target.className.includes('resource-remove')) {
                removeFileResource();
              } else {
                setModalDocument(!modalDocument);
              }
            }}>
            <p>{examResource.resource?.displayName}</p>
            <Button
              variant="danger"
              title="Remove file of resource"
              size="sm"
              className="resource-remove">
              <i className="nc-icon resource-remove nc-simple-remove" />
            </Button>
          </DocumentDownloadContainer>
        )}
      </>
    );
  };

  return (
    <>
      <div className="content">
        <DetailLayout title="Go back">
          <Card>
            <CardHeader>
              <h4 className="card-title">Exam Resource Detail</h4>
            </CardHeader>
            <CardBody>
              <Form className="form-horizontal">
                <Row>
                  <Label sm="2">Resource Name</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        value={name}
                        type="text"
                        name="name"
                        disabled={!loggedUser.isAdmin()}
                        onChange={(e: any) => setName(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Resource Description</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        value={description}
                        type="text"
                        name="description"
                        disabled={!loggedUser.isAdmin()}
                        onChange={(e: any) => setDescription(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Exam Resource</Label>
                  <Col sm="10">
                    <FormGroup>{renderExamResource()}</FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2" />
                  <Col sm="10">
                    <Button
                      className="btn-round"
                      color="info"
                      onClick={updateResource}>
                      Save
                    </Button>
                    <Button
                      className="btn-round btn btn-danger"
                      onClick={cancel}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </DetailLayout>
        {examResource.resource && (
          <DocumentPreviewModal
            isOpen={modalDocument}
            onClose={() => setModalDocument(false)}
            document={examResource.resource}
            title="Exam Resource"
          />
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default ExamResourceDetail;
