import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import AuthApi from '../../../api/AuthApi';
import ApiCaller from '../../../lib/ApiCaller';

import bgImg from '../../../assets/img/bg/yacht-background.png'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row, CardTitle
} from "reactstrap";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import { generateErrors, is } from "../../../shared/utils/validations";
import ReactBSAlert from "react-bootstrap-sweetalert";

const ResetPassword:React.FC= () =>  {
    const inputs = ['email'];
    const history = useHistory();
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState("");

    useEffect(() => {
        document.body.classList.toggle("register-page");

        var timer: ReturnType<typeof setTimeout>;
        document.addEventListener('focusout', function(e:any) {
            if(e.target && inputs.indexOf(e.target.name) !== -1) {
                let content = document.getElementById('register-page');
                if(content) {
                    timer = setTimeout(() => {
                        // @ts-ignore
                        content.scrollToTop();
                    }, 1);
                }
            }

        });

        document.addEventListener('focusin', function(e:any) {
            if(e.target && inputs.indexOf(e.target.name) !== -1) {
                // @ts-ignore
                clearTimeout(timer);
            }
        });

        return ( () => {
            document.body.classList.toggle("register-page");
        });
    },[]);

    const doReset = (e: React.FormEvent) => {
        e.preventDefault();

        const email = (document.getElementById('email') as any)?.value

        const errors:any = generateErrors(
            { email },
            { email: [is.required(), is.email()] });

        if (errors.email) {
            toast.error(errors.email);
        }
        
        if (Object.keys(errors).length !== 0) {
            return;
        }

        const authApi = new AuthApi(new ApiCaller());
        authApi.resetPassword(email).then((data: any) => {
            if (data.status === "ok") {
                setSuccess(true);
            } else {
                setError(data.status);
            }
        }).catch(err => {
            console.error(err);
            setError(err.message);
        });

        e.preventDefault();
    }

    const goToLogin = () => {
        history.push("/");
    }

    return (<>
            <AuthNavbar />
            <div className="wrapper wrapper-full-page">
                <div className="full-page section-image">
                    <div className="register-page">
                        <Container>
                            <Row>
                                <Col className="mr-auto" lg="4" md="6">
                                    <Card className="card-signup text-center">
                                        <CardHeader>
                                            <CardTitle tag="h4">Reset your password</CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                                <Form action="" className="form" method="">
                                                    <InputGroup>
                                                        <InputGroup addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="nc-icon nc-single-02" />
                                                            </InputGroupText>
                                                        </InputGroup>
                                                        <Input 
                                                            id="email" 
                                                            placeholder="Enter your email address..." 
                                                            type="text" 
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    doReset(e);
                                                                }
                                                            }}
                                                        />
                                                    </InputGroup>
                                                </Form>
                                            { success &&
                                            <ReactBSAlert
                                                success
                                                style={{ display: "block", marginTop: "-100px" }}
                                                title="Email successfully sent!"
                                                onConfirm={() => goToLogin()}
                                                onCancel={() => goToLogin()}
                                                confirmBtnBsStyle="success"
                                                btnSize=""
                                                confirmBtnText="Go To Login"
                                            >
                                                An email was sent to your account with the link to reset the password.
                                            </ReactBSAlert>
                                            }
                                            {error && (
                                                <ReactBSAlert
                                                    danger
                                                    style={{ display: "block", marginTop: "-100px" }}
                                                    title="Error sending recovery email"
                                                    onConfirm={() => setError("")}
                                                    onCancel={() => setError("")}
                                                    confirmBtnBsStyle="danger"
                                                    btnSize=""
                                                    confirmBtnText="Close"
                                                >
                                                    {error}
                                                </ReactBSAlert>
                                            )}
                                        </CardBody>
                                        <CardFooter>
                                            { !success &&
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    onClick={(e) => doReset(e)}
                                                >
                                                    Reset Password
                                                </Button>
                                            }
                                            { success &&
                                                <Button
                                                    className="btn-round"
                                                    color="info"
                                                    onClick={() => goToLogin()}
                                                >
                                                    Go to Login
                                                </Button>
                                            }
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                        <div
                            className="full-page-background"
                            style={{ backgroundImage: `url(${bgImg})`, }}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    );

}

export default ResetPassword;
