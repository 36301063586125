/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
//import PerfectScrollbar from "perfect-scrollbar";

import logo from "assets/img/iami.png";

import { useSelector } from "react-redux";
import {AppState} from "../../store/store";

interface ContainerProps {
    props: any,
    sidebar: any
    routes: any,
    bgColor: string,
    activeColor: string
}

const SidebarAdmin:React.FC<ContainerProps> = ({ props, sidebar, routes, bgColor, activeColor}) => {
    const [state, setState] = React.useState({})
    const [avatarState, setAvatarState] = React.useState({openAvatar: true,});

    // const dispatch = useDispatch();
    const layout = useSelector((state: AppState) => state.session.layout);
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const userAvatar = useSelector((state: AppState) => state.session.avatar);

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    const getCollapseStates = (routes: any) => {
        let initialState = {};
        routes.map((prop: any, key: any) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: getCollapseInitialState(prop.views),
                    ...getCollapseStates(prop.views),
                    ...initialState,
                };
            }
            return null;
        });
        return initialState;
    };

    useEffect(() => {
        setState(getCollapseStates(routes));
    }, []);

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
    const getCollapseInitialState = (routes: any) => {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }
    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes: any) => {
        return routes.map((prop: any, key: any) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !state[prop.state];
                return (
                    <li className={getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
                        <a data-toggle="collapse" aria-expanded={state[prop.state]} onClick={(e) => {e.preventDefault();setState(st);}}>
                            {prop.icon !== undefined ? (
                                <>
                                    <i className={prop.icon} />
                                    <p>{prop.name}<b className="caret" /></p>
                                </>
                            ) : (
                                <>
                                    <span className="sidebar-mini-icon">{prop.mini}</span>
                                    <span className="sidebar-normal">{prop.name} <b className="caret" /></span>
                                </>
                            )}
                        </a>
                        <Collapse isOpen={state[prop.state]}>
                            <ul className="nav">{createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }
            return (
                <li className={activeRoute(`/${layout}` + prop.path)} key={key}>
                    <NavLink to={`/${layout}`+ prop.path} activeClassName="">
                        {prop.icon !== undefined ? (
                            <>
                                <i className={prop.icon} />
                                <p>{prop.name}</p>
                            </>
                        ) : (
                            <>
                                <span className="sidebar-mini-icon">{prop.mini}</span>
                                <span className="sidebar-normal">{prop.name}</span>
                            </>
                        )}
                    </NavLink>
                </li>
            );
        });
    };
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName: any) => {
        return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    return (
        <div className="sidebar" data-color={bgColor} data-active-color={activeColor}>
            <div className="logo">
                { !avatarState.openAvatar &&
                    // <a href="https://iami.info" className="simple-text logo-mini">
                    //     <div className="logo-img"><img src={logo} alt="react-logo" /></div>
                    // </a>
                <a href="https://iami.info" className="simple-text logo-normal">
                    <div><img src={logo} alt="react-logo" /></div>
                </a>
                }
                { avatarState.openAvatar &&
                    <a href="https://iami.info" className="simple-text logo-normal">
                        <div><img src={logo} alt="react-logo" /></div>
                    </a>
                }
            </div>

            {/*<div className="sidebar-wrapper" ref="sidebar">*/}
            <div className="sidebar-wrapper">
                <div className="user">
                    <div className="photo">
                        <img src={userAvatar} alt="Avatar" />
                    </div>
                    <div className="info">
                        <a data-toggle="collapse" aria-expanded={avatarState.openAvatar} onClick={() => setAvatarState({ openAvatar: !avatarState.openAvatar })}>
                          <span>{ loggedUser.name }<b className="caret" /></span>
                        </a>
                        <Collapse isOpen={avatarState.openAvatar}>
                            <ul className="nav">
                                <li>
                                    <NavLink to={`/${layout}/user-profile`} activeClassName="">
                                        {/*<span className="sidebar-mini-icon">&nbsp;	</span>*/}
                                        <i className="nc-icon nc-single-02" />
                                        <span className="sidebar-normal">Profile</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </Collapse>
                    </div>
                </div>

                <Nav>{createLinks(routes)}</Nav>
            </div>
        </div>

    );
}

export default SidebarAdmin;
