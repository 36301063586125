import { TrendingUpTwoTone } from '@material-ui/icons';
import ApiCaller from '../lib/ApiCaller';

class NotificationApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  deleteMessage(messageId: string): Promise<void> {
    return this.apiCaller
      .call('/v1/notify/' + messageId, 'DELETE', {
        id: messageId,
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  sendMessage(
    messageType: string,
    fromEmail: string,
    emailList: string[],
    message: string,
    token?: string,
  ): Promise<boolean> {
    return this.apiCaller
      .call(
        '/v1/notify/msgUsers',
        'POST',
        {
          messageType,
          fromEmail,
          emailList,
          message,
        },
        token,
      )
      .then((response) => {
        return response.success;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  async sendCertificates(
    certificateList: string[],
    token?: string,
  ): Promise<boolean> {
    return this.apiCaller
      .call(
        '/v1/notify/certificate',
        'POST',
        {
          certificateList,
        },
        token,
      )
      .then((response) => {
        return response.success;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }
}

export default NotificationApi;
