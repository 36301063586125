import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 2px;
`;
export const RowTitle = styled(Row)`
  padding: 3px;
  margin-bottom: 3px;
  background: #f5f5f5;
`;

export const Label = styled.div`
  display: flex;
  flex: 1;
`;
export const Title = styled.div`
  display: flex;
  flex: 1;
  font-weight: bold;
`;
