import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';

import TextArea from 'antd/lib/input/TextArea';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import QuestionApi from 'api/QuestionApi';
import ReactTable from 'components/ReactTable/ReactTable';
import ApiCaller from 'lib/ApiCaller';
import QuestionCollection from 'lib/common/models/questionCollection';
import { AppState } from 'store/store';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';

import Question, {
  QuestionActions,
  QuestionStatusLabels,
} from 'lib/common/models/question';
import Syllabus from 'lib/common/models/syllabus';
import { UserTypesLabels } from 'lib/common/models/user';
import Settings from 'lib/settings';

import ModerationForm from './ModerationForm';
import { getCustomUserColumns } from './questionListCustomColumns';
import { getMessages } from './questionListMessages';
import QuestionListPresenter from './questionListPresenter';
import { typeStatus } from './questionTypeStatuses';

import {
  useClearListStateOnPageChange,
  useSyllabus,
  useTableControls,
} from 'hooks';
import { updateListSyllabus } from 'store/actions/lists/listsActions';
import { ListLabels } from './listTypes';

import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';
import QuestionMoveModal from './questionMoveModal';

interface IQuestionListProps {
  listType: string;
}

const QuestionList: React.FC<IQuestionListProps> = ({ listType }) => {
  const listName = listType;

  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const syllabus = useSelector(
    (state: AppState) => state.lists[listName].syllabus,
  );
  const [questions, updateQuestions] = useState<QuestionCollection>(
    new QuestionCollection([]),
  );
  const [selectedQuestions, updateSelectedQuestions] =
    useState<QuestionCollection>(new QuestionCollection([]));
  const { syllabus: syllabuses } = useSyllabus();

  const history = useHistory();
  const [modal, setModal] = useState<boolean>();
  const [actionModel, setActionModel] = useState<{
    message: string;
    action: string;
    current: string;
  }>({ message: '', action: '', current: '' });

  const [status, setStatus] = useState<boolean>();
  const [comments, setComment] = useState('');
  const [withForm, setWithForm] = useState<boolean>(false);
  const [withComments, setWithComments] = useState<boolean>(true);
  const [isDeletionModalOpened, setIsDeletionModalOpened] =
    useState<boolean>(false);
  const [isArchivingModalOpened, setIsArchivingModalOpened] =
    useState<boolean>(false);
  const [isMovingModalOpened, setIsMovingModalOpened] =
    useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<Question | undefined>(
    undefined,
  );
  const [loading, setloading] = useState(false);
  const questionApi = new QuestionApi(new ApiCaller());

  const dispatch = useDispatch();

  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  const onPrepareAction = (
    action: string,
    current: string,
    status?: string,
    syllabusCode?: string,
  ) => {
    const message = getMessages(
      loggedUser.type,
      (action as 'ACCEPT') || 'REVOKE',
      syllabusCode || '',
    );

    setWithForm(
      loggedUser.type === UserTypesLabels.questionModerator &&
        action === QuestionActions.ACCEPT,
    );
    setWithComments(status !== QuestionStatusLabels.IN_MODIFICATION);
    setActionModel({ message, action, current });
    setModal(true);
  };

  const view = {
    onPrepareAction: onPrepareAction,
    history: history,
    setStatus: setStatus,
  };
  const presenter = new QuestionListPresenter(loggedUser, view, listType);

  const chooseQuestion = useCallback(
    (cell: any) => {
      const question = cell.row.original;
      Settings.setCurrentAdminQuestion(question);
      const layout =
        loggedUser.type === 'admin'
          ? 'navozyme'
          : loggedUser.type.toLowerCase();
      view.history.push(`/${layout}/question/detail`);
    },
    [loggedUser, view.history],
  );

  const actionCustomFloatingMenu = useCallback(
    (row: any) => {
      const { status, id, syllabus } = row.row.original;
      const activatedAccept =
        typeStatus[loggedUser.type][QuestionActions.ACCEPT].includes(status);
      const activatedRevoke =
        typeStatus[loggedUser.type][QuestionActions.REVOKE].includes(status);
      return (
        <>
          {activatedAccept && (
            <Dropdown.Item
              onClick={() =>
                view.onPrepareAction(
                  QuestionActions.ACCEPT,
                  id,
                  status,
                  syllabus.code,
                )
              }>
              <i className="nc-icon nc-air-baloon" />
              &nbsp;&nbsp;ACCEPT
            </Dropdown.Item>
          )}
          {activatedRevoke && (
            <Dropdown.Item
              onClick={() =>
                view.onPrepareAction(
                  QuestionActions.REVOKE,
                  id,
                  undefined,
                  syllabus.code,
                )
              }>
              <i className="nc-icon nc-simple-delete" />
              &nbsp;&nbsp;REVOKE
            </Dropdown.Item>
          )}
        </>
      );
    },
    [loggedUser.type, view],
  );

  const getColumns = useMemo(() => {
    return [
      { Header: 'QUESTION ID', accessor: 'name', sortable: true },
      ...getCustomUserColumns(loggedUser),
      {
        Header: 'ACTIONS',
        Cell: (row: any) => {
          return (
            <DropdownButton variant="default" title="Actions" size="sm">
              <Dropdown.Item onClick={() => chooseQuestion(row)}>
                <i className="nc-icon nc-settings" />
                &nbsp;&nbsp;DETAILS
              </Dropdown.Item>
              {loggedUser.hasApprovePermission(row.row.original) &&
                actionCustomFloatingMenu(row)}
              {loggedUser.hasMoveQuestionPermission() &&
                listName === ListLabels.questionBank && (
                  <Dropdown.Item
                    onClick={() => {
                      setCurrentQuestion(row.row.original);
                      setIsMovingModalOpened(true);
                    }}>
                    <i className="nc-icon nc-refresh-69" />
                    &nbsp;&nbsp;MOVE
                  </Dropdown.Item>
                )}
              {loggedUser.hasArchiveQuestionPermission() &&
                listName !== ListLabels.archivedQuestions && (
                  <Dropdown.Item
                    onClick={() => {
                      setCurrentQuestion(row.row.original);
                      setIsArchivingModalOpened(true);
                    }}>
                    <i className="nc-icon nc-simple-delete" />
                    &nbsp;&nbsp;ARCHIVE
                  </Dropdown.Item>
                )}
              {loggedUser.isAdmin() &&
                listName !== ListLabels.archivedQuestions && (
                  <Dropdown.Item
                    onClick={() => {
                      setCurrentQuestion(row.row.original);
                      setIsDeletionModalOpened(true);
                    }}>
                    <i className="nc-icon nc-simple-delete" />
                    &nbsp;&nbsp;DELETE
                  </Dropdown.Item>
                )}
            </DropdownButton>
          );
        },
      },
    ];
  }, [loggedUser]);

  const onSyllabusChange = (selectedSyllabus: any) => {
    const updated = filterQuestions(questions, selectedSyllabus);
    updateSelectedQuestions(new QuestionCollection(updated));

    dispatch(updateListSyllabus(selectedSyllabus, listName));
  };

  const filterQuestions = useCallback(
    (questions: QuestionCollection, selectedSyllabus: Syllabus | undefined) => {
      return selectedSyllabus?._id
        ? questions.questions.filter(
            (q: any) => q.syllabus?._id === selectedSyllabus?._id,
          )
        : questions.questions;
    },
    [],
  );

  useClearListStateOnPageChange(listName, [
    '/question/list',
    '/question/detail',
    '/question/bank',
    '/question/archived',
    '/question/create',
  ]);

  useEffect(() => {
    presenter.getMyQuestions().then((q) => {
      updateQuestions(q);
      const filteredQuestions = filterQuestions(q, syllabus);

      updateSelectedQuestions(new QuestionCollection(filteredQuestions));
    });
  }, [isDeletionModalOpened, isArchivingModalOpened, status]);

  const onApproveQuestion = () => {
    if (loading) return;
    setloading(true);
    presenter
      .triggerAction(actionModel.action, actionModel.current, comments)
      .then(() => {
        setloading(false);
        setComment('');
        closeModal();
      });
  };

  const closeModal = () => {
    if (loading) return;
    setModal(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <Row>
                    <Col>{presenter.table.getName(listType)}</Col>
                  </Row>
                </CardTitle>
              </CardHeader>
              {loggedUser.hasAdminRole() && (
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="syllabus"
                      isClearable={true}
                      onChange={onSyllabusChange}
                      options={syllabuses}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a syllabus"
                      value={syllabus}
                    />
                  </FormGroup>
                </Col>
              )}
              <CardBody>
                {(loggedUser.isType(UserTypesLabels.questionWriter) ||
                  loggedUser.hasAdminRole()) &&
                  listType !== ListLabels.questionBank &&
                  listType !== ListLabels.archivedQuestions && (
                    <Row>
                      <Col>
                        <Link
                          to={`/${layout}/question/create`}
                          className="btn-label">
                          <Button color="info">
                            <span>
                              <i className="nc-icon nc-simple-add" />
                            </span>
                            Create
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  )}
                {(!loggedUser.hasAdminRole() || syllabus) && (
                  <ReactTable
                    data={selectedQuestions.questions}
                    columns={getColumns}
                    initialFilters={filters}
                    onChangeFilters={onChangeFilters}
                    initialSorting={sorting}
                    onChangeSorting={onChangeSorting}
                    initialPagination={pagination}
                    onChangePagination={onChangePagination}
                  />
                )}
                {loggedUser.hasAdminRole() && !syllabus && (
                  <h5 className="card-category">
                    Please, select a syllabus to view the information.
                  </h5>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ToastContainer />

        <Modal isOpen={modal} size={withForm ? 'xl' : ''} scrollable={true}>
          <ModalHeader>
            <button
              type="button"
              className="close"
              onClick={() => closeModal()}>
              ×
            </button>
            {actionModel.message}
          </ModalHeader>
          <ModalBody>
            <Form className="form-vertical">
              {withForm && <ModerationForm />}
              {withComments && (
                <>
                  <Row>
                    <Label sm="3">Comments</Label>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <TextArea
                          value={comments}
                          name="comments"
                          onChange={(e: any) => setComment(e.target.value)}
                          autoSize={{ minRows: 8, maxRows: 20 }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="justify-content-center">
                <LoadingSpinner spinning={loading}>
                  <Button color="primary" onClick={() => onApproveQuestion()}>
                    Yes
                  </Button>
                  <Button color="danger" onClick={() => closeModal()}>
                    No
                  </Button>
                </LoadingSpinner>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        {isDeletionModalOpened && (
          <ConfirmationModal
            title="Deletion confirmation"
            onConfirm={() => {
              if (currentQuestion?.id) {
                questionApi
                  .deleteQuestion(currentQuestion.id, loggedUser.token)
                  .then(() => {
                    toast.success('Question has been deleted');
                  });
              }
              setIsDeletionModalOpened(false);
            }}
            onCancel={() => setIsDeletionModalOpened(false)}
            text="Are you sure you want to delete this question?"
          />
        )}
        {isArchivingModalOpened && (
          <ConfirmationModal
            title="Archiving confirmation"
            onConfirm={() => {
              if (currentQuestion?.id) {
                questionApi
                  .archiveQuestion(currentQuestion.id, loggedUser.token)
                  .then(() => {
                    toast.success('Question has been archived');
                  });
              }
              setIsArchivingModalOpened(false);
            }}
            onCancel={() => setIsArchivingModalOpened(false)}
            text="Are you sure you want to archive this question?"
          />
        )}
        {isMovingModalOpened && currentQuestion && (
          <QuestionMoveModal
            initialQuestion={currentQuestion}
            onClose={() => setIsMovingModalOpened(false)}
          />
        )}
      </div>
    </>
  );
};

export default QuestionList;
