interface ExamStatusLogInfo {
  _id?: string;
  id?: string;
  status: string;
  displayName: string;
  examId?: string;
  createdBy?: string;
  timestamp: Date;
}

class ExamStatusLog {
  id?: string;
  status: string;
  displayName: string;
  examId?: string;
  createdBy?: string;
  timestamp: Date;

  constructor(examStatusLogInfo: ExamStatusLogInfo) {
    this.id = examStatusLogInfo.id ?? examStatusLogInfo._id;
    this.status = examStatusLogInfo.status;
    this.displayName = examStatusLogInfo.displayName;
    this.examId = examStatusLogInfo.examId;
    this.createdBy = examStatusLogInfo.createdBy;
    this.timestamp = examStatusLogInfo.timestamp;
  }

  fromDataModel(doc: any): ExamStatusLog {
    doc.createdBy = doc.createdBy
      ? `${doc.createdBy.name ?? ""} (${doc.createdBy.email ?? ""})`
      : undefined;
    return new ExamStatusLog(doc);
  }
}

export default ExamStatusLog;
