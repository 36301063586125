import { APP_STATUS } from 'types/app';
import { SET_SYLLABUS, SET_STATUS_SYLLABUS } from '../actionTypes';

export const setSyllabus = (list: any) => {
  return {
    type: SET_SYLLABUS,
    payload: list,
  };
};

export const setSyllabusStatus = (status: APP_STATUS) => {
  return {
    type: SET_STATUS_SYLLABUS,
    payload: status,
  };
};
