import React from 'react';

import {
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Card,
    Col,
} from 'reactstrap';

import ExamCenterDateFilteredTable from "../../filteredComponents/examCenterDateFilteredTable/examCenterDateFilteredTable";
import TreasurerDashboardApi from "../../../../api/TreasurerDashboardApi";
import {
    DEFAULT_TIMEZONE_FORMAT,
    formatDateTime,
    sortCurrencyTable,
    sortDateTable
} from "../../../../shared/utils/dateTime";
import { formatCurrency } from "../../../../shared/utils/currency";
import { defaultCurrency } from "../../../../lib/common/models/transaction";

const PaymentsReport: React.FC = () => {
    const columns = [
        { Header: 'EXAM CENTRE', accessor: 'examCentre', sortable: true },
        {
            Header: 'DATE',
            accessor: (d: any) => formatDateTime(d.timestamp, DEFAULT_TIMEZONE_FORMAT),
            sortable: true,
            sortType: (a: any, b: any) => sortDateTable(a, b, 'timestamp')
        },
        { Header: 'REFERENCE', accessor: 'reference', sortable: true },
        {
            Header: 'PAYPAL',
            accessor: (d: any) => formatCurrency(d.paypal, defaultCurrency),
            sortable: true,
            sortType: (a: any, b: any) => sortCurrencyTable(a, b, 'paypal')
        },
        {
            Header: 'BANK',
            accessor: (d: any) => formatCurrency(d.bank, defaultCurrency),
            sortable: true,
            sortType: (a: any, b: any) => sortCurrencyTable(a, b, 'bank')
        },
    ];

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle tag="h4">Payments Report</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <ExamCenterDateFilteredTable
                                columns={columns}
                                fetchMethod={TreasurerDashboardApi.getPaymentsReport}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default PaymentsReport;
