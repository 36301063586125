import Exam from '../../../lib/common/models/exam';
import MYDocument from '../../../lib/common/models/myDocument';
import NZDocument from '../../../lib/common/models/nzDocument';

import {
  GET_DRAFTS_LIST,
  GET_SIGNED_LIST,
  SET_CURRENT_DOC,
  SET_CURRENT_EXAM,
} from '../actionTypes';

export type DocsActionTypes =
  | SetCurrentDocAction
  | GetDraftsAction
  | GetSignedAction
  | SetCurrentExamAction;

interface SetCurrentExamAction {
  type: typeof SET_CURRENT_EXAM;
  payload: Exam;
}
interface SetCurrentDocAction {
  type: typeof SET_CURRENT_DOC;
  payload: MYDocument;
}

interface GetDraftsAction {
  type: typeof GET_DRAFTS_LIST;
  payload: MYDocument[];
}

interface GetSignedAction {
  type: typeof GET_SIGNED_LIST;
  payload: NZDocument[];
}

export function setCurrentExam(doc: Exam): DocsActionTypes {
  return {
    type: SET_CURRENT_EXAM,
    payload: doc,
  };
}
export function setCurrentDoc(doc: MYDocument): DocsActionTypes {
  return {
    type: SET_CURRENT_DOC,
    payload: doc,
  };
}
export function setCurrentDrafts(drafts: MYDocument[]): DocsActionTypes {
  return {
    type: GET_DRAFTS_LIST,
    payload: drafts,
  };
}

export function setCurrentSignedDocuments(
  drafts: NZDocument[],
): DocsActionTypes {
  return {
    type: GET_SIGNED_LIST,
    payload: drafts,
  };
}
