import User from './user';

export enum MarkerPaymentTypes {
    FIRST_MARKING = 'FIRST_MARKING',
    SECOND_MARKING = 'SECOND_MARKING',
}

export const MarkerPaymentTypesLabels = {
    [MarkerPaymentTypes.FIRST_MARKING]: 'First marking',
    [MarkerPaymentTypes.SECOND_MARKING]: 'Second marking',
}

export enum MarkerPaymentStatus {
  GENERATED = 'GENERATED',
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
}

interface MarkerPaymentInfo {
    _id?: string,
    id?: string,
    examCentreName: string;
    examNumber: string;
    syllabus?: string;
    module?: string;
    date: Date;
    type: MarkerPaymentTypes;
    numCandidates: number;
    numScripts: number;
    payment?: Date;
    marker: any;
}

class MarkerPayment {
    id?: string;
    examCentreName: string;
    examNumber: string;
    syllabus?: string;
    module?: string;
    date: Date;
    type: MarkerPaymentTypes;
    numCandidates: number;
    numScripts: number;
    payment?: Date;
    marker: any;

    constructor(markerPaymentInfo: MarkerPaymentInfo) {
        this.id = markerPaymentInfo.id ? markerPaymentInfo.id : markerPaymentInfo._id;
        this.examCentreName = markerPaymentInfo.examCentreName;
        this.examNumber = markerPaymentInfo.examNumber;
        this.syllabus = markerPaymentInfo.syllabus;
        this.module = markerPaymentInfo.module;
        this.date = markerPaymentInfo.date;
        this.type = markerPaymentInfo.type;
        this.numCandidates = markerPaymentInfo.numCandidates;
        this.numScripts = markerPaymentInfo.numScripts;
        this.payment = markerPaymentInfo.payment;
        this.marker = markerPaymentInfo.marker;
    }

    fromDataModel(data: any): MarkerPayment {
        data.marker = new User(data.marker);
        return new MarkerPayment(data);
      }
}

export default MarkerPayment;
