/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import QuestionUserList from '../../views/pages/admin/question/questionUserList';
import QuestionDetail from '../../views/pages/admin/question/questionDetail';
import QuestionBankList from '../../views/pages/admin/question/questionBankList';
import ArchivedQuestionsList from '../../views/pages/admin/question/archivedQuestionsList';
import NavigationExamDetail from '../../views/pages/admin/navigation_exam/navigationExamDetail';
import NavigationExamList from '../../views/pages/admin/navigation_exam/navigationExamList';
import ExamCenterFinanceDetail from '../../views/pages/admin/examCenter/examCenterFinanceDetail';
import ExamEditor from '../../views/pages/ExamEditor';
import NavigationExamDraftList from '../../views/pages/admin/navigation_exam_draft/navigationExamDraft';
import CancelledExamList from '../../views/pages/admin/cancelled_exam/cancelledExamList';
import ExamMark from '../../views/pages/ExamMark';
import ExamResourcesList from '../../views/pages/admin/exam_resources/examResourceList';
import ExamResourceCreate from '../../views/pages/admin/exam_resources/examResourceCreate';
import ExamResourceDetail from '../../views/pages/admin/exam_resources/examResourceDetail';
import NavigationExamClone from '../../views/pages/admin/navigation_exam/navigationExamClone';
import ExamDocumentArchive from '../../views/pages/admin/examDocument/examDocumentArchive';
import CandidateList from '../../views/pages/admin/candidate/candidateList';
import CandidateWithReportList from '../../views/pages/admin/candidate/candidateWithReportList';
import CandidateDetail from '../../views/pages/admin/candidate/candidateDetail';
import ExamCenterList from '../../views/pages/admin/examCenter/examCenterList';
import ExamCenterCreate from '../../views/pages/admin/examCenter/examCenterCreate';
import ExamCenterDetail from '../../views/pages/admin/examCenter/examCenterDetail';
import NavigationExamPending from '../../views/pages/admin/navigation_exam/navigationExamPending';
import QuestionCreate from '../../views/pages/admin/question/questionCreate';
import CompletedExamList from '../../views/pages/admin/completed_exam/completedExamList';
import NavigationExamResults from '../../views/pages/admin/completed_exam/navigationExamResults';
import UserList from '../../views/pages/admin/user/userList';
import UserCreate from '../../views/pages/admin/user/userCreate';
import UserDetail from '../../views/pages/admin/user/userDetail';
import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';
import ClosedExamList from '../../views/pages/admin/closed_exam/closedExamList';
import ClosedExamDetail from '../../views/pages/admin/closed_exam/closedExamDetail';
import ClosedExamResults from '../../views/pages/admin/closed_exam/closedExamResults';
import ExamAdminDashboard from '../../views/pages/questionusers/ExamAdminDashboard';
import QuestionPipeline from '../../views/pages/admin/question/questionPipeline';
import CalendarExamList from '../../views/pages/admin/calendar/ExamList';
import ExamAnalytics from '../../views/pages/admin/exam_analytics/ExamAnalytics';

export const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    component: ExamAdminDashboard,
  },
  {
    collapse: true,
    name: 'Questions',
    icon: 'nc-icon nc-book-bookmark',
    state: 'questionCollapse',
    views: [
      {
        path: '/question/list',
        name: 'Question List',
        mini: 'QL',
        component: QuestionUserList,
      },
      {
        path: '/question/bank',
        name: 'Question Bank',
        mini: 'QB',
        component: QuestionBankList,
      },
      {
        path: '/exam_resource/list',
        name: 'Exam Resources',
        mini: 'ER',
        component: ExamResourcesList,
      },
      {
        path: '/question/archived',
        name: 'Archived Questions',
        mini: 'QA',
        component: ArchivedQuestionsList,
      },
      {
        path: '/question/pipeline',
        name: 'Question Pipeline',
        mini: 'QP',
        component: QuestionPipeline,
      },
    ],
  },
  {
    collapse: true,
    name: 'Exams',
    icon: 'nc-icon nc-book-bookmark',
    state: 'navigationCollapse',
    views: [
      {
        path: '/navigation_exam/list',
        name: 'Exam Requests',
        mini: 'EL',
        component: NavigationExamList,
      },
      {
        path: '/completed_exam/list',
        name: 'Results Declared',
        mini: 'ER',
        component: CompletedExamList,
      },
      {
        path: '/navigation_exam/generated/list',
        name: 'Exam Drafts',
        mini: 'ED',
        component: NavigationExamDraftList,
      },
      {
        path: '/examDocument/archive/list',
        name: 'Exam Archive',
        mini: 'EA',
        component: ExamDocumentArchive,
      },
      {
        path: '/cancelled_exam/list',
        name: 'Cancelled exams',
        mini: 'EC',
        component: CancelledExamList,
      },
      {
        path: '/closed_exam/list',
        name: 'Closed Exams',
        mini: 'CE',
        component: ClosedExamList,
      },
    ],
  },
  {
    path: '/exams/calendar',
    name: 'Exam Calendar',
    icon: 'nc-icon nc-calendar-60',
    component: CalendarExamList,
  },
  {
    collapse: true,
    name: 'Marks',
    icon: 'nc-icon nc-book-bookmark',
    state: 'marksCollapse',
    views: [
      {
        path: '/navigation_exam/marked',
        name: 'Pending Exams',
        mini: 'ES',
        component: NavigationExamPending,
      },
    ],
  },
  {
    path: '/examCenter/list',
    name: 'Exam Centers',
    icon: 'nc-icon nc-shop',
    component: ExamCenterList,
  },
  {
    collapse: true,
    name: 'Candidates',
    icon: 'nc-icon nc-single-02',
    state: 'candidatesCollapse',
    views: [
      {
        path: '/candidate/list',
        name: 'Candidates list',
        mini: 'CL',
        component: CandidateList,
      },
      {
        path: '/candidateWithReports/list',
        name: 'Pending Approval',
        mini: 'CR',
        component: CandidateWithReportList,
      },
    ],
  },
  {
    path: '/user/list',
    name: 'Users',
    icon: 'nc-icon nc-user-run',
    component: UserList,
  },
];

export const hiddenRoutes = [
  {
    path: '/navigation_exam/markers',
    name: 'Exam Requests',
    mini: 'EL',
    component: NavigationExamList,
  },
  {
    path: '/question/create',
    name: 'Create Question',
    mini: 'CC',
    component: QuestionCreate,
  },
  {
    path: '/exam_resource/create',
    name: 'Create Exam Resource',
    mini: 'CR',
    component: ExamResourceCreate,
  },
  {
    path: '/exam_resource/detail',
    name: 'Exam Resource Detail',
    mini: 'RD',
    component: ExamResourceDetail,
  },
  {
    path: '/question/detail',
    name: 'Question Detail',
    mini: 'QD',
    component: QuestionDetail,
  },
  {
    path: '/navigation_exam/detail',
    name: 'Exam Detail',
    mini: 'ED',
    component: NavigationExamDetail,
  },
  {
    path: '/navigation_exam/clone',
    name: 'Exam Clone',
    mini: 'EC',
    component: NavigationExamClone,
  },
  {
    path: '/closed_exam/detail',
    name: 'Closed Exam Detail',
    mini: 'ED',
    component: ClosedExamDetail,
  },
  {
    path: '/closed_exam/results',
    name: 'Closed Exam Results',
    mini: 'ER',
    component: ClosedExamResults,
  },
  {
    path: '/navigation_exam/edit',
    name: 'Exam Editor',
    mini: 'EE',
    component: ExamEditor,
  },
  {
    path: '/candidate/detail',
    name: 'Candidate Detail',
    mini: 'CD',
    component: CandidateDetail,
  },
  {
    path: '/examCenter/detail',
    name: 'Exam Center Detail',
    mini: 'ED',
    component: ExamCenterDetail,
  },
  {
    path: '/examCenter/create',
    name: 'Exam Center Create',
    mini: 'EC',
    component: ExamCenterCreate,
  },
  {
    path: '/examCenter/transactions',
    name: 'Exam Center Detail',
    mini: 'ED',
    component: ExamCenterFinanceDetail,
  },
  {
    path: '/navigation_exam/results',
    name: 'Exam Results',
    mini: 'ER',
    component: NavigationExamResults,
  },
  {
    path: '/user/create',
    name: 'User Create',
    mini: 'UC',
    component: UserCreate,
  },
  {
    path: '/user/detail',
    name: 'User Detail',
    mini: 'UD',
    component: UserDetail,
  },
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
  {
    path: '/navigation_exam/marks',
    name: 'Current Marks',
    mini: 'EM',
    component: ExamMark,
  },
];
