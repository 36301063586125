import Candidate from "./candidate";

class CandidateCollection {
    candidates: Array<Candidate>;

    constructor(candidates: Array<Candidate>) {
        this.candidates = candidates;
    }

    length() {
        return this.candidates.length;
    }
}

export default CandidateCollection;
