import QuestionUserList from '../../views/pages/admin/question/questionUserList';
import QuestionCreate from '../../views/pages/admin/question/questionCreate';
import QuestionDetail from '../../views/pages/admin/question/questionDetail';
import QuestionBankList from '../../views/pages/admin/question/questionBankList';
import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';
import QuestionPipeline from '../../views/pages/admin/question/questionPipeline';

export const routes = [
  {
    collapse: true,
    name: 'Questions',
    icon: 'nc-icon nc-book-bookmark',
    state: 'questionCollapse',
    views: [
      {
        path: '/question/list',
        name: 'Question List',
        mini: 'QL',
        component: QuestionUserList,
      },
      {
        path: '/question/bank',
        name: 'Question Bank',
        mini: 'QB',
        component: QuestionBankList,
      },
      {
        path: '/question/pipeline',
        name: 'Question Pipeline',
        mini: 'QP',
        component: QuestionPipeline,
      },
    ],
  },
];

export const hiddenRoutes = [
  {
    path: '/question/create',
    name: 'Create Question',
    mini: 'CC',
    component: QuestionCreate,
  },
  {
    path: '/question/detail',
    name: 'Question Detail',
    mini: 'CD',
    component: QuestionDetail,
  },
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
];
