import Module from 'lib/common/models/module';
import ApiCaller from '../lib/ApiCaller';
import {ModuleCollection, Syllabus,} from '../lib/common/models/syllabusDataModels';

class ModuleApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getAllModules(syllabusId?: any): Promise<void | ModuleCollection> {
    return this.apiCaller
      .call('/v1/syllabus/' + syllabusId + '/module', 'GET')
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      }
      );
  }

  addModule(
    code: string,
    title: string,
    syllabus: any,
    token?: string,
  ): Promise<void | Module> {
    return this.apiCaller
      .call(
        '/v1/syllabus/' + syllabus._id + '/module',
        'POST',
        {
          code: code,
          title: title,
          syllabus: syllabus._id,
        },
        token,
      )
      .then((data) => {
        return new Module({
          ...data,
          syllabus: new Syllabus(data),
        });
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      }
      );
  }


  async updateModule(module: Module, token?: string): Promise<void | Syllabus> {
    try {
      const data = await this.apiCaller.call('/v1/syllabus/module/' + module._id, 'PUT', {
        code: module.code,
        title: module.title,
      }, token);

      return new Module(data);
    } catch (err) {
      console.error(err);
    }
  }

  async deleteModule(moduleId: string, token?: string): Promise<void> {
    try {
      return this.apiCaller.call(
        '/v1/syllabus/module/' + moduleId,
        'DELETE',
        {
          id: moduleId,
        },
        token,
      );
    } catch (err) {
      console.error(err);
      alert('Error deleting Module, please try again');
    }
  }
}

export default ModuleApi;
