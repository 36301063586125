import Question from "./question";

interface ExamPaymentInfo {
    _id?: string;
    id?: string;
    examNumber: string;
    syllabus: any;
    examDate: Date;
    scripts: number;
    payment?: Date;
}

class ExamPayment {
    id?: string;
    examNumber: string;
    syllabus: any;
    examDate: Date;
    scripts: number;
    payment?: Date;

    constructor(examPaymentInfo: ExamPaymentInfo) {
        this.id = examPaymentInfo.id ? examPaymentInfo.id : examPaymentInfo._id;
        this.examNumber = examPaymentInfo.examNumber;
        this.syllabus = examPaymentInfo.syllabus;
        this.examDate = examPaymentInfo.examDate;
        this.scripts = examPaymentInfo.scripts;
        this.payment = examPaymentInfo.payment;
    }

    fromDataModel(doc: any): ExamPayment {
        doc.syllabus = doc.syllabus ? Question.prototype.toDTO(doc.syllabus) : null;
        return new ExamPayment(doc)
    }
}

export default ExamPayment;
