 interface TopicInfo {
  code: string,
  title: string,
  marks: number,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  _id?:string;
}
class Topic {
  code: string;
  title: string;
  marks: number;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  _id?:string;

  constructor(TopicInfo: TopicInfo) {
    this.code = TopicInfo.code;
    this.title = TopicInfo.title;
    this.marks = TopicInfo.marks;
    this.createdAt = TopicInfo.createdAt;
    this.createdBy = TopicInfo.createdBy;
    this.updatedAt = TopicInfo.updatedAt;
    this.updatedBy = TopicInfo.updatedBy;
    this._id = TopicInfo._id;
  }

  load(data: any): Topic {
    return new Topic(data);
  }

  toObject(): any {
    return {
      code: this.code,
      title: this.title,
      marks: this.marks,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      updatedAt: this.updatedAt,
      _id: this._id,
    };
  }
}

export default Topic;
