import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Col,
  Row,
} from 'reactstrap';

import { AppState } from 'store/store';
import ApiCaller from 'lib/ApiCaller';
import TransactionApi from 'api/TransactionApi';
import Transaction, {
  ManualPaymentMethod,
} from 'lib/common/models/transaction';

import CurrencyInput from '../../../components/input/CurrencyInput';

import 'react-toastify/dist/ReactToastify.css';
import { useExamCenter } from 'hooks';

const TransactionCreate: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [transaction, setTransaction] = useState<Transaction>(
    Transaction.prototype.emptyTransaction(loggedUser),
  );
  const { examCenters: examCentres } = useExamCenter();
  const [amount, setAmount] = useState('');

  const history = useHistory();
  const onUserChange = (e: any) => {
    const info = transaction?.toObject();
    if (info) {
      info['entityId'] = e;
      setTransaction(new Transaction(info));
    }
  };

  const onSelectChange = (e: any) => {
    const info = transaction?.toObject();

    if (info) {
      info['paymentMethod'] = e.value;
      setTransaction(new Transaction(info));
    }
  };

  const handleChange = (e: any) => {
    setTransaction(
      new Transaction({
        ...transaction,
        [e.target.name]: e.target.value,
      }),
    );
  };

  const handleAmountChange = (e: any) => {
    setAmount(e);
  };

  const handleCurrencyChange = (e: any) => {
    setTransaction(
      new Transaction({
        ...transaction,
        ['amount']: e,
      }),
    );
  };

  const createTransaction = () => {
    const transactionApi = new TransactionApi(new ApiCaller());
    transactionApi
      .createTransaction(transaction, loggedUser.token)
      .then(() => {
        toast.success('Transaction created successfully');
        close();
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error creating transaction, please try again');
      });
  };

  const close = () => {
    // history.push(`/${layout}/payments/history`);
    history.goBack();
  };

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <h4 className="card-title">Add Transaction</h4>
          </CardHeader>
          <CardBody>
            <Form className="form-horizontal">
              <Row>
                <Label sm="2">Exam Centre</Label>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="entityId"
                      value={transaction?.entityId}
                      onChange={onUserChange}
                      options={examCentres.centers}
                      getOptionLabel={(item) => `[${item.code}] (${item.name})`}
                      getOptionValue={(item) => `[${item.code}] (${item.name})`}
                      placeholder="Choose an Exam Centre"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Amount</Label>
                <Col sm="10">
                  <FormGroup>
                    <CurrencyInput
                      input={amount}
                      setInput={handleAmountChange}
                      setDecimal={handleCurrencyChange}
                      currency={transaction.currency}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Description</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={transaction.description}
                      onChange={handleChange}
                      type="text"
                      name="description"
                      placeholder="Description/Details"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Reference</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={transaction.reference}
                      onChange={handleChange}
                      type="text"
                      name="reference"
                      placeholder="Reference"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Payment Method</Label>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="status"
                      value={{
                        label: ManualPaymentMethod[transaction?.paymentMethod],
                        value: transaction?.paymentMethod,
                      }}
                      onChange={onSelectChange}
                      options={Object.keys(ManualPaymentMethod).map(function (
                        method,
                      ) {
                        return {
                          value: method,
                          label: ManualPaymentMethod[method],
                        };
                      })}
                      placeholder="Payment method"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2" />
                <Col sm="10">
                  <Button
                    className="btn-round"
                    color="info"
                    onClick={createTransaction}>
                    Create
                  </Button>
                  <Button className="btn-round btn btn-danger" onClick={close}>
                    Close
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default TransactionCreate;
