import React, { useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';

import PayPalComponent from '../../../../components/payment/paypalComponent';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import CurrencyInput from '../../../../components/input/CurrencyInput';
import Transaction, {
  defaultCurrency,
} from '../../../../../lib/common/models/transaction';

interface IComponentProps {
  setModal: Function;
  setRefresh: Function;
}

const AddCredits: React.FC<IComponentProps> = ({ setModal, setRefresh }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState('');
  const [cancel, setCancel] = useState(false);
  const [transaction, setTransaction] = useState(
    Transaction.prototype.emptyTransaction(),
  );
  const [amount, setAmount] = useState('');
  const [decimal, setDecimal] = useState();

  const handleAmountChange = (e: any) => {
    setAmount(e);
  };

  const handleDecimalChange = (e: any) => {
    setDecimal(e);
    // parseFloat()
  };

  const getAmountValue = () => {
    return decimal;
  };

  const close = () => {
    setModal(false);
    setRefresh(true);
  };

  return (
    <>
      {!succeeded && !error && !cancel && (
        <>
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={close}>
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="card-title">Add credit</h4>
          </div>
          <div className="modal-body">
            {/*<StripeComponent/>*/}
            <Row>
              <Col>
                <FormGroup>
                  <CurrencyInput
                    input={amount}
                    setInput={handleAmountChange}
                    setDecimal={handleDecimalChange}
                    currency={defaultCurrency}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <PayPalComponent
                  onSuccess={setSucceeded}
                  onFailure={setError}
                  onCancel={setCancel}
                  transaction={transaction}
                  setTransaction={setTransaction}
                  amount={decimal}
                  getAmount={getAmountValue}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
      {succeeded && (
        <ReactBSAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Payment succeeded"
          onConfirm={() => close()}
          onCancel={() => close()}
          confirmBtnBsStyle="success"
          btnSize="">
          Credits added to account. Transaction ID: {transaction}
        </ReactBSAlert>
      )}
      {error && (
        <ReactBSAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error processing payment"
          onConfirm={() => close()}
          onCancel={() => close()}
          confirmBtnBsStyle="danger"
          btnSize="">
          {error}
        </ReactBSAlert>
      )}
      {cancel && (
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Payment Cancelled"
          onConfirm={() => close()}
          onCancel={() => close()}
          confirmBtnBsStyle="warning"
          btnSize="">
          Payment transaction has been cancelled.
        </ReactBSAlert>
      )}
    </>
  );
};

export default AddCredits;
