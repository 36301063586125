import React, { FC, useCallback, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import {
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Card,
  Col,
  Table,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';

import ReactTable from 'components/ReactTable/ReactTable';
import { IMarkerPaymentReport } from '../../../../../lib/common/reports/markerPaymentReport';
import {
  MarkerPaymentTypes,
  MarkerPaymentTypesLabels,
} from '../../../../../lib/common/models/markerPayment';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../../shared/utils/dateTime';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';
import MarkerPaymentApi from 'api/MarkerPaymentApi';
import MarkerPaymentsDateFilteredTable from 'views/components/filteredComponents/markerPaymentsDateFilteredTable/markerPaymentsDateFilteredTable';
import { MARKER_PAYMENT_DETAIL_COLUMNS } from './markerPaymentsDetails';

const enum DisplayedTableTypes {
  AWAITING_PAYMENT_MARKERS = 'AWAITING PAYMENT MARKERS',
  GENERATED_PAYMENTS = 'GENERATED PAYMENTS',
}

interface MarkerPaymentsReportProps {
  data: IMarkerPaymentReport;
  loggedUser: any;
  updateMarkerPayments: () => void;
}

const MarkerPaymentsReport: FC<MarkerPaymentsReportProps> = ({
  data,
  loggedUser,
  updateMarkerPayments,
}) => {
  const [releaseModal, setReleaseModal] = useState(false);
  const [displayedTableType, setDisplayedTableType] =
    useState<DisplayedTableTypes>(DisplayedTableTypes.AWAITING_PAYMENT_MARKERS);

  const mainTableData = useMemo(() => {
    return Object.values(data).reduce((previous: any, current: any) => {
      let first = 0,
        second = 0;
      current.markerPayments.map((payment: any) => {
        if (payment.type === MarkerPaymentTypes.FIRST_MARKING) {
          first += payment.numScripts;
        } else if (payment.type === MarkerPaymentTypes.SECOND_MARKING) {
          second += payment.numScripts;
        }
      });
      previous.push({
        name: current.name,
        first,
        second,
        total: first + second,
        markerPayments: current.markerPayments,
      });
      return previous;
    }, []);
  }, [data.length, data]);

  const columns = useMemo(() => {
    return [
      {
        id: 'expander',
        Header: () => null,
        Cell: (row: any) => {
          return row.row.original.markerPayments ? (
            <span {...row.row.getToggleRowExpandedProps()}>
              {row.row.isExpanded ? '▼' : '▶'}
            </span>
          ) : null;
        },
        Footer: '▶',
      },
      {
        Header: 'MARKER NAME',
        accessor: 'name',
        Footer: () => (
          <>
            <b>TOTAL</b>
          </>
        ),
        sortable: true,
      },
      {
        Header: 'FIRST MARKING',
        accessor: 'first',
        Footer: (info: any) => {
          const total = useMemo(() => {
            return info.rows.reduce(
              (sum: number, row: any) => row.original.first + sum,
              0,
            );
          }, [info]);

          return (
            <>
              <b>{total}</b>
            </>
          );
        },
        sortable: true,
      },
      {
        Header: 'SECOND MARKING',
        accessor: 'second',
        Footer: (info: any) => {
          const total = useMemo(() => {
            return info.rows.reduce(
              (sum: number, row: any) => row.original.second + sum,
              0,
            );
          }, [info]);

          return (
            <>
              <b>{total}</b>
            </>
          );
        },
        sortable: true,
      },
      {
        Header: 'TOTAL',
        accessor: 'total',
        Footer: (info: any) => {
          const total = useMemo(() => {
            return info.rows.reduce(
              (sum: number, row: any) => row.original.total + sum,
              0,
            );
          }, [info]);

          return (
            <>
              <b>{total}</b>
            </>
          );
        },
        sortable: true,
      },
    ];
  }, []);

  const renderRowSubComponent = useCallback(
    (row) => {
      return (
        <div className="table-full-width">
          <Table>
            <thead>
              <tr>
                {MARKER_PAYMENT_DETAIL_COLUMNS.map((columnName) => <th>{columnName}</th>)}
              </tr>
            </thead>
            <tbody>
              {row.original.markerPayments.map((payment: any) => (
                <tr key={payment.examNumber}>
                  <td>{payment.examCentreName}</td>
                  <td>{payment.examNumber}</td>
                  <td>{payment.syllabus ? payment.syllabus : '---'}</td>
                  <td>{payment.module ? payment.module : '---'}</td>
                  <td>
                    {formatDateTime(payment.date, DEFAULT_TIMEZONE_FORMAT)}
                  </td>
                  <td>{payment.numCandidates}</td>
                  <td>{payment.numScripts}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    },
    [data],
  );

  const onPaymentsRelease = () => {
    const markerPaymentsId: string[] = [];
    //@ts-ignore
    mainTableData.map((marker: any) => {
      marker.markerPayments.map((payment: any) =>
        markerPaymentsId.push(payment.id),
      );
    });
    MarkerPaymentApi.releaseMarkerPayments(markerPaymentsId, loggedUser?.token)
      .then(() => {
        const paymentsHeaders: any[] = [];
        const excelColumns = columns.slice(1);
        excelColumns.map((column) => paymentsHeaders.push(column.Header));
        const wb = XLSX.utils.book_new();
        //@ts-ignore
        const excelTableData = mainTableData.map((marker) => {
          return {
            name: marker.name,
            first: marker.first,
            second: marker.second,
            total: marker.total,
          };
        });
        const totalRow = excelTableData.reduce(
          (total: any, marker: any) => {
            total.first += marker.first;
            total.second += marker.second;
            total.total += marker.total;
            return total;
          },
          { name: 'Total:', first: 0, second: 0, total: 0 },
        );
        excelTableData.push(totalRow);
        const paymentsSheet = XLSX.utils.json_to_sheet(excelTableData);
        paymentsSheet['!cols'] = [{wch:25}, {wch:16}, {wch:16}, {wch:16}];
        XLSX.utils.sheet_add_aoa(paymentsSheet, [paymentsHeaders]);
        XLSX.utils.book_append_sheet(wb, paymentsSheet, 'Payments');

        //@ts-ignore
        let detailsTableData = mainTableData.map((marker) => {
          return marker.markerPayments.map((payment: any) => {
            return {
              name: marker.name,
              typeOfMarking: MarkerPaymentTypesLabels[payment.type],
              examCentre: payment.examCentreName,
              examNumber: payment.examNumber,
              syllabus: payment.syllabus ? payment.syllabus : '---',
              module: payment.module ? payment.module : '---',
              date: formatDateTime(payment.date, DEFAULT_TIMEZONE_FORMAT),
              candidates: payment.numCandidates,
              scripts: payment.numScripts
            }
          })
        });
        detailsTableData = detailsTableData.flat();
        const detailsHeaders = ['MARKER NAME', 'TYPE OF MARKING', ...MARKER_PAYMENT_DETAIL_COLUMNS];
        const detailsSheet = XLSX.utils.json_to_sheet(detailsTableData);
        detailsSheet['!cols'] = [{wch:25}, {wch:16}, {wch:25}, {wch:18}, {wch:10}, {wch:30}, {wch:25}, {wch:12}, {wch:10}];
        XLSX.utils.sheet_add_aoa(detailsSheet, [detailsHeaders]);
        XLSX.utils.book_append_sheet(wb, detailsSheet, 'Details');

        XLSX.writeFile(wb, `Marker Payments ${new Date().toDateString()}.xlsx`);
        updateMarkerPayments();
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to generate payments');
      });
    setReleaseModal(false);
  };

  const generatedPaymentsColumns = useMemo(() => {
    return [
      { Header: 'EXAM CENTRE', accessor: 'examCentreName', sortable: true },
      { Header: 'EXAM NUMBER', accessor: 'examNumber', sortable: true },
      {
        Header: 'SYLLABUS',
        accessor: (d: any) => (d.syllabus ? d.syllabus : '---'),
        sortable: true,
      },
      {
        Header: 'MODULE',
        accessor: (d: any) => (d.module ? d.module : '---'),
        sortable: true,
      },
      {
        Header: 'EXAM DATE',
        accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
      },
      {
        Header: 'TYPE',
        accessor: (d: any) => MarkerPaymentTypesLabels[d.type],
        sortable: true,
      },
      { Header: 'CANDIDATES', accessor: 'numCandidates', sortable: true },
      { Header: 'SCRIPTS', accessor: 'numScripts', sortable: true },
      {
        Header: 'PAYMENT DATE',
        accessor: (d: any) =>
          formatDateTime(d.payment, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'payment'),
      },
      {
        Header: 'MARKER NAME',
        accessor: (d: any) => d.marker.name,
        sortable: true,
      },
    ];
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag="h4">Marker Payments</CardTitle>
            </Col>
            <Col className="text-center text-md-right">
              <Button
                disabled={mainTableData.length < 2}
                onClick={() => setReleaseModal(true)}
                color="success"
                className="btn-label">
                <span>
                  <i className="nc-icon nc-cloud-download-93" />
                </span>
                Generate Payments
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <UncontrolledDropdown>
                <DropdownToggle caret>{displayedTableType}</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      updateMarkerPayments();
                      setDisplayedTableType(
                        DisplayedTableTypes.AWAITING_PAYMENT_MARKERS,
                      );
                    }}>
                    {DisplayedTableTypes.AWAITING_PAYMENT_MARKERS}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setDisplayedTableType(
                        DisplayedTableTypes.GENERATED_PAYMENTS,
                      );
                    }}>
                    {DisplayedTableTypes.GENERATED_PAYMENTS}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
          <Row>
            <Col>
              {displayedTableType ===
                DisplayedTableTypes.AWAITING_PAYMENT_MARKERS && (
                <ReactTable
                  data={mainTableData}
                  columns={columns}
                  topPaginationClassName="table-pagination_top"
                  renderRowSubComponent={renderRowSubComponent}
                  hasToggleRowColumn
                  hasTotal
                />
              )}
              {displayedTableType ===
                DisplayedTableTypes.GENERATED_PAYMENTS && (
                <MarkerPaymentsDateFilteredTable
                  fetchMethod={MarkerPaymentApi.getGeneratedMarkerPayments}
                  columns={generatedPaymentsColumns}
                />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ToastContainer />
      {releaseModal && (
        <ConfirmationModal
          title="Generate payments confirmation"
          onConfirm={() => onPaymentsRelease()}
          onCancel={() => setReleaseModal(false)}
          text="Are you sure you want to generate these payments?"
        />
      )}
    </>
  );
};

export default MarkerPaymentsReport;
