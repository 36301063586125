import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import moment from 'moment';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  Input,
} from 'reactstrap';

import ApiCaller from '../../../../lib/ApiCaller';
import ReactTable from '../../../../components/ReactTable/ReactTable';
import { AppState } from '../../../../store/store';
import CandidateApi from '../../../../api/CandidateApi';
import DocumentsApi from 'api/DocumentsApi';
import { downloadDocument } from 'shared/utils/ApiCommands';

import Candidate from 'lib/common/models/candidate';
import NZDocument, { NZDocumentStatus } from 'lib/common/models/nzDocument';
import CandidateWithNZDocument from 'lib/common/models/candidateWithNZDocument';
import CandidateWithNZDocumentCollection from 'lib/common/models/candidateWithNZDocumentCollection';
import { DocumentDownloadContainer } from 'views/components/documents/ExamScriptUploader/compStyle';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';

import 'react-toastify/dist/ReactToastify.css';

const CandidateWithReportList: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const candidateApi = new CandidateApi(new ApiCaller());
  const documentApi = new DocumentsApi(new ApiCaller());

  const [candidates, updateCandidates] =
    useState<CandidateWithNZDocumentCollection>(
      new CandidateWithNZDocumentCollection([]),
    );
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [currentDocId, setCurrentDocId] = useState<string>('');
  const [updatedReport, setUpdatedReport] = useState<NZDocument>();
  const [approvalComment, setApprovalComment] = useState<string>('');
  const [rejectionComment, setRejectionComment] = useState<string>('');
  const [reportIdToReject, setReportIdToReject] = useState<string>('');

  useEffect(() => {
    candidateApi
      .getAllCandidatesWithPendingReports(loggedUser.token)
      .then((candidates: any) => {
        if (candidates && Object.keys(candidates).length > 0) {
          updateCandidates(candidates);
        }
      });
  }, [loggedUser, updatedReport]);

  const openRejectModal = (docId: string) => {
    setReportIdToReject(docId);
    setIsRejectModalOpen(true);
  };

  const closeRejectModal = () => {
    setIsRejectModalOpen(false);
    setRejectionComment('');
  };

  const approveDiagnosisReport = useCallback(
    () => {
      setIsConfirmationModalOpen(false);
      documentApi
        .updateDiagnosisReportStatus(
          NZDocumentStatus.ACTIVE,
          currentDocId,
          loggedUser.token,
          approvalComment,
        )
        .then((report) => {
          setUpdatedReport(report);
          toast.success('Diagnosis report approved');
        });
    },
    [loggedUser, approvalComment, currentDocId],
  );

  const closeConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const rejectDiagnosisReport = useCallback(() => {
    documentApi
        .updateDiagnosisReportStatus(
          NZDocumentStatus.REVOKED,
          reportIdToReject,
          loggedUser.token,
          rejectionComment
        )
        .then((report) => {
          setUpdatedReport(report);
          toast.success('Diagnosis report rejected');
        });
    closeRejectModal();
  }, [loggedUser, rejectionComment, reportIdToReject]);


  const columns = [
    {
      Header: 'SERIAL NO.',
      accessor: (d: CandidateWithNZDocument) =>
        d.candidate.serialNumber ? d.candidate.serialNumber : '-',
      sortable: true,
    },
    {
      Header: 'NAME',
      accessor: (d: CandidateWithNZDocument) => {
        const candidate: Candidate = new Candidate(d.candidate);
        return candidate.fullName();
      },
      sortable: true,
    },
    {
      Header: 'BIRTH DATE',
      accessor: (d: CandidateWithNZDocument) =>
        moment(d.candidate.birthDate).utc().format('DD-MM-YYYY'),
    },
    { Header: 'EMAIL', accessor: 'candidate.email', sortable: true },
    {
      Header: 'LEARNING DIFF.',
      accessor: (d: CandidateWithNZDocument) =>
        d.candidate.special ? 'Yes' : 'No',
      sortable: true,
    },
    {
      Header: 'DIAGNOSIS REPORT',
      accessor: (d: CandidateWithNZDocument) => {
        const doc = d.nzDocument;
        return <DocumentDownloadContainer
          onClick={() => downloadDocument(
            doc.fileHash,
            doc.displayName,
            loggedUser.token
          )}>
          <p>{doc.displayName}</p>
        </DocumentDownloadContainer>
      },
      sortable: true,
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => {
        const doc = row.row.original.nzDocument;
        return (
          <>
          { loggedUser.hasMedicalReportsApprovalPermissions() &&
          <DropdownButton variant="default" title="Actions" size="sm">
              <Dropdown.Item onClick={() => {
                  setCurrentDocId(doc.id);
                  setIsConfirmationModalOpen(true);
                }
              }>
              <i className="nc-icon nc-check-2" />
              &nbsp;&nbsp;APPROVE
            </Dropdown.Item>
            <Dropdown.Item onClick={() => {
              openRejectModal(doc.id)
            }}>
              <i className="nc-icon nc-simple-remove" />
              &nbsp;&nbsp;REJECT
            </Dropdown.Item>
          </DropdownButton>
          }
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Pending Reports</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable data={candidates?.candidates} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {isRejectModalOpen && (
          <ConfirmationModal
            title="Diagnosis report rejection"
            onConfirm={rejectDiagnosisReport}
            onCancel={closeRejectModal}
            text="Are you sure you want to reject this report?"
          >
            <Col>
              <Input
                value={rejectionComment}
                className="formControl"
                type="textarea"
                name="comment"
                placeholder="Write a comment"
                rows="5"
                onChange={(e) => setRejectionComment(e.target.value)}
              />
            </Col>
          </ConfirmationModal>)
        }
        {isConfirmationModalOpen && (
          <ConfirmationModal
            title="Approving confirmation"
            text="Are you sure you want to approve the report?"
            onConfirm={approveDiagnosisReport}
            onCancel={closeConfirmationModal}
          >
            <Col>
              <Input
                value={approvalComment}
                className="formControl"
                type="textarea"
                name="comment"
                placeholder="Write a comment"
                rows="5"
                onChange={(e) => setApprovalComment(e.target.value)}
              />
            </Col>
          </ConfirmationModal>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default CandidateWithReportList;
