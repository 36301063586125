import React, { useState, useMemo, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import {
  Calendar as ReactBigCalendar,
  momentLocalizer,
  Views,
} from 'react-big-calendar';
import moment from 'moment';
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Spinner,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import NavigationExam from 'lib/common/models/navigationExam';
import NavigationExamApi from 'api/NavigationExamApi';
import { AppState } from 'store/store';
import ApiCaller from 'lib/ApiCaller';
import CustomToolbar from './CustomToolbar';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filters from './Filters';
import { eventStyleGetter } from './helper';
import EventDetail from './EventDetail';
import type { IEvent } from './EventDetail';

const localizer = momentLocalizer(moment);

const ExamList: FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const requestExams = new NavigationExamApi(new ApiCaller(loggedUser.token));
  const [events, setEvents] = useState<NavigationExam[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<NavigationExam[]>([]);
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState<IEvent | null>(null);
  const [filter, setFilter] = useState({
    status: 'CANCELLED',
    date: moment().format('YYYY-MM-DD'),
  });
  const [active, setActive] = useState('ALL');

  const toggle = () => setExam(null);

  const selectedEvent = (event: any) => {
    setExam(event);
  };

  const onFilter = (code: string) => {
    setActive(code);
  };

  useEffect(() => {
    if (active !== 'ALL') {
      setSelectedEvents(events.filter((evt: any) => evt?.code === active));
    } else {
      setSelectedEvents(events);
    }
    setLoading(false);
  }, [active, events]);

  const { views, components } = useMemo(
    () => ({
      components: {
        toolbar: CustomToolbar,
      },
      views: [Views.MONTH],
    }),
    [],
  );

  const onNavigate = useCallback(
    (newDate) => {
      setLoading(true);
      setFilter((prev) => ({
        ...prev,
        date: moment(newDate).format('YYYY-MM-DD'),
      }));
    },
    [setFilter],
  );

  const getAllScheduledExams = async () => {
    try {
      const response: any = await requestExams.getAllScheduledExams(filter);
      if (response?.navigationExams.length > 0) {
        const evts = response?.navigationExams?.map((exam: any) => {
          const { displayName, date, syllabus } = exam;
          const endDate = moment(date)
            .add(2, 'hour')
            .format('YYYY-MM-DD HH:mm');
          return {
            title: `${displayName} - ${exam?.module?.code} ${exam?.module?.title}`,
            start: new Date(date),
            end: new Date(endDate),
            allDay: false,
            data: exam,
            code: syllabus?.code,
          };
        });
        setEvents(evts);
      }
    } catch (error) {
      toast.error(
        'Error loading exam calendar, please try again or contact and administrator',
      );
      console.log(`Error adding exams to the calendar. Detail: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllScheduledExams();
  }, [filter]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-calendar">
              <CardHeader>
                <CardTitle tag="h4">Exam Calendar</CardTitle>
              </CardHeader>
              <CardBody>
                {loading && (
                  <div className="loading">
                    <Spinner
                      color="primary"
                      style={{
                        height: '3rem',
                        width: '3rem',
                      }}
                      type="grow">
                      Loading...
                    </Spinner>
                  </div>
                )}
                <Filters {...{ active, onFilter }} />
                <ReactBigCalendar
                  selectable
                  {...{ localizer, views, components, onNavigate }}
                  events={selectedEvents}
                  defaultView="month"
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={(event) => selectedEvent(event)}
                  eventPropGetter={eventStyleGetter}
                  popup
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EventDetail {...{ toggle, exam }} />
      </div>
      <ToastContainer />
    </>
  );
};
export default ExamList;
