import React from 'react';
import {Button, Col, Modal, Row} from "reactstrap";

interface IModalProps {
    modal: boolean,
    setModal: Function
}

const UserAlertModal: React.FC<IModalProps> = ({modal, setModal}) => {
    return (
        <Modal
            isOpen={modal}
            toggle={()=> setModal(false)}
        >
            <div className="modal-header">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={()=> setModal(false)}
                >
                    <i className="nc-icon nc-simple-remove" />
                </button>
                <h5 className="modal-title" id="myModalLabel">
                    User Permissions
                </h5>
            </div>
            <div className="modal-body">
                <div className="instruction">
                    <Row>
                        <Col>
                            <strong>1. User permissions</strong>
                            <p className="description">
                                Some users need specific permissions
                                to see and work with a selected
                                syllabus and/or modules. They won't
                                be able to see any content if they
                                have no syllabus/modules assigned.
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className="instruction">
                    <Row>
                        <Col>
                            <strong>2. Assign syllabus/modules</strong>
                            <p className="description">
                                For those users who need to see the
                                whole content of a specific syllabus,
                                select the required syllabus from
                                the Available List.
                            </p>
                            <p className="description">
                                For those users who only need to see
                                specific modules, select a syllabus
                                and pick any available module from
                                the list.
                                A user can have many modules assigned
                                and the same module can be assigned
                                to multiple users.
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="modal-footer justify-content-center">
                <Button
                    className="btn-round"
                    color="info"
                    data-dismiss="modal"
                    type="button"
                    onClick={()=> setModal(false)}
                >
                    Proceed
                </Button>
            </div>
        </Modal>
    )
}

export default UserAlertModal;
