import React, { useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Table,
  Row,
  Col,
} from 'reactstrap';
import Question from 'lib/common/models/question';
import Comment from 'lib/common/models/comment';
import QuestionApi from 'api/QuestionApi';
import ApiCaller from 'lib/ApiCaller';
import { UserTypes } from 'lib/common/models/user';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { formatDateTime } from 'shared/utils/dateTime';
import UserApi from 'api/UserApi';

interface props {
  question: Question;
}

const Comments: React.FC<props> = (props) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [comments, setComments] = useState<Comment[]>([]);
  const userApi = new UserApi(new ApiCaller());

  React.useEffect(() => {
    const id = props.question.id || '';
    const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));
    questionApi.getComments(id).then((c: Comment[]) => setComments(c));
  }, []);

  return (
    <>
      <Row>
        <Col className="text-center">
          <Card className="card-tasks">
            <CardHeader>
              <CardTitle tag="h4">Question Trail</CardTitle>
              <p className="card-description">
                See what is happening with your question
              </p>
            </CardHeader>
            <CardBody>
              <div className="table-full-width table-responsive">
                <Table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>User</th>
                      <th>User Type</th>
                      <th>Status</th>
                      <th>Comment</th>
                      <th>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {comments &&
                      comments.map((comment) => (
                        <tr>
                          <td className="img-row">
                            <div className="img-wrapper">
                              <img
                                alt="..."
                                className="img-raised"
                                src={userApi.getAvatarUrl(
                                  comment.commentAuthor?._id,
                                )}
                              />
                            </div>
                          </td>
                          <td>{comment.commentAuthor?.name}</td>
                          <td>{UserTypes[comment.commentAuthor?.type]}</td>
                          <td>{comment.commentType}</td>
                          <td className="text-left">
                            {comment.commentContent}
                          </td>
                          <td>{formatDateTime(comment.commentDate)}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="fa fa-refresh spin" />
                Updated 1 minutes ago
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Comments;
