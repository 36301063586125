import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from 'shared/utils/dateTime';
import User, { UserTypesLabels } from '../../../../lib/common/models/user';
import { QuestionStatus } from '../../../../lib/common/models/question';

export const getCustomUserColumns = (user: User) => {
  let cols = [];

  switch (user.type) {
    case UserTypesLabels.questionWriter:
    case UserTypesLabels.questionModerator:
      cols = [
        {
          Header: 'CREATION DATE',
          accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
          sortable: true,
          sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
        },
        {
          Header: 'USER',
          accessor: (d: any) => d.createdBy?.name,
          sortable: true,
        },
        {
          Header: 'STATUS',
          accessor: (d: any) => (d.status ? QuestionStatus[d.status] : '-'),
          sortable: true,
        },
        {
          Header: 'TOTAL MARK',
          accessor: (d: any) => (d.topic?.marks ? `${d.topic.marks}` : 0),
          sortable: true,
        },
      ];
      break;
    case UserTypesLabels.IAMIExamAdmin:
      cols = [
        {
          Header: 'CREATION DATE',
          accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
          sortable: true,
          sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
        },
        {
          Header: 'STATUS',
          accessor: (d: any) => (d.status ? QuestionStatus[d.status] : '-'),
          sortable: true,
        },
        {
          Header: 'MODULE',
          accessor: (d: any) =>
            d.module ? `${d.module.code} - ${d.module.title}` : '',
          sortable: true,
        },
        {
          Header: 'TOTAL MARK',
          accessor: (d: any) => (d.topic?.marks ? `${d.topic.marks}` : 0),
          sortable: true,
        },
      ];
      break;
    case UserTypesLabels.mcaExaminer:
      cols = [
        {
          Header: 'CREATION DATE',
          accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
          sortable: true,
          sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
        },
        {
          Header: 'STATUS',
          accessor: (d: any) => (d.status ? QuestionStatus[d.status] : '-'),
          sortable: true,
        },
        {
          Header: 'TOTAL MARK',
          accessor: (d: any) => (d.topic?.marks ? `${d.topic.marks}` : 0),
          sortable: true,
        },
      ];
      break;
    default:
      cols = [
        {
          Header: 'CREATION DATE',
          accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
          sortable: true,
          sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
        },
        {
          Header: 'STATUS',
          accessor: (d: any) => (d.status ? QuestionStatus[d.status] : '-'),
          sortable: true,
        },
        {
          Header: 'SYLLABUS',
          accessor: (d: any) =>
            d.syllabus ? `${d.syllabus.code} - ${d.syllabus.title}` : '',
          sortable: true,
        },
        {
          Header: 'MODULE',
          accessor: (d: any) =>
            d.module ? `${d.module.code} - ${d.module.title}` : '',
          sortable: true,
        },
        {
          Header: 'TOTAL MARK',
          accessor: (d: any) => (d.topic?.marks ? `${d.topic.marks}` : 0),
          sortable: true,
        },
      ];
  }
  return cols;
};
