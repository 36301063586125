export const eventStyleGetter = (event: any) => {
  let backgroundColor = '#3b647f';
  switch (event.code) {
    case 'S01':
      backgroundColor = '#568db0';
      break;
    case 'S02':
      backgroundColor = '#2D566E';
      break;
    case 'S03':
      backgroundColor = '#5189ae';
      break;
    case 'S04':
      backgroundColor = '#6666ff';
      break;
  }
  return {
    style: {
      backgroundColor,
    },
  };
};
