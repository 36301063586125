/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Settings from 'lib/settings';
import AuthApi from 'api/AuthApi';
import ApiCaller from 'lib/ApiCaller';
import User from 'lib/common/models/user';

import bgImg from 'assets/img/bg/yacht-background.png';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardTitle,
  FormText,
} from 'reactstrap';
import UserApi from 'api/UserApi';

import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import { generateErrors, is } from 'shared/utils/validations';
import Notifications from 'lib/notifications';
import {
  updateAvatar,
  updateSession,
} from 'store/actions/session/sessionActions';
import { updateNotifications } from 'store/notificationStore';
import { SessionState } from 'store/reducers/sessionReducer';
import { getJwtExpiration } from 'shared/utils/jwt';
import { pushMatomoUserId } from 'utils/matomo';
import PasswordInput from 'views/components/input/PasswordInput';
import { userTypesRedirect } from 'layouts/routes';

const Login: React.FC = () => {
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const dispatch = useDispatch();
  const inputs = ['email', 'password'];
  const history = useHistory();

  useEffect(() => {
    const user = Settings.getCurrentUser();
    if (!user.email) {
      return;
    }
    const sessionData: SessionState = {
      loggedIn: true,
      ready: true,
      userInfo: user,
      tokenExpiration: getJwtExpiration(user.token),
      type: user.type,
      layout: user.type === 'admin' ? 'navozyme' : user.type,
    };
    dispatch(updateSession(sessionData));
    if (user.type === 'admin') {
      history.push('/navozyme/dashboard');
    } else {
      const userType: string = user.type.toLowerCase();
      history.push(`/${userType}${userTypesRedirect[userType]}`);
    }

    document.body.classList.toggle('register-page');

    let timer: ReturnType<typeof setTimeout>;
    document.addEventListener('focusout', function (e: any) {
      if (e.target && inputs.indexOf(e.target.name) !== -1) {
        const content = document.getElementById('register-page');
        if (content) {
          timer = setTimeout(() => {
            // @ts-ignore
            content.scrollToTop();
          }, 1);
        }
      }
    });

    document.addEventListener('focusin', function (e: any) {
      if (e.target && inputs.indexOf(e.target.name) !== -1) {
        // @ts-ignore
        clearTimeout(timer);
      }
    });

    return () => {
      document.body.classList.toggle('register-page');
    };
  }, [history]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault();
        if (isCodeSent) {
          submit(e);
        } else {
          generateAuthCode(e);
        }
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isCodeSent]);

  const submit = (e: React.FormEvent | KeyboardEvent) => {
    e.preventDefault();

    const code = (document.getElementById('code') as any)?.value;
    const errors: any = generateErrors(
      { code },
      { code: [is.required(), is.number(), is.minLength(6), is.maxLength(6)] },
    );

    if (errors.code) toast.error(errors.code);

    if (Object.keys(errors).length !== 0) return;

    const authApi = new AuthApi(new ApiCaller());
    authApi
      .submitCode(email, parseInt(code))
      .then((data: any) => {
        if (data.error) {
          toast.error('Invalid credentials.');
        } else {
          const user = new User({
            name: data.name,
            email: data.email,
            token: data.token,
            type: data.type,
            status: data.status,
            entityId: data.entityId,
          });

          user._id = data._id;

          const sessionData: SessionState = {
            loggedIn: user.email ? true : false,
            ready: true,
            userInfo: user,
            tokenExpiration: getJwtExpiration(user.token),
            type: user.type,
            layout: user.type === 'admin' ? 'navozyme' : user.type,
          };
          pushMatomoUserId(data._id)
          dispatch(updateSession(sessionData));

          Settings.setCurrentUser(user).then(() => {
            const userApi = new UserApi(new ApiCaller());
            const url = userApi.getAvatarUrl(user._id || '');
            dispatch(updateAvatar(url));
            dispatch(
              updateNotifications({
                options: Notifications.restoreNotifications(user._id),
              }),
            );

            if (sessionData.userInfo.type === 'admin') {
              history.push('/navozyme/dashboard');
            } else {
              const userType: string = sessionData.userInfo.type.toLowerCase();
              history.push(`/${userType}${userTypesRedirect[userType]}`);
            }
          });
        }
      })
      .catch((err) => {
        toast.error('Invalid credentials.');
      });

    e.preventDefault();
  };

  const generateAuthCode = (e: React.FormEvent | KeyboardEvent) => {
    e.preventDefault();

    const email = (document.getElementById('email') as any)?.value;
    const password = (document.getElementById('password') as any)?.value;

    const errors: any = generateErrors(
      { email, password },
      { email: [is.required(), is.email()], password: [is.required()] },
    );

    if (errors.email) toast.error(errors.email);
    if (errors.password) toast.error(errors.password);

    if (Object.keys(errors).length !== 0) return;

    const authApi = new AuthApi(new ApiCaller());
    authApi
      .login(email, password)
      .then((data: any) => {
        if (data.success) {
          setEmail(email);
          setIsCodeSent(true);
        } else {
          toast.error('Invalid credentials.');
        }
      })
      .catch((err) => {
        toast.error('Invalid credentials.');
      });

    e.preventDefault();
  };

  return (
    <>
      <AuthNavbar />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image">
          <div className="register-page">
            <Container>
              <Row>
                <Col className="mr-auto" lg="4" md="6">
                  <Card className="card-signup text-center">
                    {!isCodeSent && (
                      <>
                        <CardHeader>
                          <CardTitle tag="h4">IAMI - Exam System</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Form action="" className="form" method="">
                            <InputGroup addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-single-02" />
                              </InputGroupText>
                            </InputGroup>
                            <InputGroup>
                              <Input
                                id="email"
                                placeholder="Email"
                                type="text"
                              />
                            </InputGroup>
                            <InputGroup addonType="prepend">
                              <InputGroupText>
                                <i className="nc-icon nc-key-25" />
                              </InputGroupText>
                            </InputGroup>
                            <PasswordInput
                              id="password"
                              placeholder="Password"
                            />
                            <FormGroup>
                              <FormText color="default" tag="span">
                                <a href="/recovery/new">Forgot password?</a>
                              </FormText>
                            </FormGroup>
                          </Form>
                        </CardBody>
                        <CardFooter>
                          <Button
                            className="btn-round"
                            color="info"
                            href="#pablo"
                            onClick={(e) => generateAuthCode(e)}>
                            Login
                          </Button>
                        </CardFooter>
                      </>
                    )}
                    {isCodeSent && (
                      <>
                        <CardHeader>
                          <CardTitle tag="h4">
                            Enter verification code
                          </CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Form action="" className="form" method="">
                            <FormGroup>
                              <FormText color="default" tag="span">
                                <p>
                                  A code has been sent to your account email.
                                  Please introduce it to proceed.
                                </p>
                              </FormText>
                            </FormGroup>
                            <InputGroup>
                              <Input
                                id="code"
                                placeholder="Six-digit code"
                                type="text"
                                autoComplete="off"
                              />
                            </InputGroup>
                          </Form>
                        </CardBody>
                        <CardFooter>
                          <Button
                            className="btn-round"
                            color="link"
                            onClick={() => setIsCodeSent(false)}>
                            <i className="nc-icon nc-minimal-left" />
                            Go back
                          </Button>
                          <Button
                            className="btn-round"
                            color="info"
                            onClick={(e) => submit(e)}>
                            Submit
                          </Button>
                        </CardFooter>
                      </>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
            <div
              className="full-page-background"
              style={{ backgroundImage: `url(${bgImg})` }}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
