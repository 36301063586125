import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { useTable, useSortBy } from 'react-table';
import { Button, Table, Input, Label, FormGroup } from 'reactstrap';

import NZDocument from '../../../lib/common/models/nzDocument';
import NavigationExam, {
  ScriptIntentionTypes,
} from '../../../lib/common/models/navigationExam';
import {
  MarkingGridRow,
  MarkingQuestion,
  IGridResults,
} from '../../../lib/common/models/markingData';

interface IRowResult {
  result: string;
  grade?: number;
  details?: { [key: string]: { result: string; grade?: number } };
}

export interface ExamMarkTableProps {
  exam: NavigationExam | undefined;
  grid: MarkingGridRow[];
  questions: MarkingQuestion[];
  docs: NZDocument[];
  results: IGridResults;
  manualTotal: { [key: string]: number };
  onChangeManualTotal: (value: { [key: string]: number }) => void;
  onViewQuestionAnswer: (questionId: string) => void;
  onOpenExamDocument: (candidateId: string) => void;
  onSetFieldParams: (
    index: number,
    value: string,
    data: string,
    field: string,
  ) => void;
  onSetCandidateVoid: (index: number, value: boolean) => void;
    blockInput?: boolean
}

const ExamMarkTable: React.FC<ExamMarkTableProps> = ({
  exam,
  grid,
  questions,
  docs,
  results,
  manualTotal,
  onChangeManualTotal,
  onViewQuestionAnswer,
  onOpenExamDocument,
  onSetFieldParams,
  onSetCandidateVoid,
                                                         blockInput
}) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const marks = questions.map((question) => question.marker);
    const total = marks.reduce((subtotal, element) => subtotal + element, 0);
    setTotal(total);
  }, [questions]);

  const displayResult = (result: IRowResult): string => {
    return result.grade ? `${result.result} (${result.grade})` : result.result;
  };

  const getScriptColumn = useCallback(() => {
    if (exam?.scriptIntention === ScriptIntentionTypes.SCAN) {
      return [{ Header: 'Script', accessor: 'script', disableSortBy: true }];
    }
    return [];
  }, [exam]);

  const getColumns = useMemo(() => {
    return [
      { Header: 'Candidate', accessor: 'candidate' },
      ...getScriptColumn(),
      { Header: 'Void', accessor: 'isVoid', disableSortBy: true },
      { Header: 'Result', accessor: 'result' },
      ...questions.map((question: MarkingQuestion, index: number) => {
        return {
          Header: (
            <Button
              id={`pop_${question._id}`}
              className={'btn-sm'}
              type="button"
              onClick={() => {
                onViewQuestionAnswer(question.questionName);
              }}>
              Q{String(index + 1).padStart(2, '0')} ({question.marker})
            </Button>
          ),
          accessor: `${question.questionName}`,
          disableSortBy: true,
        };
      }),
      {
        Header: `Total Marks ${total}`,
        accessor: 'totalMarks',
        disableSortBy: true,
      },
    ];
  }, [questions, total, getScriptColumn, onViewQuestionAnswer]);

  const tableData = useMemo(
    () =>
      grid.map((value: MarkingGridRow, index: number) => {
        return {
          candidate: value.name,
          script:
            exam?.scriptIntention === ScriptIntentionTypes.SCAN ? (
              <Button onClick={() => onOpenExamDocument(value.id)}>
                Download
              </Button>
            ) : null,
          isVoid: (
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={Boolean(value.isVoid)}
                  onChange={(e: any) => {
                    onSetCandidateVoid(index, e.target.checked);
                  }}
                />
                <span className="form-check-sign" />
              </Label>
            </FormGroup>
          ),
          result: displayResult(results[value.id]),
          ...questions.reduce((prev: any, curr: any, j: number) => {
            prev[curr.questionName] = (
              <>
                <small>Q{String(j + 1).padStart(2, '0')}</small>
                <input
                  className="table-input__input-field"
                  value={value[curr.questionName]}
                  type="number"
                  min={0}
                  placeholder="--"
                  pattern="[0-9]*"
                  onChange={(e: any) => {
                    onSetFieldParams(
                      index,
                      e.target.value,
                      e.nativeEvent.data,
                      curr.questionName,
                    );
                  }}
                  disabled={blockInput || !Object.prototype.hasOwnProperty.call(value, curr.questionName,) || Boolean(value.isVoid)}
                />
              </>
            );
            return prev;
          }, {}),
          totalMarks: (
            <>
              <small>TOTAL</small>
              <input
                className="table-input__input-field"
                value={manualTotal[value.id]}
                placeholder="--"
                type="number"
                onChange={(e: any) => {
                  onChangeManualTotal({
                    ...manualTotal,
                    [value.id]: e.target.value,
                  });
                }}
                disabled={Boolean(value.isVoid)}
              />
            </>
          ),
        };
      }),
    [exam, grid, questions, manualTotal, results, onChangeManualTotal,
      onOpenExamDocument, onSetCandidateVoid, onSetFieldParams],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: getColumns, data: tableData }, useSortBy);

  return (
    <Table {...getTableProps()} responsive className="grid-table">
      <thead className="text-primary">
        {grid &&
          headerGroups.map((headerGroup: any, i: number) => (
            <tr key={`tr-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  key={`th-${i}`}
                  className={!column.disableSortBy ? 'marks-table-nowrap' : ''}
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                </th>
              ))}
            </tr>
          ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {grid &&
          rows.map((row: any, i: any) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()} className="table-input">
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <td key={`cell-${index}`} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default ExamMarkTable;
