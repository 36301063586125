import User from '../../lib/common/models/user';
import defaultAvatar from 'assets/img/placeholder.jpg';
import Settings from '../../lib/settings';
import {UPDATE_AVATAR, UPDATE_JWT, UPDATE_SESSION} from 'store/actions/actionTypes';
import {SessionActionTypes} from 'store/actions/session/sessionActions';
import {getJwtExpiration} from "../../shared/utils/jwt";

export interface SessionState {
  loggedIn: boolean;
  userInfo: User;
  tokenExpiration: number;
  layout: string;
  ready: boolean;
  type: string;
  avatar?: string;
}

const initialState: SessionState = {
  loggedIn: false,
  userInfo: Settings.getCurrentUser(),
  tokenExpiration: getJwtExpiration(Settings.getCurrentUser().token),
  layout:
    Settings.getCurrentUserType() === 'admin'
      ? 'navozyme'
      : Settings.getCurrentUserType(),
  ready: false,
  type: Settings.getCurrentUserType(),
  avatar: defaultAvatar,
};

export function sessionReducer(
  state = initialState,
  action: SessionActionTypes,
): SessionState {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_AVATAR: {
      return {
        ...state,
        avatar: action.payload,
      };
    }
    case UPDATE_JWT: {
      return {
        ...state,
        userInfo: new User({
          ...state.userInfo,
          token: action.jwt
        }),
        tokenExpiration: action.jwtExpiry
      }
    }
    default:
      return state;
  }
}
