import {
  SET_CANDIDATES,
  SET_GRID,
  SET_HISTORICAL_GRID,
  SET_QUESTIONS,
  SET_RESULTS,
} from 'store/actions/actionTypes';
import { MarkersActionTypes } from 'store/actions/marker/markerActions';
import Candidate from '../../lib/common/models/candidate';
import {
  MarkingGridRow,
  MarkingHistoricalGridRow,
  MarkingQuestion,
  IGridResults,
} from '../../lib/common/models/markingData';

export interface MarkerState {
  questions: MarkingQuestion[];
  markerGrid: MarkingGridRow[];
  historicalGrid: MarkingHistoricalGridRow[],
  candidates: Candidate[];
  results: IGridResults;
}

const initialState: MarkerState = {
  candidates: [],
  markerGrid: [],
  historicalGrid: [],
  questions: [],
  results: {},
};

export function markerReducer(
  state = initialState,
  action: MarkersActionTypes,
): MarkerState {
  switch (action.type) {
    case SET_QUESTIONS: {
      return {
        ...state,
        questions: action.payload,
      };
    }
    case SET_CANDIDATES: {
      return {
        ...state,
        candidates: action.payload,
      };
    }
    case SET_GRID: {
      return {
        ...state,
        markerGrid: action.payload,
      };
    }
    case SET_HISTORICAL_GRID: {
      return {
        ...state,
        historicalGrid: action.payload,
      };
    }
    case SET_RESULTS: {
      return {
        ...state,
        results: action.payload,
      };
    }
    default:
      return state;
  }
}
