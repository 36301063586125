import Question from './question';

class QuestionCollection {
    questions: Array<Question>;

    constructor(questions: Array<Question>) {
        this.questions = questions;
    }

    length() {
        return this.questions.length;
    }
}

export default QuestionCollection;
