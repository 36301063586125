import ApiCaller from '../lib/ApiCaller';
import moment from 'moment';

class TreasurerDashboardApi {
    static getPositionSummaryReport(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        token?: string,
        params?: any
    ): Promise<any> {
        let url = '/v1/transactionAndPayment/positionSummaryReport';
        const apiCaller = new ApiCaller(token);

        const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
        const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';

        if (dateStart && dateEnd) {
            url = `${url}?startDate=${dateStart}&endDate=${dateEnd}`;
        }

        if (params?.examCentreId) {
            url = url.indexOf('?') !== -1 ? `${url}&examCentreId=${params.examCentreId}` : `${url}?examCentreId=${params.examCentreId}`
        }

        return apiCaller.call(url, 'GET')
            .then((data) => {
                if (data.error) throw Error(data.error);
                return data.rows;
            }).catch((err) => {
                throw Error(err);
            });    }

    static getPaymentsReport(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        token?: string,
        params?: any
    ): Promise<any> {
        let url = '/v1/transactionAndPayment/paymentReport';
        const apiCaller = new ApiCaller(token);

        const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
        const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';

        if (dateStart && dateEnd) {
            url = `${url}?startDate=${dateStart}&endDate=${dateEnd}`;
        }

        if (params?.examCentreId) {
            url = url.indexOf('?') !== -1 ? `${url}&examCentreId=${params.examCentreId}` : `${url}?examCentreId=${params.examCentreId}`
        }

        return apiCaller.call(url, 'GET')
            .then((data) => {
                if (data.error) throw Error(data.error);
                return data.rows;
            }).catch((err) => {
                throw Error(err);
            });    }

    static getInvoicesReport(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        token?: string,
        params?: any
    ): Promise<any> {
        let url = '/v1/transactionAndPayment/invoiceReport';
        const apiCaller = new ApiCaller(token);

        const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
        const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';

        if (dateStart && dateEnd) {
            url = `${url}?startDate=${dateStart}&endDate=${dateEnd}`;
        }

        if (params?.examCentreId) {
            url = url.indexOf('?') !== -1 ? `${url}&examCentreId=${params.examCentreId}` : `${url}?examCentreId=${params.examCentreId}`
        }

        return apiCaller.call(url, 'GET')
            .then((data) => {
                if (data.error) throw Error(data.error);
                return data.rows;
            }).catch((err) => {
                throw Error(err);
            });    }

    static getStatementReport(
        startDate: moment.Moment | undefined,
        endDate: moment.Moment | undefined,
        token?: string,
        params?: any
    ): Promise<any> {
        let url = '/v1/transactionAndPayment/statementReport';
        const apiCaller = new ApiCaller(token);

        const dateStart = startDate ? startDate.format('YYYY-MM-DD') : '';
        const dateEnd = endDate ? endDate.format('YYYY-MM-DD') : '';

        if (dateStart && dateEnd) {
            url = `${url}?startDate=${dateStart}&endDate=${dateEnd}`;
        }

        if (params?.examCentreId) {
            url = url.indexOf('?') !== -1 ? `${url}&examCentreId=${params.examCentreId}` : `${url}?examCentreId=${params.examCentreId}`
        }

        return apiCaller.call(url, 'GET')
            .then((data) => {
                if (data.error) throw Error(data.error);
                return data.rows;
            }).catch((err) => {
                throw Error(err);
            });    }
}

export default TreasurerDashboardApi;
