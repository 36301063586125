import ApiCaller from '../lib/ApiCaller';
import ExamGradeCollection from "../lib/common/models/examGradeCollection";
import ExamGrade from '../lib/common/models/examGrade';

class GradesApi {
    apiCaller: ApiCaller;

    constructor(apiCaller: ApiCaller) {
        this.apiCaller = apiCaller;
    }

    getAllGrades(): Promise<void | ExamGradeCollection> {
        return this.apiCaller.call('/v1/grades', 'GET')
            .then(data => {
                const apiArr = data.grades.map((grade: any) => new ExamGrade(grade));
                return new ExamGradeCollection(apiArr);
            }).catch(err => {
                console.error(err);
                throw Error(err);
            });
    }

    getCandidateGrades(candidateId: string): Promise<void | ExamGradeCollection> {
        return this.apiCaller.call('/v1/grades/' + candidateId, 'GET')
            .then(data => {
                const apiArr = data.grades.map((grade: any) => new ExamGrade(grade));
                return new ExamGradeCollection(apiArr);
            }).catch(err => {
                console.error(err);
                throw Error(err);
            });
    }

    createExamGrade(examGrade: ExamGrade, token?: string): Promise<ExamGrade> {
        return this.apiCaller.call(
            '/v1/grades',
            'PUT',
            examGrade,
            token
        ).then(data => {
            return new ExamGrade(data);
        }).catch(err => {
            console.error(err);
            throw Error(err);
        });
    }

    updateExamGrade(examGrade: ExamGrade, token?: string): Promise<ExamGrade> {
        return this.apiCaller.call(
            '/v1/grades/' + examGrade._id,
            'POST',
            examGrade,
            token
        ).then(data => {
            return new ExamGrade(data);
        }).catch(err => {
            console.error(err);
            throw Error(err);
        });
    }

    deleteExamGrade(examGradeId: string, token?: string): Promise<void> {
        return this.apiCaller.call('/v1/rest/grades/' + examGradeId, 'DELETE', {
            id: examGradeId
        }, token).catch((err) => {
            console.error(err);
        })
    }
}

export default GradesApi;
