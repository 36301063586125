interface ExamCenterInfo {
    code: string,
    name: string,
    address: string,
    createdAt?: string,
    createdBy?: string,
    updatedAt?: string,
    updatedBy?: string,
    _id?:string;
    balance?: string
}

class ExamCenter {
    code: string;
    name: string;
    address: string;
    createdAt?: string;
    createdBy?: string;
    updatedAt?: string;
    updatedBy?: string;
    _id?:string;
    balance?: string;

    constructor(examCenterInfo: ExamCenterInfo) {
        this.code = examCenterInfo.code;
        this.name = examCenterInfo.name;
        this.address = examCenterInfo.address;
        this.createdAt = examCenterInfo?.createdAt;
        this.createdBy = examCenterInfo?.createdBy;
        this.updatedAt = examCenterInfo?.updatedAt;
        this.updatedBy = examCenterInfo?.updatedBy;
        this._id = examCenterInfo?._id;
        this.balance = examCenterInfo?.balance;
    }

    load(data: any): ExamCenter {
        return new ExamCenter(data);
    }

    toObject(): any {
        return {
            code: this.code,
            name: this.name,
            address: this.address,
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            updatedBy: this.updatedBy,
            updatedAt: this.updatedAt,
            _id: this._id,
            balance: this.balance
        };
    }
}

export default ExamCenter;
