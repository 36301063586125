import MarkerPayment from './markerPayment';

class MarkerPaymentCollection {
  payments: Array<MarkerPayment>;

  constructor(payments: Array<MarkerPayment>) {
    this.payments = payments;
  }

  length() {
    return this.payments.length;
  }
}

export default MarkerPaymentCollection;
