import React from 'react';
import { Button } from 'reactstrap';
import { QuestionTotal } from '../questions/types';

type SyllabusFilterProps = {
  disableButtons: {
    S01: boolean;
    S02: boolean;
    S03: boolean;
    S04: boolean;
  };
  syllabus: string;
  setSyllabus: (code: string) => void;
  displayTotal?: boolean;
  questionTotal?: QuestionTotal;
  allowedSyllabuses?: string[];
};

const SyllabusFilter = ({
  disableButtons,
  syllabus,
  setSyllabus,
  displayTotal = false,
  questionTotal,
  allowedSyllabuses = []
}: SyllabusFilterProps) => {
  return (
    <>
      {allowedSyllabuses.includes('S01') && <Button
        color="primary"
        outline
        active={!disableButtons.S01 && syllabus === 'S01'}
        disabled={disableButtons.S01}
        onClick={() => setSyllabus('S01')}>
        {displayTotal ? `YDES: ${questionTotal?.S01}` : 'YDES'}
      </Button>}
      {allowedSyllabuses.includes('S02') && <Button
        color="primary"
        outline
        active={!disableButtons.S02 && syllabus === 'S02'}
        disabled={disableButtons.S02}
        onClick={() => setSyllabus('S02')}>
        {displayTotal ? `EKES: ${questionTotal?.S02}` : 'EKES'}
      </Button>}
      {allowedSyllabuses.includes('S03') && <Button
        color="primary"
        outline
        active={!disableButtons.S03 && syllabus === 'S03'}
        disabled={disableButtons.S03}
        onClick={() => setSyllabus('S03')}>
        {displayTotal ? `FISHING: ${questionTotal?.S03}` : 'FISHING'}
      </Button>}
      {allowedSyllabuses.includes('S04') && <Button
        color="primary"
        outline
        active={!disableButtons.S04 && syllabus === 'S04'}
        disabled={disableButtons.S04}
        onClick={() => setSyllabus('S04')}>
        {displayTotal ? `ETO: ${questionTotal?.S04}` : 'ETO'}
      </Button>}
    </>
  );
};

export default SyllabusFilter;
