
const Storage  = localStorage;

export default {
  write: function write(key: string, value: any): void {
    return Storage.setItem(key,JSON.stringify(value));
  },

  read: function read(key: string): any {
    const item = Storage.getItem(key );
    return JSON.parse(item || 'false');
  },

  clear: function clear(key: string): void {
    return Storage.removeItem(key);
  }
}
