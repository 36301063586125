import React, { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../../store/store';
import ApiCaller from '../../../../../lib/ApiCaller';
import CandidateEnrolment, {
  CandidateEnrolmentTypes,
} from '../../../../../lib/common/models/candidateEnrolment';
import CandidateApi from '../../../../../api/CandidateApi';
import Select from 'react-select';

interface IEnrolmentComponentProps {
  candidateId: any;
  examId: any;
  setModal: Function;
  readOnly?: boolean;
}

const CandidateEnrolmentDetail: React.FC<IEnrolmentComponentProps> = ({
  candidateId,
  examId,
  setModal,
  readOnly = false,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const [enrollment, setEnrollment] = useState<CandidateEnrolment>(
    new CandidateEnrolment({
      type: '',
      courseCompletion: false,
      candidateId: candidateId?._id,
      navigationExamId: examId?.id,
      comment: '',
    }),
  );

  useEffect(() => {
    const candidateApi = new CandidateApi(new ApiCaller());
    candidateApi
      .getCandidateEnrolment(examId.id, candidateId._id, loggedUser.token)
      .then((enrollment) => {
        setEnrollment(enrollment);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  }, [candidateId, examId]);

  const ccrToggle = (e: any) => {
    const enrollmentInfo = enrollment?.toObject();
    if (enrollmentInfo) {
      enrollmentInfo['courseCompletion'] = e.target.checked;
      setEnrollment(new CandidateEnrolment(enrollmentInfo));
    }
  };

  const noShowToggle = (e: any) => {
    const enrollmentInfo = enrollment?.toObject();
    if (enrollmentInfo) {
      enrollmentInfo['noShow'] = e.target.checked;
      setEnrollment(new CandidateEnrolment(enrollmentInfo));
    }
  };

  const onSelectChange = (e: any) => {
    const enrollmentInfo = enrollment?.toObject();
    if (enrollmentInfo) {
      enrollmentInfo['type'] = e.value;
      setEnrollment(new CandidateEnrolment(enrollmentInfo));
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const enrollmentInfo = enrollment?.toObject();
    if (enrollmentInfo) {
      enrollmentInfo[name] = value;
      setEnrollment(new CandidateEnrolment(enrollmentInfo));
    }
  };

  const updateEnrolment = () => {
    const data = new CandidateEnrolment({
      ...enrollment,
      candidateId: candidateId._id,
      navigationExamId: examId.id,
    });
    const candidateApi = new CandidateApi(new ApiCaller());
    candidateApi
      .createCandidateEnrolment(data, loggedUser.token)
      .then((res) => {
        setEnrollment(res);
        toast.success('Enrolment updated successfully');
        setModal(false);
      })
      .catch(() => {
        toast.error('Error updating candidate data');
      });
  };

  return (
    <>
      <Form className="form-horizontal">
        <Row>
          <Label sm="3">
            <b>Candidate</b>
          </Label>
          <Col sm="9">
            <FormGroup>
              <p className="form-control-static">
                {candidateId.firstName + ' ' + candidateId.lastName}
              </p>
            </FormGroup>
          </Col>
        </Row>
        {examId?.hasCandidateTypes() && (
          <Row>
            <Label sm="3">
              <b>Candidate Type</b>
            </Label>
            <Col sm="9">
              <FormGroup>
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="scriptIntention"
                  // @ts-ignore
                  value={{ label: CandidateEnrolmentTypes[enrollment?.type], value: enrollment?.type, }}
                  onChange={onSelectChange}
                  options={Object.keys(CandidateEnrolmentTypes).map(function (
                    type,
                  ) {
                    return {
                      value: type,
                      label: CandidateEnrolmentTypes[type],
                    };
                  })}
                  placeholder="Script Intention"
                  required
                  isDisabled={readOnly}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Label sm="3">
            <b />
          </Label>
          <Col sm="9">
            <FormGroup>
              <Label check>
                <input
                  checked={enrollment?.courseCompletion}
                  type="checkbox"
                  name="courseCompletion"
                  onChange={ccrToggle}
                  disabled={loggedUser.isExamCentreAuditor() || readOnly}
                />{' '}
                <span className="form-check-sign" />
                Course Completion
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="3">
            <b />
          </Label>
          <Col sm="9">
            <FormGroup>
              <Label check>
                <input
                  checked={enrollment?.noShow}
                  type="checkbox"
                  name="noShow"
                  onChange={noShowToggle}
                  disabled={loggedUser.isExamCentreAuditor() || readOnly}
                />{' '}
                <span className="form-check-sign" />
                No Show
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="3">
            <b>Comments</b>
          </Label>
          <Col sm="9">
            <FormGroup>
              <Input
                value={enrollment?.comment}
                type="text"
                name="comment"
                onChange={handleChange}
                disabled={loggedUser.isExamCentreAuditor() || readOnly}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="3" />
          <Col sm="9" className="checkbox-radios">
            <Button
              className="btn-round btn btn-danger"
              onClick={() => setModal(false)}>
              Cancel
            </Button>
            {!loggedUser.isExamCentreAuditor() && (
              <Button
                className="btn-round btn btn-warning"
                onClick={updateEnrolment}
                disabled={readOnly}>
                Update
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CandidateEnrolmentDetail;
