import Syllabus from 'lib/common/models/syllabus';
import { SET_SYLLABUS, SET_STATUS_SYLLABUS } from 'store/actions/actionTypes';
import { APP_STATUS } from 'types/app';

interface ISyllabusState {
  list: Syllabus[];
  status: APP_STATUS;
}

export const INITIAL_EXAM_STATE: ISyllabusState = {
  list: [],
  status: APP_STATUS.IDLE,
};

const syllabusReducer = (state = INITIAL_EXAM_STATE, action: any) => {
  switch (action.type) {
    case SET_SYLLABUS: {
      return {
        ...state,
        list: action.payload,
      };
    }

    case SET_STATUS_SYLLABUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default syllabusReducer;
