import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import Settings from '../../../../lib/settings';
import NavigationExam from '../../../../lib/common/models/navigationExam';
import DetailLayout from 'layouts/DetailLayout';
import CandidateResults from 'views/components/candidateResults/CandidateResults';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';

const ClosedExamResults: React.FC = () => {
  const [currentExam, setCurrentExam] = useState<NavigationExam>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTip, setLoadingTip] = useState<string>('');

  React.useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();
    setCurrentExam(exam);
  }, []);

  return (
    <>
      <div className="content">
        <LoadingSpinner spinning={loading} tip={loadingTip} >
          <DetailLayout title={'Go back to Closed Exams'}>
            {currentExam && (
              <CandidateResults
                currentExam={currentExam}
                setLoading={setLoading}
                setLoadingTip={setLoadingTip}
                loading={loading} />
            )}
          </DetailLayout>
          <ToastContainer />
        </LoadingSpinner>
      </div>
    </>
  );
};

export default ClosedExamResults;
