import React, { useState } from 'react';

import { useSelector } from "react-redux";

import { Card, CardBody, Label, FormGroup, Form, Input, Row, Col, CardHeader, } from "reactstrap";

import Settings from "../../../../lib/settings";
import { AppState } from "../../../../store/store";

import ApiCaller from "../../../../lib/ApiCaller";

import ExamCenter from "../../../../lib/common/models/examCenter";
import {formatDateTimeConversational} from "../../../../shared/utils/dateTime";
import TransactionApi from "../../../../api/TransactionApi";
import TransactionList from "../transactions/transactionList";


const ExamCenterFinanceDetail: React.FC = () => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const [examCenter, setExamCenter] = useState<ExamCenter>();
    const [balanceState, setBalanceState] = React.useState({ balance: 0, timestamp: 0 })
    const [balanceDisplay, setBalanceDisplay] = React.useState('0.00')
    const [refresh, setRefresh] = React.useState<boolean>(false);

    React.useEffect(() => {
        const exam = Settings.getCurrentExamCenter()
        setExamCenter(exam);
        const transactionApi = new TransactionApi(new ApiCaller(loggedUser.token));
        transactionApi.getBalance(exam._id).then((data) => {
            setBalanceState(data);
        })
    }, [refresh]);

    React.useEffect(() => {
        setBalanceDisplay(balanceState.balance.toFixed(2));
    }, [balanceState]);

    return (
        <>
            <div className="content">
                {examCenter &&
                <>
                    <Row>
                        <Col>
                            <Card>
                                <Row>
                                    <Col>
                                        <CardHeader>
                                            <CardHeader><h4 className="card-title">Exam Centre</h4></CardHeader>
                                        </CardHeader>
                                        <CardBody>
                                            <Form className="form-horizontal">
                                                <Row>
                                                    <Label sm="2">Code</Label>
                                                    <Col sm="10">
                                                        <FormGroup>
                                                            <Input value={examCenter.code} readOnly/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label sm="2">Name</Label>
                                                    <Col sm="10">
                                                        <FormGroup>
                                                            <Input value={examCenter.name} readOnly/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Label sm="2">Address</Label>
                                                    <Col sm="10">
                                                        <FormGroup>
                                                            <Input value={examCenter.address} readOnly/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Row>
                                    <Col>
                                        <CardHeader>
                                            <CardHeader><h4 className="card-title">Account balance</h4></CardHeader>
                                        </CardHeader>
                                        <CardBody>
                                            <p>
                                                <a id="balance-board">£{balanceDisplay.split('.')[0]}.</a>
                                                <a id="balance-board-decimal">{balanceDisplay.split('.')[1]}</a>
                                            </p>
                                            <br/>
                                            <p>
                                                Last update: {formatDateTimeConversational(balanceState.timestamp)}
                                            </p>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TransactionList refresh={refresh} centreId={examCenter._id}/>
                        </Col>
                    </Row>
                </>
                }
            </div>
        </>
    )
}

export default ExamCenterFinanceDetail;





