import ExamResource from './examResource';

class ExamResourceCollection {
  resources: Array<ExamResource>;

  constructor(resources: Array<ExamResource>) {
    this.resources = resources;
  }

  length() {
    return this.resources.length;
  }
}

export default ExamResourceCollection;
