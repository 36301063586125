import React from 'react';
import { ListLabels } from './listTypes';
import QuestionList from './questionList';

const QuestionBankList: React.FC = () => {
  return (
    <>
      <QuestionList listType={ListLabels.questionBank} />
    </>
  );
};

export default QuestionBankList;
