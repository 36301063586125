import { MATOMO_SITE_ID } from "lib/buildSettings";

export const getPaq = () => {
  //@ts-ignore
  return (window._paq = window._paq || []);
};

export const pushMatomoUserId = (id: number | null) => {
  const _paq = getPaq();
  if (_paq) {
    _paq.push(['setUserId', id]);
  }
};

export const matomoCode = () => {
  const _paq = getPaq();
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    const u = 'https://matomo.navozyme.com/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', MATOMO_SITE_ID]);
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode && s.parentNode.insertBefore(g, s);
  })();
}