import Syllabus from "./syllabus";

export enum ExamScheduleTypesLabels {
    ADD_DAY = "ADD_DAY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY"
}

export const ExamScheduleTypes = {
    [ExamScheduleTypesLabels.ADD_DAY]: "Add date",
    [ExamScheduleTypesLabels.WEEKLY]: "Weekly",
    [ExamScheduleTypesLabels.MONTHLY]: "Monthly"
}

interface ExamScheduleInfo {
    id?: string,
    syllabus?: any,
    module?: any,
    rrule: string,
    timeSlots: string[]
}

class ExamSchedule {
    id?: string;
    syllabus?: any;
    module?: any;
    rrule: string;
    timeSlots: string[]

    constructor(examScheduleInfo: ExamScheduleInfo) {
        this.id = examScheduleInfo.id;
        this.syllabus = examScheduleInfo.syllabus;
        this.module = examScheduleInfo.module;
        this.rrule = examScheduleInfo.rrule;
        this.timeSlots = examScheduleInfo.timeSlots;
    }

    toObject(): any {
        return {
            id: this.id,
            syllabus: this.syllabus,
            module: this.module,
            rrule: this.rrule,
            timeSlots: this.timeSlots
        }
    }

    load(data: any): ExamSchedule {
        return new ExamSchedule(data);
    }

    fromDataModel(data: any): ExamSchedule {
        data.syllabus = data.syllabusId ? Syllabus.prototype.toDto(data.syllabusId): null;
        data.module = data.moduleId ? Syllabus.prototype.toDto(data.moduleId): null;
        return new ExamSchedule(data);
    }
}

export default ExamSchedule;
