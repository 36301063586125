import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from 'reactstrap';

import { AppState } from 'store/store';
import ApiCaller from 'lib/ApiCaller';
import ModuleApi from 'api/ModuleApi';
import { useSyllabus } from 'hooks';

const ModuleCreate: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const { syllabus } = useSyllabus();
  const [form, setState] = React.useState({
    code: '',
    title: '',
    syllabus: '',
  });

  const resetForm = () => {
    setState({
      code: '',
      title: '',
      syllabus: '',
    });
  };

  const handleChange = (e: any) => {
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const onSyllabusChange = (selectedSyllabus: any) => {
    setState({
      ...form,
      syllabus: selectedSyllabus,
    });
  };

  const createModule = (e: React.FormEvent) => {
    e.preventDefault();
    var moduleApi = new ModuleApi(new ApiCaller());
    moduleApi
      .addModule(form.code, form.title, form.syllabus, loggedUser.token)
      .then((createModule: any) => {
        if (createModule) {
          toast.success('Module created successfully');
          resetForm();
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast.error('Error creating module, please try again');
      });
  };

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <h4 className="card-title">Create Module</h4>
          </CardHeader>
          <CardBody>
            <Form className="form-horizontal" onSubmit={createModule}>
              <Row>
                <Label sm="2">Code</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={form.code}
                      onChange={handleChange}
                      type="text"
                      name="code"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Title</Label>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      value={form.title}
                      onChange={handleChange}
                      type="text"
                      name="title"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2">Syllabus</Label>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="syllabus"
                      value={form.syllabus as any}
                      onChange={onSyllabusChange}
                      options={syllabus}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a syllabus"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Label sm="2"></Label>
                <Col sm="10">
                  <Button type="submit" className="btn-round" color="info">
                    Create
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <ToastContainer />
      </div>
    </>
  );
};

export default ModuleCreate;
