import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  ModalHeader,
  ModalBody,
  Modal,
  Button,
} from "reactstrap";

import ApiCaller from "../../../../lib/ApiCaller";
import ReactTable from "../../../../components/ReactTable/ReactTable";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";
import NavigationExam from '../../../../lib/common/models/navigationExam';
import ExamGradeCollection from "../../../../lib/common/models/examGradeCollection";
import GradesApi from "../../../../api/GradesApi";
import {DEFAULT_TIMEZONE_FORMAT, formatDateTime, sortDateTable} from "../../../../shared/utils/dateTime";
import CandidateEnrolmentDetail from '../navigation_exam/navigationExamDetail/candidateEnrollmentDetail';
import NavigationExamApi from 'api/NavigationExamApi';

interface IComponentProps {
    candidate: any
}

const GradesList: React.FC<IComponentProps> = ({ candidate }) => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const [grades, updateGrades] = React.useState<ExamGradeCollection>(new ExamGradeCollection([]));
    const [modalDetail, setModalDetail] = useState<boolean>(false);
    const [currentExam, setCurrentExam] = useState<NavigationExam>();

    React.useEffect(() => {
        if (candidate?._id) {
            const gradesApi = new GradesApi(new ApiCaller(loggedUser.token));
            gradesApi.getCandidateGrades(candidate?._id).then((data: any) => {
                updateGrades(data);
            });
        }
    }, [candidate?._id]);

    const columns = [
        { Header: 'EXAM NUMBER', accessor: (d: any) => (d.syllabus && d.module && d.examId)
                    ?`${d.syllabus?.code}-${d.module?.code}-${new Date().getFullYear()}${String(d.examId?.sequence).padStart(4, '0')}`
                : String(d.examId?.sequence).padStart(4, '0')
            , sortable: true, },
        { Header: 'SYLLABUS', accessor: (d: any) => d.syllabus ? `${d.syllabus.code} - ${d.syllabus.title}` : '', sortable: true, },
        { Header: 'MODULE', accessor: (d: any) => d.module ? `${d.module.code} - ${d.module.title}` : '', sortable: true, },
        { Header: 'DATE', accessor: (d: any) => formatDateTime((d.examId)?d.examId.date:d.date, DEFAULT_TIMEZONE_FORMAT), sortable: true, sortType: (a: any, b: any) => sortDateTable(a, b, "date")},
        { Header: 'EXAM CENTRE', accessor: (d: any) => d.examCentreId?.name, sortable: true, },
        { Header: 'RESULT', accessor: 'result', sortable: true, },
        {
          Header: 'ACTIONS',
          Cell: (row: any) => (
            <Button
              className="btn-round"
              color="info"
              onClick={() => {
                const examApi = new NavigationExamApi(new ApiCaller(loggedUser.token));
                examApi.getById(row.row.original.examId?._id).then((exam) => {
                  setCurrentExam(exam);
                  setModalDetail(true);
                });
              }}
            >
              DETAILS
            </Button>
          ),
        },
    ]

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Grades List</CardTitle>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={grades?.grades}
                  columns={columns}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modalDetail}>
          <ModalHeader>Enrollment Detail</ModalHeader>
          <ModalBody>
            <CandidateEnrolmentDetail
              candidateId={candidate}
              examId={currentExam}
              setModal={setModalDetail}
            />
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}

export default GradesList;

