import React, { FC, useCallback, useState } from 'react';
import ReactTable from 'components/ReactTable/ReactTable';
import FilteredComponent from '../filteredComponent';
import { DateFilteredTableProps } from '../types';
import AvCandidates from './AvCandidates';
import ExamCenterFilter from '../filters/examCenterFilter';
import { Button, Col, Row } from 'reactstrap';
import { onTableExport } from 'shared/utils/excel';

type ExamCenterDateFilteredTableProps = DateFilteredTableProps & {
  examCentreId?: string;
};

const ExamCenterDateFilteredTable: FC<ExamCenterDateFilteredTableProps> = ({
  columns,
  fetchMethod,
  multipleTables,
  tableName = 'Data',
  initialGroupBy = [],
  hasTotal = false,
  examCentreId,
}) => {
  const [tableData, setTableData] = useState<any>([]);
  const [filterParams, setFilterParams] = useState<any>(
    examCentreId ? { examCentreId } : undefined,
  );

  const getExportInvoices = useCallback(() => {
    if (tableData?.length) {
      const listInvoices = tableData.map((invoice: any) => {
        const { date, invoiceNumber, amount, examCentre } = invoice;
        let invoicePrint: any = {
          date,
          invoiceNumber,
          amount,
        };
        if (!examCentreId) {
          invoicePrint = {
            examCentre,
            ...invoicePrint,
          };
        }
        return invoicePrint;
      });
      return listInvoices;
    }
    return [];
  }, [tableData]);

  return (
    <div className="filtered-table__container">
      <Row>
        {!examCentreId && (
          <Col>
            <ExamCenterFilter onSetFilter={setFilterParams} />
          </Col>
        )}
        <Col className="text-right">
          <Button
            onClick={() => {
              const tableDataForExport = getExportInvoices();
              onTableExport(tableName, columns, tableDataForExport);
            }}
            color="success"
            className="btn-label">
            <span>
              <i className="nc-icon nc-cloud-download-93" />
            </span>
            Export to Excel
          </Button>
        </Col>
      </Row>
      <FilteredComponent
        component={[
          multipleTables && <AvCandidates data={tableData} />,
          <ReactTable
            data={tableData}
            {...{ columns, initialGroupBy, hasTotal }}
            topPaginationClassName="table-pagination_top"
          />,
        ]}
        {...{ fetchMethod }}
        updateDataMethod={setTableData}
        componentName="table"
        examCenterFilter
        params={filterParams}
      />
    </div>
  );
};

export default ExamCenterDateFilteredTable;
