import Syllabus from './syllabus';

class SyllabusCollection {
  syllabuses: Array<Syllabus>;

  constructor(syllabuses: Array<Syllabus>) {
    this.syllabuses = syllabuses;
  }

  length() {
    return this.syllabuses.length;
  }
}

export default SyllabusCollection;
