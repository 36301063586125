import ApiCaller from '../lib/ApiCaller';
import Question, { QuestionInfo } from '../lib/common/models/question';
import Comment from '../lib/common/models/comment';
import { ExamResourceInfo } from '../lib/common/models/examResource';
import QuestionCollection from '../lib/common/models/questionCollection';
import {
  Module,
  ModuleCollection,
  Syllabus,
  Topic,
  TopicCollection,
} from '../lib/common/models/syllabusDataModels';
import FileSaver from 'file-saver';

class QuestionApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getModulesFromSyllabus(syllId: string): Promise<void | ModuleCollection> {
    return this.apiCaller
      .call(`/v1/syllabus/${syllId}/module`, 'GET')
      .then((data) => {
        const moduleArr = data.modules.map(
          (mod: any) =>
            new Module({
              code: mod.code,
              title: mod.title,
              createdAt: mod.createdAt,
              createdBy: mod.createdBy,
              updatedAt: mod.updatedAt,
              updatedBy: mod.updatedBy,
              _id: mod._id,
            }),
        );

        return new ModuleCollection(moduleArr);
      });
  }

  getTopicsFromModule(modId: string): Promise<void | TopicCollection> {
    return this.apiCaller
      .call(`/v1/syllabus/module/${modId}/topic`, 'GET')
      .then((data) => {
        const tArr = data.topics.map(
          (mod: any) =>
            new Topic({
              code: mod.code,
              title: mod.title,
              marks: mod.marks,
              createdAt: mod.createdAt,
              createdBy: mod.createdBy,
              updatedAt: mod.updatedAt,
              updatedBy: mod.updatedBy,
              _id: mod._id,
            }),
        );

        return new TopicCollection(tArr);
      });
  }

  getMyQuestions(action: string): Promise<void | QuestionCollection> {
    const endpoint: { [index: string]: string } = {
      questionList: 'getMyQuestions',
      questionBank: 'getQuestionBank',
      archivedQuestions: 'getArchivedQuestions',
    };

    return this.apiCaller
      .call(`/v1/question/${endpoint[action]}`, 'GET')
      .then((data) => {
        const apiArr = data.questions.map((question: QuestionInfo) => {
          return Question.prototype.toQuestionWithResources({ ...question });
        });
        return new QuestionCollection(apiArr);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getQuestionsInPipeline(): Promise<void | QuestionCollection> {
    return this.apiCaller
      .call('/v1/question/getQuestionsInPipeline', 'GET')
      .then((data) => {
        const apiArr = data.questions.map((question: QuestionInfo) => {
          return Question.prototype.toQuestionWithResources({ ...question });
        });
        return new QuestionCollection(apiArr);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getQuestionById(questionId: string): Promise<Question> {
    return this.apiCaller
      .call('/v1/question/getQuestionById/' + questionId, 'GET')
      .then((question) => {
        return Question.prototype.toQuestionWithResources({ ...question });
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getAnswer(questionId: string): Promise<string> {
    return this.apiCaller
      .call('/v1/question/getAnswer/' + questionId, 'GET')
      .then((answer) => answer.answer)
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getQuestionByNumber(name: string): Promise<void | Question> {
    return this.apiCaller
      .call('/v1/question/getQuestionByNumber' + name, 'GET')
      .then((data) => {
        const question = new Question({
          ...data,
        });
        return question;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getAllQuestionByVessel(vesselId: string): Promise<void | QuestionCollection> {
    return this.apiCaller
      .call('/v1/question/getAllQuestionByVessel/' + vesselId, 'GET')
      .then((data) => {
        const callNumberArr = data.questions.map(
          (callNumber: any) =>
            new Question({
              ...callNumber,
            }),
        );
        const callNumberCollection = new QuestionCollection(callNumberArr);
        return callNumberCollection;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  addQuestion(question: Question, token?: string): Promise<void | Question> {
    return this.apiCaller
      .call(
        '/v1/question/create',
        'POST',
        {
          name: question.name,
          description: question.description,
          associatedAnswer: question.associatedAnswer,
          syllabus: question.syllabus ? question.syllabus._id : '',
          module: question.module ? question.module._id : '',
          topic: question.topic ? question.topic._id : '',
          //date: question.date,
          status: question.status,
          resources: question.resources
            ? question.resources.map(
                (resource: ExamResourceInfo) => resource.id,
              )
            : [],
        },
        token,
      )
      .then((data) => {
        return Question.prototype.toQuestionWithResources({
          ...data,
          syllabus: new Syllabus(data),
          module: new Module(data),
        });
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  updateQuestion(
    callNumber: Question,
    token?: string,
  ): Promise<void | Question> {
    return this.apiCaller
      .call(
        '/v1/question/update/' + callNumber.id,
        'PUT',
        {
          name: callNumber.name,
          description: callNumber.description,
          associatedAnswer: callNumber.associatedAnswer,
          syllabus: callNumber.syllabus.id,
          module: callNumber.module.id,
          topic: callNumber.topic.id,
          status: callNumber.status,
          resources: callNumber.resources
            ? callNumber.resources.map(
                (resource: ExamResourceInfo) => resource.id,
              )
            : [],
        },
        token,
      )
      .then((data) => {
        return Question.prototype.toQuestionWithResources({ ...data });
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getPDF(callNumber: Question): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/question/downloadQuestion/' + callNumber.id, 'GET')
      .then((file) => {
        const blob = new Blob([file], {
          type: 'application/pdf',
        });
        FileSaver.saveAs(blob, `${callNumber.name}.pdf`);
        return file;
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  archiveQuestion(questionId: string, token?: string): Promise<void> {
    return this.apiCaller
      .call(
        '/v1/question/archiveQuestion/' + questionId,
        'PUT',
        {
          id: questionId,
        },
        token,
      )
      .catch((err) => {
        throw Error(err);
      });
  }

  moveQuestion(questionId: string, moduleId: string, topicId: string, token?: string): Promise<Question> {
    return this.apiCaller
      .call(
        `/v1/question/move/${questionId}`,
        'POST',
        { moduleId, topicId },
        token,
      )
      .then((question) => {
        return Question.prototype.toQuestionWithResources({ ...question });
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  deleteQuestion(callNumberId: string, token?: string): Promise<void> {
    return this.apiCaller
      .call(
        '/v1/rest/question/' + callNumberId,
        'DELETE',
        {
          id: callNumberId,
        },
        token,
      )
      .catch((err) => {
        throw Error(err);
      });
  }

  removeQuestionImage(id: string): Promise<void | any | undefined> {
    return this.apiCaller.call(`/v1/documents/slug/${id}`, 'DELETE');
  }

  addComment(questionId: string, content: string, type: string): Promise<void> {
    return this.apiCaller
      .call('/v1/question/addComment', 'POST', {
        commentContent: content,
        commentQuestionId: questionId,
        commentType: type,
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getComments(questionId: string): Promise<Comment[]> {
    return this.apiCaller
      .call(`/v1/question/${questionId}/getComments`, 'GET')
      .then((data) => {
        if (data.comments.length === 0) return [];
        return data.comments.map((c: any) => new Comment({ ...c })).reverse();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  triggerAction(
    action: string,
    questionId: string,
    userType: string,
  ): Promise<void> {
    const endpoint: { [index: string]: { [index: string]: string } } = {
      admin: { ACCEPT: 'questionToRevision', REVOKE: 'revokeQuestion' },
      questionWriter: { ACCEPT: 'questionToRevision' },
      questionModerator: { ACCEPT: 'acceptQuestion', REVOKE: 'revokeQuestion' },
      mcaExaminer: { ACCEPT: 'approveQuestion', REVOKE: 'revokeQuestion' },
      IAMIExamAdmin: { ACCEPT: 'activateQuestion', REVOKE: 'revokeQuestion' },
      IAMIExamManager: { ACCEPT: 'activateQuestion', REVOKE: 'revokeQuestion' },
    };

    return this.apiCaller
      .call(`/v1/question/${endpoint[userType][action]}/` + questionId, 'PUT', {
        id: questionId,
      })
      .catch((err) => {
        throw Error(err);
      });
  }
}

export default QuestionApi;
