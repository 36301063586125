import React, { useCallback } from 'react';
import type { FC } from 'react';
import { Button, Card, CardBody, CardHeader, FormGroup } from 'reactstrap';
import { onTableExport } from 'shared/utils/excel';
import ReactTable from 'components/ReactTable/ReactTable';
import CandidateCollection from 'lib/common/models/candidateCollection';
import NavigationExam from 'lib/common/models/navigationExam';

interface ICandidatesAssigned {
  examCandidates: CandidateCollection;
  columns: any;
  currentExam: NavigationExam;
}
const CandidatesAssigned: FC<ICandidatesAssigned> = ({
  examCandidates,
  columns,
  currentExam,
}) => {
  const getExportCandidates = useCallback(() => {
    if (examCandidates?.candidates.length) {
      const listCandidates = examCandidates?.candidates.map(
        (candidate: any) => {
          const {
            serialNumber,
            firstName,
            lastName,
            birthDate,
            passportNumber,
            email,
            special,
          } = candidate;
          return {
            serialNumber,
            name: `${firstName} ${lastName}`,
            birthDate,
            passportNumber,
            email,
            special: special ? 'Yes' : 'No',
          };
        },
      );
      return listCandidates;
    }
    return [];
  }, [examCandidates]);

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title">Assigned Candidates ({examCandidates?.candidates.length})</h4>
        {currentExam?.isDeadlineReached() && (
          <FormGroup className={`has-label has-danger`}>
            <label className="error">
              Deadline reached, candidates list cannot be modified 24h prior to
              exam.
            </label>
          </FormGroup>
        )}
      </CardHeader>
      <CardBody>
        <Button
          onClick={() =>
            onTableExport('CandidateList', columns, getExportCandidates())
          }
          color="success"
          className="btn-label"
          disabled={!examCandidates?.candidates.length}>
          <span>
            <i className="nc-icon nc-cloud-download-93" />
          </span>
          Export to Excel
        </Button>
        <ReactTable data={examCandidates?.candidates} {...{ columns }} />
      </CardBody>
    </Card>
  );
};

export default CandidatesAssigned;
