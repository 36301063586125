import Storage from './storage';
import Notification from './common/models/notification';

var currentNotification: Notification;
var storedNotifications: Notification[];

export interface SessionNofiticationData {
    notifications: Notification[]
}
var getCurrentSessionData = (userId?: string): SessionNofiticationData => {
    var data = Storage.read(`notifications_${userId}`);
    storedNotifications = Notification.prototype.loadMany(data);
    return {notifications: storedNotifications};
}

var getCurrentNotifications = (userId?: string): Notification[] => {
    var data = Storage.read(`notifications_${userId}`);
    storedNotifications = Notification.prototype.loadMany(data);
    return storedNotifications;
}

var Notifications = {

    storedNotifications: () : Notification[] => storedNotifications,
    currentNotification: () : Notification => currentNotification,

    getCurrentNotifications: (userId?: string) => getCurrentNotifications(userId),
    getCurrentSessionData: getCurrentSessionData,

    addNotification: (cn: Notification, userId?: string) => {
        storedNotifications = getCurrentNotifications(userId)
        storedNotifications.unshift(cn);
        return Storage.write(`notifications_${userId}`, storedNotifications);
    },
    restoreNotifications: (userId?: string) => {
        storedNotifications = getCurrentNotifications(userId);
        return storedNotifications;
    },
    resetNotifications: (userId?: string) => {
        storedNotifications = [];
        return Storage.write(`notifications_${userId}`, '');
    },
    removeNotifications: (idx:number, userId?: string) => {
        storedNotifications = getCurrentNotifications(userId)
        return Storage.write(`notifications_${userId}`, storedNotifications.filter((n,i)=>i!=idx));
    },

}

export default Notifications;
