import React from 'react';
import StatsCard from '../../components/StatsCard/StatsCard';

type StatsCardsContainerProps = {
  data: {
    label: string,
    value: string,
    timestamp: number,
  }[],
}

const StatsCardsContainer: React.FC<StatsCardsContainerProps> = ({ data }) => {
  return (
    <>
      {
        data[0] &&
        <StatsCard data={data[0]} iconClassName="nc-icon nc-world-2 text-warning" />
      }
      {
        data[1] &&
        <StatsCard data={data[1]} iconClassName="nc-icon nc-tap-01 text-success" />
      }
      {
        data[2] &&
        <StatsCard data={data[2]} iconClassName="nc-icon nc-paper text-danger" />
      }
      {
        data[3] &&
        <StatsCard data={data[3]} iconClassName="nc-icon nc-lock-circle-open text-primary" />
      }</>
  );
};

export default StatsCardsContainer;
