import ApiCaller from '../lib/ApiCaller';
import {IPayment, IPaymentOrder} from "../lib/common/models/transaction";
import Transaction from '../lib/common/models/transaction';
import TransactionCollection from '../lib/common/models/transactionCollection';

class PaymentApi {
    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    getClientSecret() : Promise<string> {
        return this.apiCaller.call('/v1/payment/intent', 'GET')
            .then(data => {
                if (data.error) {
                    alert("Server error");
                } else {
                    return data.client_secret;
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    getPayments(centreId?: string): Promise<TransactionCollection | void> {
        const url = centreId
          ? `/v1/payment/centre/${centreId}`
          : '/v1/payment';
        return this.apiCaller
          .call(url, 'GET')
          .then((data) => {
            const apiArr = data.transactions.map(
              (transaction: any) => new Transaction(transaction),
            );
            return new TransactionCollection(apiArr);
          })
          .catch((err) => {
            console.error(err);
            throw Error(err.message);
          });
      }

    getPaymentIntent(): Promise<IPayment> {
        return this.apiCaller.call('/v1/payment/payment-intent', 'GET')
            .then((data: any) => {
                if (data.error) {
                    alert("Server error");
                } else {
                    return data;
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    getPaymentOrder(amount: number, token?: string): Promise<IPayment> {
                return this.apiCaller.call('/v1/payment/payment-order-request', 'POST',
            { amount: amount },
            token
            ).then((data: any) => {
                if (data.error) {
                    alert("Server error");
                } else {
                    return data;
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    capturePaymentOrder(orderId: string, token?: string): Promise<any> {
        return this.apiCaller.call('/v1/payment/payment-order-capture', 'POST', {
            orderId: orderId,
        }, token).then((data) => {
            console.log(data);
            return data;
        }).catch(err => {
            console.error(err);
        });
    }

    getPaymentStatus() : Promise<string> {
        return this.apiCaller.call('/v1/payment/status', 'GET')
            .then(data => {
                if (data.error) {
                    alert("Server error");
                } else {
                    return data.status;
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    createPaymentSession(token?: string): Promise<string> {
        return this.apiCaller.call('/v1/payment/create-session', 'POST', {}, token)
            .then(data => {
                return data;
            }).catch(err => {
                console.error(err.message);
            });
    }

    generatePayment(
        examId: any,
        paymentType: string,
        reference: string,
        certificateId: string,
        token?: string
    ): Promise<void> {
        let url = '/v1/payment';
        if (certificateId) {
            url = `${url}/certificate`
        } else {
            url = `${url}/exam`
        }
        return this.apiCaller.call(url, 'POST', {
            examId: examId,
            paymentType: paymentType,
            reference: reference,
            certificateId: certificateId,
        }, token)
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
                return data;
            }).catch(err => {
                console.error(err)
                throw err;
            });
    }

    getPaymentAmount(paymentType: string, examId?: any): Promise<IPaymentOrder> {
        return this.apiCaller.call(`/v1/payment/exam/${examId}?type=${paymentType}`, 'GET').then(data => {
            return data;
        }).catch(err => {
            console.error(err)
            throw err;
        });
    }
}

export default PaymentApi;
