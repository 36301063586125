
interface TrackInfo {
    _id: string;
    questionId: any;
    url: string;
    userId: any;
    createdAt: Date;
    updatedAt: Date;
}
class Track {
    _id: string;
    questionId: any;
    url: string;
    userId: any;
    createdAt: Date;
    updatedAt: Date;

  constructor(info: TrackInfo) {
      this._id = info._id;
      this.questionId = info.questionId;
      this.url = info.url;
      this.userId = info.userId;
      this.createdAt = info.createdAt;
      this.updatedAt = info.updatedAt;
  }


}

export default Track;
