import React, { useCallback, ReactNode } from "react";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

interface DetailLayoutProps {
  title: string,
  children?: ReactNode,
}

const DetailLayout: React.FC<DetailLayoutProps> = ( props ) => {
  const { title, children } = props;

  const history = useHistory();

  const close = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <Button onClick={close} style={{ marginTop: 0 }}>
        <i className="nc-icon nc-minimal-left" />
        {title}
      </Button>
      {children}
    </>
  )
};

export default DetailLayout;