import Settings from '../lib/settings';

class ApiCaller {
  token: string;
  constructor(token?: string) {
    this.token = token ? token : '';
  }

  call(
    url: string,
    method: string,
    params?: object,
    token?: string,
  ): Promise<any> {
    const fetchParams: any = {
      method,
      headers: { 'Content-Type': 'application/json' },
    };

    if (params) {
      fetchParams.body = JSON.stringify(params);
    }

    if (token) {
      fetchParams.headers['x-access-token'] = token;
    }

    let separator = '?';

    if (url.indexOf('?') !== -1) {
      separator = '&';
    }

    const defUrl = url + (this.token ? separator + 'token=' + this.token : '');

    return fetch(Settings.getApiURL() + defUrl, fetchParams).then(
      (response) => {
        if (response.ok) {
          return response.json();
        }
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json().then((data) => {
            throw Error(data.message || '');
          });
        } else {
          return response.text().then((text) => {
            throw Error(text);
          });
        }
      },
    );
  }

  callImage(
    url: string,
    method: string,
    params?: object,
    token?: string,
  ): Promise<any> {
    const fetchParams: any = {
      method: method,
    };

    if (params) {
      fetchParams.body = params;
    }

    let separator = '?';
    if (url.indexOf('?') !== -1) {
      separator = '&';
    }
    const defUrl = url + (token ? separator + 'token=' + token : '');

    return fetch(Settings.getApiURL() + defUrl, fetchParams).then((response) =>
      response.json(),
    );
  }

  download(url: string, method: string, params?: object): Promise<any> {
    const fetchParams: any = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (params) {
      fetchParams.body = JSON.stringify(params);
    }

    let separator = '?';
    if (url.indexOf('?') !== -1) {
      separator = '&';
    }

    const defUrl = url + (this.token ? separator + 'token=' + this.token : '');

    return fetch(Settings.getApiURL() + defUrl, fetchParams).then(
      (response) => {
        if (response.status === 404) {
          throw new Error('Document not found in the system.');
        }
        return response.blob();
      },
    );
  }
}

export default ApiCaller;
