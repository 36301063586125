export interface ExamContentElement {
  _id: string;
  html: string;
  plainText: string;
  questionName: string;
  questionId: string;
  questionResources?: string[];
  topicName: string;
  topicId: string;
  marker: number;
}

export interface ExamContent {
  questions:number;
  content: ExamContentElement[];
  outerTag: string;
  vars: string[];
}

export interface Metadata {
  descriptionExtended?: string;
  descriptionExtendedHTML?: string;
  description?: string;
  country?: string;
  relatedDocuments?: any[];
  alternativeNames?: string[];
}

interface ExamInfo {
  id?: string;
  title: string;
  langCode?: string;
  source?: string;
  slug: string;
  examContent: ExamContent;
  metadata?: any;
  module: string;
  date: Date;
  parent?: string;
  category?: string;
  createdBy?: any;
  createdOn?: string;
  updatedBy?: string;
  updatedOn?: string;
}

class Exam {
  id?: string;
  title: string;
  langCode?: string;
  source?: string;
  slug: string;
  examContent: ExamContent;
  metadata?: any;
  module: string;
  date: Date;
  parent?: string;
  category?: string;
  createdBy?: any;
  createdOn?: string;
  updatedBy?: string;
  updatedOn?: string;

  constructor(info: ExamInfo) {
    this.id = info.id;
    this.title = info.title;
    this.langCode = info.langCode || 'en-gb';
    this.source = info.source;
    this.slug = info.slug;
    this.examContent = info.examContent;
    this.metadata = info.metadata;
    this.module = info.module;
    this.date = info.date;
    this.parent = info.parent;
    this.category = info.category;
    this.createdBy = info.createdBy;
    this.createdOn = info.createdOn;
    this.updatedBy = info.updatedBy;
    this.updatedOn = info.updatedOn;
  }

  load(data: any): Exam {
    return new Exam(data);
  }
  
  toObject(): any {
    return {
      id: this.id,
      title: this.title,
      langCode: this.langCode,
      source: this.source,
      slug: this.slug,
      examContent: this.examContent,
      metadata: this.metadata,
      module: this.module,
      date: this.date,
      parent: this.parent,
      category: this.category,
      createdBy: this.createdBy,
      createdOn: this.createdOn,
      updatedBy: this.updatedBy,
      updatedOn: this.updatedOn,
    };
  }
}

export class ExamLite {
  id?: string;
  title: string;
  langCode: string;
  slug?: string;
  description?: any;
  parent?: string;
  category?: string;
  
  constructor(info: ExamInfo) {
    this.id = info.id;
    this.langCode = info.langCode  || 'en-gb';
    this.title = info.title;
    this.description = info.metadata.description;
    this.slug = info.slug;
    this.parent = info.parent;
    this.category = info.category;
  }
  
  load(data: ExamInfo): ExamLite {
    return new ExamLite(data);
  }
  
  toObject(): any {
    return {
      id: this.id,
      title: this.title,
      langCode: this.langCode,
      slug: this.slug,
      description: this.description,
      parent: this.parent,
      category: this.category,
    };
  }
}

export default Exam;
