import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';

import {
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  CardHeader,
} from 'reactstrap';

import { AppState } from 'store/store';
import User, {
  UserTypes,
  UserStatus,
  UserTypesLabels,
} from 'lib/common/models/user';
import { AssignableObjects } from 'lib/common/models/userRole';
import Settings from 'lib/settings';
import ApiCaller from 'lib/ApiCaller';
import UserApi from 'api/UserApi';
import UserRoleList from './userRoleList';
import { generateErrors, is } from 'shared/utils/validations';
import { Form as BootstrapForm } from 'react-bootstrap';
import { UserSecondaryProfiles } from 'lib/common/models/userPreference';
import { useExamCenter } from 'hooks';
import PasswordInput from 'views/components/input/PasswordInput';

const UserDetail: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [selectedUser, updateSelectedUser] = useState<User>();
  const [newPassword, setNewPassword] = React.useState({
    password: '',
    passwordConfirm: '',
  });
  const { examCenters: entities } = useExamCenter();
  const [fieldState, setFieldState] = React.useState({
    passwordState: '',
    passwordConfirmState: '',
  });
  const history = useHistory();

  React.useEffect(() => {
    updateSelectedUser(Settings.getCurrentAdminUser());
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const userInfo = selectedUser?.toObject();

    if (userInfo) {
      userInfo[name] = value;
      updateSelectedUser(new User(userInfo));
    }
  };

  const typeChange = (e: any) => {
    const userInfo = selectedUser?.toObject();

    if (userInfo) {
      userInfo['type'] = e.value;

      updateSelectedUser(new User(userInfo));
    }
  };

  const passwordOnChange = (e: any, compareField: string) => {
    const password = e.target.value;

    const errors: any = generateErrors(
      { password },
      { password: [is.securePassword()] },
    );

    const newState =
      e.target.value == newPassword[compareField] && !errors.password
        ? 'has-success'
        : 'has-danger';
    const confirmNewState =
      e.target.value == newPassword[compareField]
        ? 'has-success'
        : 'has-danger';

    setFieldState({
      passwordState: newState,
      passwordConfirmState: confirmNewState,
    });

    setNewPassword({
      ...newPassword,
      [e.target.name]: e.target.value,
    });
  };

  const status = (e: any) => {
    const userInfo = selectedUser?.toObject();

    if (userInfo) {
      userInfo['status'] = e.value;

      updateSelectedUser(new User(userInfo));
    }
  };

  const onEntityChange = (e: any) => {
    const userInfo = selectedUser?.toObject();

    if (userInfo) {
      userInfo['entityId'] = e;
      updateSelectedUser(new User(userInfo));
    }
  };

  const updateUser = () => {
    if (selectedUser) {
      const userApi = new UserApi(new ApiCaller());
      userApi
        .updateUserAdmin(selectedUser, loggedUser.token)
        .then((user: any) => {
          history.push('/navozyme/user/list');
          toast.info('Updated Successfully');
        })
        .catch((err) => {
          console.log(err);
          toast.error('Error updating user, please try again');
        });
    }
  };

  const updatePassword = (e: React.FormEvent) => {
    e.preventDefault();

    if (selectedUser) {
      const { password } = newPassword;

      const errors: any = generateErrors(
        { password },
        {
          password: [
            is.required(),
            is.minLength(3),
            is.maxLength(30),
            is.securePassword(),
          ],
        },
      );

      if (errors.password) {
        toast.error(errors.password);
        return;
      }

      const userApi = new UserApi(new ApiCaller());
      userApi
        .updatePasswordAdmin(
          selectedUser._id!,
          newPassword.password,
          loggedUser.token,
        )
        .then(() => {
          toast.success('Password updated');
          setNewPassword({ password: '', passwordConfirm: '' });
        })
        .catch(() => {
          toast.error('Error changing password');
        });
    }
  };

  const sendResetPasswordEmail = () => {
    const userApi = new UserApi(new ApiCaller());
    userApi
      .sendResetPasswordEmail(selectedUser?.email!, loggedUser.token)
      .then((data) => {
        if (data.status == 'ok')
          toast.success('Reset password email sent successfully.');
        else throw Error(data.status);
      })
      .catch((err) => {
        toast.error(`Error: ${err.message}`);
      });
  };

  const [profileGrid, setProfileGrid] = useState<any>();

  React.useEffect(() => {
    const userApi = new UserApi(new ApiCaller(loggedUser.token));
    selectedUser &&
      userApi
        .getSecondaryProfile(selectedUser?._id!)
        .then((data: UserSecondaryProfiles) => {
          setProfileGrid(data.secondaryProfiles);
        });
  }, [selectedUser]);

  const handleEProfileChange = (changedType: any) => {
    let newProfileGrid = {
      ...profileGrid,
      [changedType]: !profileGrid[changedType],
    };
    setProfileGrid(newProfileGrid);
    const userApi = new UserApi(new ApiCaller());
    userApi
      .updateUserSecondaryProfile(
        newProfileGrid,
        selectedUser?._id!,
        loggedUser.token,
      )
      .then((data: UserSecondaryProfiles) => {
        toast.success('Secondary Profile Updated');
      });
  };

  return (
    <>
      <div className="content">
        {loggedUser.isAdmin() && selectedUser && (
          <>
            <Row>
              <Col md="6">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update User</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form className="form-horizontal">
                      <Row>
                        <Label sm="2">Name</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              value={selectedUser.name}
                              onChange={handleChange}
                              type="text"
                              name="name"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Email</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              value={selectedUser.email}
                              onChange={handleChange}
                              type="text"
                              name="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Type</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="singleSelect"
                              value={{
                                label: UserTypes[selectedUser.type],
                                value: selectedUser.type,
                              }}
                              onChange={typeChange}
                              options={Object.keys(UserTypes).map(function (
                                type,
                              ) {
                                return {
                                  value: type,
                                  label: UserTypes[type],
                                };
                              })}
                              placeholder="User Type"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Status</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="singleSelect"
                              value={{
                                label: UserStatus[selectedUser.status],
                                value: selectedUser.status,
                              }}
                              onChange={status}
                              options={Object.keys(UserStatus).map(function (
                                status,
                              ) {
                                return {
                                  value: status,
                                  label: UserStatus[status],
                                };
                              })}
                              placeholder="User Type"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {selectedUser.isType(UserTypesLabels.examCentre) && (
                        <Row>
                          <Label sm="2">Exam Center</Label>
                          <Col sm="10">
                            <FormGroup>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="entityId"
                                value={selectedUser.entityId}
                                onChange={onEntityChange}
                                options={entities?.centers}
                                getOptionLabel={(item) =>
                                  item.code + ' ' + item.name
                                }
                                getOptionValue={(item) => item.code}
                                placeholder="Choose an exam center"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Label sm="2" />
                        <Col sm="10">
                          <Button
                            className="btn-round"
                            color="warning"
                            onClick={updateUser}>
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Change Password</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={updatePassword}>
                      <label>New Password</label>
                      <FormGroup
                        className={`has-label ${fieldState['passwordState']}`}>
                        <PasswordInput
                          placeholder="New Password"
                          name="password"
                          value={newPassword.password}
                          onChange={(e) =>
                            passwordOnChange(e, 'passwordConfirm')
                          }
                        />
                        {fieldState['passwordState'] === 'has-danger' ? (
                          <label className="error">
                            Password strength to access system must be at least
                            8 characters, and include one or more capital
                            letters, numbers and symbols.{' '}
                          </label>
                        ) : null}
                      </FormGroup>
                      <label>Confirm Password</label>
                      <FormGroup
                        className={`has-label ${fieldState['passwordConfirmState']}`}>
                        <PasswordInput
                          placeholder="Confirm Password"
                          name="passwordConfirm"
                          value={newPassword.passwordConfirm}
                          onChange={(e) => passwordOnChange(e, 'password')}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Col sm="10">
                          <Button
                            className="btn-round"
                            color="warning"
                            type="submit"
                            disabled={
                              !newPassword.password ||
                              newPassword.password !==
                                newPassword.passwordConfirm
                            }>
                            Change password
                          </Button>
                          or
                          <Button
                            className="btn-round"
                            color="info"
                            onClick={sendResetPasswordEmail}>
                            Send reset email
                          </Button>
                        </Col>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {selectedUser?.hasSecondaryPermissions() && (
              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Secondary Profiles</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form className="form-horizontal">
                        {profileGrid &&
                          Object.keys(profileGrid)
                            .filter((item) => item !== selectedUser.type)
                            .map((type: string) => (
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <div className="card-customized">
                                      <BootstrapForm.Check
                                        type="switch"
                                        checked={profileGrid[type]}
                                        onClick={() =>
                                          handleEProfileChange(type)
                                        }
                                        id={`${type}-switch`}
                                      />
                                      <p>{UserTypes[type]}</p>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            ))}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </>
        )}
        {selectedUser?.needsModulePermission() && (
          <UserRoleList objectName={AssignableObjects.Module} />
        )}
        {selectedUser?.needsSyllabusPermission() && (
          <UserRoleList objectName={AssignableObjects.Syllabus} />
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default UserDetail;
