import React, { useState, FC } from 'react';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Input, InputProps, InputGroupText, InputGroup } from 'reactstrap';

type PasswordInputProps = InputProps & {
  autoComplete?: string;
};

const PasswordInput: FC<PasswordInputProps> = ({
  autoComplete = 'off',
  ...other
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputGroup>
      <Input
        type={showPassword ? 'text' : 'password'}
        autoComplete={autoComplete}
        {...other}
      />
      <InputGroupText
        onClick={() => setShowPassword(!showPassword)}
        style={{
          cursor: 'pointer',
          borderLeft: '0 none',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}>
        {showPassword ? (
          <VisibilityOffIcon fontSize="small" />
        ) : (
          <VisibilityIcon fontSize="small" />
        )}
      </InputGroupText>
    </InputGroup>
  );
};

export default PasswordInput;
