import React, { useState, useEffect } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../store/store';
import Settings from '../../../../lib/settings';
import ApiCaller from '../../../../lib/ApiCaller';
import NavigationExam from '../../../../lib//common/models/navigationExam';

import PaymentApi from '../../../../api/PaymentApi';
import TransactionApi from '../../../../api/TransactionApi';
import { formatCurrency } from '../../../../shared/utils/currency';
import {
  defaultCurrency,
  IPaymentOrder,
  PaymentTypeLabels,
} from '../../../../lib/common/models/transaction';

import '../stripeComponent/CheckoutComponent.css';

interface IPaymentComponentProps {
  onCancel: Function;
  onSuccess: Function;
  paymentType: string;
  reference?: string;
  certificateId?: string;
  disableConfirm?: boolean;
}

const NavigationExamPayComponent: React.FC<IPaymentComponentProps> = ({
  onCancel,
  onSuccess,
  paymentType,
  reference,
  certificateId = '',
  disableConfirm = false
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const history = useHistory();
  const [balanceState, setBalanceState] = React.useState({
    balance: 0,
    timestamp: 0,
  });
  const [currentExam, setCurrentExam] = useState<NavigationExam>();
  const [order, setOrder] = React.useState<IPaymentOrder>({
    amount: 0,
    concept: 'Concept',
    quantity: 0,
  });
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState('');
  const [cancel, setCancel] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [confirmAction, setConfirmAction] = useState(false);
  const [transaction, setTransaction] = useState();
  const [poNumber, setPoNumber] = useState('');

  const getCancelAlertMessage = () => {
    return currentExam?.isSoftDeadlineReached()
      ? 'Late exam cancellation fee applied'
      : 'Exam cancellation refund';
  };

  const componentLabels = {
    [PaymentTypeLabels.PAYMENT]: {
      generalDescription: 'Add extra candidate',
      generalAmount: 'Amount to pay',
      confirmTitle: `${formatCurrency(
        Math.abs(order.amount),
        defaultCurrency,
      )} will be deducted from your account`,
      confirmButton: 'Confirm Payment',
      errorTitle: 'Payment failed',
      successTitle: 'Payment succeeded',
      successMessage: 'Candidate payment completed',
      cancelTitle: 'Payment cancelled',
      cancelMessage: 'Candidate payment cancelled',
      transactionReference: `Add candidate ${reference}`,
    },
    [PaymentTypeLabels.REFUND]: {
      generalDescription: 'Refund candidate fee',
      generalAmount: 'Refund amount',
      confirmTitle: `${formatCurrency(
        order.amount,
        defaultCurrency,
      )} will be refunded to your account`,
      confirmButton: 'Confirm refund',
      errorTitle: 'Refund failed',
      successTitle: 'Refund succeeded',
      successMessage: 'Candidate refund completed',
      cancelTitle: 'Refund cancelled',
      cancelMessage: 'Candidate refund cancelled',
      transactionReference: `Remove candidate ${reference}`,
    },
    [PaymentTypeLabels.LATE_FEE]: {
      generalDescription: 'Late Request Fee',
      generalAmount: 'Amount to pay',
      confirmTitle: `${formatCurrency(
        Math.abs(order.amount),
        defaultCurrency,
      )} will be deducted from your account`,
      confirmButton: 'Confirm payment',
      errorTitle: 'Payment failed',
      successTitle: 'Payment succeeded',
      successMessage: 'Exam request completed',
      cancelTitle: 'Payment cancelled',
      cancelMessage: 'Exam request cancelled',
      transactionReference: `Late request fee ${reference}`,
    },
    [PaymentTypeLabels.CANCELLATION]: {
      generalDescription: getCancelAlertMessage(),
      generalAmount: `Amount to ${order.amount > 0 ? 'refund' : 'pay'}`,
      confirmTitle: `${formatCurrency(
        Math.abs(order.amount),
        defaultCurrency,
      )} will be ${
        order.amount > 0 ? 'added to' : 'deducted from'
      } your account`,
      confirmButton: 'Confirm cancellation',
      errorTitle: 'Cancellation failed',
      successTitle: 'Cancellation succeeded',
      successMessage: 'Exam cancellation completed',
      cancelTitle: 'Operation cancelled',
      cancelMessage: 'Exam cancellation aborted',
      transactionReference: getCancelAlertMessage(),
    },
    [PaymentTypeLabels.CERTIFICATE_REISSUE_FEE]: {
      generalDescription: 'Certificate Reissue Fee. Only reissue if the candidate’s data has been amended',
      generalAmount: 'Amount to pay',
      confirmTitle: `${formatCurrency(
        Math.abs(order.amount),
        defaultCurrency,
      )} will be will be deducted from your account`,
      confirmButton: 'Confirm payment',
      errorTitle: 'Payment failed',
      successTitle: 'Payment succeeded',
      successMessage: 'Certificate has been reissued',
      cancelTitle: 'Payment cancelled',
      cancelMessage: 'Certificate reissue cancelled',
      transactionReference: `Certificate Reissue Fee ${reference}`,
    },
  };

  useEffect(() => {
    const exam = Settings.getCurrentNavigationExam();
    setCurrentExam(exam);

    const transactionApi = new TransactionApi(new ApiCaller(loggedUser.token));
    transactionApi
      .getBalance()
      .then((data) => {
        setBalanceState(data);
      })
      .catch(() => {
        toast.error('Error retrieving user balance');
      });

    const paymentApi = new PaymentApi(new ApiCaller(loggedUser.token));
    paymentApi
      .getPaymentAmount(paymentType, exam?.id)
      .then((data: IPaymentOrder) => {
        setOrder(data);
      })
      .catch(() => {
        toast.error('Error retrieving payment amount');
      });
  }, [loggedUser, paymentType]);

  const handleChange = (e: any) => {
    setPoNumber(e.target.value);
  };

  const goToProfile = () => {
    history.push(`/${layout}/user-profile`);
  };

  const confirmPayment = () => {
    setConfirmAction(true);
    const reference = poNumber
      ? `${componentLabels[paymentType].transactionReference} [${poNumber}]`
      : componentLabels[paymentType].transactionReference;

    const paymentApi = new PaymentApi(new ApiCaller());
    const examId = paymentType === PaymentTypeLabels.CERTIFICATE_REISSUE_FEE ? null : currentExam?.id;
    paymentApi
      .generatePayment(
        examId,
        paymentType,
        reference,
        certificateId,
        loggedUser.token,
      )
      .then((data: any) => {
        setTransaction(data);
        setProcessing(false);
        setSucceeded(true);
        setConfirmAction(false);
      })
      .catch((err) => {
        setProcessing(false);
        setError(err.message);
        setConfirmAction(false);
      });
  };

  const cancelPayment = () => {
    setProcessing(false);
    setCancel(true);
  };

  const close = () => {
    setCancel(false);
    onCancel();
  };

  const success = () => {
    setSucceeded(false);
    onSuccess(transaction);
  };

  return (
    <>
      <Form className="form-horizontal">
        <Row>
          <Label sm="3">
            <b>Description</b>
          </Label>
          <Col sm="9">
            <FormGroup>
              <p className="form-control-static">
                {componentLabels[paymentType].generalDescription}
              </p>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Label sm="3">
            <b>{componentLabels[paymentType].generalAmount}</b>
          </Label>
          <Col sm="9">
            <FormGroup>
              <p className="form-control-static">
                {formatCurrency(Math.abs(order.amount), defaultCurrency)}
              </p>
            </FormGroup>
          </Col>
        </Row>
        {paymentType == PaymentTypeLabels.PAYMENT && (
          <Row>
            <Label sm="3">
              <b>PO Number</b>
            </Label>
            <Col sm="9">
              <FormGroup>
                <Input
                  value={poNumber}
                  onChange={handleChange}
                  type="text"
                  name="poNumber"
                  placeholder="PO Number (optional)"
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Label sm="3" />
          <Col sm="9">
            <Button className="btn-round btn btn-danger" onClick={close} disabled={disableConfirm}>
              Cancel
            </Button>
            <Button
              className="btn-round btn btn-success"
              onClick={() => setProcessing(true)}
              disabled={!order.quantity || disableConfirm}>
              Confirm
            </Button>
          </Col>
        </Row>
      </Form>
      {processing && (
        <ReactBSAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title={componentLabels[paymentType].confirmTitle}
          onConfirm={() => confirmPayment()}
          customButtons={
            <>
              <Button
                className="btn-md"
                color="danger"
                disabled={confirmAction}
                onClick={cancelPayment}>
                Cancel
              </Button>
              <Button className="btn-md" color="info" disabled={confirmAction} onClick={confirmPayment}>
                {componentLabels[paymentType].confirmButton}
              </Button>
            </>
          }>
          Current balance:{' '}
          {formatCurrency(balanceState.balance, defaultCurrency)}
        </ReactBSAlert>
      )}
      {error && (
        <ReactBSAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={componentLabels[paymentType].errorTitle}
          onConfirm={() => goToProfile()}
          onCancel={() => goToProfile()}
          confirmBtnBsStyle="danger"
          btnSize=""
          confirmBtnText="Add Credit">
          {error}
        </ReactBSAlert>
      )}
      {succeeded && (
        <ReactBSAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title={componentLabels[paymentType].successTitle}
          onConfirm={() => success()}
          onCancel={() => success()}
          confirmBtnBsStyle="success"
          btnSize=""
          confirmBtnText="Return">
          {componentLabels[paymentType].successMessage}
        </ReactBSAlert>
      )}
      {cancel && (
        <ReactBSAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title={componentLabels[paymentType].cancelTitle}
          onConfirm={() => close()}
          onCancel={() => close()}
          confirmBtnBsStyle="info"
          btnSize="">
          {componentLabels[paymentType].cancelMessage}
        </ReactBSAlert>
      )}
      <ToastContainer />
    </>
  );
};

export default NavigationExamPayComponent;
