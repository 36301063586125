import React from "react";

interface ContainerProps {
    bgcolor: string;
    label: string;
    completed:number
}

const ProgressBar:React.FC<ContainerProps> = ({ bgcolor, completed, label }) => {

    const containerStyles = {
        height: 20,
        width: '80%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        margin: 50
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
    }

    return (
        <>
            <div style={containerStyles}>
                <div style={fillerStyles}>
                    <span style={labelStyles}>{`${completed}%`}</span>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;
