
import React, {useState} from "react";

import {Badge, Button, Card, CardHeader, CardBody, CardFooter, CardTitle, Table, Row, Col,} from "reactstrap";
import Question from "../../../../lib/common/models/question";
import Track from "../../../../lib/common/models/track";
import TrackApi from "../../../../api/TrackApi";
import ApiCaller from "../../../../lib/ApiCaller";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store/store";
import {formatDate, formatDateTime} from "../../../../shared/utils/dateTime";

interface props {
    question:Question;
}

const TrackQuestions : React.FC<props> = (props) => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const [track, setTracks] = useState<Track[]>([]);

    React.useEffect(() => {
        const api = new TrackApi(new ApiCaller(loggedUser.token));
        api.questions().then((c: Track[]) => setTracks(c));
    }, []);

    return (
        <>
            <div className="content">
                <Card>
                    <CardHeader>
                        <h4 className="card-title">Question Activity</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="table-full-width table-responsive">
                            <Table>
                                <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Question</th>
                                    <th>User</th>
                                    <th className="text-right">Time</th>
                                </tr>
                                </thead>
                                <tbody>
                                {track &&
                                track.map((item) => <tr>
                                        <td>{item.url.split('/')[4]}</td>
                                        <td className="text-left">{item.questionId?.name}</td>
                                        <td>{item.userId?.name}</td>
                                        <td className="td-actions text-right">{formatDateTime(item.updatedAt)}</td>
                                    </tr>
                                ) }
                                </tbody>
                            </Table>


                        </div>
                    </CardBody>
                </Card>
            </div>
        </>
    );

}

export default TrackQuestions;
