import ApiCaller from '../lib/ApiCaller';
import Transaction, { IBalance } from '../lib/common/models/transaction';
import TransactionCollection from '../lib/common/models/transactionCollection';

class TransactionApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getTransactions(centreId?: string): Promise<TransactionCollection | void> {
    const url = centreId
      ? `/v1/transaction/centre/${centreId}`
      : '/v1/transaction';
    return this.apiCaller
      .call(url, 'GET')
      .then((data) => {
        const apiArr = data.transactions.map(
          (transaction: any) => new Transaction(transaction),
        );
        return new TransactionCollection(apiArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err.message);
      });
  }

  getExamPayments(examId: string): Promise<TransactionCollection | void> {
    return this.apiCaller
      .call('/v1/transaction/exam/' + examId, 'GET')
      .then((data) => {
        const apiArr = data.transactions.map(
          (transaction: any) => new Transaction(transaction),
        );
        return new TransactionCollection(apiArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err.message);
      });
  }

  getBalance(centreId?: string): Promise<IBalance> {
    const url = centreId
      ? `/v1/transaction/balance/${centreId}`
      : '/v1/transaction/balance';
    return this.apiCaller
      .call(url, 'GET')
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err.message);
      });
  }

  getReceipt(transactionId: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/transaction/receipt/' + transactionId, 'GET')
      .then((file) => {
        return file;
      });
  }

  confirmTransaction(id: string, token?: string): Promise<Transaction | void> {
    return this.apiCaller
      .call('/v1/transaction/confirmed/' + id, 'POST', {}, token)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  failTransaction(id: string, token?: string): Promise<Transaction | void> {
    return this.apiCaller
      .call('/v1/transaction/failed/' + id, 'POST', {}, token)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  cancelTransactionByReference(
    reference: string,
    token?: string,
  ): Promise<Transaction | void> {
    return this.apiCaller
      .call('/v1/transaction/cancel/' + reference, 'POST', {}, token)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  createTransaction(
    transaction: Transaction,
    token?: string,
  ): Promise<Transaction | void> {
    return this.apiCaller.call(
      '/v1/transaction',
      'PUT',
      {
        userId: transaction.userId._id,
        amount: transaction.amount,
        currency: transaction.currency,
        description: transaction.description,
        reference: transaction.reference,
        paymentMethod: transaction.paymentMethod,
        examId: transaction.examId ? transaction.examId._id : null,
        entityId: transaction.entityId ? transaction.entityId._id : null,
      },
      token,
    );
  }

  revertTransaction(
    transactionId: string,
    userId: string,
    token?: string,
  ): Promise<void | { error: string }> {
    return this.apiCaller.call(
      '/v1/transaction/revert/' + transactionId,
      'PUT',
      {
        userId: userId,
      },
      token,
    );
  }
}

export default TransactionApi;
