import ApiCaller from '../lib/ApiCaller';
import UserCollection from '../lib/common/models/userCollection';
import User from '../lib/common/models/user';
import UserRole from '../lib/common/models/userRole';
import UserPreference,{UserSecondaryProfiles} from '../lib/common/models/userPreference';
import Settings from '../lib/settings';
import {SessionState} from "../store/reducers/sessionReducer";
import {updateSession} from "../store/actions/session/sessionActions";

class UserApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getAllUsers(token?: string): Promise<UserCollection | void> {
    return this.apiCaller
      .call('/v1/admin/getAllUser', 'GET', undefined, token)
      .then((data) => {
        const userArr = data.users.map((user: any) => new User(user));
        return new UserCollection(userArr);
      })
      .catch((err) => {
        console.error(err);
        alert('Error fetching users');
      });
  }

  getUsersByType(type: string): Promise<UserCollection | void> {
    return this.apiCaller
      .call('/v1/admin/getUserByType/' + type, 'GET', undefined)
      .then((data) => {
        const apiArr = data.users.map((user: any) => new User(user));
        return new UserCollection(apiArr);
      });
  }

  getUsersByPage(
    recordsByPage?: number,
    page?: number,
  ): Promise<UserCollection | void> {
    return this.apiCaller
      .call(
        '/v1/rest/user?recordsByPage=' + recordsByPage + '&page=' + page,
        'GET',
      )
      .then((data) => {
        const userArr = data.items.map((user: any) => new User(user));
        return new UserCollection(userArr);
      })
      .catch((err) => {
        console.error(err);
        alert('Error fetching users');
      });
  }

  create(
    email: string,
    password: string,
    name: string,
    type: string,
    entityId?: any,
    token?: string,
  ): Promise<void | User> {
    return this.apiCaller
      .call(
        '/v1/admin/register',
        'POST',
        {
          name: name,
          email: email,
          password: password,
          type: type,
          entityId: entityId?._id,
        },
        token,
      )
      .then((data) => {
        return new User(data);
      })
      .catch((err) => {
        console.error(err);
        alert('Error creating user, please try again.');
      });
  }

  updateUser(user: User, token?: string): Promise<void | User> {
    return this.apiCaller
      .call(
        '/v1/admin/update',
        'PUT',
        {
          name: user.name,
          email: user.email,
          type: user.type,
          status: user.status,
        },
        token,
      )
      .then((data) => {
        return new User(data);
      })
      .catch((err: any) => {
        console.error(err);
        throw Error(err);
      });
  }

  updateUserAdmin(user: User, token?: string): Promise<void | User> {
    return this.apiCaller
      .call(
        '/v1/admin/update/' + user._id,
        'PUT',
        {
          name: user.name,
          email: user.email,
          type: user.type,
          status: user.status,
          entityId: user.entityId?._id,
        },
        token,
      )
      .then((data) => {
        return new User(data);
      })
      .catch((err: any) => {
        console.error(err);
        throw Error(err);
      });
  }

  updatePasswordAdmin(
    userId: string,
    password: string,
    token?: string,
  ): Promise<void | User> {
    return this.apiCaller
      .call(
        '/v1/admin/updatePassword/' + userId,
        'PUT',
        {
          password: password,
        },
        token,
      )
      .then((data) => {
        return new User(data);
      })
      .catch((err: any) => {
        console.error(err);
        throw Error(err);
      });
  }

  deleteUser(userId: string, token?: string): Promise<void> {
    return this.apiCaller
      .call(
        '/v1/rest/user/' + userId,
        'DELETE',
        {
          id: userId,
        },
        token,
      )
      .catch((err) => {
        console.error(err);
        alert('Error deleting user, please try again');
      });
  }

  getAvatar(userId: string, token?: string): Promise<void | any> {
    return this.apiCaller
      .call('/v1/image/user/', 'GET', undefined, token)
      .then((data) => {
        if (data) {
          return data?.src;
        }
      })
      .catch((err) => {
        throw Error(err);
      });
  }

  getAvatarUrl(userId: string): string {
    return Settings.getApiURL() + '/v1/image/user/' + userId;
  }

  setAvatar(userId: any, data: any, token?: string): Promise<void> {
    const params = new FormData();

    params.append('image', data, userId);

    return this.apiCaller
      .callImage('/v1/image/user', 'POST', params, token)
      .then((res) => {
        return res.message;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getMyStats(token?: string): Promise<any> {
    return this.apiCaller
      .call('/v1/dashboard/getMyStats', 'GET', undefined, token)
      .then((data: any) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getFinancialStats(token?: string): Promise<any> {
    return this.apiCaller
      .call('/v1/dashboard/getFinancialStats', 'GET', undefined, token)
      .then((data: any) => {
        if (data.error) {
          throw Error('Error retrieving dashboard data.');
        }
        return data;
      })
      .catch((err) => {
          throw Error(err);
      });
  }

  getUserRole(userId: string, objectName: string): Promise<void | UserRole> {
    return this.apiCaller
      .call(`/v1/details/getRole/${userId}/${objectName}`, 'GET')
      .then((data: any) => {
        return new UserRole(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateUserRoles(
    userId: string,
    objectName: string,
    objectIds: Array<string>,
    token?: string,
  ): Promise<void | UserRole> {
    return this.apiCaller
      .call(
        '/v1/details/addRole',
        'POST',
        {
          userId: userId,
          objectName: objectName,
          objectIds: objectIds,
        },
        token,
      )
      .then((data: any) => {
        return new UserRole(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  sendResetPasswordEmail(email: string, token?: string): Promise<any> {
    return this.apiCaller
      .call(
        '/v1/admin/resetPassword',
        'POST',
        {
          email: email,
        },
        token,
      )
      .then((data: any) => {
        if (data.error) {
          throw new Error(data.error);
        } else {
          return data;
        }
      })
      .catch((err) => {
        console.error(err.message);
        throw err;
      });
  }

  getUserPreference(): Promise<any> {
    return this.apiCaller
      .call('/v1/userPreferences/', 'GET')
      .then((data: any) => {
        return new UserPreference(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateUserPreference(preferences: any, token: string): Promise<any> {
    return this.apiCaller
      .call('/v1/userPreferences/', 'PUT', { ...preferences }, token)
      .then((data: any) => {
        return new UserPreference(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  updateUserSecondaryProfile(secondaryProfiles: any, userId:string, token: string): Promise<any> {
    return this.apiCaller
      .call('/v1/userPreferences/userSecondaryProfile/', 'PUT', {userId, secondaryProfiles}, token)
      .then((data: any) => {
        return new UserSecondaryProfiles(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

    getSecondaryProfile(userId:string): Promise<any> {
        return this.apiCaller
            .call(`/v1/userPreferences/userSecondaryProfile/${userId}`, 'GET')
            .then((data: any) => {
                return new UserSecondaryProfiles(data);
            })
            .catch((err) => {
                console.error(err);
            });
    }
    switchProfile(profile:string, token: string){
    }
}

export default UserApi;
