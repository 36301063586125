import ExamCertificate from "./examCertificate";

class ExamCertificateCollection {
    certificates: Array<ExamCertificate>;

    constructor(certificates: ExamCertificate[]) {
        this.certificates = certificates;
    }

    length() {
        return this.certificates.length;
    }
}

export default ExamCertificateCollection;
