export const getJwtExpiration = (jwtToken: string): number => {
    try {
        if (!jwtToken) return 0;

        const tokenParts = jwtToken.split('.');

        const payload = JSON.parse(atob(tokenParts[1]));

        return payload.exp;
    } catch (err) {
        console.error('Failed to decode JWT', err);
        throw err;
    }
}
