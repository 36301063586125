import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Col, Row, Label, FormGroup } from 'reactstrap';

import QuestionApi from 'api/QuestionApi';
import ApiCaller from 'lib/ApiCaller';
import { AppState } from 'store/store';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';

import Question, { QuestionInfo } from 'lib/common/models/question';

import 'react-toastify/dist/ReactToastify.css';
import Topic from 'lib/common/models/topic';
import Settings from 'lib/settings';
import { useHistory } from 'react-router-dom';

interface IQuestionMoveModal {
  initialQuestion: Question;
  onClose: () => void;
}

const QuestionMoveModal: React.FC<IQuestionMoveModal> = ({
  initialQuestion,
  onClose,
}) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const history = useHistory();

  const [modules, setModules] = useState<any[]>([]);
  const [topics, setTopics] = useState<any[]>([]);
  const [question, setQuestion] = useState<Question>(initialQuestion);

  const questionApi = new QuestionApi(new ApiCaller(loggedUser.token));

  useEffect(() => {
    if (question.syllabus) {
      questionApi
        .getModulesFromSyllabus(question.syllabus._id)
        .then((m: any) => {
          setModules(m.modules);
        });
    }
  }, [question.syllabus?._id]);

  useEffect(() => {
    if (question.module) {
      questionApi.getTopicsFromModule(question.module._id).then((t: any) => {
        setTopics(t.topics);
        if (
          t.topics.length &&
          !t.topics.find((topic: Topic) => topic._id === question.topic._id)
        ) {
          setQuestion(
            new Question({ ...question, topic: t.topics[0] } as QuestionInfo),
          );
        }
      });
    }
  }, [question.module?._id]);

  return (
    <ConfirmationModal
      processingAction={
        question.module._id === initialQuestion.module._id &&
        question.topic._id === initialQuestion.topic._id
      }
      style={{ maxWidth: '600px', width: '100%', overflow: 'visible' }}
      title={`Move question ${question.displayName}`}
      confirmBtnText="Move"
      cancelBtnText="Close"
      onConfirm={() => {
        if (question?.id) {
          questionApi
            .moveQuestion(
              question.id,
              question.module._id,
              question.topic._id,
              loggedUser.token,
            )
            .then((newQuestion) => {
              Settings.setCurrentAdminQuestion(newQuestion);
              const layout =
                loggedUser.type === 'admin'
                  ? 'navozyme'
                  : loggedUser.type.toLowerCase();
              history.push(`/${layout}/question/detail`);
              toast.success('Question has been moved');
            });
        }
        onClose();
      }}
      onCancel={onClose}
      text="Select new topic and/or module:">
      <Row>
        <Label sm="3">Module</Label>
        <Col sm="9">
          <FormGroup>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="module"
              value={question?.module}
              onChange={(module) =>
                setQuestion(
                  new Question({ ...question, module } as QuestionInfo),
                )
              }
              options={modules}
              getOptionLabel={(item) => item.code + ' ' + item.title}
              getOptionValue={(item) => item.code}
              placeholder="Choose a module"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Label sm="3">Topic</Label>
        <Col sm="9">
          <FormGroup>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="topic"
              value={question?.topic}
              onChange={(topic) =>
                setQuestion(
                  new Question({ ...question, topic } as QuestionInfo),
                )
              }
              options={topics}
              getOptionLabel={(item) => item.code + ' ' + item.title}
              getOptionValue={(item) => item.code}
              placeholder="Choose a topic"
            />
          </FormGroup>
        </Col>
      </Row>
    </ConfirmationModal>
  );
};

export default QuestionMoveModal;
