import ApiCaller from '../lib/ApiCaller';
import CandidateCollection from '../lib/common/models/candidateCollection';
import Candidate from "../lib/common/models/candidate";


class MarkerApi {

    apiCaller: ApiCaller;

    constructor(apiCaller:ApiCaller) {
        this.apiCaller = apiCaller;
    }

    getCandidates(navigationExamId: string): Promise<void | CandidateCollection> {
        return this.apiCaller.call(
            '/v1/navigationExam/candidates/' + navigationExamId,
            'GET'
        ).then((data) => {
            const apiArr = data.candidates.map((candidate: any) => new Candidate(candidate));
            return new CandidateCollection(apiArr);
        }).catch(err => {
            console.error(err);
            throw Error(err);
        });
    }
    getMarkerInitialData(navigationExamId: string): Promise<void | any> {
        return this.apiCaller.call(
            '/v1/marker/' + navigationExamId,
            'GET'
        ).then((data) => {
            return data;
        }).catch(err => {
            console.error(err);
            throw Error(err);
        });
    }

    updateMarks(questions: any, payload:any, examId: string, token?: string): Promise<void|any> {
        let params = { payload: payload, questions: questions, examId: examId };
        return this.apiCaller.call(`/v1/marker/${examId}`, 'POST', params, token).then(data => {
            return { grid: data.grid, results: data.results };
        }).catch(err => {
            console.error(err);
            alert('Error updating marker');
        });
    }

    submitMarks(payload: any, examId: string, token?: string): Promise<void | any> {
        let params = {payload: payload};
        return this.apiCaller.call(`/v1/marker/${examId}`, 'PUT', params, token).then(data => {
            if (data.error) throw Error(data.error);
            return data;
        }).catch(err => {
            console.error(err);
            throw Error(err.message)
        });
    }
    reviewCandidateMark(payload: any, examId: string, forcedResult: any, token?: string): Promise<void | any> {
        let params = {payload: payload, forcedResult: forcedResult };
        return this.apiCaller.call(`/v1/marker/review/${examId}`, 'PUT', params, token).then(data => {
            if (data.error) throw Error(data.error);
            return data;
        }).catch(err => {
            console.error(err);
            throw Error(err.message)
        });
    }
    submitRevissionMarks(payload: any, examId: string, token?: string): Promise<void | any> {
        let params = {payload: payload};
        return this.apiCaller.call(`/v1/marker/revission/${examId}`, 'PUT', params, token).then(data => {
            if (data.error) throw Error(data.error);
            return data;
        }).catch(err => {
            console.error(err);
            throw Error(err.message)
        });
    }
}

export default MarkerApi;
