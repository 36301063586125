import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import FileViewer from 'react-file-viewer';

import {Button, Card, CardBody, Col, Modal, Row,} from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { toast, ToastContainer } from 'react-toastify';

import {downloadAnswers, downloadDocument, downloadExamByExamId,} from '../../../shared/utils/ApiCommands';
import { AppState } from '../../../store/store';
import ApiCaller from '../../../lib/ApiCaller';
import DocumentsApi from '../../../api/DocumentsApi';
import MarkerApi from '../../../api/MarkerApi';
import QuestionApi from '../../../api/QuestionApi';
import Settings from '../../../lib/settings';
import { setResults } from '../../../store/actions/marker/markerActions';

import NZDocument, {NZDocumentType,} from '../../../lib/common/models/nzDocument';
import NavigationExam, {NavigationExamStatusLabels,} from '../../../lib/common/models/navigationExam';
import { MarkingGridRow } from '../../../lib/common/models/markingData';
import nzDocument from '../../../lib/common/models/nzDocument';
import ExamMarkDetail from '../ExamMark/ExamMarkDetail';
import ExamMarkTable from './ExamMarkTable';

import './style.css';
import SubmitMarkersPresenter from '../ExamMark/SubmitMarkersPresenter';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';

type MyProps = RouteComponentProps<{
  slug: string;
}>;

const ExamMarkViewOnly: React.FC<MyProps> = () => {
  const history = useHistory();
  const layout = useSelector((state: AppState) => state.session.layout);
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);

  const questions = useSelector((state: AppState) => state.marker.questions);
  const markerGrid = useSelector((state: AppState) => state.marker.markerGrid);
  const results = useSelector((state: AppState) => state.marker.results);
  const historicalGrid = useSelector((state: AppState) => state.marker.historicalGrid);

  const [grid, setGrid] = useState<MarkingGridRow[]>(markerGrid);
  const [exam, setExam] = useState<NavigationExam>();

  const [manualTotal, setManualTotal] = useState<{ [key: string]: number }>({});
  const [checksumTotal, setChecksumTotal] = useState<{ [key: string]: number }>(
    {},
  );

  const [modal, setModal] = useState<boolean>(false);
  const [questionAnswer, setQuestionAnswer] = useState<string>('');
  const [modalDocument, setModalDocument] = useState<boolean>(false);
  const [documentSrc, setDocumentSrc] = useState<any>({
    mimeType: 'app/nothing',
    fileHash: '',
  });

  const [error, setError] = useState<string | undefined>();

  const wmArray = grid;
  const dispatch = useDispatch();

  const [docs, setDocs] = useState<Array<nzDocument>>([]);
  const [markerReport, setMarkerReport] = useState<NZDocument>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const presenter = new SubmitMarkersPresenter(
    loggedUser,
    {
      dispatch: useDispatch(),
      onSubmitted: () => {
        history.push(`/${layout}/navigation_exam/markers`);
        toast.success('Marks submitted successfully');
      },
    },
    setSubmitting,
  );

  useEffect(() => {
    if (exam) {
      const api = new DocumentsApi(new ApiCaller());
      api
        .getAllByProjectId(exam?.id!)
        .then((item: any) => {
          setDocs(item.files);
          setMarkerReport(
            item.files.filter(
              (doc: nzDocument) => doc.type === NZDocumentType.MARKER_REPORT,
            )[0],
          );
        })
        .catch(() => {
          toast.error('No exams scripts found');
        });
    }
  }, [exam]);

  const calcCurrentMarkers = (obj: any) => {
    let calc = 0;
    Object.keys(obj).forEach((k) => {
      if (k === 'id' || k === 'name' || k === 'isVoid' || k === 'questionCount') return;
      const mark = obj[k] === '' ? 0 : parseInt(obj[k]);
      calc = calc + mark;
    });
    return calc;
  };

  const invalidMarkChars = ['+', '-', 'e'];

  const setFieldParams = (index: number, value: string, data: string, field: string,) => {
    if (invalidMarkChars.includes(data)) {
      toast.error(`Mark can not include values like "${data}"`);
      return;
    }
    if (parseInt(value) < 0) {
      toast.error(`Mark can not include values like "${value}"`);
      return;
    }
    const topMark = questions.filter((q) => q.questionName === field)[0].marker;
    if (parseInt(value) > topMark) {
      toast.error(`Marker can not exceed the max marking value (${topMark})`);
      return;
    }
    const myArray = [...wmArray];
    myArray[index][field] = value;
    setGrid(myArray);
    setChecksumTotal({
      ...checksumTotal,
      ...{ [grid[index]['id']]: calcCurrentMarkers(grid[index]) },
    });
  };

  const setCandidateVoid = (index: number, value: boolean) => {
    const myArray = [...wmArray];
    myArray[index].isVoid = value ? 1 : 0;
    setGrid(myArray);
  };

  const checkTotals = useCallback(() => {
    const errors: number[] = [];
    let error = false;
    grid.forEach((row, index) => {
      // @ts-ignore
      const manual = parseInt(manualTotal[row['id']]);
      if (manual !== calcCurrentMarkers(grid[index]) && !row.isVoid) {
        errors.push(index + 1);
        error = true;
      }
    });

    if (errors.length !== 0) {
      toast.error(
        `Some totals do not match the result, rows: ${errors.join(',')}`,
      );
      return true;
    }
    return error;
  }, [grid, manualTotal]);

  const checkErrors = useCallback(() => {
    const errors: number[] = [];
    let error = false;

    grid.forEach((row, index) => {
      // @ts-ignore
      const manual = parseInt(manualTotal[row['id']]);
      const rowForCheckingValues = Object.fromEntries(Object.entries(row).filter(([key, value]) => key !== 'isVoid'));
      const emptyValues = Object.values(rowForCheckingValues).some(
        (element) => !element || element === '',
      );
      if (emptyValues && !row.isVoid) {
        toast.error(
          `Some marks are empty or invalid for the candidate: ${row['name']}`,
        );
        error = true;
      }
      if (manual !== calcCurrentMarkers(grid[index]) && !row.isVoid) {
        errors.push(index + 1);
        error = true;
      }
    });

    if (errors.length !== 0) {
      toast.error(
        `Some totals do not match the result, rows: ${errors.join(',')}`,
      );
      return true;
    }

    if (
      exam?.status === NavigationExamStatusLabels.GENERATED &&
      !markerReport
    ) {
      toast.error('Marker Report must be uploaded');
      return true;
    }

    return error;
  }, [grid, manualTotal, markerReport]);

  const update = useCallback(() => {
    if (!checkTotals() && !loading) {
      setLoading(true);
      const api = new MarkerApi(new ApiCaller());
      api
        .updateMarks(questions, grid, exam?.id!, loggedUser.token)
        .then((data) => {
          toast.info('Exam marks saved successfully');
          dispatch(setResults(data.results));
          setLoading(false);
        })
        .catch(() => {
          toast.error('Error marking exams, please try again');
          setLoading(false);
        });
    }
  }, [grid, questions, exam, loggedUser, checkTotals]);

  const viewQuestionAnswer = (questionId: string) => {
    const questionApi = new QuestionApi(new ApiCaller());
    questionApi
      .getAnswer(questionId)
      .then((answer: string) => {
        setQuestionAnswer(answer);
        setModal(true);
      })
      .catch(() => {
        toast.error('Error displaying answer');
      });
  };

  const openExamDocument = (candidateId: string) => {
    const document = docs.filter((doc) => doc.type === candidateId);

    if (document.length) {
      setDocumentSrc(document[0]);
      setModalDocument(true);
    } else {
      setError('Exam script has not been uploaded yet.');
    }
  };

  useEffect(() => {
    setExam(Settings.getCurrentNavigationExam());
    setGrid(markerGrid);
    const totals = {};

    for (const k in markerGrid) {
      const m = markerGrid[k];
      totals[m['id']] = calcCurrentMarkers(m);
    }

    setChecksumTotal(totals);
    setManualTotal(totals);
  }, [markerGrid]);

  const close = () => {
    setError(undefined);
  };

  return (
    <>
      <div className="content">
        <LoadingSpinner
          spinning={submitting || loading}
          tip={submitting ? 'Submitting marks...' : 'Saving marks...'}
          delay={loading ? 700 : 0}
        >
          <Row>
            <Col className="text-center" lg="12" md="12">
              <ExamMarkDetail
                presenter={presenter}
                exam={exam}
                grid={grid}
                currentDoc={markerReport}
                onSaveMarks={update}
                onDownloadExam={() =>
                  downloadExamByExamId(
                    exam?.examId!,
                    exam?.getExamNumber(),
                    loggedUser.token,
                  )
                }
                onDownloadAnswers={() =>
                  downloadAnswers(exam?.examId!, exam?.getExamNumber())
                }
                onSubmitUploading={(doc: nzDocument) => setMarkerReport(doc)}
                checkErrors={checkErrors}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-center" lg="12" md="12">
              <Card>
                <CardBody className="marks-table-card">
                  {exam && <ExamMarkTable
                    exam={exam}
                    grid={grid}
                    historicalGrid={historicalGrid}
                    questions={questions}
                    docs={docs}
                    results={results}
                    manualTotal={manualTotal}
                    onChangeManualTotal={setManualTotal}
                    onViewQuestionAnswer={viewQuestionAnswer}
                    onOpenExamDocument={openExamDocument}
                    onSetFieldParams={setFieldParams}
                    onSetCandidateVoid={setCandidateVoid}
                  /> }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </LoadingSpinner>
      </div>
      <Modal isOpen={modal}>
        <div>
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setModal(false)}>
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h4 className="card-title">Question answer</h4>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                <div dangerouslySetInnerHTML={{ __html: questionAnswer }} />
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalDocument}
        size="lg"
        style={{ maxWidth: '800px', width: '100%' }}>
        <div>
          <div className="modal-header">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setModalDocument(false)}>
              <i className="nc-icon nc-simple-remove" />
            </button>
            <h5 className="card-title">Candidate Exam</h5>
            <Button
              id={`download_${documentSrc.fileHash}`}
              className={'btn-sm'}
              type="button"
              onClick={() =>
                downloadDocument(
                  documentSrc.fileHash,
                  documentSrc.displayName,
                  loggedUser.token,
                )
              }>
              Download Script
            </Button>
          </div>
          <div className="modal-body">
            <FileViewer
              fileType={`${documentSrc.mimeType.split('/')[1]}`}
              filePath={`${Settings.getApiURL()}/v1/documents/document/download/${
                documentSrc.fileHash
              }?token=${loggedUser.token}`}
              onError={() => {
                console.log('Error loading PDF');
              }}
            />
          </div>
        </div>
      </Modal>
      {error && (
        <ReactBSAlert
          danger
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error processing script"
          onConfirm={() => close()}
          onCancel={() => close()}
          confirmBtnBsStyle="danger"
          btnSize="">
          {error}
        </ReactBSAlert>
      )}
      <ToastContainer />
    </>
  );
};

export default ExamMarkViewOnly;
